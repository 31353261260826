import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/centrosColaboradores';
import CrearCentros from './CrearCentros';

const ms2p = (state) => {
    return {
        ...state.centrosColaboradores,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CrearCentros);
