import React, { Component } from 'react'
import Card from "Utils/Cards/Card"
import CardForm from 'Utils/Cards/CardForm';
import Header from "Utils/Grid/Header";
import { Link } from 'react-router-dom';

import Actual from './Actual';
import Anterior from './Anterior';

import moment from 'moment';

export default class Bitacora extends Component{

    componentWillMount() {
        this.props.leer(this.props.match.params.id);
    }

    render() {
        const { item, loader } = this.props; //Constantes

        return(
            <div className="mb-4 col-12 mt-4">
                <Header/>
                <CardForm col="11">
                    <div className="d-flex flex-column flex-1 mb-4">
                        <span className="text-sm azul-claro bold">Detalle de bitacora</span>
                        <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px' }} />
                    </div>
                    <div className="d-flex flex-lg-row flex-column px-3">
                        <div className="d-flex flex-column flex-1">
                            <span className="bold">Fecha</span>
                            <span>{item ? moment(item.creado).format("DD/MM/YYYY HH:mm") : ''}</span>
                        </div>
                        <div className="d-flex flex-column flex-1">
                            <span className="bold">Usuario</span>
                            <span>{item ? item.usuario ? item.usuario.titulo : '' : ''}</span>
                        </div>
                        <div className="d-flex flex-column flex-1">
                            <span className="bold">Acción</span>
                            <span>{item ? item.accion : ''}</span>
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-1 my-4">
                        <span className="text-sm azul-claro bold">Resumen</span>
                        <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px' }} />
                    </div>
                    <div className="d-flex flex-column flex-1 flex-lg-row">
                        {!!item.detalle_anterior && (
                            <div className="flex-1 pr-4" style={{overflowX: "auto"}}>
                                <Anterior detalle={JSON.parse(item.detalle_anterior)}/>
                                {/* dfd */}
                            </div>
                        )}
                        {!!item.detalle && (
                            <div className="flex-1 pr-4" style={{overflowX: "auto"}}>
                                <Actual detalle={JSON.parse(item.detalle)} anterior={JSON.parse(item.detalle_anterior)} />
                                {/* df */}
                            </div>
                        )}
                    </div>
                    <div className="d-flex flex-row flex-1 justify-content-center mt-5 mb-3">
                        <Link className="btn btn-secondary" to="/bitacora">Regresar</Link>
                    </div>
                </CardForm>
            </div>
        )
    }
}
