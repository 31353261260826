import React, {Fragment} from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { renderField, SelectField, renderNumber, renderEditorField, renderFieldRadio2} from '../../../Utils/renderField/renderField';
import { api } from "api";
import CardEmpty from "Utils/Cards/CardEmpty";
import { tipos_preguntas, EJERCICIO, EXAMEN, RESPUESTA_BREVE, OPC_MULT } from "../../../../../utility/constants";

const validators = (values, props) => {
    let errors = {};
    if(values){
        if(!values.titulo){
            errors.titulo="Campo requerido";  
        }
        if(values.preguntas && values.preguntas.length){
            const preguntas =[];
            values.preguntas.forEach((pregunta, indexP)=>{
                
                if(!pregunta.respuesta_correcta && pregunta.respuesta_correcta!=0 &&pregunta.tipo==20){
                    preguntas[indexP] = { pregunta: "Debe marcar una respuesta correcta"}
                }
            });
            errors.preguntas=preguntas;
        }
    }
    return errors;
};
const renderRespuestas = ({fields, marcarRespuesta, pregunta_field}) => {
    if (!fields.length){
        fields.push({});
    }
    return (
        <div className="col-12 px-0">
            {fields.map((respuesta, index) => {
                return(
                <div key={index} className="col-12 mt-3 px-0">
                    <div className="col-md-12 col-sm-12 px-0">
                        <label htmlFor={`${respuesta}.respuesta`} className="m-0">Respuesta {index + 1}</label>
                    </div>
                    <div className="col-md-12 form-group row px-0">
                        <div className="col-7 sin-padding-responsive">
                            <Field name={`${respuesta}.respuesta`} component={renderField} type="text" className="form-control" />
                        </div>
                        <div className="col-5 d-flex align-items-center px-0">
                            {index !== (fields.length - 1) && (
                                <Fragment>
                                    <button className ="btn-delete" onClick={(e) =>{e.preventDefault(); fields.remove(index)}}>
                                        <span className="material-icons rosa">close</span>
                                    </button>
                                    <div className="ml-1">
                                        <Field
                                            label='Ejercicio'
                                            type="radio"
                                            value={index}
                                            name={`${pregunta_field}.respuesta_correcta`}
                                            parse={val => +val}
                                            component={renderFieldRadio2}
                                        />
                                    </div>
                                </Fragment>
                            )}
                            {index === (fields.length - 1) && (
                                <Fragment>
                                    <button className ="btn-delete" onClick={(e) =>{e.preventDefault(); fields.remove(index)}}>
                                        <span className="material-icons rosa">close</span>
                                    </button>
                                    <button className ="btn-delete" onClick={(e) =>{e.preventDefault(); fields.push({})}}>
                                        <span className="material-icons verde">add</span>
                                    </button>
                                    <div className="ml-1">
                                        <Field
                                            label='Ejercicio'
                                            type="radio"
                                            value={index}
                                            name={`${pregunta_field}.respuesta_correcta`}
                                            parse={val => +val}
                                            component={renderFieldRadio2}
                                        />
                                    </div>
                                </Fragment>
                            )}
                        </div>
                    </div>
                </div>
            )})}
        </div>

    )
};

const renderPreguntas = ({fields, updateData, preguntas, preguntas_array, marcarRespuesta}) => {
    if (!fields.length){
        fields.push({tipo: RESPUESTA_BREVE});
    }
    return (
        <div className="row col-12 pr-md-0 mr-md-0">
            <div className="col-md-12 col-12 pr-md-0 mr-md-0 sin-padding-responsive">
                {fields.map((pregunta, index) => {
                    let todos = fields.getAll();
                    const dato_actual = fields.get(index);
                    todos[index]['index'] = index; //Mutar cada fila par agregarle el index
                    return(
                    <div key={index} style={{border: "1px solid #707070", borderRadius: "0.75rem"}} className="mb-3 col-12 py-3 sin-padding-responsive">
                        <div className="col-12 d-flex justify-content-between align-items-center mb-2 px-md-0">
                            <h4 className="">Pregunta #{index + 1}</h4>
                            <button className ="btn-add d-flex justify-content-center align-items-center" onClick={(e) =>{e.preventDefault(); fields.insert(index +1, {tipo: RESPUESTA_BREVE})}}>
                                <span className="material-icons azul-claro">add</span>
                            </button>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <div className="col-md-12 col-12 px-0">
                                    <label htmlFor={`${pregunta}.pregunta`} className="m-0">Pregunta</label>
                                </div>
                                <div className="col-md-12 col-12 px-0">
                                    <Field name={`${pregunta}.pregunta`} component={renderField} type="text" className="form-control" />
                                </div>
                            </div>
                            <div className="col-md-4 col-12 mt-sm-0 mt-3">
                                <div className="col-md-12 col-12 px-0">
                                    <label htmlFor={`${pregunta}.tipo`} className="m-0">Tipo</label>
                                </div>
                                <div className="col-md-12 col-12 px-0">
                                    <Field name={`${pregunta}.tipo`} component={SelectField} options={tipos_preguntas} placeholder="Tipo de pregunta..." type="text" className="form-control" />
                                </div>
                            </div>
                            <div className="col-md-2 col-12 mt-sm-0 mt-3">
                                <div className="col-md-12 col-12 px-0">
                                    <label htmlFor={`${pregunta}.puntaje`} className="m-0">Puntaje</label>
                                </div>
                                <div className="col-md-12 col-12 px-0">
                                    <Field name={`${pregunta}.puntaje`} component={renderNumber} type="number" className="form-control" />
                                </div>
                            </div>
                            {(preguntas_array[index] && preguntas_array[index].tipo) && preguntas_array[index].tipo === OPC_MULT && (
                                <div className="col-12">
                                    <FieldArray
                                        name={`${pregunta}.respuestas`}
                                        component={renderRespuestas}
                                        preguntas={preguntas}
                                        marcarRespuesta={marcarRespuesta}
                                        pregunta_field={pregunta}
                                    />
                                </div>
                            )}
                        </div>
                        {fields.length > 1 && (
                            <div className="col-md-12 col-12 mt-3 pr-md-0 mr-md-0 pl-md-0 ml-md-0">
                                <button type="button" className="btn btn-eliminar btn-block" onClick={(e) =>{e.preventDefault(); fields.remove(index)}}>Eliminar</button>
                            </div>
                        )}
                    </div>
                )})}
            </div>
        </div>
    )
};

let PasoDosForm = props => {
    const { handleSubmit, setStep, ver_password, change_ver_password, previousStep, titulo, preguntas, preguntas_array,marcarRespuesta } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="col-12">
                    <span className="text-sm azul-claro bold">{titulo}</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{height: '2px'}}/>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="titulo" className="m-0">Titulo</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="titulo" component={renderField} type="text"/>
                        </div>
                    </div>
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="notas" className="m-0">Notas</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="notas" height="10%" height2="70%" component={renderEditorField} />
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-3">
                    <span className="text-sm azul-claro bold">Preguntas</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{height: '2px'}}/>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 px-0">
                            <FieldArray
                                name="preguntas"
                                component={renderPreguntas}
                                marcarRespuesta={marcarRespuesta}
                                preguntas={preguntas}
                                preguntas_array={preguntas_array}
                            />
                        </div>
                    </div>
                </div>
                <div className="buttons-box mt-3">
                    <button className="btn btn-secondary mr-5" onClick={previousStep}>Regresar</button>
                    <button type="submit" className="btn btn-primary">Siguiente</button>
                </div>
            </div>
        </form>
    )
};

PasoDosForm = reduxForm({
    form: 'formularioForm',
    destroyOnUnmount: false,
    validate: validators,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    initialValues: {
        preguntas: [{}],
    }
})(PasoDosForm);

const selector = formValueSelector('PasoDosForm');
PasoDosForm = connect(state => {
    const form = state.form.formularioForm;

    let titulo = "";
    let preguntas = 0;
    let preguntas_array = [];
    
    if (form && form.values) {
        const values = form.values;

        // Aqui se evalua que tipo de formulario se elige para mostrar sus datos de creacion.
        if (values.tipo === EJERCICIO) {
            titulo = "Nuevo ejercicio"
        }
        if (values.tipo === EXAMEN) {
            titulo = "Nuevo examen"
        }

        if (values && values.preguntas) {
            if (values.preguntas) {
                preguntas = values.preguntas.length;
                preguntas_array = values.preguntas;
            }
        }
    }
    return {
        titulo,
        preguntas,
        preguntas_array
    }
})(PasoDosForm);

export default PasoDosForm;
