import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form'
import login from './modules/cuenta/login';
import register from './modules/cuenta/register';
import profile from './modules/cuenta/profile';
import usuarios from './modules/usuarios/usuarios';
// import notificaciones from './modules/notificaciones/notificaciones';
import administradores from "./modules/administradores";
import profesores from "./modules/profesores";
import alumnos from "./modules/alumnos";
import configComercial from './modules/config/configComercial';
import configPlataforma from './modules/config/configPlataforma';
import descuentos from './modules/config/descuentos';
import aulas from './modules/aulas';
import aula from './modules/aulas/aula';
import clases from './modules/aulas/clases';
import formsCursos from './modules/aulas/formularios';
import asignaturaNiveles from './modules/asignaturaNiveles';
import archivos from './modules/biblioteca/archivos';
import formularios from './modules/formularios';
import cursosGrupales from './modules/cursosGrupales';
import colaboradores from './modules/colaboradores';
import cupones from './modules/cupones';
import creditosAlumno from './modules/reportes/creditosAlumno';
import puntuaciones from './modules/reportes/puntuaciones';
import reporteCupones from './modules/reportes/cupones';
import horasTrabajadas from './modules/reportes/horasTrabajadas';
import ingresosYGastos from './modules/reportes/ingresosYGastos';
import notificaciones from './modules/reportes/notificaciones';
import facturacionProfesor from './modules/reportes/facturacionProfesor';
import mensajes from './modules/config/mensajes';
import comunicados from './modules/comunicados';
import seriesFacturas from './modules/areaFinanciera/seriesFacturas';
import nominas from './modules/areaFinanciera/nominas';
import facturas from './modules/areaFinanciera/facturas';
import noticiasCampañas from './modules/noticiasCampañas';
import centrosColaboradores from './modules/centrosColaboradores';
import servicios from './modules/servicios';
import bitacora from './modules/bitacora';
import perfil from './modules/perfil';

export default combineReducers({
    form: formReducer,
    login,
    register,
    profile,
    usuarios,
    // notificaciones,
    administradores,
    profesores,
    alumnos,
    configComercial,
    configPlataforma,
    descuentos,
    aulas,
    aula,
    clases,
    formsCursos,
    asignaturaNiveles,
    archivos,
    formularios,
    cursosGrupales,
    colaboradores,
    cupones,
    seriesFacturas,
    nominas,
    comunicados,
    facturas,
    noticiasCampañas,
    creditosAlumno,
    puntuaciones,
    reporteCupones,
    horasTrabajadas,
    ingresosYGastos,
    notificaciones,
    facturacionProfesor,
    mensajes,
    centrosColaboradores,
    servicios,
    bitacora,
    perfil,
    routing,
});
