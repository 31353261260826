import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { Link } from 'react-router-dom';
import { renderDatePicker, renderMonthPicker, renderField, renderNumber, AsyncSelectField, renderFieldCheck, renderFieldRadio2, renderCurrency } from '../../../Utils/renderField';
import Calendario from '../../../Utils/Calendario/Calendario';
import { api } from '../../../../../utility/api';


const getSeriesFacturas = (search) => {
    let params = {}
    if (search) {
        params.search = search;
    }
    return api.get("serie_factura", params).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const renderAlumnos = ({ fields }) => (
    <div className="row col-lg-10 col-12">
        <div className="col-12 px-0">
            {fields.map((alumno, index) => {
                return (
                    <div key={index}>
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor={`${alumno}.titulo`} className="m-0">{`Alumno no. ${index + 1}`}</label>
                        </div>
                        <div className="col-md-12 form-group row">
                            <div className="col-8">
                                <Field name={`${alumno}.titulo`} disabled={true} component={renderField} type="text" className="form-control" />
                            </div>
                            <div className="col-4 d-flex align-items-center px-0">
                                <Field name={`${alumno}.renovar_curso`} component={renderFieldCheck} label=" Renovación del curso"/>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    </div>
);

class Form extends Component {

    render() {

        const { handleSubmit, item } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <div className="form-group grid-container mx-md-5 px-md-5">
                    <div className="col-12">
                        <span className="text-sm azul-claro bold">{`Curso: ${item ? item.nombre : ''}`}</span>
                        <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                    </div>
                </div>
                <div className="form-group grid-container row mx-md-5 px-md-5 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">Nombre del Curso</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombre" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="horas_tutorias" className="m-0">Cantidad maxíma de horas de tutoría</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="horas_tutorias" component={renderNumber} type="text" suffix=" hora(s)" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="form-group grid-container row mx-md-5 px-md-5 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="fecha_inicio" className="m-0">Fecha inicio</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="fecha_inicio" component={renderDatePicker} />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="fecha_fin" className="m-0">Mes de finalización</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="fecha_fin" component={renderMonthPicker} placeholder="Seleccione un mes..." />
                        </div>
                    </div>
                </div>
                <div className="form-group grid-container row mx-md-5 px-md-5 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="fecha_maxima_pago" className="m-0">Fecha máxima de pago</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="fecha_maxima_pago" component={renderDatePicker} />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="meses_renovacion" className="m-0">Meses a renovar</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="meses_renovacion" component={renderNumber} suffix=" meses" type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="form-group grid-container row mx-md-5 px-md-5 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="creditos_clase" className="m-0">Créditos por clase</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="creditos_clase" component={renderNumber} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="serie_factura" className="m-0">Serie de factura</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                name="serie_factura"
                                loadOptions={getSeriesFacturas}
                                type="text"
                                component={AsyncSelectField}
                                valueKey="id"
                                labelKey="prefijo"
                                isClearable={true}
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group grid-container row mx-md-5 px-md-5 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="precio_hora" className="m-0">Precio por hora</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="precio_hora" component={renderCurrency} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="form-group grid-container row mx-md-5 px-md-5 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12">
                            <Field name="curso_empresa" component={renderFieldCheck} label=" Curso de empresa" />
                        </div>
                        <div className="col-md-12">
                            <Field name="renovar_curso_empresa" component={renderFieldCheck} label=" Renovar curso de empresa" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12">
                            <Field name="curso_calificable" component={renderFieldCheck} label=" Curso calificable" />
                        </div>
                        <div className="col-md-12">
                            <Field name="tiene_iva" component={renderFieldCheck} label=" Agregar iva" onCambio={this.props.calcularTotales} />
                        </div>
                    </div>
                </div>

                <div className="form-group grid-container row mx-md-5 px-md-5 mt-3">
                    <div className="col-12 mt-3">
                        <span className="text-sm azul-claro bold">Alumnos</span>
                        <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                    </div>
                    <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                        <div className="col-md-12 p-0">
                            <div className="col-md-12 px-0">
                                <FieldArray name="asignados" component={renderAlumnos} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-group grid-container">
                    <div className="d-flex flex-1 w-100" style={{ overflowX: "auto" }}>
                        <Calendario
                            eventos={this.props.horarios}
                            toolbar={true}
                            onlySemana={true}
                            onSelectSlot={this.props.openModal}
                            onSelectEvent={this.props.eliminarReserva}
                        />
                    </div>
                    <div className="d-none d-lg-flex buttons-box mt-5">
                        <Link className="btn btn-secondary mr-3" to="/cursos_individuales">Cancelar</Link>
                        <button type="submit" className="btn btn-primary ml-3">Guardar</button>
                    </div>
                    <div className="d-flex d-lg-none flex-column mt-5">
                        <Link className="w-100 btn btn-secondary mb-3" to="/cursos_individuales">Cancelar</Link>
                        <button type="submit" className="w-100 btn btn-primary">Guardar</button>
                    </div>
                </div>
            </form>
        );
    }
};

const selector = formValueSelector('EditarAulaForm');

const EditarAulaForm = reduxForm({
    form: 'EditarAulaForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()('Campo requerido.'),
            serie_factura: validators.exists()('Campo requerido.'),
            horas_tutorias: validators.exists()('Campo requerido.'),
            fecha_maxima_pago: validators.exists()('Campo requerido.'),
            fecha_inicio: validators.exists()('Campo requerido.'),
            fecha_fin: validators.exists()('Campo requerido.'),
            // meses_renovacion: validators.exists()('Campo requerido.'),
            creditos_clase: validators.exists()('Campo requerido.'),
        });
    },
})(Form);

const mstp = state => {
    let horario = selector(state, 'horario');

    horario = horario ? horario.length : 0;

    return {
        horario
    }
};

export default connect(mstp, null)(EditarAulaForm);
