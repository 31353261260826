import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import _ from "lodash";
import {reset} from 'redux-form';
import moment from 'moment';

const LOADER = 'LOADER_NOMINAS';
const DATA = 'DATA_NOMINAS';
const ITEM_DATA = 'ITEM_NOMINAS';
const PAGE = 'PAGE_NOMINAS';
const ORDERING = 'ORDERING_NOMINAS';
const SEARCH = 'SEARCH_NOMINAS';
const FILTRO_MES_INICIO_NOMINAS = 'FILTRO_MES_INICIO_NOMINAS';
const FILTRO_MES_FIN_NOMINAS = 'FILTRO_MES_FIN_NOMINAS';
// DATOS DE EDICION DE LA NOMINA
const DATOS_NOMINAS = 'EDITAR_NOMINAS';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().nominas;
    const params = { page };
    if (resource.ordering) {
        params.ordering = resource.ordering;
    }
    if (resource.search) {
        params.search = resource.search;
    }
    params.fecha_inicio = moment(resource.mes_inicio_filtro_nominas).startOf('month').format('YYYY-MM-DD')
    params.fecha_fin = moment(resource.mes_fin_filtro_nominas).endOf('month').format('YYYY-MM-DD')
    dispatch(setLoader(true));
    api.get('nomina', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const getProfesores = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.get('nomina/get_profesores_nomina').then((response) => {
        const data = response;
        data.mes_anio = moment();
        dispatch(initializeForm('NominaForm', data));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${'nomina'}/${id}`).then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm('NominaForm', response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    const _data = data;
    _data.mes_anio = moment(data.mes_anio).startOf('month').format('YYYY-MM-DD');
    dispatch(setLoader(true));
    api.post('nomina', _data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(cleanForm()); // Limpiar el formulario
        dispatch(push('/nominas'));
    }).catch((error) => {
        let mensaje = "Error en la creación";
        if (error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const editar = (data, id) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${'nomina'}/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push('/nominas'));
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${'nomina'}/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('NominaForm', {}));
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().nominas.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const filtroMesInicioNominas = mes_inicio_filtro_nominas => (dispatch) => {
    dispatch({type: FILTRO_MES_INICIO_NOMINAS, mes_inicio_filtro_nominas})
    dispatch(listar());
};

const filtroMesFinNominas = mes_fin_filtro_nominas => (dispatch) => {
    dispatch({type: FILTRO_MES_FIN_NOMINAS, mes_fin_filtro_nominas})
    dispatch(listar());
};

export const descargar = () => (dispatch, getStore) => {
    const resource = getStore().nominas;
    const fecha_inicio = moment(resource.mes_inicio_filtro_nominas).startOf('month').format('YYYY-MM-DD')
    const fecha_fin = moment(resource.mes_fin_filtro_nominas).endOf('month').format('YYYY-MM-DD')
    const url = `/api/nomina/descargar_nominas/?fecha_inicio=${fecha_inicio}&fecha_fin=${fecha_fin}&auth_token=${sessionStorage.getItem("token")}`
    window.location.replace(url);
};

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    cleanForm,
    descargar,
    getProfesores,
    // Filtros en las nominas
    filtroMesInicioNominas,
    filtroMesFinNominas
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    // Filtros en las nominas
    [FILTRO_MES_INICIO_NOMINAS]: (state, { mes_inicio_filtro_nominas }) => {
        return {
            ...state,
            mes_inicio_filtro_nominas,
        };
    },
    [FILTRO_MES_FIN_NOMINAS]: (state, { mes_fin_filtro_nominas }) => {
        return {
            ...state,
            mes_fin_filtro_nominas,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    mes_inicio_filtro_nominas: moment().startOf('month').subtract(3, 'M'),
    mes_fin_filtro_nominas: moment().startOf('month'),
    datos_nominas: [],
};

export default handleActions(reducers, initialState);
