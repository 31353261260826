import React, { Component, Fragment } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import {ProgressBar, Step} from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";

import PasoUnoForm from './PasoUnoForm';
import PasoDosForm from './PasoDosForm';
import PasoTresForm from './PasoTresForm';

const check = require("assets/img/icons/check.png");

export default class CursoGrupalCrear extends Component {
    state = {
        step: 1,
        editar: false,
        fotografia: null,
        fotografia2: null,
        ver_foto: null,
        ver_foto2: null,
    };

    componentWillMount() {
        this.props.cleanForm();
        if (this.props.match.params.id) {
            this.props.leer(this.props.match.params.id).then(() => {
                if (this.props.item.fotografia) {
                    this.setState({ ver_foto: this.props.item.fotografia });
                    this.setState({ ver_foto2: this.props.item.foto_profesor });
                }
                this.setState({
                    editar: true,
                });
            });
        }
    }

    componentWillUnmount() {
        this.props.cleanForm();
        this.setState({ ver_foto: null, ver_foto2: null })
    }

    setFotografia = (fotografia) => {
        this.setState({ fotografia });
    };

    setFotografia2 = (fotografia) => {
        this.setState({ fotografia2: fotografia });
    };

    nextStep = () => {
        this.setState({ step: this.state.step + 1 })
    };

    previousStep =() => {
        this.setState({ step: this.state.step - 1 })
    };

    actualizar = (data) => {
        const body = { ...data }
        body.asignatura = body.asignatura.id;
        if (body.centro_colaborador)
            delete body.centro_colaborador;

        body.precio = parseFloat(body.precio_sin_iva);
        if (body.iva) {
            body.precio = body.precio + ( body.precio * parseFloat(body.porcentaje_iva))
        }
        const array = [];
        array.push({ "file": this.state.fotografia, "name": "fotografia" })
        array.push({ "file": this.state.fotografia2, "name": "foto_profesor" })
        this.props.editar(body, this.props.match.params.id, array)
    };

    crear = (data) => {
        const body = { ...data }
        body.asignatura = body.asignatura.id;
        body.precio = parseFloat(body.precio_sin_iva);
        if (body.centro_colaborador)
            body.centro_colaborador = body.centro_colaborador.id;

        if (body.iva) {
            body.precio = body.precio + ( body.precio * parseFloat(body.porcentaje_iva))
        }
        const array = [];
        array.push({ "file": this.state.fotografia, "name": "fotografia" })
        array.push({ "file": this.state.fotografia2, "name": "foto_profesor" });

        console.log("body ", body);
        this.props.crear(body, array)
    }

    render() {
        const { updateData, loader, item } = this.props;
        const { step, editar, ver_foto, ver_foto2 } = this.state;
        return (
            <div className="mb-4 col-12 mt-4">
                <div className="mb-5 mt-5 col-12 row d-flex justify-content-center">
                    <div className="col-sm-8 col-10 px-0">
                        <ProgressBar
                            percent={step === 1 ? 0 : step === 2 ? 50 : 100}
                            filledBackground="#686A75"
                            unfilledBackground="#686A75"
                            stepPositions={[0, 50, 100]}
                            height={5}
                        >
                            <Step transition="scale">
                                {({ accomplished, index, position }) => (
                                    <div style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }} className="mt-3">
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            {index + 1 === step ?
                                                <div className="step-number">
                                                    <div className="step-dot" />
                                                </div>
                                                :
                                                (!!accomplished ?
                                                    <div className="step-checked d-flex align-items-center justify-content-center">
                                                        <img src={check} alt="" width="15" height="15" />
                                                    </div>
                                                    :
                                                    <div className="step-number">
                                                    </div>
                                                )
                                            }
                                            <span className={`mt-2 ${index + 1 === step ? 'azul' : (!!accomplished ? 'azul ocultar-texto-movil' : 'gris-oscuro ocultar-texto-movil')}`}>Datos generales</span>
                                        </div>
                                    </div>
                                )}
                            </Step>
                            <Step transition="scale">
                                {({ accomplished, index, position }) => (
                                    <div style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }} className="mt-3">
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            {index + 1 === step ?
                                                <div className="step-number">
                                                    <div className="step-dot" />
                                                </div>
                                                :
                                                (!!accomplished ?
                                                    <div className="step-checked d-flex align-items-center justify-content-center">
                                                        <img src={check} alt="" width="15" height="15" />
                                                    </div>
                                                    :
                                                    <div className="step-number">
                                                    </div>
                                                )
                                            }
                                            <span className={`mt-2 ${index + 1 === step ? 'azul' : (!!accomplished ? 'azul ocultar-texto-movil' : 'gris-oscuro ocultar-texto-movil')}`}>Datos profesor</span>
                                        </div>
                                    </div>
                                )}
                            </Step>
                            <Step transition="scale">
                                {({ accomplished, index, position }) => (
                                    <div style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }} className="mt-3">
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            {index + 1 === step ?
                                                <div className="step-number">
                                                    <div className="step-dot" />
                                                </div>
                                                :
                                                (!!accomplished ?
                                                    <div className="step-checked d-flex align-items-center">
                                                        <img src={check} alt="" width="15" height="15" />
                                                    </div>
                                                    :
                                                    <div className="step-number">
                                                    </div>
                                                )
                                            }
                                            <span className={`mt-2 ${index + 1 === step ? 'azul' : (!!accomplished ? 'azul ocultar-texto-movil' : 'gris-oscuro ocultar-texto-movil')}`}>Contenido</span>
                                        </div>
                                    </div>
                                )}
                            </Step>
                        </ProgressBar>
                    </div>
                </div>
                <CardForm col={10}>
                    <LoadMask loading={loader} blur>
                        <Fragment>
                            {step === 1 && (<PasoUnoForm onSubmit={this.nextStep} editando={editar} setFotografia={this.setFotografia} foto={ver_foto} changeIva={this.props.changeIva} />)}
                            {step === 2 && (<PasoDosForm onSubmit={this.nextStep} cancelar={this.previousStep} editando={editar} setFotografia={this.setFotografia2} foto={ver_foto2} />)}
                            {step === 3 && (<PasoTresForm onSubmit={editar ? this.actualizar : this.crear} cancelar={this.previousStep} editando={editar}/>)}
                        </Fragment>
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
