import React from 'react';
import {
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

import {Login, Profile, Registro} from './common/components/LoginRegister';
import sendRecoverPassword from './common/components/LoginRegister/RecoverPassword/SendRecovery';
import PassRecover from './common/components/LoginRegister/RecoverPassword/ChangePassword/';
import Demo from './common/components/Demo/Demo';
import ProtectedRoute from './ProtectedRoute';
import Examples from './common/components/Examples/Basic';
import NotFound from './common/components/layout/NotFound/NotFound';

import '../assets/fonts/fonts.css';

require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
import 'bootstrap/dist/css/bootstrap.min.css';
import Grids from "./common/components/Examples/Grids";
// import Notificaciones from './common/components/Examples/Notificaciones';
import ExampleTabs from './common/components/Examples/Tabs/Tabs';
import Calendar from './common/components/Examples/Calendar/Calendar';
require('../style/index.css');

try {require('../assets/firebase-messaging-sw')}catch (e) {
    console.error('Firebase messaging', e)
}

// ADMINISTRADORES
import { AdminsList, CrearAdmin } from './common/components/App/Administradores';
// PROFESORES
import { ProfesoresList, CrearProfesor, CalendarioProfesor } from './common/components/App/Profesores';
// ALUMNOS
import { AlumnosList, AlumnoCrear } from './common/components/App/Alumnos';
// COLABORADORES
import { ColaboradoresGrid, ColaboradorCrear, ColaboradorEditar } from './common/components/App/Colaboradores';
// CONFIGURACIONES
import { MensajeBienvenida, ListarLogos, CrearLogo } from './common/components/App/ConfigComercial';
import { ConfigPlataforma } from './common/components/App/ConfigPlataforma';
import { ListarMensajes, MensajeCrear } from './common/components/App/Mensajes';
// ASIGNATURAS Y NIVELES
import { ListarAsigNiveles, AsignaturaCrear, NivelCrear } from './common/components/App/AsignaturasNiveles';
// EXAMENES Y EJERCICIOS
import { FormulariosList, FormularioCrear } from './common/components/App/EjerciciosExamenes';
// BIBLIOTECA - ARCHIVOS
import { NivelesArchivosGrid, SubirArchivos, BibliotecaArchivos } from './common/components/App/Archivos';
// CURSOS INDIVIDUALES
import { CursosIndividualesGrid, CrearCursosIndividuales, EditarAula } from './common/components/App/CursosIndividuales';
// CURSOS GRUPALES
import { CursosGrupalesList, CursoGrupalCrear, SeccionCrear } from './common/components/App/CursosGrupales';
// CUPONES
import { CuponesList, CuponCrear, VerCupon } from './common/components/App/Cupones';
// SERIES FACTURAS
import { SeriesFacturasList, SerieFacturaCrear } from './common/components/App/SeriesFacturas';
// NOMINAS
import {NominasList, NominaCrear} from './common/components/App/Nominas'
// CURSOS GENERAL
import { FormsCursosGrid, DetalleFormulario, RecuperacionesAdmin, CambioProfesor, DetalleClase } from './common/components/App/Cursos';
// REPORTES
import { ReporteCreditosAlumno, PuntuacionesClases, ReporteCupones, ReporteHorasTrabajadas, DetalleHorasTrabajadas, NotificacionesGrid, ReporteFacturacionProfesor, ReporteIngresosYGastos } from './common/components/App/Reportes';
// PERFIL
import { Perfil, EditarPerfil } from './common/components/App/Perfil';
// NOTICIAS Y CAMPAÑAS
import { NoticiasCampañasList, NoticiasCampañasCrear } from './common/components/App/NoticiasCampañas';
// COMUNICADOS
import { ComunicadosList, ComunicadoCrear, VerComunicado } from './common/components/App/Comunicados';
// FACTURAS
import { ListarFacturas, CrearFactura } from './common/components/App/Facturas';
// CENTROS COLABORADORES
// SERVICIOS
import { CrearServicio, ListarServicios } from './common/components/App/Servicios';
import { ListarCentros, CentrosForm } from './common/components/App/CentrosColaboradores';
import { CrearDescuento, ListarDescuentos } from './common/components/App/Descuentos';
import { Bitacora, DetalleBitacora } from './common/components/App/Bitacora';

// ICONOS
const inicio_azul = require("assets/img/icons/inicio_azul.png");
const conf_azul = require("assets/img/icons/conf_azul.png");
const dep_comercial_azul = require("assets/img/icons/dep_comercial_azul.png");
const gestion_azul = require("assets/img/icons/asignatura_azul.png");
const biblioteca_azul = require("assets/img/icons/biblioteca_azul.png");
const cursos_azul = require("assets/img/icons/cursos_azul.png");
const reportes_azul = require("assets/img/icons/reportes_azul.png");
const perfil_azul = require("assets/img/icons/perfil_azul.png");
const euro_azul = require("assets/img/icons/euro_azul.png");
const mensaje_azul = require("assets/img/icons/mensaje_azul.png");

module.exports = (
    <div>
        <div className="container__content">
            <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/registro" component={Registro} />
                <Route exact path="/recuperar_pass" component={sendRecoverPassword}/>
                <Route exact path="/cambiar_pass/:token" component={PassRecover}/>
                <ProtectedRoute exact path="/" component={Demo} title={"Inicio"} icon={inicio_azul}/>

                {/* ADMINISTRADORES */}
                <ProtectedRoute exact path="/administradores" component={AdminsList} title={"Administración / "} subtitle={"Administradores"} icon={conf_azul}/>
                <ProtectedRoute exact path="/administrador/crear" component={CrearAdmin} title={"Administración / "} subtitle={"Administradores / Nuevo"} icon={conf_azul}/>
                <ProtectedRoute exact path="/administrador/:id/editar" component={CrearAdmin} title={"Administración / "} subtitle={"Administrador"} icon={conf_azul}/>

                {/* PROFESORES */}
                <ProtectedRoute exact path="/profesores" component={ProfesoresList} title={"Administración / "} subtitle={"Profesores"} icon={conf_azul}/>
                <ProtectedRoute exact path="/profesor/crear" component={CrearProfesor} title={"Administración / "} subtitle={"Profesores / Nuevo"} icon={conf_azul}/>
                <ProtectedRoute exact path="/profesor/:id/editar" component={CrearProfesor} title={"Administración / "} subtitle={"Profesor"} icon={conf_azul}/>
                <ProtectedRoute exact path="/profesor/:id/calendario" component={CalendarioProfesor} title={"Administración / "} subtitle={"Profesor / Calendario"} icon={conf_azul}/>

                {/* ALUMNOS */}
                <ProtectedRoute exact path="/alumnos" component={AlumnosList} title={"Administración / "} subtitle={"Alumnos"} icon={conf_azul}/>
                <ProtectedRoute exact path="/alumno/crear" component={AlumnoCrear} title={"Administración / "} subtitle={"Alumnos / Nuevo"} icon={conf_azul}/>
                <ProtectedRoute exact path="/alumno/:id/editar" component={AlumnoCrear} title={"Administración / "} subtitle={"Alumnos / Editar"} icon={conf_azul}/>

                {/* CONFIG PLATAFORMA */}
                <ProtectedRoute exact path="/config_plataforma" component={ConfigPlataforma} title={"Configuración / "} subtitle={"Plataforma"} icon={conf_azul}/>

                {/* COLABORADORES */}
                <ProtectedRoute exact path="/colaboradores" component={ColaboradoresGrid} title={"Administración / "} subtitle={"Colaboradores"} icon={conf_azul}/>
                <ProtectedRoute exact path="/colaborador/crear" component={ColaboradorCrear} title={"Administración / "} subtitle={"Colaboradores / Nuevo"} icon={conf_azul}/>
                <ProtectedRoute exact path="/colaborador/:id/editar" component={ColaboradorEditar} title={"Administración / "} subtitle={"Colaboradores / Editar"} icon={conf_azul}/>

                {/* CENTROS COLABORADORES */}
                <ProtectedRoute exact path="/centros_colaboradores" component={ListarCentros} title={"Administración / "} subtitle={"Centros Colaboradores"} icon={conf_azul}/>
                <ProtectedRoute exact path="/centros_colaboradores/crear" component={CentrosForm} title={"Administración / "} subtitle={"Centros Colaboradores / Nuevo"} icon={conf_azul}/>
                <ProtectedRoute exact path="/centros_colaboradores/:id/editar" component={CentrosForm} title={"Administración / "} subtitle={"Centros Colaboradores / Editar"} icon={conf_azul}/>


                {/* DEPARATMENTO COMERCIAL */}
                <ProtectedRoute exact path="/logos" component={ListarLogos} title={"Depto. Comercial / "} subtitle={"Logos"} icon={dep_comercial_azul}/>
                <ProtectedRoute exact path="/logo/crear" component={CrearLogo} title={"Depto. Comercial / "} subtitle={"Logos / Nuevo"} icon={dep_comercial_azul}/>
                <ProtectedRoute exact path="/logo/:id/editar" component={CrearLogo} title={"Depto. Comercial / "} subtitle={"Logos / Editar"} icon={dep_comercial_azul}/>
                <ProtectedRoute exact path="/comercial/mensaje" component={MensajeBienvenida} title={"Depto. Comercial / "} subtitle={"Textos de la pagina"} icon={dep_comercial_azul}/>
                <ProtectedRoute exact path="/mensajes" component={ListarMensajes} title={"Gestión de mensajes profesor"} icon={dep_comercial_azul}/>
                <ProtectedRoute exact path="/mensajes/crear" component={MensajeCrear} title={"Gestión de mensajes profesor / "} subtitle={"Nuevo"} icon={dep_comercial_azul}/>
                <ProtectedRoute exact path="/mensajes/:id/editar" component={MensajeCrear} title={"Gestión de mensajes profesor / "} subtitle={"Editar"} icon={dep_comercial_azul}/>
                <ProtectedRoute exact path="/comercial/descuentos" component={ListarDescuentos} title={"Depto. Comercial / "} subtitle={"Descuentos"} icon={dep_comercial_azul}/>
                <ProtectedRoute exact path="/comercial/descuento/crear" component={CrearDescuento} title={"Depto. Comercial / "} subtitle={"Descuentos / Nuevo"} icon={dep_comercial_azul}/>
                <ProtectedRoute exact path="/comercial/descuento/:id/editar" component={CrearDescuento} title={"Depto. Comercial / "} subtitle={"Descuentos / Editar"} icon={dep_comercial_azul}/>

                {/* ASIGNATURAS Y NIVELES */}
                <ProtectedRoute exact path="/asignaturas_niveles" component={ListarAsigNiveles} title={"Gestión asignaturas y niveles"} icon={biblioteca_azul}/>
                <ProtectedRoute exact path="/asignatura/crear" component={AsignaturaCrear} title={"Gestión asignaturas y niveles / "} subtitle={"Nueva asignatura"} icon={biblioteca_azul}/>
                <ProtectedRoute exact path="/asignatura/:id/editar" component={AsignaturaCrear} title={"Gestión asignaturas y niveles / "} subtitle={"Editar asignatura"} icon={biblioteca_azul}/>
                <ProtectedRoute exact path="/nivel/crear" component={NivelCrear} title={"Gestión asignaturas y niveles / "} subtitle={"Nuevo nivel"} icon={biblioteca_azul}/>
                <ProtectedRoute exact path="/nivel/:id/editar" component={NivelCrear} title={"Gestión asignaturas y niveles / "} subtitle={"Editar nivel"} icon={biblioteca_azul}/>

                {/* EJERCICIOS Y EXAMENES */}
                <ProtectedRoute exact path="/formularios" component={FormulariosList} title={"Ejercicios y exámenes"} subtitle={""} icon={biblioteca_azul}/>
                <ProtectedRoute exact path="/formulario/crear" component={FormularioCrear} title={"Ejercicios y exámenes / "} subtitle={"Nuevo"} icon={biblioteca_azul}/>
                <ProtectedRoute exact path="/formulario/:id/editar" component={FormularioCrear} title={"Ejercicios y exámenes / "} subtitle={"Editar"} icon={biblioteca_azul}/>

                {/* BIBLIOTECA - ARCHIVOS */}
                {/* <ProtectedRoute exact path="/biblioteca_archivos" component={NivelesArchivosGrid} title={"Gestión de archivos"} icon={biblioteca_azul}/> */}
                <ProtectedRoute exact path="/biblioteca_archivos/:id" component={BibliotecaArchivos} title={"Gestión de archivos"} icon={biblioteca_azul}/>
                <ProtectedRoute exact path="/biblioteca_archivos/:id/crear" component={SubirArchivos} title={"Gestión de archivos / "} subtitle={"Nuevo"} icon={biblioteca_azul}/>

                {/* CURSOS INDIVIDUALES */}
                <ProtectedRoute exact path="/cursos_individuales" component={CursosIndividualesGrid} title={"Cursos / "} subtitle={"Individuales"} icon={cursos_azul}/>
                <ProtectedRoute exact path="/curso_individual/crear" component={CrearCursosIndividuales} title={"Cursos / "} subtitle={"Individuales / Nuevo"} icon={cursos_azul}/>
                <ProtectedRoute exact path="/curso/:id/editar" component={EditarAula} title={"Cursos / "} subtitle={"Editar"} icon={cursos_azul}/>

                {/* CURSOS GRUPALES */}
                <ProtectedRoute exact path="/cursos_grupales" component={CursosGrupalesList} title={"Cursos / "} subtitle={"Grupales"} icon={cursos_azul}/>
                <ProtectedRoute exact path="/curso_grupal/crear" component={CursoGrupalCrear} title={"Cursos / "} subtitle={"Grupales / Nuevo"} icon={cursos_azul}/>
                <ProtectedRoute exact path="/curso_grupal/:id/editar" component={CursoGrupalCrear} title={"Cursos /"} subtitle={"Grupales / Editar"} icon={cursos_azul}/>

                {/* CURSOS */}
                <ProtectedRoute exact path="/cursos/formularios" component={FormsCursosGrid} title={"Cursos / "} subtitle={"Ejercicios y exámenes"} icon={cursos_azul}/>
                <ProtectedRoute exact path="/cursos/formulario/:id" component={DetalleFormulario} title={"Cursos / "} subtitle={"Ejercicios y exámenes / Detalle"} icon={cursos_azul}/>
                <ProtectedRoute exact path="/cursos/recuperaciones" component={RecuperacionesAdmin} title={"Cursos / "} subtitle={"Tiempo de recuperación"} icon={cursos_azul}/>
                <ProtectedRoute exact path="/cursos/:id/reasignar" component={CambioProfesor} title={"Cursos / "} subtitle={"Reasignar alumnos"} icon={cursos_azul}/>
                <ProtectedRoute exact path="/cursos/:id/clase/:id_clase" component={DetalleClase} title={"Cursos / "} subtitle={"Detalle Clase"} icon={cursos_azul}/>

                {/* SECCIONES / CURSOS GRUPALES */}
                <ProtectedRoute exact path="/seccion/crear" component={SeccionCrear} title={"Cursos /"} subtitle={"Grupales / Seccion / Nueva"} icon={cursos_azul}/>
                <ProtectedRoute exact path="/seccion/:id/editar" component={SeccionCrear} title={"Cursos /"} subtitle={"Grupales / Seccion / Editar"} icon={cursos_azul}/>

                {/* CUPONES */}
                <ProtectedRoute exact path="/cupones" component={CuponesList} title={"Cupones"} icon={dep_comercial_azul}/>
                <ProtectedRoute exact path="/cupon/crear" component={CuponCrear} title={"Cupones / "} subtitle={"Nuevo"} icon={dep_comercial_azul}/>
                <ProtectedRoute exact path="/cupon/:id/editar" component={CuponCrear} title={"Cupones /"} subtitle={"Editar"} icon={dep_comercial_azul}/>
                <ProtectedRoute exact path="/cupon/:id" component={VerCupon} title={"Cupones / "} subtitle={"Detalle"} icon={dep_comercial_azul}/>

                {/* SERIES FACTURAS */}
                <ProtectedRoute exact path="/series_facturas" component={SeriesFacturasList} title={"Series para facturas"} icon={euro_azul}/>
                <ProtectedRoute exact path="/serie_factura/crear" component={SerieFacturaCrear} title={"Series para facturas / "} subtitle={" Nuevo"} icon={euro_azul}/>
                <ProtectedRoute exact path="/serie_factura/:id/editar" component={SerieFacturaCrear} title={"Series para facturas /"} subtitle={" Editar"} icon={euro_azul}/>

                {/* Nominas */}
                <ProtectedRoute exact path="/nominas" component={NominasList} title={"Nominas"} icon={euro_azul}/>
                <ProtectedRoute exact path="/nomina/crear" component={NominaCrear} title={"Nominas / "} subtitle={" Nuevo"} icon={euro_azul}/>
                <ProtectedRoute exact path="/nomina/:id/editar" component={NominaCrear} title={"Nominas /"} subtitle={" Editar"} icon={euro_azul}/>

                {/* REPORTES */}
                <ProtectedRoute exact path="/reporte/avance_alumnos" component={ReporteCreditosAlumno} title={"Reportes / "} subtitle={"Avance de alumnos"} icon={reportes_azul}/>
                <ProtectedRoute exact path="/reporte/puntuaciones" component={PuntuacionesClases} title={"Reportes / "} subtitle={"Puntuación de alumnos"} icon={reportes_azul}/>
                <ProtectedRoute exact path="/reporte/cupones" component={ReporteCupones} title={"Reportes / "} subtitle={"Códigos canjeados"} icon={reportes_azul}/>
                <ProtectedRoute exact path="/reporte/horas_trabajadas" component={ReporteHorasTrabajadas} title={"Reportes / "} subtitle={"Horas trabajadas"} icon={reportes_azul}/>
                <ProtectedRoute exact path="/reporte/horas_trabajadas/:fecha" component={DetalleHorasTrabajadas} title={"Reportes / "} subtitle={"Horas trabajadas / Detalle"} icon={reportes_azul}/>
                <ProtectedRoute exact path="/reporte/ingresos_gastos" component={ReporteIngresosYGastos} title={"Reportes / "} subtitle={"Ingresos y gastos"} icon={reportes_azul}/>
                <ProtectedRoute exact path="/reporte/facturacion_profesor" component={ReporteFacturacionProfesor} title={"Reportes / "} subtitle={"Facturación por profesor"} icon={reportes_azul}/>
                <ProtectedRoute exact path="/notificaciones" component={NotificacionesGrid} title={"Notificaciones"} icon={reportes_azul}/>
                <ProtectedRoute exact path="/bitacora" component={Bitacora} title={"Bitacora"} icon={reportes_azul}/>
                <ProtectedRoute exact path="/bitacora/:id" component={DetalleBitacora} title={"Bitacora / "} subtitle={"Detalle"}  icon={reportes_azul}/>

                {/* PERFIL */}
                <ProtectedRoute exact path="/perfil" component={Perfil} title={"Mi perfil"} icon={perfil_azul}/>
                <ProtectedRoute exact path="/perfil/editar" component={EditarPerfil} title={"Mi perfil / "} subtitle={"Editar"} icon={perfil_azul}/>

                {/* NOTICIAS Y CAMPAÑAS */}
                <ProtectedRoute exact path="/noticias_campañas" component={NoticiasCampañasList} title={"Noticias y campañas"} icon={dep_comercial_azul}/>
                <ProtectedRoute exact path="/noticias_campañas/crear" component={NoticiasCampañasCrear} title={"Noticias y campañas / "} subtitle={" Nuevo"} icon={dep_comercial_azul}/>
                <ProtectedRoute exact path="/noticias_campañas/:id/editar" component={NoticiasCampañasCrear} title={"Noticias y campañas /"} subtitle={" Editar"} icon={dep_comercial_azul}/>

                {/* COMUNICADOS */}
                <ProtectedRoute exact path="/comunicados" component={ComunicadosList} title={"Comunicados"} icon={mensaje_azul}/>
                <ProtectedRoute exact path="/comunicado/crear" component={ComunicadoCrear} title={"Comunicados / "} subtitle={" Nuevo"} icon={mensaje_azul}/>
                <ProtectedRoute exact path="/comunicado/:id/editar" component={ComunicadoCrear} title={"Comunicados /"} subtitle={" Editar"} icon={mensaje_azul}/>
                <ProtectedRoute exact path="/comunicado/:id" component={VerComunicado} title={"Comunicados / "} subtitle={"Detalle"} icon={mensaje_azul}/>

                {/* FACTURAS */}
                <ProtectedRoute exact path="/facturas" component={ListarFacturas} title={"Facturas"} icon={euro_azul}/>
                <ProtectedRoute exact path="/factura/crear" component={CrearFactura} title={"Facturas /"} subtitle={" Nuevo"} icon={euro_azul}/>

                {/* SERVICIOS */}
                <ProtectedRoute exact path="/servicios" component={ListarServicios} title={"Servicios"} icon={euro_azul}/>
                <ProtectedRoute exact path="/servicio/crear" component={CrearServicio} title={"Servicios / "} subtitle={"Nuevo"} icon={euro_azul}/>
                <ProtectedRoute exact path="/servicio/:id/editar" component={CrearServicio} title={"Servicios / "} subtitle={"Editar"} icon={euro_azul}/>

                {/* STARTER */}
                <ProtectedRoute exact path="/page2" component={Examples} title={"Basic Components"}/>
                <ProtectedRoute exact path="/user-profile" component={Profile} title={"Profile"} />
                <ProtectedRoute exact path="/grids" component={Grids} title={"Grids"}/>
                {/* <ProtectedRoute exact path="/notifications" component={Notificaciones} title={"Notificaciones"}/> */}
                <ProtectedRoute exact path="/tabs" component={ExampleTabs} title={"Tabs"}/>
                <ProtectedRoute exact path="/calendario" component={Calendar} title={"Calendario"}/>
                {/* NOT FOUND */}
                <Route component={NotFound} />
            </Switch>
        </div>
        <NotificationContainer />
    </div>
);
