import React, { useState, Component } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, AsyncSelectField, renderEditorField } from '../../../Utils/renderField';
import { api } from '../../../../../utility/api';

const getAsignaturas = (search) => {
    let params = {}
    if (search) {
        params.search = search;
    }
    return api.get("asignatura_niveles/listar_asignaturas", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
}


const NivelForm = (props) => {
    const { handleSubmit } = props;
    return (
        <form name="NivelForm" onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="row  col-md-12 p-0 px-md-5">
                    <div className="col-md-6 p-0 ">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="asignatura" className="m-0">Asignatura</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                name="asignatura"
                                loadOptions={getAsignaturas}
                                type="text"
                                component={AsyncSelectField}
                                valueKey="id"
                                labelKey="nombre"
                                isClearable={false}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="col-md-6 p-0 ">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">Nivel</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombre" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-12 p-0">
                        <div className="col-md-12  form-group">
                            <Field name="temario" height="10%" height2="70%" component={renderEditorField} />
                        </div>
                    </div>
                </div>
                <div className="buttons-box mt-5">
                    <Link className="btn btn-secondary mr-5" to="/asignaturas_niveles">Cancelar</Link>
                    <button type="submit" className="btn btn-primary">Guardar</button>
                </div>
            </div>
        </form>
    );
};


export default reduxForm({
    form: 'NivelForm', // a unique identifier for this form
    asyncBlurFields: [],
    validate: data => {
        return validate(data, {
            'nombre': validators.exists()('Campo requerido.'),
            'asignatura': validators.exists()('Campo requerido.'),
        })
    }
})(NivelForm);
