import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/colaboradores';
import ColaboradoresGrid from './ColaboradoresGrid';


const ms2p = (state) => {
  return {
    ...state.colaboradores,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ColaboradoresGrid);
