import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import Card from "Utils/Cards/Card";
import Header from "Utils/Grid/Header";
import {formatActivo} from "Utils/renderField/renderReadField";
import moment from 'moment';

export default class CuponesList extends Component{
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar: onPageChange, onSortChange, eliminar } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return(
            <div className="mb-4 col-12 mt-4">
                <Header to="cupon/crear" textBtn="Nuevo cupón" {...this.props} />
                <Card>
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                    page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='12%'
                            dataFormat={standardActions({ editar: "cupon", ver: "cupon", eliminar})}
                        />
                        <TableHeaderColumn
                            dataField="colaborador"
                            dataSort
                            dataFormat={(cell) => cell ? `${cell.nombres} ${cell.apellidos}` : '-----'}
                        >
                            COLABORADOR
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="centro_colaborador"
                            dataSort
                            dataFormat={(cell) => cell ? cell.nombre : '-----'}
                        >
                            C. COLABORADOR
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipo_cupon_text"
                            dataSort
                        >
                            TIPO DE CUPÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre"
                            dataSort
                        >
                            NOMBRE DEL CUPÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_inicial"
                            dataSort
                            dataFormat={(cell) => cell ? moment(cell).format('DD/MM/YYYY') : ""}
                        >
                            FECHA INICIAL
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_final"
                            dataSort
                            dataFormat={(cell) => cell ? moment(cell).format('DD/MM/YYYY') : ""}
                        >
                            FECHA FINAL
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
