import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField, renderTextArea } from '../../../Utils/renderField/renderField';

const MensajeForm = props => {
    const { handleSubmit } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="titulo" className="m-0">Título del mensaje</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="titulo" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="mensaje" className="m-0">Cuerpo del mensaje al profesor</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="mensaje" component={renderTextArea} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="d-none d-lg-flex buttons-box mt-5">
                    <Link className="btn btn-secondary mr-3" to="/mensajes">Cancelar</Link>
                    <button type="submit" className="btn btn-primary ml-3">Guardar</button>
                </div>
                <div className="d-flex d-lg-none flex-column mt-5">
                    <Link className="w-100 btn btn-secondary mb-3" to="/mensajes">Cancelar</Link>
                    <button type="submit" className="w-100 btn btn-primary">Guardar</button>
                </div>
            </div>
        </form>
    )
};

export default reduxForm({
    form: 'MensajeForm',
    validate: data => {
        return validate(data, {
            'titulo': validators.exists()('Campo requerido.'),
            'mensaje': validators.exists()('Campo requerido.'),
        })
    }
})(MensajeForm);
