import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { AsyncSelectField, renderField, SelectField, renderCurrency, renderNumber, renderPasswordField, renderFieldRadio, renderDatePicker, renderTimeField } from '../../../Utils/renderField/renderField';
import { api } from "api";
import CardEmpty from "Utils/Cards/CardEmpty";
import { generos, EJERCICIO, EXAMEN } from "../../../../../utility/constants";

const getAsignaturas = (search) => {
    return api.get("asignatura_niveles/listar_asignaturas", { search }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};


class PasoUnoCForm extends Component {

    state = {
        niveles : []
    }

    getNiveles = (search) => {
        const { asignatura } = this.props;
        const params = { search, asignatura: asignatura && asignatura.id}
        return api.get("asignatura_niveles", params).then((data) => {
            if (data) {
                this.setState({niveles: data.results});
                return data.results
            };
            return [];
        }).catch(() => {
            return [];
        });
    };

    componentDidUpdate(prevProps) {
        if (this.props.asignatura !== prevProps.asignatura) {
            this.getNiveles('');
        }
    }

    render() {
        const { handleSubmit, editar } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <div className="form-group grid-container">
                    {editar === false && (
                        <Fragment>
                            <div className="col-12">
                                <span className="text-sm azul-claro bold">Selección del tipo de formulario que deseas hacer</span>
                                <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                            </div>
                            <div className="row col-md-12 m-0 d-flex flex-wrap align-items-center px-0 mt-3">
                                <div className="col-md-12 d-flex flex-wrap justify-content-start align-items-center">
                                    <Field
                                        label='Ejercicio'
                                        type="radio"
                                        value={EJERCICIO}
                                        name="tipo"
                                        parse={val => +val}
                                        component={renderFieldRadio}
                                    />
                                    <div className="mt-md-0 mt-2">
                                        <Field
                                            label='Examen'
                                            type="radio"
                                            value={EXAMEN}
                                            name="tipo"
                                            parse={val => +val}
                                            component={renderFieldRadio}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )}
                    <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="asignatura" className="m-0">Asignatura *</label>
                            </div>
                            <div className="col-md-12 form-group">
                                <Field
                                    valueKey="id"
                                    name="asignatura"
                                    labelKey="nombre"
                                    label="nombre"
                                    component={AsyncSelectField}
                                    loadOptions={getAsignaturas}
                                    onCambio={this.props.clearSelect}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="nivel" className="m-0">Nivel *</label>
                            </div>
                            <div className="col-md-12 form-group">
                                <Field
                                    valueKey="id"
                                    name="nivel"
                                    labelKey="nombre"
                                    label="nombre"
                                    component={AsyncSelectField}
                                    loadOptions={this.getNiveles}
                                    defaultOptions={this.state.niveles}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 text-right">
                        <span className="azul-marino text-xsm">* Campos requeridos</span>
                    </div>
                    <div className="buttons-box mt-3">
                        <Link className="btn btn-secondary mr-5" to="/formularios">Cancelar</Link>
                        <button type="submit" className="btn btn-primary">Siguiente</button>
                    </div>
                </div>
            </form>
        )
    }
}


let PasoUnoForm = reduxForm({
    form: 'formularioForm',
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    initialValues: {
        tipo: EJERCICIO,
    },
    validate: data => {
        return validate(data, {
            'tipo': validators.exists()('Campo requerido.'),
            'asignatura': validators.exists()('Campo requerido.'),
            'nivel': validators.exists()('Campo requerido.')
        })
    }
})(PasoUnoCForm);

const selector = formValueSelector('formularioForm');

PasoUnoForm = connect(state => {
    const form = state.form.formularioForm;
    let tipo_ejercicio = true;
    let tipo_examen = false;
    const asignatura = selector(state, 'asignatura')

    if (form && form.values) {
        const values = form.values;

        // Aqui se evalua que tipo de formulario se elige para mostrar sus datos de creacion.
        if (values.tipo === EJERCICIO) {
            tipo_examen = false;
            tipo_ejercicio = true;
        }
        if (values.tipo === EXAMEN) {
            tipo_examen = true;
            tipo_ejercicio = false;
        }
    }
    return {
        tipo_examen,
        tipo_ejercicio,
        asignatura,
    }
})(PasoUnoForm);

export default PasoUnoForm;
