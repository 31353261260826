import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/areaFinanciera/seriesFacturas';
import SeriesFacturasList from './SeriesFacturas';

const ms2p = (state) => {
    return {
        ...state.seriesFacturas,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(SeriesFacturasList);
