import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import DescuentoForm from './DescuentoForm';


export default class CrearDescuento extends Component {

    componentWillMount() {
        const id = this.props.match.params.id;
        if(id){
            this.props.leer(id);
        }
    };

    submit = (values) => {
        const id = this.props.match.params.id;
        const data = {...values};
        data.alumno = data.alumno.id;
        data.aula = data.aula.id;

        if (id) {
            this.props.editar(id, data);
        } else {
            this.props.crear(data);
        }

    }


    render() {
        const { loader, item } = this.props;
        const id = this.props.match.params.id;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm>
                    <LoadMask loading={loader} blur>
                        {(id) ? (
                            (item.alumno) && (
                                <DescuentoForm
                                    onSubmit={this.submit}
                                    id={this.props.match.params.id}
                                    limpiarAula={this.props.limpiarAula}
                                    item={item}
                                />
                            )
                        ) : (
                            <DescuentoForm
                                onSubmit={this.submit}
                                id={this.props.match.params.id}
                                limpiarAula={this.props.limpiarAula}
                                item={item}
                            />
                        )}
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
