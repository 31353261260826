import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card";
import Header from "Utils/Grid/Header";

export default class ListarCentros extends Component{
    componentWillMount() {
        const { listar } = this.props;
        listar();
    }

    render() {
        const { listar: onPageChange, onSortChange, eliminar } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes

        return(
            <div className="mb-4 col-12 mt-4">
                <Header
                    to="centros_colaboradores/crear"
                    textBtn="Agregar centro"
                    {...this.props}
                />
                <Card>
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                    page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='12%'
                            dataFormat={standardActions({ editar: "centros_colaboradores", eliminar})}
                        >
                            ACCIONES
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre"
                            dataSort
                        >
                            NOMBRE DEL CENTRO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="responsable"
                            dataSort
                        >
                            RESPONSABLE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="telefono"
                            dataSort
                        >
                            Teléfono
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nif"
                            dataSort
                        >
                            NIF / CIF
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="email"
                            dataSort
                        >
                            EMAIL
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="direccion"
                            dataSort
                        >
                            DIRECCIÓN
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
