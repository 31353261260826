import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import Card from "Utils/Cards/Card";
import Header from "Utils/Grid/Header";
import {formatActivo} from "Utils/renderField/renderReadField";
import moment from 'moment';

export default class CuponesList extends Component{
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar: onPageChange, onSortChange, eliminar } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return(
            <div className="mb-4 col-12 mt-4">
                <Header to="noticias_campañas/crear" textBtn="Agregar" {...this.props} />
                <Card>
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                    page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='15%'
                            dataFormat={standardActions({ editar: "noticias_campañas", eliminar})}
                        />
                        <TableHeaderColumn
                            dataField="titulo"
                            dataSort
                        >
                            TITULO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_publicacion"
                            dataSort
                            dataFormat={(cell) => cell ? moment(cell).format('DD/MM/YYYY') : "No publicado"}
                        >
                            FECHA PUBLICACIÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="en_slider"
                            dataSort
                            dataFormat={(cell) => cell ? "Slider" : "Noticias"}
                        >
                            UBICACIÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipo_text"
                            dataSort
                        >
                            TIPO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="publicado"
                            dataSort
                            dataFormat={(cell) => cell === true ? (
                                <div className="d-flex flex-row align-items-center">
                                    <div className="circulo bg-aqua mr-1"></div>
                                    <span className="aqua ml-1">Publicado</span>
                                </div>
                            ) : (
                                <div className="d-flex flex-row align-items-center">
                                    <div className="circulo bg-rosa mr-1"></div>
                                    <span className="rosa ml-1">Borrador</span>
                                </div>
                            )}
                        >
                            ESTADO
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
