import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { SelectField, renderTimeField, renderEditorField, renderTextArea } from '../../../Utils/renderField';
import { DIAS_SEMANA, CLASE_CANCELADA, CLASE_INDIVIDUAL, CLASE_RECUPERACION } from '../../../../../utility/constants';
import { api } from 'api';


const EventoDetalleModal = (props) => {
    const { handleSubmit, cancelar, evento, obtenerLink } = props;
    return (
        <form name="AsignacionForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="form-group grid-container mx-md-2">
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-md-5 mt-3">
                    <div className="d-flex flex-row align-items-center w-100">
                        <div className="flex-1">
                            <label htmlFor="dia" className="m-0">Día</label>
                        </div>
                        <div className="flex-1">
                            <Field name="dia" disabled component={SelectField} options={DIAS_SEMANA} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center w-100 mt-2">
                        <div className="flex-1">
                            <label htmlFor="hora_inicio" className="m-0">Hora inicio</label>
                        </div>
                        <div className="flex-1">
                            <Field name="hora_inicio" disabled component={renderTimeField} />
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center w-100 mt-2">
                        <div className="flex-1">
                            <label htmlFor="hora_fin" className="m-0">Hora fin</label>
                        </div>
                        <div className="flex-1">
                            <Field name="hora_fin" disabled component={renderTimeField} />
                        </div>
                    </div>
                </div>
                {
                    (evento) && (evento.tipo_evento != CLASE_CANCELADA) && (
                        <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end mt-2">
                            {
                                (evento) && (evento.contenido) ? (
                                    // <div className="d-flex flex-column w-100 mt-3">
                                    //     <div className="flex-1">
                                    //         <Field name="contenido" height="10rem" disabled component={renderEditorField} />
                                    //     </div>
                                    // </div>
                                    <div className="d-flex flex-column w-100 mt-3">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <button
                                                type="button"
                                                onClick={()=> props.openClase(evento.aula, evento.id)}
                                                // to={`/apuntes/${evento.aula}/clase/${evento.id}`}
                                                className="btn btn-outline-secondary"
                                            >Ver apuntes</button>
                                        </div>
                                        <div className="flex-1">
                                            <Field name="contenido" height="10rem" disabled component={renderEditorField} />
                                        </div>
                                    </div>
                                ) : (
                                        <div className="rd-flex flex-row align-items-center w-100 mt-3">
                                            <h4 className="rosa" style={{ textAlign: "center" }}>NO HAY TEMARIO</h4>
                                        </div>
                                    )
                            }
                        </div>
                    )
                }
                {
                    (evento) && (evento.tipo_evento == CLASE_CANCELADA) ? (
                        <Fragment>
                            <div className="rd-flex flex-row align-items-center w-100 mt-3">
                                <h4 className="rosa" style={{ textAlign: "center" }}>CLASE CANCELADA</h4>
                                <div className="d-flex flex-row align-items-center w-100 my-3">
                                    <div className="flex-1 w-100">
                                        <label style={{ fontSize: "14px" }} className="m-0 mr-3">Usuario:</label>
                                        <label style={{ fontWeight: "600" }} className="m-0">
                                            {evento.usuario_cancelacion.nombres}
                                        </label>
                                    </div>
                                </div>
                                <div className="d-flex flex-column align-items-center w-100">
                                    <div className="flex-1 w-100">
                                        <label style={{ fontSize: "14px" }} className="m-0">Motivo de anulación:</label>
                                    </div>
                                    <div className="flex-1 w-100">
                                        <label style={{ fontWeight: "600" }} className="m-0">
                                            {evento.motivo_anulacion}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    ) : (
                            (evento) && (evento.tipo_evento == CLASE_INDIVIDUAL || evento.tipo_evento == CLASE_RECUPERACION) && (
                                <Fragment>
                                    <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-md-4 mt-2 ">
                                        <div className="d-flex flex-column align-items-center w-100">
                                            <div className="flex-1 w-100">
                                                <label htmlFor="motivo_anulacion" className="m-0">Motivo de cancelación</label>
                                            </div>
                                            <div className="flex-1 w-100">
                                                <Field name="motivo_anulacion" component={renderTextArea} type="text" className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            )
                        )
                }
                <div className="buttons-box d-flex flex-column flex-lg-row mt-5 px-md-5 mx-md-5">
                    <button type="button" className="btn d-flex flex-1 w-100 btn-secondary mr-lg-3" onClick={cancelar}>Regresar</button>
                    {
                        (evento) && (evento.tipo_evento != CLASE_CANCELADA) ? (
                            <button type="button" className="btn d-flex flex-1 w-100 btn-primary ml-lg-3 mt-3 mt-lg-0" onClick={() => obtenerLink(evento.id)}>Unirse a la clase</button>
                        ) : (<div></div>)
                    }
                </div>
                <div className="buttons-box mt-3 px-md-5 mx-md-5">
                    {
                        (evento) && (evento.tipo_evento == CLASE_INDIVIDUAL || evento.tipo_evento == CLASE_RECUPERACION) ? (
                            <button type="submit" className="btn d-flex flex-1 w-100 btn-alter1 mx-lg-5">Cancelar clase</button>
                        ) : (<div></div>)
                    }
                </div>
            </div>
        </form>
    )
}

export default reduxForm({
    form: 'EventoEditForm',
    asyncBlurFields: [],
    validate: data => {
        return validate(data, {
            'motivo_anulacion': validators.exists()('Campo requerido.'),
        })
    }
})(EventoDetalleModal);


