import React, { Component, Fragment } from 'react';
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import Select, { Async } from 'react-select';
import classNames from 'classnames';
import Card from "Utils/Cards/Card";
import Header from "Utils/Grid/Header";
import { RenderNumber } from '../../../Utils/renderField/renderReadField';
import { EJERCICIO, USER_PROFESOR, USER_ALUMNO } from '../../../../../utility/constants';

import moment from 'moment';
import { Link } from 'react-router-dom';
import { api } from "api";


const ESTADOS_FORM = [
    { value: 1, label: 'Pendientes' },
    { value: 2, label: 'Enviados' },
    { value: 3, label: 'Calificados' },
]

const getProfesores = (search) => {
    return api.get("user", { search, tipo_usuario: USER_PROFESOR }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const getAlumnos = (search) => {
    return api.get("user", { search, tipo_usuario: USER_ALUMNO }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};


export default class FormsCursosGrid extends Component {

    componentWillMount() {

        this.props.listar();
    }

    resolverExamen = (data) => {
        this.props.validarExamen(data);
    }

    render() {
        const { data, loader, page, search, item, listar, searchChange } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <h1 className="azul-claro bold">{item.nombres}</h1>
                <div className="mt-4">
                    <Header
                        {...this.props}
                        searchChange={searchChange}
                        search={search}
                        filtro1={
                            <Select
                                isClearable={true}
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                options={ESTADOS_FORM}
                                placeholder={"Filtrar por estado"}
                                value={this.props.estado_form}
                                onChange={this.props.changeEstadoForm}
                            />
                        }
                        filtro2={
                            <Async
                                isClearable={true}
                                backspaceRemovesValue={false}
                                value={this.props.profesor}
                                isSearchable={true}
                                loadOptions={getProfesores}
                                getOptionValue={(option) => (option["id"])}
                                getOptionLabel={(option) => (option["titulo"])}
                                type="text"
                                onChange={(e) => this.props.changeProfesor(e)}
                                multi={false}
                                autoload={false}
                                cache={false}
                                className={classNames('react-select-container ')}
                                defaultOptions={true}
                                placeholder="Filtrar por profesor.."
                            />
                        }
                        filtro3={
                            <Async
                                isClearable={true}
                                backspaceRemovesValue={false}
                                value={this.props.alumno}
                                isSearchable={true}
                                loadOptions={getAlumnos}
                                getOptionValue={(option) => (option["id"])}
                                getOptionLabel={(option) => (option["titulo"])}
                                type="text"
                                onChange={(e) => this.props.changeAlumno(e)}
                                multi={false}
                                autoload={false}
                                cache={false}
                                className={classNames('react-select-container ')}
                                defaultOptions={true}
                                placeholder="Filtrar por alumno.."
                            />
                        }
                    />
                    <Card>
                        <Grid
                            hover striped
                            data={data}
                            loading={loader}
                            onPageChange={listar}
                            page={page}
                        >
                            <TableHeaderColumn
                                dataField="id"
                                dataAlign="center"
                                isKey
                                width='8%'
                                dataFormat={(cell, row) => {
                                    return (
                                        <div className="d-flex justify-content-center">
                                            {
                                                row.enviado && (
                                                    <Link to={`/cursos/formulario/${cell}`} className="" >
                                                        <i className="material-icons ver-action">remove_red_eye</i>
                                                    </Link>
                                                )
                                            }
                                        </div>
                                    )
                                }}
                            />
                            <TableHeaderColumn
                                dataField="nombre_form"
                            >
                                EJERCICIO / EXÁMEN
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="tipo_form"
                                width='8%'
                            >
                                TIPO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="aula"
                            >
                                CURSO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="alumno"
                                dataFormat={cell => cell ? `${cell.id} / ${cell.nombres}` : ''}
                            >
                                ALUMNO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="fecha_entrega"
                                dataFormat={cell => moment(cell).format("DD/MM/YYYY HH:mm")}
                                width="25%"
                                dataFormat={(cell, row) => {
                                    let fecha = null;
                                    if (row.formulario.fecha_hora_examen) {
                                        fecha = moment(row.formulario.fecha_hora_examen).add(row.formulario.tiempo_maximo, 'h');
                                    } else {
                                        fecha = moment(row.formulario.fecha_hora_entrega)
                                    }
                                    return (
                                        <div>
                                            <Fragment>
                                                <span className="bold azul-marino">Estado: </span>
                                                {
                                                    row.enviado ? (
                                                        row.calificado ? (
                                                            <span className="azul-claro">Calificado</span>
                                                        ) : (
                                                            <span className="verde">Enviado</span>
                                                        )
                                                    ) : (
                                                        <span className="mandarina">Pendiente</span>
                                                    )
                                                }
                                                <br />
                                            </Fragment>
                                            <Fragment>
                                                <span className="bold azul-marino">Fecha de entrega: </span>
                                                <span>{fecha.format("DD/MM/YYYY HH:mm")}</span>
                                                <br />
                                            </Fragment>
                                            <Fragment>
                                                <span className="bold azul-marino">Fecha de envio: </span>
                                                <span>{moment(cell).format("DD/MM/YYYY HH:mm")}</span>
                                                <br />
                                            </Fragment>
                                        </div>
                                    )
                                }}
                            >
                                DATOS FORMULARIO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="punteo"
                                dataAlign="right"
                                dataFormat={(cell, row) => (
                                    <div className="d-flex flex-row align-items-center justify-content-end">
                                        <span className='azul-claro'>
                                            <RenderNumber value={cell ? cell : 0} decimalScale={2} />
                                        </span>
                                    &nbsp;/&nbsp;
                                        <span className='azul-claro'>
                                            <RenderNumber value={row.puntaje_form} decimalScale={2} />
                                        </span>
                                    </div>
                                )}
                                thStyle={{ marginRight: "2rem" }}
                                tdStyle={{ marginRight: "2rem" }}
                            >
                                PUNTEO
                            </TableHeaderColumn>
                        </Grid>
                    </Card>
                </div>
            </div>
        )
    }
}
