import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/aulas/formularios';
import DetalleFormulario from './DetalleFormulario';


const ms2p = (state) => {
    return {
        me: state.login.me,
        ...state.formsCursos,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(DetalleFormulario);
