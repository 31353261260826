import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/reportes/cupones';
import ReporteCupones from './ReporteCupones';


const ms2p = (state) => {
    return {
        ...state.reporteCupones,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ReporteCupones);
