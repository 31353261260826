import React, { Component } from 'react';
import PropTypes from 'prop-types';

import icono from '../../../../../assets/img/iconos/search.png';

class SearchInput extends Component {
    static propTypes = {
        search: PropTypes.string.isRequired,
        searchChange: PropTypes.func.isRequired,
    };

    render() {
        const { search, searchChange, placeholder } = this.props;

        return (
            <div className="toolbar-search">
                <input
                    className="form-control search-input"
                    style={{backgroundImage: `url(${icono})`}}
                    id="buscador"
                    type="text"
                    placeholder={placeholder ? placeholder : "Buscar..."}
                    ref={(node) => {
                        this.buscar = node;
                        if (this.buscar) {
                            this.buscar.value = search;
                        }
                    }}
                    onChange={() => {
                        if (this.buscar) {
                            searchChange(this.buscar.value);
                        }
                    }}
                    autoComplete="off"
                />
            </div>
        );
    }
}

export default SearchInput;
