import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {push} from "react-router-redux";
import moment from 'moment';
import _ from "lodash";

const LOADER = 'LOADER_BITACORA';
const DATA = 'DATA_BITACORA';
const PAGE = 'PAGE_BITACORA';
const ITEM = 'ITEM_BITACORA';
const ORDERING = 'ORDERING_BITACORA';
const SEARCH = 'SEARCH_BITACORA';
const FECHA_INICIO = 'FECHA_INICIO_BITACORA';
const FECHA_FIN = 'FECHA_FIN_BITACORA';


// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (type, data) => ({
    type,
    data,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().bitacora;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    if (resource.fecha_inicio)
        params.fecha_inicio = moment(resource.fecha_inicio).hour(0).minute(0).seconds(0).format()
    if (resource.fecha_fin)
        params.fecha_fin = moment(resource.fecha_fin).hour(0).minute(0).seconds(0).add(1, 'd').format()
    dispatch(setLoader(true));
    api.get('bitacora', params).then((response) => {
        dispatch(setData(DATA, response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`bitacora/${id}`).then((response) => {
        dispatch(setData(ITEM, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().bitacora.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const changeFechaInicio = value => (dispatch) => {
    dispatch(setData(FECHA_INICIO, value));
    dispatch(listar());
}

const changeFechaFin = value => (dispatch) => {
    dispatch(setData(FECHA_FIN, value));
    dispatch(listar());
}

export const actions = {
    listar,
    leer,
    searchChange,
    onSortChange,
    changeFechaInicio,
    changeFechaFin,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ITEM]: (state, { data }) => {
        return {
            ...state,
            item: data,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [FECHA_INICIO]: (state, { data }) => {
        return {
            ...state,
            fecha_inicio: data,
        };
    },
    [FECHA_FIN]: (state, { data }) => {
        return {
            ...state,
            fecha_fin: data,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    page: 1,
    item: {},
    ordering: '',
    search: '',
    fecha_inicio: null,
    fecha_fin: null,
};

export default handleActions(reducers, initialState);
