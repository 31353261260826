import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';
import {NotificationManager} from "react-notifications";
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Modal from 'react-responsive-modal';
import SeccionForm from './SeccionForm';
import SeccionModal from './SeccionModal';
import { api } from '../../../../../utility/api';
import { RESERVAR } from '../../../../../utility/constants';
import moment from 'moment';
import Swal from 'sweetalert2';

export default class SeccionCrear extends Component {

    state = {
        modal: false,
    }

    componentWillMount() {
        if(this.props.match.params.id){
            this.props.leer(this.props.match.params.id)
            this.props.obtenerIva();
        }
        else {
            this.props.limpiar();
            this.props.obtenerIva();
        }
    };

    componentWillUnmount() {
        this.props.limpiar();
    }

    openModal = (event) => {
        this.props.preCargarEvento(event);
        this.setState({ modal: true });
    };

    closeModal = () => {
        this.setState({ modal: false });
    };

    agregarEvento = (values) => {
        this.props.agregarHorarioClaseGrupal(values);
        this.closeModal();
    }

    crear = (values) => {
        const inicio = moment(values.fecha_inicio);
        const fin = moment(values.fecha_fin);

        if (fin <= inicio) {
            NotificationManager.error('La fecha de fin debe ser mayor a la fecha de inicio', 'ERROR');
        } else if (inicio < moment().subtract(1, 'd')) {
            NotificationManager.error('La fecha de inicio no puede ser menor a la actual', 'ERROR');
        } else {
            const body = {...values};
            body.fecha_inicio = inicio.format("YYYY-MM-DD");
            body.fecha_fin = fin.format("YYYY-MM-DD");
            body.fecha_maxima_pago = moment(values.fecha_maxima_pago).format("YYYY-MM-DD");
            if (body.curso.interno)
                body.profesor = values.profesor.id;
            body.curso = values.curso.id;
            body.serie_factura = values.serie_factura.id;
            body.clase_grupal = true;

            this.props.crear(body, false);
        }
    }

    eliminarReserva = (evento) => {
        if (evento.tipo == RESERVAR) {
            Swal.fire({
                title: '¿Borrar el horario reservado?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, borrar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminarReservaClaseGrupal(evento);
                }
            });
        }
    }

    renderModal = () => {
        return (
            <Modal
                open={this.state.modal}
                onClose={(e) => {
                    this.closeModal();
                }}
                center
            >
                <Fragment>
                    <div className="my-4">
                        <div className="row col-12">
                            <span className="text-sm azul-claro bold">Asignación de horario</span>
                            <div className="d-flex w-100 bg-azul-claro" style={{ height: '3px' }} />
                        </div>
                        <div className="row col-12">
                            <SeccionModal
                                cancelar={this.closeModal}
                                onSubmit={this.agregarEvento}
                            />
                        </div>
                    </div>
                </Fragment>
            </Modal>
        )
    };

    render() {
        const { crear, loader } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm col='12'>
                    <LoadMask loading={loader} blur>
                        <div>
                            <SeccionForm
                                {...this.props}
                                onSubmit={this.crear}
                                nombreAlumno={this.state.nombreAlumno}
                                openModal={this.openModal}
                                eliminarReserva={this.eliminarReserva}
                            />
                        </div>
                    </LoadMask>
                    {this.renderModal()}
                </CardForm>
            </div>
        )
    }
}
