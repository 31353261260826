import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import NivelForm from './NivelForm';
import {NotificationManager} from "react-notifications";

export default class NivelCrear extends Component {

    state = {
        editar: false
    }

    componentWillMount(){
        if(this.props.match.params.id){
            this.props.leerNivel(this.props.match.params.id);
            this.setState({editar: true});
        }
    }


    onSubmit = (values) => {
        if (values.temario) {
            if (this.state.editar) {
                this.props.editarNivel(values, this.props.match.params.id);
            } else {
                this.props.crearNivel(values);
            }
        } else {
            NotificationManager.error('Temario es necesario', 'ERROR');
        }
    }

    render() {
        const { loader } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm col={10}>
                    <LoadMask loading={loader} blur>
                        <NivelForm
                            onSubmit={this.onSubmit}
                        />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
