import React, { Component } from 'react'
import {NotificationManager} from "react-notifications";
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import FormServicio from './FormServicio';

export default class CrearServicio extends Component {
    
    state = {
        editar: false,
        imagen: null,
    };

    componentWillMount(){
        if(this.props.match.params.id){
            this.props.leer(this.props.match.params.id);
            this.setState({ editar: true });
        }
    }

    setImagen = (imagen) => {
        this.setState({imagen});
    };

    onSubmit = (data) => {
        const id = this.props.match.params.id;
        const body = {...data}
        if (id) {
            this.props.editar(body, id, [{"file": this.state.imagen, "name": "imagen"}])
        } else {
            if (this.state.imagen) {
                this.props.crear(body, [{"file": this.state.imagen, "name": "imagen"}])
            } else {
                NotificationManager.error('La imagen es requerida', 'ERROR');
            }
        }
    }

    render() {
        const { loader, item } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm>
                    <LoadMask loading={loader} blur>
                        <FormServicio {...this.props} onSubmit={this.onSubmit} setImagen={this.setImagen} editar={this.state.editar} />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
