import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import _ from "lodash";

import { EditorState, convertFromHTML, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const LOADER = 'LOADER_ASIGNATURANIVEL';
const DATA = 'DATA_ASIGNATURANIVEL';
const DATA2 = 'DATA2_ASIGNATURANIVEL';
const ITEM = 'ITEM_ASIGNATURANIVEL';
const ITEM2 = 'ITEM2_ASIGNATURANIVEL';
const PAGE = 'PAGE_ASIGNATURANIVEL';
const PAGE2 = 'PAGE2_ASIGNATURANIVEL';
const ORDERING = 'ORDERING_ASIGNATURANIVEL';
const SEARCH = 'SEARCH_ASIGNATURANIVEL';
const SEARCH2 = 'SEARCH2_ASIGNATURANIVEL';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (type, data) => ({
    type,
    data,
});

const setItem = (type, item) => ({
    type,
    item,
});

const setPage = (type, page) => ({
    type,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = (type, search) => ({
    type,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const listarAsignaturas = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().asignaturaNiveles;
    const params = { page };
    params.search = resource.search2;
    dispatch(setLoader(true));
    api.get('asignatura_niveles/listar_asignaturas', params).then((response) => {
        dispatch(setData(DATA2, response));
        dispatch(setPage(PAGE2, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarNiveles = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().asignaturaNiveles;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get('asignatura_niveles', params).then((response) => {
        dispatch(setData(DATA, response));
        dispatch(setPage(PAGE, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leerAsignatura = id => (dispatch) => {
    const params = {id};
    dispatch(setLoader(true));
    api.get('asignatura_niveles/get_asignatura', params).then((response) => {
        dispatch(setItem(ITEM2, response));
        dispatch(initializeForm('AsignaturaForm', response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leerNivel = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`asignatura_niveles/${id}`).then((response) => {
        const blocksFromHtml = htmlToDraft(response.temario ? response.temario : "");
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        const data = { ...response, temario: EditorState.createWithContent(contentState) };

        dispatch(setItem(ITEM, response));
        dispatch(initializeForm('NivelForm', data));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crearAsignatura = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post('asignatura_niveles/crear_asignatura', data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(cleanForm()); // Limpiar el formulario
        dispatch(push('/asignaturas_niveles'));
    }).catch((error) => {
        let mensaje = "Error en la creación";
        if (error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crearNivel = data => (dispatch) => {
    const draw = data.temario;
    const html = draftToHtml(convertToRaw(draw.getCurrentContent()))
    const body = {...data, temario: html, asignatura: data.asignatura.id};
    dispatch(setLoader(true));
    api.post('asignatura_niveles', body).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(cleanForm()); // Limpiar el formulario
        dispatch(push('/asignaturas_niveles'));
    }).catch((error) => {
        let mensaje = "Error en la creación";
        if (error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editarAsignatura = (data, id) => (dispatch) => {
    dispatch(setLoader(true));
    api.put('asignatura_niveles/editar_asignatura', data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push('/asignaturas_niveles'));
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editarNivel= (data, id) => (dispatch) => {
    const draw = data.temario;
    const html = draftToHtml(convertToRaw(draw.getCurrentContent()))
    const body = {...data, temario: html, asignatura: data.asignatura.id};
    dispatch(setLoader(true));
    api.put(`asignatura_niveles/${id}`, body).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push('/asignaturas_niveles'));
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminarAsignatura = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get('asignatura_niveles/eliminar_asignatura', {id: id}).then(() => {
        dispatch(listarAsignaturas());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminarNivel = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`asignatura_niveles/${id}`).then(() => {
        dispatch(listarNiveles());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('NivelForm', {}));
    dispatch(initializeForm('AsignaturaForm', {}));
};

const searchChangeAsignatura = search => (dispatch) => {
    dispatch(setSearch(SEARCH2,search));
    dispatch(listarAsignaturas());
};

const searchChangeNivel = search => (dispatch) => {
    dispatch(setSearch(SEARCH,search));
    dispatch(listarNiveles());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().asignaturaNiveles.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listarNiveles());
};

export const actions = {
    listarAsignaturas,
    listarNiveles,
    leerAsignatura,
    leerNivel,
    crearAsignatura,
    crearNivel,
    editarAsignatura,
    editarNivel,
    eliminarAsignatura,
    eliminarNivel,
    searchChangeAsignatura,
    searchChangeNivel,
    onSortChange
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DATA2]: (state, { data }) => {
        return {
            ...state,
            data2: data,
        };
    },
    [ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [ITEM2]: (state, { item }) => {
        return {
            ...state,
            item2: item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [PAGE2]: (state, { page }) => {
        return {
            ...state,
            page2: page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH2]: (state, { search }) => {
        return {
            ...state,
            search2: search,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    data2: {
        results: [],
        count: 0,
    },
    item: {},
    item2: {},
    page: 1,
    page2: 1,
    ordering: '',
    search: '',
    search2: '',
};

export default handleActions(reducers, initialState);
