import React, { Component } from 'react';
import Calendario from '../../Utils/Calendario/Calendario';
import moment from 'moment'


class ExampleTabs extends Component {
    state = {
        eventos: [
            {
                id: 0,
                title: 'Clase 1',
                // allDay: true,
                start: new Date(moment("2020-06-26 06:30")),
                end: new Date(moment("2020-06-26 12:30")),
                desc: 'Big conference for important people',
            },
            {
                id: 1,
                title: 'Clase Ingles Avanzado',
                start: new Date(moment("2020-07-02 06:30")),
                end: new Date(moment("2020-07-02 8:30")),
                // allDay: true,
                // desc: 'Big conference for important people',
            },
            {
                id: 2,
                title: 'Clase Frances Kiddi',
                start: new Date(),
                end: new Date(),
                allDay: true,
                desc: 'Big conference for important people',
                tipo: 1,
            },
            {
                id: 3,
                title: 'Clase Italiano Medio',
                start: new Date(moment("2020-07-02 10:30")),
                end: new Date(moment("2020-07-02 12:30")),
                desc: 'Big conference for important people',
                tipo: 3,
            },
            {
                id: 4,
                title: 'Clase Programacion A',
                start: new Date(moment("2020-07-03 06:30")),
                end: new Date(moment("2020-07-03 8:30")),
                desc: 'Big conference for important people',
                tipo: 2,
            },
            {
                id: 5,
                title: 'Clase Programacion B',
                start: new Date(moment("2020-07-03 8:30")),
                end: new Date(moment("2020-07-03 9:30")),
                desc: 'Big conference for important people',
                tipo: 1,
            },
        ]
    }

    render() {
        return (
            <div className="py-4">
                <h2>Wizard</h2>
                <div className="row">
                    <div className="mb-4 col-lg-12">
                        <div className="mb-4 card card-small">
                            <div className="border-bottom card-header d-flex justify-content-center">
                                <h3 className="m-0">Calendario</h3>
                            </div>
                            <div className="p-0 px-3 pt-3 mb-3">
                                <Calendario
                                    eventos={this.state.eventos}
                                    toolbar={true}
                                    onlySemana={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ExampleTabs;
