import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/aulas';
import SeccionCrear from './SeccionCrear';


const ms2p = (state) => {
    return {
        ...state.aulas,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(SeccionCrear);
