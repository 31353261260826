import React, {Component} from 'react';
import DatePicker from 'react-datepicker';
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import Link from "react-router-dom/es/Link";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";
import moment from 'moment';

export default class Notificaciones extends Component{
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar: onPageChange, onSortChange } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return(
            <div className="mb-4 col-12 mt-4">
                <Header
                    {...this.props}
                    filtro1={
                        <DatePicker
                            showPopperArrow={false}
                            isClearable
                            dateFormat="DD/MM/YYYY"
                            placeholderText="Fecha"
                            onChange={this.props.changeFecha}
                            selected={this.props.fecha}
                        />
                    }
                />
                <Card>
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                    page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='8%'
                            dataFormat={ (cell, row) => row.link ? (
                                <div className="d-flex justify-content-center">
                                    <Link className="px-1" to={row.link} title="Ver" >
                                        <i className="material-icons ver-action">remove_red_eye</i>
                                    </Link>
                                </div>
                            ) : (<div></div>)}
                        />
                        <TableHeaderColumn
                            dataField="creado"
                            dataSort
                            dataFormat={cell => moment(cell).format("DD/MM/YYYY HH:mm")}
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="generado_por"
                            dataSort
                            dataFormat={cell => cell ? cell.titulo : '-----'}
                        >
                            ORIGEN DEL MENSAJE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="generado_por"
                            width='12%'
                            dataFormat={cell => cell ? cell.tipo_usuario_text : '-----'}
                        >
                            TIPO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="mensaje"
                            width='45%'
                        >
                            MENSAJE
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
