import React, { Component, Fragment } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import "react-step-progress-bar/styles.css";
import {ProgressBar, Step} from "react-step-progress-bar";

import PasoUnoForm from './PasoUnoForm';
import { PasoUnoUpdateForm } from './PasoUnoForm';
import PasoDosForm from './PasoDosForm';
import PasoTresForm from './PasoTresForm';
import PasoCuatroForm from './PasoCuatroForm';
import PasoCincoForm from './PasoCincoForm';

import moment from 'moment';
const check = require("assets/img/icons/check.png");

export default class ProfesorCrear extends Component {
    state = {
        editar: false,
        step: 1,
        ver_password: false
    };

    componentWillMount(){
        if(this.props.match.params.id){
            this.props.leer(this.props.match.params.id);
            this.setState({editar: true});
        }
    }

    actualizar = (data) =>{
        const body = {...data};
        const feriados = [];
        for (const item of body.feriados) {
            feriados.push({
                ...item,
                fecha_inicio: moment(item.fecha_inicio).format("YYYY-MM-DD"),
                fecha_fin: moment(item.fecha_fin).format("YYYY-MM-DD")
            })
        }
        body.feriados = feriados;
        if (body.password)
            body.new_password = body.password
        this.props.editar(body, this.props.match.params.id)
    };

    crear = (data) => {
        const body = {...data};
        const feriados = [];
        if (body.feriados) {
            for (const item of body.feriados) {
                feriados.push({
                    ...item,
                    fecha_inicio: moment(item.fecha_inicio).format("YYYY-MM-DD"),
                    fecha_fin: moment(item.fecha_fin).format("YYYY-MM-DD")
                })
            }
        }
        body.feriados = feriados;
        this.props.crear(body)
    };

    nextStep = () => {
        this.setState({ step: this.state.step + 1 })
    };

    previousStep =() => {
        this.setState({ step: this.state.step - 1 })
    };

    componentDidMount() {
        this.props.cleanForm()
    };

    change_ver_password = () => {
        this.setState({ver_password: !this.state.ver_password});
    };

    render() {
        const { crear, municipios } = this.props;
        const { updateData, loader, item } = this.props;
        const { step } = this.state;
        return (
            <div className="mb-4 col-12 mt-4">
                <div className="mb-5 mt-5 col-12 row d-flex justify-content-center">
                    <div className="col-sm-8 col-10 px-0">
                        <ProgressBar
                            percent={step === 1 ? 0: step === 2 ? 25 : step === 3 ? 50 : step === 4 ? 75 : 100}
                            filledBackground="#686A75"
                            unfilledBackground="#686A75"
                            stepPositions={[0, 25, 50, 75, 100]}
                            height={5}
                        >
                            <Step transition="scale">
                                {({ accomplished, index, position }) => (
                                    <div style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }} className="mt-3">
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            {index + 1 === step ?
                                                <div className="step-number">
                                                    <div className="step-dot"/>
                                                </div>
                                                :
                                                (!!accomplished ?
                                                    <div className="step-checked d-flex align-items-center justify-content-center">
                                                        <img src={check} alt="" width="15" height="15"/>
                                                    </div>
                                                    :
                                                    <div className="step-number">
                                                    </div>
                                                )
                                            }
                                            <span className={`mt-2 ${index+1 === step ? 'azul': (!!accomplished ? 'azul ocultar-texto-movil' : 'gris-oscuro ocultar-texto-movil')}`}>Datos generales</span>
                                        </div>
                                    </div>
                                )}
                            </Step>
                            <Step transition="scale">
                                {({ accomplished, index, position }) => (
                                    <div style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }} className="mt-3">
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            {index + 1 === step ?
                                                <div className="step-number">
                                                    <div className="step-dot"/>
                                                </div>
                                                :
                                                (!!accomplished ?
                                                    <div className="step-checked d-flex align-items-center justify-content-center">
                                                        <img src={check} alt="" width="15" height="15"/>
                                                    </div>
                                                    :
                                                    <div className="step-number">
                                                    </div>
                                                )
                                            }
                                            <span className={`mt-2 ${index+1 === step ? 'azul': (!!accomplished ? 'azul ocultar-texto-movil' : 'gris-oscuro ocultar-texto-movil')}`}>Titulación</span>
                                        </div>
                                    </div>
                                )}
                            </Step>
                            <Step transition="scale">
                                {({ accomplished, index, position }) => (
                                    <div style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }} className="mt-3">
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            {index + 1 === step ?
                                                <div className="step-number">
                                                    <div className="step-dot"/>
                                                </div>
                                                :
                                                (!!accomplished ?
                                                    <div className="step-checked d-flex align-items-center">
                                                        <img src={check} alt="" width="15" height="15"/>
                                                    </div>
                                                    :
                                                    <div className="step-number">
                                                    </div>
                                                )
                                            }
                                            <span className={`mt-2 ${index+1 === step ? 'azul': (!!accomplished ? 'azul ocultar-texto-movil' : 'gris-oscuro ocultar-texto-movil')}`}>Horarios</span>
                                        </div>
                                    </div>
                                )}
                            </Step>
                            <Step transition="scale">
                                {({ accomplished, index, position }) => (
                                    <div style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }} className="mt-3">
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            {index + 1 === step ?
                                                <div className="step-number">
                                                    <div className="step-dot"/>
                                                </div>
                                                :
                                                (!!accomplished ?
                                                    <div className="step-checked d-flex align-items-center">
                                                        <img src={check} alt="" width="15" height="15"/>
                                                    </div>
                                                    :
                                                    <div className="step-number">
                                                    </div>
                                                )
                                            }
                                            <span className={`mt-2 ${index+1 === step ? 'azul': (!!accomplished ? 'azul ocultar-texto-movil' : 'gris-oscuro ocultar-texto-movil')}`}>Niveles</span>
                                        </div>
                                    </div>
                                )}
                            </Step>
                            <Step transition="scale">
                                {({ accomplished, index, position }) => (
                                    <div style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }} className="mt-3">
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            {index + 1 === step ?
                                                <div className="step-number">
                                                    <div className="step-dot"/>
                                                </div>
                                                :
                                                (!!accomplished ?
                                                    <div className="step-checked d-flex align-items-center">
                                                        <img src={check} alt="" width="15" height="15"/>
                                                    </div>
                                                    :
                                                    <div className="step-number">
                                                    </div>
                                                )
                                            }
                                            <span className={`mt-2 ${index+1 === step ? 'azul': (!!accomplished ? 'azul ocultar-texto-movil' : 'gris-oscuro ocultar-texto-movil')}`}>Vacaciones</span>
                                        </div>
                                    </div>
                                )}
                            </Step>
                        </ProgressBar>
                    </div>
                </div>
                <CardForm>
                    <LoadMask loading={loader} blur>
                        {this.state.editar ?
                            (<Fragment>
                                {step === 1 && (<PasoUnoUpdateForm onSubmit={this.nextStep} change_ver_password={this.change_ver_password} ver_password={this.state.ver_password} updateData={updateData} editando={this.state.editar}/>)}
                                {step === 2 && (<PasoDosForm onSubmit={this.nextStep} updateData={updateData} previousStep={this.previousStep} />)}
                                {step === 3 && (<PasoTresForm onSubmit={this.nextStep} updateData={updateData} previousStep={this.previousStep}/>)}
                                {step === 4 && (<PasoCuatroForm onSubmit={this.nextStep} updateData={updateData} previousStep={this.previousStep}/>)}
                                {step === 5 && (<PasoCincoForm onSubmit={this.actualizar} updateData={updateData} previousStep={this.previousStep} editar={true}/>)}
                            </Fragment>):

                            (<Fragment>
                                {step === 1 && (<PasoUnoForm onSubmit={this.nextStep} change_ver_password={this.change_ver_password} ver_password={this.state.ver_password}/>)}
                                {step === 2 && (<PasoDosForm onSubmit={this.nextStep} previousStep={this.previousStep} />)}
                                {step === 3 && (<PasoTresForm onSubmit={this.nextStep} previousStep={this.previousStep}/>)}
                                {step === 4 && (<PasoCuatroForm onSubmit={this.nextStep} previousStep={this.previousStep}/>)}
                                {step === 5 && (<PasoCincoForm onSubmit={this.crear} previousStep={this.previousStep} editar={false}/>)}
                            </Fragment>)
                        }
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
  }
