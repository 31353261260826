import React, {Fragment} from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField, renderEditorField, renderCurrency, renderNumber, renderImagePicker, renderFieldCheck, SelectField } from '../../../Utils/renderField/renderField';
import { tipo_documento_identificacion, CIF, NIF } from '../../../../../utility/constants';

let SerieFacturaForm = props => {
    const { handleSubmit, editando, tipo_documento} = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="col-12 mt-4">
                    <span className="text-sm azul-claro bold">Datos factura</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{height: '3px'}}/>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="prefijo" className="m-0">Prefijo*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="prefijo" component={renderField} type="text" className="form-control" maxLength={2} />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="numeracion" className="m-0">Numeración*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="numeracion" component={renderNumber} type="number" className="form-control" disabled={editando ? true : false} />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12  form-group">
                            <Field component={renderFieldCheck} className="form-control" name="tiene_iva" label=" IVA" />
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-4">
                    <span className="text-sm azul-claro bold">Datos del emisor</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{height: '3px'}}/>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre_emisor" className="m-0">Nombre*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombre_emisor" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="direccion" className="m-0">Dirección*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="direccion" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="tipo_documento" className="m-0">Tipo documento*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="tipo_documento" component={SelectField} options={tipo_documento_identificacion} placeholder="Seleccione un tipo de documento..." type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="documento_identificacion" className="m-0">{tipo_documento}*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="documento_identificacion" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="email" className="m-0">Correo electronico*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="email" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="telefono" className="m-0">Telefono*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="telefono" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="col-12 text-right">
                    <span className="azul-marino text-xsm">* Campos requeridos</span>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-3">
                    <div className="col-md-4 col-12">
                        <Link className="btn btn-secondary btn-block" to="/series_facturas">Cancelar</Link>
                    </div>
                    <div className="col-md-4 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Guardar</button>
                    </div>
                </div>
            </div>
        </form>
    )
};
SerieFacturaForm = reduxForm({
    form: 'SerieFacturaForm',
    asyncBlurFields: [],
    validate: data => {
        return validate(data, {
            'prefijo': validators.exists()('Campo requerido.'),
            // 'numeracion': validators.exists()('Campo requerido.'),
            'nombre_emisor': validators.exists()('Campo requerido.'),
            'direccion': validators.exists()('Campo requerido.'),
            'tipo_documento': validators.exists()('Campo requerido.'),
            'documento_identificacion': validators.exists()('Campo requerido.'),
            'email': validators.exists()('Campo requerido.'),
            'telefono': validators.exists()('Campo requerido.'),
        })
    },
    initialValues: {
        tipo_documento: NIF,
    },
})(SerieFacturaForm);

const selector = formValueSelector('SerieFacturaForm');
SerieFacturaForm = connect(state => {
    const form = state.form.SerieFacturaForm;
    let tipo_documento = "";

    if (form && form.values) {
        const values = form.values;

        if (values.tipo_documento === CIF) {
            tipo_documento = "CIF"
        }
        if (values.tipo_documento === NIF) {
            tipo_documento = "NIF"
        }
    }
    return {
        tipo_documento,
    }
})(SerieFacturaForm);

export default SerieFacturaForm;
