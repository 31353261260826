import React, {Fragment} from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField, renderEditorField, renderImagePicker, renderVideoPicker, renderFieldCheck, renderFieldRadio, renderTextArea, AsyncSelectField } from '../../../Utils/renderField/renderField';
import { NOTICIA, CAMPAÑA, IMAGEN, VIDEO } from '../../../../../utility/constants';
import { api } from "api";

const getCupones = (search) => {
    const params = {};
    params.search = search;
    return api.get("cupon", params).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

let NoticiasCampañasForm = props => {
    const {
        handleSubmit,
        editando,
        mostrar_cupon,
        publicar,
        setImagen,
        setVideo,
        publicado,
        item,
        editando2,
        viewPreview
    } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                {(editando || editando2) && (
                    <div className="col-12 d-flex flex-wrap justify-content-end align-items-center">
                        <div className="col-md-4 col-12 px-0">
                            <button type="button" onClick={(e) => viewPreview()} className="btn btn-alter1 btn-block">Ver preview</button>
                        </div>
                    </div>
                )}
                <div className="col-12 mt-4">
                    <span className="text-sm azul-claro bold">Tipo *</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{height: '3px'}}/>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-center px-0 mt-3">
                    <div className="col-md-12 d-flex flex-wrap justify-content-start align-items-center">
                        <Field
                            label='Noticia'
                            type="radio"
                            value={NOTICIA}
                            name="tipo"
                            parse={val => +val}
                            component={renderFieldRadio}
                        />
                        <div className="ml-3" />
                        <Field
                            label='Campaña'
                            type="radio"
                            value={CAMPAÑA}
                            name="tipo"
                            parse={val => +val}
                            component={renderFieldRadio}
                        />
                    </div>
                </div>
                <div className="col-12 mt-4">
                    <span className="text-sm azul-claro bold">Información</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{height: '3px'}}/>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="en_slider" className="m-0">Activar en slider</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field component={renderFieldCheck} className="form-control" name="en_slider" />
                        </div>
                    </div>
                    <div className="col-md-8 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="titulo" className="m-0">Titulo *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="titulo" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="contenido" className="m-0">Contenido *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field component={renderTextArea} className="form-control" name="contenido" rows={5} />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="activar_cupon" className="m-0">Activar cupón</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field component={renderFieldCheck} className="form-control" name="activar_cupon" />
                        </div>
                    </div>
                    {mostrar_cupon && (
                        <div className="col-md-8 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="cupon" className="m-0">Cupon</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    valueKey="id"
                                    name="cupon"
                                    labelKey="nombre"
                                    label="nombre"
                                    component={AsyncSelectField}
                                    loadOptions={getCupones}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className="col-12 mt-4">
                    <span className="text-sm azul-claro bold">Imagen</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{height: '3px'}}/>
                </div>
                {(editando || editando2) && item && item.imagen && (
                    <div className="col-md-12 p-0 mb-3 mt-3">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="imagen" className="m-0">Imagen actual</label>
                        </div>
                        <div className="col-md-12 form-group text-center">
                            <img src={item.imagen} alt="imagen" className="img-fluid"/>
                        </div>
                    </div>
                )}
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="en_slider" className="m-0">Imagen *</label>
                        </div>
                        <div className="col-md-12  form-group">
                        <Field
                                setFile={setImagen}
                                component={renderImagePicker}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-4">
                    <span className="text-sm azul-claro bold">Video</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{height: '3px'}}/>
                </div>
                {(editando || editando2) && item && item.video && (
                    <div className="col-md-12 p-0 mb-3 mt-3">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="imagen" className="m-0">Video actual</label>
                        </div>
                        <div className="col-md-12 form-group text-center">
                            <video width="200" controls>
                                <source src={item.video} />
                            </video>
                        </div>
                    </div>
                )}
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="en_slider" className="m-0">Video</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                name="video"
                                component={renderVideoPicker}
                                setFile={setVideo}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 text-right">
                    <span className="azul-marino text-xsm">* Campos requeridos</span>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-3">
                    <div className="col-md-4 col-12">
                        <Link className="btn btn-secondary btn-block" to="/noticias_campañas">Cancelar</Link>
                    </div>
                    <div className="col-md-4 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Guardar</button>
                    </div>
                    {(editando || editando2) && !publicado && (
                        <div className="col-md-4 col-12 mt-md-0 mt-3">
                            <button type="button" onClick={(e) => publicar()} className="btn btn-alter1 btn-block">Publicar</button>
                        </div>
                    )}
                </div>
            </div>
        </form>
    )
};
NoticiasCampañasForm = reduxForm({
    form: 'NoticiasCampañasForm',
    asyncBlurFields: [],
    validate: data => {
        return validate(data, {
            'tipo': validators.exists()('Campo requerido.'),
            'titulo': validators.exists()('Campo requerido.'),
            'contenido': validators.exists()('Campo requerido.')
        })
    },
    initialValues: {
        tipo: NOTICIA,
        tipo_multimedia: IMAGEN
    },
})(NoticiasCampañasForm);

const selector = formValueSelector('NoticiasCampañasForm');
NoticiasCampañasForm = connect(state => {
    const form = state.form.NoticiasCampañasForm;
    let mostrar_cupon = false;
    let publicado = false;
    let editando2=false;

    if (form && form.values) {
        const values = form.values;
        if (values.publicado) {
            publicado = values.publicado;
        }
        if (values.activar_cupon) {
            mostrar_cupon = true;
        }
        else{
            mostrar_cupon = false;
            values.cupon = null;
        }

        if (values.id) {
            editando2 = true;
        }
    }
    return {
        mostrar_cupon,
        publicado,
        editando2
    }
})(NoticiasCampañasForm);

export default NoticiasCampañasForm;
