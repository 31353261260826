import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { AsyncSelectField, renderField, SelectField, renderCurrency, renderNumber, renderPasswordField, renderFieldRadio, renderDatePicker, renderTimeField, renderFieldCheck } from '../../../Utils/renderField/renderField';
import { api } from "api";
import CardEmpty from "Utils/Cards/CardEmpty";
import {
    CUPON_UNICO,
    CUPON_MULTIPLE,
    CLASE_GRUPAL_CUPONES,
    CLASE_INDIVIDUAL_CUPONES,
    PORCENTAJE,
    DINERO,
    USER_COLABORADOR,
    USER_ALUMNO
} from '../../../../../utility/constants';

import moment from 'moment';

const getColaboradores = (search) => {
    const params = {};
    params.es_colaborador = true;
    params.search = search;
    return api.get("user", params).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const getCentros = (search) => {
    const params = { search };
    return api.get("centro_colaborador", params).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const getCursosGrupales = (search) => {
    const params = {};
    params.search = search;
    return api.get("curso", params).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const renderGanaciasColaborador = ({ fields, updateData }) => (
    <div className=" col-12">
        <div className="react-bs-table-container">
            <div className="react-bs-table react-bs-table-bordered" style={{ height: "100%" }}>
                <div className="react-bs-container-header table-header-wrapper">
                    <table className="table table-hover table-bordered">
                        <colgroup>
                            <col style={{ width: "10%", minWidth: "10%" }} />
                            <col style={{ width: "10%", minWidth: "10%" }} />
                            <col style={{ width: "30%", minWidth: "30%" }} />
                            <col style={{ width: "30%", minWidth: "30%" }} />
                            <col style={{ width: "10%", minWidth: "10%" }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th className="sort-column" style={{ textAlign: "center" }}>
                                    <span className="order">
                                        <span className="dropdown">
                                            <span className="caret" style={{ margin: "10px 0px 10px 5px", color: "rgb(204, 204, 204)" }}></span>
                                        </span>
                                        <span className="dropup">
                                            <span className="caret" style={{ margin: "10px 0px", color: "rgb(204, 204, 204)" }}></span>
                                        </span>
                                    </span>
                                    <div></div>
                                </th>
                                <th className="sort-column" style={{ textAlign: "center" }}>
                                    <span className="order">
                                        <span className="dropdown">
                                            <span className="caret" style={{ margin: "10px 0px 10px 5px", color: "rgb(204, 204, 204)" }}></span>
                                        </span>
                                        <span className="dropup">
                                            <span className="caret" style={{ margin: "10px 0px", color: "rgb(204, 204, 204)" }}></span>
                                        </span>
                                    </span>
                                    <div></div>
                                </th>
                                <th className="sort-column" title="Cantidad estudiantes" style={{ textAlign: "center" }}>Cantidad estudiantes
                                    <span className="order">
                                        <span className="dropdown">
                                            <span className="caret" style={{ margin: "10px 0px 10px 5px", color: "rgb(204, 204, 204)" }}></span>
                                        </span>
                                        <span className="dropup">
                                            <span className="caret" style={{ margin: "10px 0px", color: "rgb(204, 204, 204)" }}></span>
                                        </span>
                                    </span>
                                    <div></div>
                                </th>
                                <th className="sort-column" title="Euro/Alumno" style={{ textAlign: "center" }}>Euro/Alumno
                                    <span className="order">
                                        <span className="dropdown">
                                            <span className="caret" style={{ margin: "10px 0px 10px 5px", color: "rgb(204, 204, 204)" }}></span>
                                        </span>
                                        <span className="dropup">
                                            <span className="caret" style={{ margin: "10px 0px", color: "rgb(204, 204, 204)" }}></span>
                                        </span>
                                    </span>
                                    <div></div>
                                </th>
                                <th className="sort-column" style={{ textAlign: "center" }}>
                                    <span className="order">
                                        <span className="dropdown">
                                            <span className="caret" style={{ margin: "10px 0px 10px 5px", color: "rgb(204, 204, 204)" }}></span>
                                        </span>
                                        <span className="dropup">
                                            <span className="caret" style={{ margin: "10px 0px", color: "rgb(204, 204, 204)" }}></span>
                                        </span>
                                    </span>
                                    <div></div>
                                </th>
                            </tr>
                        </thead>
                    </table>
                </div>
                {/*Contenido de la tabla*/}
                <div className="react-bs-container-body">
                    <table className="table table-striped table-bordered table-hover">
                        <colgroup>
                            <col style={{ width: "10%", minWidth: "10%" }} />
                            <col style={{ width: "10%", minWidth: "10%" }} />
                            <col style={{ width: "30%", minWidth: "30%" }} />
                            <col style={{ width: "30%", minWidth: "30%" }} />
                            <col style={{ width: "10%", minWidth: "10%" }} />
                        </colgroup>
                        <tbody>
                            {fields.map((ganancia, index) => {
                                let todos = fields.getAll();
                                const dato_actual = fields.get(index); // Pago actual
                                todos[index]['index'] = index; //Mutar cada fila par agregarle el index
                                return (
                                    <tr key={index}>
                                        <td></td>
                                        <td className="text-center sin-borde-top">
                                            <button className="btn-delete" onClick={(e) => { e.preventDefault(); fields.remove(index) }}><span className="material-icons rosa">close</span></button>
                                        </td>
                                        <td className="sin-borde-top">
                                            <Field name={`${ganancia}.cantidad_estudiantes`} component={renderNumber} suffix=" alumnos" />
                                        </td>
                                        <td>
                                            <Field name={`${ganancia}.precio_x_estudiante`} component={renderNumber} decimalScale={2} suffix=" €" />
                                        </td>
                                        <td></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="d-flex justify-content-center mt-2">
            <button type="button" className="btn btn-dark py-0" onClick={() => fields.push({})}>
                Agregar ganancia colaborador
            </button>
        </div>
    </div>
);

const renderCursosGrupales = ({ fields }) => {
    if (!fields.length) {
        fields.push({});
    }
    return (
        <div className="col-12 px-0">
            {fields.map((curso, index) => {
                let todos = fields.getAll();
                const dato_actual = fields.get(index);
                todos[index]['index'] = index; //Mutar cada fila par agregarle el index
                return (
                    <div key={index} className="col-12 px-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor={`${curso}.nombre`} className="m-0">Curso</label>
                        </div>
                        <div className="col-md-12 form-group row">
                            <div className="col-10">
                                <Field
                                    valueKey="id"
                                    name={`${curso}.curso_grupal`}
                                    labelKey="titulo"
                                    label="titulo"
                                    component={AsyncSelectField}
                                    loadOptions={getCursosGrupales}
                                />
                            </div>
                            <div className="col-2 d-flex align-items-center px-0">
                                <button className="btn-delete" onClick={(e) => { e.preventDefault(); fields.remove(index) }}><span className="material-icons rosa">close</span></button>
                            </div>
                        </div>
                    </div>
                )
            })}
            <div className="col-12 form-group mt-sm-3 mt-0">
                <button type="button" className="btn btn-dark btn-block" onClick={() => fields.push({})}>Agregar</button>
            </div>
        </div>

    )
};

let CuponForm = props => {
    const { handleSubmit, mostrar_data_cupon_individual, mostrar_data_cupon_multiple, mostrar_cursos_grupales,
        tipo_descuento, editando, tipo_colaborador } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="col-12">
                    <span className="text-sm azul-claro bold">Selección que tipo de cupón para el colaborador</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-center px-0 mt-3">
                    <div className="col-md-12 d-flex flex-wrap justify-content-start align-items-center">
                        <Field
                            label='Cupón único'
                            type="radio"
                            value={CUPON_UNICO}
                            name="tipo_cupon"
                            parse={val => +val}
                            component={renderFieldRadio}
                            disabled={editando}
                        />
                        <div className="ml-3" />
                        <Field
                            label='Múltiples cupones'
                            type="radio"
                            value={CUPON_MULTIPLE}
                            name="tipo_cupon"
                            parse={val => +val}
                            component={renderFieldRadio}
                            disabled={editando}
                        />
                    </div>
                </div>
                <div className="col-12 mt-4 ">
                    <span className="text-sm azul-claro bold">Datos del cupón</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-12 d-flex flex-wrap justify-content-start align-items-center">
                        <Field
                            label='Colaborador'
                            type="radio"
                            value={0}
                            name="tipo_colaborador"
                            parse={val => +val}
                            component={renderFieldRadio}
                            disabled={editando}
                        />
                        <div className="ml-3" />
                        <Field
                            label='Centro colaborador'
                            type="radio"
                            value={1}
                            name="tipo_colaborador"
                            parse={val => +val}
                            component={renderFieldRadio}
                            disabled={editando}
                        />
                    </div>

                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    {(tipo_colaborador == 0)  && (
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="colaborador" className="m-0">Colaborador *</label>
                            </div>
                            <div className="col-md-12 form-group">
                                <Field
                                    valueKey="id"
                                    name="colaborador"
                                    labelKey="nombres"
                                    label="nombres"
                                    component={AsyncSelectField}
                                    loadOptions={getColaboradores}
                                    disabled={editando}
                                />
                            </div>
                        </div>
                    )}
                    {(tipo_colaborador == 1) && (
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="centro_colaborador" className="m-0">Centro Colaborador *</label>
                            </div>
                            <div className="col-md-12 form-group">
                                <Field
                                    valueKey="id"
                                    name="centro_colaborador"
                                    labelKey="nombre"
                                    label="nombre"
                                    component={AsyncSelectField}
                                    loadOptions={getCentros}
                                    disabled={editando}
                                />
                            </div>
                        </div>
                    )}
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">Nombre del cupón *</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field name="nombre" component={renderField} type="text" />
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-4">
                    <span className="text-sm azul-claro bold">Tipo de formación</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-center px-0 mt-3">
                    <div className="col-md-12 d-flex flex-wrap justify-content-start align-items-center">
                        <Field
                            label='Individual'
                            type="radio"
                            value={CLASE_INDIVIDUAL_CUPONES}
                            name="tipo_formacion"
                            parse={val => +val}
                            component={renderFieldRadio}
                        />
                        <div className="ml-3" />
                        <Field
                            label='Grupal'
                            type="radio"
                            value={CLASE_GRUPAL_CUPONES}
                            name="tipo_formacion"
                            parse={val => +val}
                            component={renderFieldRadio}
                        />
                    </div>
                </div>
                {mostrar_cursos_grupales && (
                    <div className="row col-md-12 m-0 d-flex flex-wrap align-items-center px-0 mt-3">
                        <div className="col-md-6 px-0">
                            <FieldArray name="cursos_grupales" component={renderCursosGrupales} {...props} />
                        </div>
                    </div>
                )}
                <div className="col-12 mt-4">
                    <span className="text-sm azul-claro bold">Fechas</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="fecha_inicial" className="m-0">Fecha inicial *</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field name="fecha_inicial" component={renderDatePicker} />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="fecha_final" className="m-0">Fecha final *</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field name="fecha_final" component={renderDatePicker} />
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-4">
                    <span className="text-sm azul-claro bold">Otros</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    {mostrar_data_cupon_individual && (
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="cantidad_usos" className="m-0">Cantidad de usos *</label>
                            </div>
                            <div className="col-md-12 form-group">
                                <Field name="cantidad_usos" component={renderNumber} suffix=" usos" disabled={editando ? true : false} />
                            </div>
                        </div>
                    )}
                    {mostrar_data_cupon_multiple && (
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="cantidad_a_generar" className="m-0">Cantidad de cupones a generar *</label>
                            </div>
                            <div className="col-md-12 form-group">
                                <Field name="cantidad_a_generar" component={renderNumber} suffix=" cupones" disabled={editando ? true : false} />
                            </div>
                        </div>
                    )}
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="tipo_descuento" className="m-0">Descuento *</label>
                        </div>
                        <div className="col-md-12 d-flex flex-wrap justify-content-between align-items-center form-group">
                            <div className="col-md-3 col-12 px-0 d-flex align-items-center mb-md-0 mb-3">
                                <Field
                                    label='Dinero'
                                    type="radio"
                                    value={DINERO}
                                    name="tipo_descuento"
                                    parse={val => +val}
                                    component={renderFieldRadio}
                                />
                            </div>
                            <div className="col-md-4 col-12 px-0 d-flex align-items-center mb-md-0 mb-3">
                                <Field
                                    label='Porcentaje'
                                    type="radio"
                                    value={PORCENTAJE}
                                    name="tipo_descuento"
                                    parse={val => +val}
                                    component={renderFieldRadio}
                                />
                            </div>
                            <div className="col-md-3 col-12 px-0 mb-md-0 mb-3">
                                <Field name="descuento" component={renderNumber} decimalScale={2} suffix={tipo_descuento} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-1">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 form-group">
                            <Field
                                name="recurrente"
                                label="Recurrente"
                                component={renderFieldCheck}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-4">
                    <span className="text-sm azul-claro bold">Ganancia del colaborador</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-12 px-0">
                        <FieldArray name="ganancias_colaborador" component={renderGanaciasColaborador} {...props} />
                    </div>
                </div>
                <div className="col-12 text-right">
                    <span className="azul-marino text-xsm">* Campos requeridos</span>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-3">
                    <div className="col-md-6 col-12">
                        <Link className="btn btn-secondary btn-block" to="/cupones">Cancelar</Link>
                    </div>
                    <div className="col-md-6 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Guardar</button>
                    </div>
                </div>
            </div>
        </form>
    );
};

const selector = formValueSelector('CuponForm');
CuponForm = reduxForm({
    form: 'CuponForm',
    initialValues: {
        fecha_inicial: moment(),
        fecha_final: moment(),
        tipo_cupon: CUPON_UNICO,
        tipo_formacion: CLASE_INDIVIDUAL_CUPONES,
        tipo_descuento: DINERO,
        tipo_colaborador: 0,
        recurrente: false
    },
})(CuponForm);

CuponForm = connect(state => {
    const form = state.form.CuponForm;
    let mostrar_data_cupon_individual = true;
    let mostrar_data_cupon_multiple = false;
    let mostrar_cursos_grupales = true
    let tipo_descuento = ""
    let tipo_colaborador = 0

    if (form && form.values) {
        const values = form.values;

        tipo_colaborador = values.tipo_colaborador;

        // Aqui se evalua que tipo de cupón para ver que partes del formulario son las que se van a mostrar
        if (values.tipo_cupon === CUPON_UNICO) {
            mostrar_data_cupon_individual = true;
            mostrar_data_cupon_multiple = false;
        }
        if (values.tipo_cupon === CUPON_MULTIPLE) {
            mostrar_data_cupon_individual = false;
            mostrar_data_cupon_multiple = true;
        }
        // Mostrar o no la seleccion de multiples cursos grupales
        if (values.tipo_formacion === CLASE_GRUPAL_CUPONES) {
            mostrar_cursos_grupales = true;
        }
        else {
            mostrar_cursos_grupales = false;
            values.cursos_grupales = null;
        }
        // Tipo de descuento
        if (values.tipo_descuento === DINERO) {
            tipo_descuento = " €";
        }
        else {
            tipo_descuento = "%";
        }

    }
    return {
        mostrar_data_cupon_individual,
        mostrar_data_cupon_multiple,
        mostrar_cursos_grupales,
        tipo_descuento,
        tipo_colaborador,
    }
})(CuponForm);

export default CuponForm;
