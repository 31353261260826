import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";
import {formatActivo} from "Utils/renderField/renderReadField"
import { RenderCurrency } from '../../../Utils/renderField/renderReadField';

export default class CursosGrupalesList extends Component{
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar: onPageChange, onSortChange, eliminar } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return(
            <div className="mb-4 col-12 mt-4">
                <Header to="curso_grupal/crear" textBtn="Nuevo curso grupal" {...this.props} />
                <Card>
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                    page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='12%'
                            dataFormat={standardActions({ editar: "curso_grupal", eliminar})}
                        >
                            ACCIONES
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="interno"
                            width='12%'
                            dataFormat={ cell => cell ? <span className="azul-claro">Interno</span> : <span className="mandarina">Externo</span>}
                        >
                            TIPO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="titulo"
                            dataSort
                        >
                            NOMBRE DEL CURSO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="centro_colaborador"
                            dataSort
                            dataFormat={(cell, row) => cell ? `${cell.nombre} (${row.porcentaje_centro} %)` : '----'}
                        >
                            CENTRO COLABORADOR
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="precio"
                            dataAlign="right"
                            dataFormat={(cell, row) => <RenderCurrency value={cell} />}
                        >
                            Precio
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="iva"
                            dataAlign="center"
                            width='8%'
                            dataFormat={ cell => cell ? <span className="azul-claro">Sí</span> : <span className="mandarina">No</span>}
                        >
                            IVA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="left"
                            dataField="secciones_activas"
                            dataSort
                        >
                            SECCIONES ACTIVAS
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
