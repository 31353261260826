import React, { Component } from 'react'
import { NotificationManager } from 'react-notifications';
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import CentrosForm from './CentrosForm';

export default class MensajeCrear extends Component {

    componentWillMount(){
        if(this.props.match.params.id){
            this.props.leer(this.props.match.params.id);
        }
    }

    onSubmit = (values) => {
        const id = this.props.match.params.id;
        const { crear, editar } = this.props;
        if (id) {
            editar(id, values);
        } else {
            crear(values);
        }
    }

    render() {
        const { loader } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm col={10}>
                    <LoadMask loading={loader} blur>
                        <CentrosForm onSubmit={this.onSubmit}/>
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
