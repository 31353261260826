import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card";
import { RenderCurrency } from '../../../Utils/renderField/renderReadField';
import Header from "Utils/Grid/Header";

export default class ListarServicios extends Component{
    
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar: onPageChange, onSortChange, eliminar } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return(
            <div className="mb-4 col-12 mt-4">
                <Header to="servicio/crear" textBtn="Nuevo servicio" {...this.props} />
                <Card>
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                    page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='15%'
                            dataFormat={standardActions({ editar: "servicio", eliminar})}
                        >
                            Acciones
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre"
                            dataSort
                        >
                            Servicio
                        </TableHeaderColumn>
                        {/* <TableHeaderColumn
                            dataField="descripcion"
                            dataSort
                            width='30%'
                            dataFormat={cell => (
                                <div dangerouslySetInnerHTML={{ __html: cell ? cell : '' }} className="text-cortado"></div>
                            )}
                        >
                            Descripción
                        </TableHeaderColumn> */}
                        <TableHeaderColumn
                            dataField="precio"
                            dataSort
                            dataAlign="center"
                            thStyle={{ paddingRight: "0.2rem" }}
                            tdStyle={{ paddingRight: "0.2rem" }}
                            dataFormat={cell => <RenderCurrency value={cell} />}
                        >
                            Precio
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="visible"
                            dataSort
                            width='10%'
                            dataFormat={(cell) => cell ? "Visible" : "No visible"}
                        >
                            Visible
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
