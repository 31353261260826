import React, {Fragment} from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, FieldArray, } from 'redux-form';
import {AsyncSelectField, renderField, SelectField, renderCurrency, renderNumber, renderPasswordField, renderDatePicker, renderTimeField} from '../../../Utils/renderField/renderField';
import { api } from "api";
import CardEmpty from "Utils/Cards/CardEmpty";
import { generos, dias } from "../../../../../utility/constants";
// Horarios
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


function MaysPrimera(string){
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const renderDiasHabiles = ({dia, expanded, handleChange, fields, updateData}) => {
    const nombre = dia;
    const nombreMayus = MaysPrimera(dia);
    return (
    <Accordion expanded={expanded === nombre} onChange={handleChange(nombre)}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${nombre}-content`}
            id={`${nombre}-header`}
        >
            <span className="bold">Dia <span className="regular">{nombreMayus}</span></span>
        </AccordionSummary>
        <AccordionDetails>
            <div className="col-12 d-flex flex-column align-items-center">
                <div className="col-md-12 col-12 px-0">
                    {fields.map((dia_habil, index) => {
                        let todos = fields.getAll();
                        const dato_actual = fields.get(index);
                        todos[index]['index'] = index; //Mutar cada fila par agregarle el index
                        return(
                            <div key={index} className="mb-3">
                                <div className="col-12 row time-container">
                                    <div className="col-11">
                                        <div className="col-md-12 row px-0">
                                            <div className="col-md-6 col-12 time-picker-profesores">
                                                <div className="col-12">
                                                    <label className="m-0">Desde</label>
                                                </div>
                                                <div className="col-12">
                                                    <Field
                                                        name={`${dia_habil}.hora_inicio`}
                                                        component={renderTimeField}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 time-picker-profesores">
                                                <div className="col-12">
                                                    <label className="m-0">Hasta</label>
                                                </div>
                                                <div className="col-12">
                                                    <Field
                                                        name={`${dia_habil}.hora_fin`}
                                                        component={renderTimeField}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-1 d-flex align-items-end px-0">
                                        <button className ="btn-delete" onClick={(e) =>{e.preventDefault(); fields.remove(index)}}><span className="material-icons rosa">close</span></button>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="col-md-6 col-12 form-group mt-0 px-0">
                    <button type="button" className="btn btn-dark btn-block" onClick={() => fields.push({})}>Agregar</button>
                </div>
            </div>
        </AccordionDetails>
    </Accordion>
    )
};

const renderDiasInhabiles = ({fields, updateData}) => (
    <div className="row col-12">
        <div className="col-md-8 col-12 px-0">
            {fields.map((dia_habil, index) => {
                let todos = fields.getAll();
                const dato_actual = fields.get(index);
                todos[index]['index'] = index; //Mutar cada fila par agregarle el index
                return(
                    <div key={index} className="mb-4">
                        <div className="col-12 row date-container">
                            <div className="col-11">
                                <div className="col-md-12 row px-0">
                                    <div className="col-md-6 col-12 day-picker-profesores">
                                        <div className="col-md-12 col-sm-12">
                                            <label className="m-0">Desde</label>
                                        </div>
                                        <div className="col-md-12 col-sm-12">
                                            <Field
                                                name={`${dia_habil}.fecha_inicio`}
                                                component={renderDatePicker}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 day-picker-profesores">
                                        <div className="col-md-12 col-sm-12">
                                            <label className="m-0">Hasta</label>
                                        </div>
                                        <div className="col-md-12 col-sm-12">
                                            <Field
                                                name={`${dia_habil}.fecha_fin`}
                                                component={renderDatePicker}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-1 d-flex align-items-end px-0">
                                <button className ="btn-delete" onClick={(e) =>{e.preventDefault(); fields.remove(index)}}><span className="material-icons rosa">close</span></button>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
        <div className="col-md-4 col-12 form-group mt-sm-3 mt-0 px-0">
            <button type="button" className="btn btn-dark btn-block" onClick={() => fields.push({})}>Agregar</button>
        </div>
    </div>
);

let PasoDosForm = props => {
    const { handleSubmit, setStep, ver_password, change_ver_password, previousStep } = props;
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="col-12">
                    <span className="text-sm azul-claro bold">Dias hábiles</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{height: '3px'}}/>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12">
                            <FieldArray name="lunes" dia="lunes" component={renderDiasHabiles} {...props} expanded={expanded} handleChange={handleChange} />
                            <FieldArray name="martes" dia="martes" component={renderDiasHabiles} {...props} expanded={expanded} handleChange={handleChange} />
                            <FieldArray name="miercoles" dia="miercoles" component={renderDiasHabiles} {...props} expanded={expanded} handleChange={handleChange} />
                            <FieldArray name="jueves" dia="jueves" component={renderDiasHabiles} {...props} expanded={expanded} handleChange={handleChange} />
                            <FieldArray name="viernes" dia="viernes" component={renderDiasHabiles} {...props} expanded={expanded} handleChange={handleChange} />
                            <FieldArray name="sabado" dia="sabado" component={renderDiasHabiles} {...props} expanded={expanded} handleChange={handleChange} />
                            <FieldArray name="domingo" dia="domingo" component={renderDiasHabiles} {...props} expanded={expanded} handleChange={handleChange} />
                        </div>
                    </div>
                </div>
                {/* <div className="col-12">
                    <span className="text-sm azul-claro bold">Dias inhábiles <span className="text-sm azul-claro regular">(Vacaciones o dia festivo)</span></span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{height: '3px'}}/>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 px-0">
                            <FieldArray name="horarios2" component={renderDiasInhabiles} {...props} />
                        </div>
                    </div>
                </div> */}
                <div className="d-none d-lg-flex buttons-box mt-4">
                    <button className="btn btn-secondary mr-5" onClick={previousStep}>Regresar</button>
                    <button type="submit" className="btn btn-primary">Siguiente</button>
                </div>
                <div className="d-flex d-lg-none flex-column mt-4">
                    <button className="btn btn-secondary flex-1" onClick={previousStep}>Regresar</button>
                    <button type="submit" className="btn btn-primary flex-1 mt-3">Siguiente</button>
                </div>
            </div>
        </form>
    )
};
PasoDosForm = reduxForm({
    form: 'ProfesorForm',
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    asyncBlurFields: [],
    initialValues:{
        horario: [{}],
        // horario2: [{}]
    },
    // validate: data => {
    //     return validate(data, {
    //         'titulaciones': validators.exists()('Campo requerido.'),
    //     })
    // }
})(PasoDosForm);

export default PasoDosForm
