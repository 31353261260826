import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './AdminForm';
import { AdminUpdateForm } from './AdminForm';

export default class AdminCrear extends Component {
    state = {
        editar: false,
        password: false,
    };
    componentWillMount(){
        if(this.props.match.params.id){
            this.props.leer(this.props.match.params.id);
            this.setState({
                editar: true,
            });
        }
    }
    actualizar = (data) => {
        if (data.password)
            data.new_password = data.password

        this.props.editar(this.props.match.params.id, data)
    };
    render() {
        const { crear, update } = this.props;
        const { updateData, loader } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm>
                    <LoadMask loading={loader} blur>
                        {
                        this.state.editar ?
                            <AdminUpdateForm
                                mostrar_pass={this.state.password}
                                onSubmit={this.actualizar}
                                updateData={updateData}
                                editando={true}
                            />
                            :
                            <Form onSubmit={crear}/>
                        }
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
