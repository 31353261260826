import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import Select from 'react-select';
import classNames from 'classnames';
import Card from "Utils/Cards/Card"
import Header2 from "Utils/Grid/Header2";
import { RenderCurrency } from '../../../Utils/renderField/renderReadField';
import { TIPO_FACTURA, MANUAL } from '../../../../../utility/constants'

import moment from 'moment';
import Swal from 'sweetalert2';


export default class ListarFacturas extends Component{

    componentWillMount() {
        const { listar, descargarFacturas } = this.props;
        listar();
        this.interval = setInterval(() => descargarFacturas(), 1000)
    }

    anular = (id) => {
        Swal.fire({
            title: '¿Desea anular esta factura?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, anular!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.anular(id);
            }
        });
    }

    render() {
        const { listar: onPageChange, onSortChange, anular } = this.props; //Funciones
        const { data, loader, page, task_id } = this.props; //Constantes
        return(
            <div className="mb-4 col-12 mt-4">
                <Header2
                    to="/factura/crear"
                    textBtn="Crear factura"
                    textBtn2="Descargar facturas"
                    btn2Click={this.props.SolicitarDescargaFacturas}
                    disabledBtn2={Object.is(task_id, null) ? false : true}
                    filtro2={
                        <Select
                            isClearable={true}
                            className={classNames('react-select-container')}
                            backspaceRemovesValue={false}
                            isSearchable={true}
                            options={[
                                {value: false, label: "Activa"},
                                {value: true, label: "Anulada"}
                            ]}
                            placeholder={"Filtrar por estado"}
                            value={this.props.estado}
                            onChange={this.props.changeEstado}
                        />
                    }
                    filtro1={
                        <Select
                            isClearable={true}
                            className={classNames('react-select-container')}
                            backspaceRemovesValue={false}
                            isSearchable={true}
                            options={TIPO_FACTURA}
                            placeholder={"Filtrar por tipo"}
                            value={this.props.tipo}
                            onChange={this.props.changeTipo}
                        />
                    }
                    {...this.props} />
                <Card>
                    <Grid
                        hover
                        striped
                        data={data}
                        loading={loader}
                        onPageChange={onPageChange}
                        onSortChange={onSortChange}
                        page={page}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='12%'
                            dataFormat={ (cell, row) => (
                                <div className="d-flex justify-content-center">
                                    <a target="_blank" href={row.documento} className="px-1" title="Ver factura" >
                                        <i className="material-icons ver-action">visibility</i>
                                    </a>
                                    {
                                        (!row.anulada) && (row.tipo_factura == MANUAL) && (
                                            <a className="px-1" style={{cursor: "pointer", color: "#c4183c"}} onClick={() => this.anular(cell)} title="Reasignar" >
                                                <i style={{color: "#9C023B"}} className="material-icons azul-claro anular-action">block</i>
                                            </a>
                                        )
                                    }
                                </div>
                            )}
                        />
                        <TableHeaderColumn
                            dataField="creado"
                            dataSort
                            dataFormat={(cell, row) => moment(cell).format("DD/MM/YYYY")}
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="serie_factura"
                            dataFormat={(cell, row) => `${cell.prefijo}-${row.correlativo}`}
                        >
                            NO. FACTURA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipo_factura_text"
                        >
                            TIPO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nif"
                        >
                            DOC PERSONAL
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre"
                            width='20%'
                        >
                            NOMBRE DEL CLIENTE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="total"
                            dataSort
                            dataAlign="right"
                            tdStyle={{paddingRight: "0.8rem"}}
                            thStyle={{paddingRight: "0.8rem"}}
                            dataFormat={(cell, row) => <RenderCurrency value={cell}  />}
                        >
                            TOTAl
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="anulada"
                            width='10%'
                            dataFormat={(cell) => cell === false ? (
                                <div className="d-flex flex-row align-items-center">
                                    <div className="circulo bg-aqua mr-1"></div>
                                    <span className="aqua ml-1">Activa</span>
                                </div>
                            ) : (
                                <div className="d-flex flex-row align-items-center">
                                    <div className="circulo bg-rosa mr-1"></div>
                                    <span className="rosa ml-1">Anulada</span>
                                </div>
                            )}
                        >
                            ESTADO
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
