import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, FieldArray, } from 'redux-form';
import { renderField, AsyncSelectField, renderNumber, renderCurrency } from '../../../Utils/renderField/renderField';
import { api } from '../../../../../utility/api';
import { RenderCurrency, RenderNumber } from '../../../Utils/renderField/renderReadField';
import { render } from 'react-dom';


const getSeries = (search) => {
    const params = {};
    if (search) {
        params.search = search;
    }
    return api.get("serie_factura", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
};

const renderServicios = ({ fields }) => (
    <div className="row col-12">
        <div className="col-12 px-0">
            {fields.map((servicio, index) => {
                return (
                    <div key={index}>
                        <div className="col-md-12 col-sm-12 row">
                            <div className="col-6">
                                <label htmlFor={`${servicio}.servicio`} className="m-0">Servicio</label>
                            </div>
                            <div className="col-6">
                                <label htmlFor={`${servicio}.monto`} className="m-0">Monto</label>
                            </div>
                        </div>
                        <div className="col-md-12 form-group row">
                            <div className="col-6">
                                <Field name={`${servicio}.servicio`} component={renderField} type="text" className="form-control" />
                            </div>
                            <div className="col-4">
                                <Field name={`${servicio}.monto`} component={renderCurrency} type="text" className="form-control" />
                            </div>
                            <div className="col-2 d-flex align-items-center px-0">
                                <button className="btn-delete" onClick={(e) => { e.preventDefault(); fields.remove(index) }}><span className="material-icons rosa">close</span></button>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
        <div className="col-12 form-group mt-sm-3 mt-0" style={{ paddingLeft: "1rem", paddingRight: "6rem" }}>
            <button type="button" className="btn btn-dark btn-block" onClick={() => fields.push({})}>Agregar</button>
        </div>
    </div>
);

let FacturaForm = props => {
    const { handleSubmit, serie_factura, config, sub_total } = props;

    const getTotalIva = () => {
        let total = sub_total;
        if (serie_factura) {
            if (serie_factura.tiene_iva && config) {
                total = config.iva ? total += (total * config.iva) : total = total;
            }
        }
        return total;
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="col-12">
                    <span className="text-sm azul-claro bold">Datos de quien factura</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">Número de serie</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                name="serie_factura"
                                loadOptions={getSeries}
                                type="text"
                                component={AsyncSelectField}
                                valueKey="id"
                                labelKey="prefijo"
                                isClearable={false}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="profesor" className="m-0">NIF/ DNI/ NIE</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <span className="mx-1">{serie_factura ? serie_factura.documento_identificacion : ''}</span>
                        </div>
                    </div>
                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">No. Factura</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <span className="mx-1">{serie_factura ? serie_factura.numeracion + 1 : ''}</span>
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">Dirección</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <span className="mx-1">{serie_factura ? serie_factura.direccion : ''}</span>
                        </div>
                    </div>
                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="profesor" className="m-0">Teléfono</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <span className="mx-1">{serie_factura ? serie_factura.telefono : ''}</span>
                        </div>
                    </div>
                    <div className="col-md-4 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="profesor" className="m-0">% de IVA</label>
                        </div>
                        <div className="col-md-12  form-group">
                            {serie_factura && serie_factura.tiene_iva ? (
                                <RenderNumber value={config.iva ? (config.iva * 100) : 0} decimalScale={2} suffix=" %" className="span mx-1" />
                            ) : (
                                <span className="mx-1">Sin iva</span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <h2 className="mr-2">Total sin iva</h2>
                        </div>
                        <div className="col-md-12  form-group">
                            <RenderCurrency value={sub_total} className="h2 verde" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <h2 className="mr-2">Total</h2>
                        </div>
                        <div className="col-md-12  form-group">
                            <RenderCurrency value={getTotalIva()} className="h2 verde" />
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <span className="text-sm azul-claro bold">Datos de quien recibe</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nif" className="m-0">NIF/ DNI/ NIE</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nif" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">Nombre y apellido</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombre" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="direccion" className="m-0">Dirección</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="direccion" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="telefono" className="m-0">Teléfono</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="telefono" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="ciudad" className="m-0">Ciudad</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="ciudad" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <span className="text-sm azul-claro bold">Datos de los servicios</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 px-0">
                            <FieldArray name="detalle_factura" component={renderServicios} />
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-5">
                    <div className="col-md-4 col-12">
                        <Link className="btn btn-secondary btn-block" to="/facturas">Cancelar</Link>
                    </div>
                    <div className="col-md-4 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Guardar</button>
                    </div>
                </div>
            </div>
        </form>
    )
};

const selector = formValueSelector('FacturaForm');

FacturaForm = reduxForm({
    form: 'FacturaForm',
    initialValues: {
        tipo_factura: 20
    },
    validate: data => {
        return validate(data, {
            'serie_factura': validators.exists()('Campo requerido.'),
            'nif': validators.exists()('Campo requerido.'),
            'nombre': validators.exists()('Campo requerido.'),
            'direccion': validators.exists()('Campo requerido.'),
            'telefono': validators.exists()('Campo requerido.'),
            'ciudad': validators.exists()('Campo requerido.'),
        })
    }
})(FacturaForm);

const mstp = state => {
    const serie_factura = selector(state, 'serie_factura');
    const detalle_factura = selector(state, 'detalle_factura');

    let sub_total = 0;

    if (detalle_factura) {
        if (detalle_factura.length) {
            detalle_factura.forEach(item => item.monto ? sub_total += parseFloat(item.monto) : sub_total += 0)
        }
    }

    return {
        serie_factura,
        sub_total,
    }
};

export default connect(mstp, null)(FacturaForm);
