import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm, change} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import {reset} from 'redux-form';
import _ from "lodash";
import moment from "moment";
import { DIAS_SEMANA, RESERVADO, DISPONIBLE, RESERVAR, DIAS_CALENDARIO } from '../../../utility/constants';
import Swal from 'sweetalert2';

const LOADER = 'LOADER_AULA';
const DATA = 'DATA_AULA';
const DATA_ALUMNOS = 'DATA_ALUMNOS_AULA';
const ITEM_DATA = 'ITEM_AULA';
const PAGE = 'PAGE_AULA';
const HORARIOS_OCUPADOS = 'HORARIOS_OCUPADOS_AULA';
// TIEMPO DE RECUPERACION
const FILTRO_ALUMNO_TIEMPO_RECUPERACION = 'FILTRO_ALUMNO_TIEMPO_RECUPERACION';
const FILTRO_PROFESOR_TIEMPO_RECUPERACION = 'FILTRO_PROFESOR_TIEMPO_RECUPERACION';
const FILTRO_CURSO_TIEMPO_RECUPERACION = 'FILTRO_CURSO_TIEMPO_RECUPERACION';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (type, data) => ({
    type,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = (type, page) => ({
    type,
    page,
});

const setHorarios = horarios => ({
    type: HORARIOS_OCUPADOS,
    horarios,
});

// ------------------------------------
// Actions
// ------------------------------------

// Recuperaciones
const listarRecuperaciones = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().aula;
    const params = { page };
    if (resource.curso) {
        params.curso = resource.curso.id;
    }
    if (resource.profesor) {
        params.profesor = resource.profesor.id;
    }
    if (resource.alumno) {
        params.alumno = resource.alumno.id;
    }
    dispatch(setLoader(true));
    api.get('reportes/recuperaciones', params).then((response) => {
        dispatch(setData(DATA, response));
        dispatch(setPage(PAGE, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const filtroCursoRecuperaciones = (curso) => (dispatch) => {
    dispatch({type: FILTRO_CURSO_TIEMPO_RECUPERACION, curso});
    dispatch(listarRecuperaciones());
}

const filtroAlumnoRecuperaciones = (alumno) => (dispatch) => {
    dispatch({type: FILTRO_ALUMNO_TIEMPO_RECUPERACION, alumno});
    dispatch(listarRecuperaciones());
}

const filtroProfesorRecuperaciones = (profesor) => (dispatch) => {
    dispatch({type: FILTRO_PROFESOR_TIEMPO_RECUPERACION, profesor});
    dispatch(listarRecuperaciones());
}

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`aula/${id}`).then((response) => {
        const data = _.cloneDeep(response);
        const nombre = data.nombre.split(" -");
        data.nombre = nombre[0];
        data.tiene_iva = data.porcentaje_iva ? true : false;
        delete data.horario;

        const horario = response.horario.map(item => {
            const fecha = moment(item.fecha_hora_inicio);
            const dia = _.find(DIAS_SEMANA, { id: fecha.get('d') });
            item.dia = dia.value;
            return item;
        })
        dispatch(setItem({...response, horario: horario}));
        dispatch(initializeForm('EditarAulaForm', data));
        dispatch(obtenerHorario(id));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarAlumnos = id => (dispatch) => {
    const params = { aula: id, sin_paginacion: true };
    dispatch(setLoader(true));
    api.get(`aula/alumnos_asignados`, params).then((response) => {
        const data = response.map( item => {item.reasignado = false; return item})
        dispatch(setData(DATA_ALUMNOS, data));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtenerHorario = id => (dispatch, getStore) => {
    const form = getStore().form.EditarAulaForm.values;
    dispatch(setLoader(true));
    api.get(`aula/${id}/horario_aula`).then((response) => {
        const horarios = _.cloneDeep(response);
        // Transformamos
        for (const item of horarios) {
            const dia = moment(item.fecha_hora_inicio);
            let start = null;
            let end = null;

            if (moment().get('d') != 0 &&  dia.get('d') == 0) {
                start = moment(dia.format("HH:mm:ss"), "HH:mm:ss").day(7);
                end = _.cloneDeep(start).add(item.duracion, 'h');
            } else {
                if (moment().get('d') == 0) {
                    if (dia.get('d') != 0) {
                        start = moment(dia.format("HH:mm:ss"), "HH:mm:ss").day(-1);
                    }
                }
                start = moment(dia.format("HH:mm:ss"), "HH:mm:ss").day(dia.get('d'));
                end = _.cloneDeep(start).add(item.duracion, 'h');
            }

            item.title = 'Horario anterior';
            item.tipo = RESERVADO;
            item.start = new Date(start);
            item.end   = new Date(end);
        }

        for (const dia of DIAS_CALENDARIO) {
            for (const item of form.profesor[dia.key]) {
                let start = moment(item.hora_inicio, "HH:mm:ss");
                let end = moment(item.hora_fin, "HH:mm:ss");

                if (moment().get('d') != 0 && dia.value == 0) {
                    start = start.day(7);
                    end = end.day(7);
                } else {
                    if (moment().get('d') == 0) {
                        if (dia.value != 0) {
                            start = start.day(-1);
                            end = end.day(-1);
                        }
                    }
                    start = start.day(dia.value);
                    end = end.day(dia.value);
                }
                horarios.push({
                    title: '',
                    tipo: DISPONIBLE,
                    start: new Date(start),
                    end: new Date(end),
                })
            }
        }
        dispatch(setHorarios(horarios));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

/**
 * Función que verifica si hubo cambios que ameriten recalcular horas y recargos.
 * Estos cambios pueden ser cambio en fechas o cambio de horario.
 * Retorna true si hay que recalcular, false si no.
 * @param {*} aula Objeto con el estado anterior del aula
 * @param {*} form Objeto con los cambios realizados en el aula
 */
function verificarCambioHorario(aula, form) {
    const antiguo_inicio = moment(aula.fecha_inicio).format("YYYY-MM-DD");
    const antiguo_fin = moment(aula.fecha_fin).format("YYYY-MM-DD");

    const nuevo_inicio = moment(form.fecha_inicio).format("YYYY-MM-DD");
    const nuevo_fin = moment(form.fecha_fin).endOf('month').format("YYYY-MM-DD");

    if (antiguo_inicio != nuevo_inicio)
        return true;
    if (antiguo_fin != nuevo_fin)
        return true;
    if (form.horario) {
        if (form.horario.length)
        return true;
    }
    return false;
}

const calcularCargos = (id, values) => (dispatch, getStore) => {
    const aula = getStore().aula.item;
    const form = getStore().form.EditarAulaForm.values;
    const horario = form.horario ? form.horario : aula.horario;

    let mensaje = 'No se realizarán cargos extras ni devoluciones.';
    let total_horas = 0;
    const fecha_inicio = moment(form.fecha_inicio);
    let inicio = moment("00:00:00", "HH:mm:ss");


    // !verificar si esta correcto
    if (moment(fecha_inicio).format() != moment(aula.fecha_inicio).format() || fecha_inicio > inicio) {
        inicio = fecha_inicio;
    }

    const fin = moment(form.fecha_fin).endOf('month').hour(23);

    const verificarCambio = verificarCambioHorario(aula, form, horario);
    console.log("verificar cambio ", verificarCambio);

    // Obtenemos las horas que estaban pendientes
    api.get(`aula/${id}/horas_pendientes`).then((response) => {

        if (verificarCambio) { // Cambiaron fechas u horarios
            const horas_pendientes = response.horas_pendientes;
            let limite = moment().add(response.limite_cancelacion, 'h');
            if (fecha_inicio != moment(aula.fecha_inicio) && fecha_inicio < moment("00:00:00", "HH:mm:ss")) {
                limite = moment(fecha_inicio.format("YYYY-MM-DD")).add(response.limite_cancelacion, 'h');
            }
            // Obtenemos el total de horas a mover
            while (inicio <= fin) {
                const dia_semana = _.find(DIAS_SEMANA, { id: inicio.get('d') });
                const dias = horario.filter(item => item.dia == dia_semana.value );
                for (const dia of dias) {
                    let elegida = _.cloneDeep(inicio);
                    elegida = elegida.hour(moment(dia.fecha_hora_inicio).get('h'));
                    elegida = elegida.minute(moment(dia.fecha_hora_inicio).get('minute'));
                    if (elegida > limite) {
                        total_horas += dia.duracion;
                    }
                }
                inicio = inicio.add(1, 'd');
            }
            // Obtenemos la diferencia y calculamos el monto en dinero
            total_horas = horas_pendientes - total_horas;
            // const precio_hora = aula.precio_sin_iva / aula.horas_prometidas;
            const precio_hora = form.precio_hora;
            const precio = (total_horas * precio_hora).toFixed(2);

            if (precio > 0) {
                // Se quedan a deber horas por lo tanto es un reembolso
                mensaje = `Se debe de realizar una devolución de ${precio} € a los alumnos de este curso.`;
            } else if (precio < 0) {
                // Se quedan hicieron horas extras por lo tanto el alumno debe hacer un pago extra
                mensaje = `Los alumnos de este curso deben de realizar un pago extra de ${precio * -1} €.`;
            }
        }

        // Mensaje
        Swal.fire({
            title: '¿Confirmar cambio de datos del curso?',
            text: `${mensaje} ¡No podrá revertir esta acción!`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, cambiar!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                dispatch(editar(values, id));
            }
        });

    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const obtenerHorarioProfe = id => (dispatch, getStore) => {
    const form = getStore().form.HorarioForm.values;
    const params = {};
    params.profesor = id;
    dispatch(setLoader(true));
    api.get(`aula/horario_profesor`, params).then((response) => {
        // Transformamos
        for (const item of response) {
            const dia = moment(item.fecha_hora_inicio);
            let start = null;
            let end = null;

            if (moment().get('d') != 0 &&  dia.get('d') == 0) {
                start = moment(dia.format("HH:mm:ss"), "HH:mm:ss").day(7);
                end = _.cloneDeep(start).add(item.duracion, 'h');
            } else {
                if (moment().get('d') == 0) {
                    if (dia.get('d') != 0) {
                        start = moment(dia.format("HH:mm:ss"), "HH:mm:ss").day(-1);
                    }
                }
                start = moment(dia.format("HH:mm:ss"), "HH:mm:ss").day(dia.get('d'));
                end = _.cloneDeep(start).add(item.duracion, 'h');
            }

            item.title = 'Reservado';
            item.tipo = RESERVADO;
            item.start = new Date(start);
            item.end   = new Date(end);
        }

        for (const dia of DIAS_CALENDARIO) {
            for (const item of form.profesor[dia.key]) {
                // Lunes
                let start = moment(item.hora_inicio, "HH:mm:ss");
                let end = moment(item.hora_fin, "HH:mm:ss");

                if (moment().get('d') != 0 && dia.value == 0) {
                    start = start.day(7);
                    end = end.day(7);
                } else {
                    if (moment().get('d') == 0) {
                        if (dia.value != 0) {
                            start = start.day(-1);
                            end = end.day(-1);
                        }
                    }
                    start = start.day(dia.value);
                    end = end.day(dia.value);
                }
                response.push({
                    title: '',
                    tipo: DISPONIBLE,
                    start: new Date(start),
                    end: new Date(end),
                })
            }
        }
        dispatch(setHorarios(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const preCargarEvento = (evento) => (dispatch) => {
    const start = moment(evento.start);
    const end = moment(evento.end);
    const dia = _.find(DIAS_SEMANA, { id: start.get('d') });
    const data = {
        dia: dia.value,
        hora_inicio: start.format('HH:mm'),
        hora_fin: end.format('HH:mm')
    }
    dispatch(initializeForm('AsignacionEventoForm', data));
};

const agregarHorario = (evento, form_name="EditarAulaForm") => (dispatch, getStore) => {
    const horarios = getStore().aula.horarios;
    const form = getStore().form[form_name].values;
    const horario = form.horario ? form.horario : [];
    const dia = _.find(DIAS_SEMANA, { value: evento.dia });

    let start = moment(evento.hora_inicio, "HH:mm");
    let end = moment(evento.hora_fin, "HH:mm");

    if (moment().get('d') != 0 &&  dia.id == 0) {
        start = start.day(7);
        end = end.day(7);
    } else {
        if (moment().get('d') == 0) {
            if (dia.id != 0) {
                start = start.day(-1);
                end = end.day(-1);
            }
        }
        start = start.day(dia.id);
        end = end.day(dia.id);
    }

    if (form.profesor) {
        // Encontramos la menor hora inicio y la mayor hora fin
        let min_inicio = null;
        let max_fin = null;

        const dia_aux = _.find(DIAS_CALENDARIO, { value: start.get('d') });
        const dia_habil = form.profesor[dia_aux.key]

        for (const item of dia_habil) {
            if (min_inicio == null)
                min_inicio = moment(item.hora_inicio, "HH:mm:ss")
            if (max_fin == null)
                max_fin = moment(item.hora_fin, "HH:mm:ss")
            if (moment(item.hora_inicio, "HH:mm:ss") < min_inicio )
                min_inicio = moment(item.hora_inicio, "HH:mm:ss")
            if (moment(item.hora_fin, "HH:mm:ss") > max_fin )
                max_fin = moment(item.hora_fin, "HH:mm:ss")
        }

        if (min_inicio != null && max_fin != null) {
            if (moment().get('d') != 0 &&  dia.id == 0) {
                min_inicio = min_inicio.day(7);
                max_fin = max_fin.day(7);
            } else {
                if (moment().get('d') == 0) {
                    if (dia.id != 0) {
                        min_inicio = min_inicio.day(-1);
                        max_fin = max_fin.day(-1);
                    }
                }
                min_inicio = min_inicio.day(dia.id);
                max_fin = max_fin.day(dia.id);
            }
        }

        // Validamos si es dentro del día habil
        if (min_inicio == null || max_fin == null) {
            NotificationManager.error('Clase fuera del horario disponible del profesor', 'ERROR');
        } else if (min_inicio > start) {
            NotificationManager.error('Clase fuera del horario disponible del profesor', 'ERROR');
        } else if (max_fin < start) {
            NotificationManager.error('Clase fuera del horario disponible del profesor', 'ERROR');
        } else if (max_fin < end) {
            NotificationManager.error('Clase fuera del horario disponible del profesor', 'ERROR');
        } else {
            // Dentro del rango de fechas
            const id = `id-${horario.length + 1}`;
            horarios.push({
                id: id,
                title : 'Horario nuevo',
                tipo  : RESERVAR,
                start : new Date(start),
                end   : new Date(end),
                fd: 'dfd',
            }),
            dispatch(setHorarios(horarios));
            horario.push({
                id: id,
                fecha_hora_inicio: start.format(),
                duracion: (end - start) / 3600000,
                dia: evento.dia,
                hora_inicio: evento.hora_inicio,
                hora_fin: evento.hora_fin
            });
            dispatch(change(form_name, 'horario', horario));
        }
    } else {
        NotificationManager.error('Seleccione un profesor antes', 'ERROR');
    }
};

const eliminarReserva = (evento, form_name="EditarAulaForm") => (dispatch, getStore) => {
    const resource = getStore().aula;
    const form = getStore().form[form_name].values;

    const horarios = resource.horarios.filter(item => item.id != evento.id );
    const horario  = form.horario.filter(item => item.id != evento.id );

    dispatch(setHorarios(horarios));
    dispatch(change(form_name, 'horario', horario));

    // dispatch(calcularTotales());
};

const editar = (data, id) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`aula/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push('/cursos_individuales'));
    }).catch(() => {
        NotificationManager.error('Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const agregarReasignacion = (values) => (dispatch, getStore) => {
    const resource = getStore().aula;
    const form = getStore().form.CambioProfeForm.values;
    const cursos = form.cursos ? form.cursos : [];
    const alumnos = resource.alumnos.map( item => {
        const _item = _.find(values.alumnos, {alumno: item.id});
        if (_item) {
            item.reasignado = true;
        };

        return item;
    });
    dispatch(setData(DATA_ALUMNOS, alumnos));
    cursos.push({...values, profesor: values.profesor.id});
    dispatch(change('CambioProfeForm', 'cursos', cursos));
    dispatch(limpiar());
};

const reasignar = (values, id, es_grupal) => (dispatch) => {
    api.post(`aula/${id}/reasignar_alumnos`, values).then((response) => {
        NotificationManager.success('Se han reasignado todos los alumnos', 'Éxito', 3000);
        if (es_grupal) {
            dispatch(push('/cursos_grupales'));
        } else {
            dispatch(push('/cursos_individuales'));
        }
        dispatch(setHorarios(response));
    }).catch(() => {
        NotificationManager.error('Error al reasignar alumnos', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const limpiar = () => (dispatch, getStore) => {
    dispatch(initializeForm('HorarioForm', {}));
    // dispatch(initializeForm('CambioProfeForm', {}));
    dispatch(setHorarios([]));
}

const limpiarTodo = () => (dispatch, getStore) => {
    dispatch(initializeForm('HorarioForm', {}));
    dispatch(initializeForm('CambioProfeForm', {}));
    dispatch(setHorarios([]));
}


export const actions = {
    listarRecuperaciones,
    leer,
    listarAlumnos,
    obtenerHorario,
    obtenerHorarioProfe,
    preCargarEvento,
    agregarHorario,
    eliminarReserva,
    calcularCargos,
    editar,
    agregarReasignacion,
    reasignar,
    // Recuperaciones
    filtroCursoRecuperaciones,
    filtroAlumnoRecuperaciones,
    filtroProfesorRecuperaciones,
    limpiar,
    limpiarTodo,
}

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DATA_ALUMNOS]: (state, { data }) => {
        return {
            ...state,
            alumnos: data,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [HORARIOS_OCUPADOS]: (state, { horarios }) => {
        return {
            ...state,
            horarios,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    // Recuperaciones
    [FILTRO_ALUMNO_TIEMPO_RECUPERACION]: (state, { alumno }) => {
        return {
            ...state,
            alumno,
        };
    },
    [FILTRO_CURSO_TIEMPO_RECUPERACION]: (state, { curso }) => {
        return {
            ...state,
            curso,
        };
    },
    [FILTRO_PROFESOR_TIEMPO_RECUPERACION]: (state, { profesor }) => {
        return {
            ...state,
            profesor,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    alumnos: [],
    item: {},
    page: 1,
    horarios: [],
    alumno: null,
    curso: null,
    profesor: null,
};

export default handleActions(reducers, initialState);
