import React, { useState, Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { Link } from 'react-router-dom';
import { renderField, renderNumber, AsyncSelectField, renderDatePicker, renderFieldCheck, SelectField, renderMonthPicker, renderCurrency } from '../../../Utils/renderField';
import { RenderCurrency, RenderNumber } from '../../../Utils/renderField/renderReadField';
import Calendario from '../../../Utils/Calendario/Calendario';
import { api } from '../../../../../utility/api';
import { USER_ALUMNO, USER_PROFESOR, meses } from '../../../../../utility/constants';
import moment from 'moment';


const getProfesores = (search) => {
    let params = { tipo_usuario: USER_PROFESOR }
    if (search) {
        params.search = search;
    }
    return api.get("user", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
};

const getAlumnos = (search) => {
    let params = { tipo_usuario: USER_ALUMNO }
    if (search) {
        params.search = search;
    }
    return api.get("user", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
};

const getSeriesFacturas = (search) => {
    let params = {}
    if (search) {
        params.search = search;
    }
    return api.get("serie_factura", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
}

const renderAlumnos = ({ fields }) => (
    <div className="row col-lg-8 col-12">
        <div className="col-12 px-0">
            {fields.map((alumno, index) => {
                return(
                    <div key={index}>
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor={`${alumno}.alumno`} className="m-0">{`Alumno no. ${index+1}`}</label>
                        </div>
                        <div className="col-md-12 form-group row">
                            <div className="col-10">
                                <Field
                                    name={`${alumno}.alumno`}
                                    loadOptions={getAlumnos}
                                    type="text"
                                    component={AsyncSelectField}
                                    valueKey="id"
                                    labelKey="titulo"
                                    isClearable={true}
                                    className="form-control"
                                />
                            </div>
                            <div className="col-2 d-flex align-items-center px-0">
                                <button className ="btn-delete" onClick={(e) =>{e.preventDefault(); fields.remove(index)}}><span className="material-icons rosa">close</span></button>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
        <div className="col-12 form-group mt-sm-3 mt-0" style={{paddingLeft: "1rem", paddingRight: "6rem"}}>
            <button type="button" className="btn btn-dark btn-block" onClick={() => fields.push({})}>Agregar</button>
        </div>
    </div>
);

class Asignacion extends Component {

    render() {

        const { handleSubmit } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <div className="form-group grid-container mx-md-5 px-md-5">
                    <div className="col-12">
                        <span className="text-sm azul-claro bold">Datos Generales</span>
                        <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                    </div>
                    <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="nombre" className="m-0">Nombre del Curso</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="nombre" component={renderField} type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="profesor" className="m-0">Profesor</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name="profesor"
                                    loadOptions={getProfesores}
                                    type="text"
                                    component={AsyncSelectField}
                                    valueKey="id"
                                    labelKey="titulo"
                                    isClearable={false}
                                    className="form-control"
                                    onCambio={(value) => {
                                        if (value) {
                                            this.props.obtenerHorario(value.id);
                                            this.props.preCargarPrecio(value);
                                            // this.props.calcularTotales();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="horas_tutorias" className="m-0">Cantidad maxíma de horas de tutoría</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="horas_tutorias" component={renderNumber} type="text" suffix=" hora(s)" className="form-control" />
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="fecha_maxima_pago" className="m-0">Fecha máxima de pago</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="fecha_maxima_pago" component={renderDatePicker} />
                            </div>
                        </div>
                    </div>
                    <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="fecha_inicio" className="m-0">Fecha inicio</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="fecha_inicio" component={renderDatePicker} onCambio={this.props.calcularTotales} />
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="fecha_fin" className="m-0">Mes de finalización</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="fecha_fin" component={renderMonthPicker} placeholder="Seleccione un mes..." onCambio={this.props.calcularTotales} />
                                {/* <Field name="fecha_fin" component={SelectField} options={meses} placeholder="Seleccione un mes..." type="text" className="form-control" onCambio={this.props.calcularTotales}/> */}
                            </div>
                        </div>
                    </div>
                    <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="precio_hora" className="m-0">Precio por hora</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="precio_hora" component={renderCurrency} onCambio={this.props.calcularTotales} type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="creditos_clase" className="m-0">Créditos por clase</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="creditos_clase" component={renderNumber} type="text" className="form-control" />
                            </div>
                        </div>
                    </div>
                    <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="meses_renovacion" className="m-0">Meses a renovar</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="meses_renovacion" component={renderNumber} suffix=" meses" type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="serie_factura" className="m-0">Serie de factura</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name="serie_factura"
                                    loadOptions={getSeriesFacturas}
                                    type="text"
                                    component={AsyncSelectField}
                                    valueKey="id"
                                    labelKey="prefijo"
                                    isClearable={true}
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                        <div className="col-md-6 p-0">
                            <div className="col-md-12">
                                <Field name="curso_empresa" component={renderFieldCheck} label=" Curso de empresa"/>
                            </div>
                            <div className="col-md-12">
                                <Field name="renovar_curso_empresa" component={renderFieldCheck} label=" Renovar curso de empresa"/>
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="col-md-12">
                                <Field name="curso_calificable" component={renderFieldCheck} label=" Curso calificable"/>
                            </div>
                            <div className="col-md-12">
                                <Field name="tiene_iva" component={renderFieldCheck} label=" Agregar iva" onCambio={this.props.calcularTotales}/>
                            </div>
                        </div>
                    </div>
                    <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                        <div className="d-flex flex-1 justify-content-center align-items-center">
                            <div className="d-flex flex-md-row flex-column justify-content-center align-items-center">
                                <h2 className="mr-2">Total de horas</h2>
                                <RenderNumber value={this.props.horas_prometidas} decimalScale={2} className="h2 rosa" />
                            </div>
                        </div>
                        <div className="d-flex flex-1 justify-content-center align-items-center">
                            <div className="d-flex flex-md-row flex-column justify-content-center align-items-center ">
                                <h2 className="mr-2">Total a pagar</h2>
                                <RenderCurrency value={this.props.precio_total} className="h2 verde" />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <span className="text-sm azul-claro bold">Alumnos</span>
                        <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                    </div>
                    <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                        <div className="col-md-12 p-0">
                            <div className="col-md-12 px-0">
                                <FieldArray name="alumnos" component={renderAlumnos}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-group grid-container">
                    <div className="d-flex flex-1 w-100" style={{ overflowX: "auto" }}>
                        <Calendario
                            eventos={this.props.horarios}
                            toolbar={true}
                            onlySemana={true}
                            onSelectSlot={this.props.openModal}
                            onSelectEvent={this.props.eliminarReserva}
                        />
                    </div>
                    <div className="d-none d-lg-flex buttons-box mt-5">
                        <Link className="btn btn-secondary mr-3" to="/cursos_individuales">Cancelar</Link>
                        <button type="submit" className="btn btn-primary ml-3">Guardar</button>
                    </div>
                    <div className="d-flex d-lg-none flex-column mt-5">
                        <Link className="w-100 btn btn-secondary mb-3" to="/cursos_individuales">Cancelar</Link>
                        <button type="submit" className="w-100 btn btn-primary">Guardar</button>
                    </div>
                </div>
            </form>
        );
    }
};

const selector = formValueSelector('AsignacionForm');

const AsignacionForm = reduxForm({
    form: 'AsignacionForm', // a unique identifier for this form
    asyncBlurFields: [],
    initialValues: {
        fecha_maxima_pago: moment(),
        fecha_inicio: moment(),
        fecha_fin: moment(),
        horas_prometidas: 0,
        precio_total: 0,
        clase_grupal: false,
        tiene_iva: false,
        curso_empresa: false,
        renovar_curso_empresa: false,
        curso_calificable: false,
        precio_hora: 0,
    },
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()('Campo requerido.'),
            profesor: validators.exists()('Campo requerido.'),
            serie_factura: validators.exists()('Campo requerido.'),
            horas_tutorias: validators.exists()('Campo requerido.'),
            fecha_maxima_pago: validators.exists()('Campo requerido.'),
            fecha_inicio: validators.exists()('Campo requerido.'),
            fecha_fin: validators.exists()('Campo requerido.'),
            meses_renovacion: validators.exists()('Campo requerido.'),
            precio_hora: validators.exists()('Campo requerido.'),
            creditos_clase: validators.exists()('Campo requerido.'),
        });
    },
})(Asignacion);

const mstp = state => {
    const horas_prometidas = selector(state, 'horas_prometidas');
    const fecha_fin = selector(state, 'fecha_fin');
    const precio_total = selector(state, 'precio_total');
    let horario = selector(state, 'horario');

    horario = horario ? horario.length : 0;

    return {
        horas_prometidas,
        fecha_fin,
        precio_total,
        horario
    }
};

export default connect(mstp, null)(AsignacionForm);
