import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import FacturaForm from './FacturaForm';
import { NotificationManager } from 'react-notifications';

export default class MensajeCrear extends Component {

    componentWillMount(){
        this.props.obtenerConfigPlataforma();
    }

    onSubmit = (values) => {
        if (!values.detalle_factura) {
            NotificationManager.error('Ingrese al menos un servicio', 'ERROR');
        } else {
            const { crear } = this.props;
            crear({...values, serie_factura: values.serie_factura.id});
        }
    }

    render() {
        const { loader } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm col={10}>
                    <LoadMask loading={loader} blur>
                        <FacturaForm onSubmit={this.onSubmit} config={this.props.config}/>
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
