import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm, change} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import _ from "lodash";
import {reset} from 'redux-form';
import moment from 'moment';
import { EJERCICIO, EXAMEN, OPC_MULT } from "../../../utility/constants";
import { EditorState, convertFromHTML, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const LOADER = 'LOADER_FORMULARIO';
const DATA = 'DATA_FORMULARIO';
const ITEM_DATA = 'ITEM_FORMULARIO';
const PAGE = 'PAGE_FORMULARIO';
const ORDERING = 'ORDERING_FORMULARIO';
const SEARCH = 'SEARCH_FORMULARIO';
const ARCHIVOS = 'ARCHIVOS_FORMULARIO';
const SEARCH_ARCHIVOS = 'SEARCH_ARCHIVOS_FORMULARIO';
const NIVEL_ARCHIVOS = 'NIVEL_ARCHIVOS_FORMULARIO';
const USUARIO = 'USUARIO_FORMULARIO';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = (type, search) => ({
    type,
    search,
});

const setUsuario = data => ({
    type: USUARIO,
    data,
});


// ------------------------------------
// Actions
// ------------------------------------

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().formularios;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    if (resource.usuario)
        params.subido_por = resource.usuario.id;
    dispatch(setLoader(true));
    api.get('formulario', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch) => {
    const params = {};
    dispatch(setLoader(true));
    api.get(`${'formulario'}/${id}`, params).then((response) => {
        const blocksFromHtml = htmlToDraft(response.notas ? response.notas : "");
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        const _data = { ...response, notas: EditorState.createWithContent(contentState) };
        let index = 0
        _data.preguntas = _data.preguntas.map( item => {
            if (item.tipo == OPC_MULT) {
                index = _.findIndex(item.respuestas, respuesta => respuesta.respuesta_correcta == true )
                item.respuesta_correcta = index;
            }
            return item;
        })
        dispatch(setItem(response));
        dispatch(initializeForm('formularioForm', _data));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    const _data = data;
    if (_data.tipo === EJERCICIO) {
        _data.fecha_entrega = moment(_data.fecha_entrega).format('YYYY-MM-DD');
    }
    if (_data.tipo === EXAMEN) {
        _data.fecha_hora_examen = moment(_data.fecha_examen).format('YYYY-MM-DD') + ' ' +_data.hora_examen;
    }
    const body = {..._data}
    if (_data.notas) {
        const draw = _data.notas;
        const html = draftToHtml(convertToRaw(draw.getCurrentContent()))
        body.notas = html;
    }
    dispatch(setLoader(true));
    api.post('formulario', body).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(cleanForm()); // Limpiar el formulario
        dispatch(push('/formularios'));
    }).catch((error) => {
        let mensaje = "Error en la creación";
        if (error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const editar = (data, id) => (dispatch) => {
    const _data = data;
    if (_data.tipo === EJERCICIO) {
        _data.fecha_entrega = moment(_data.fecha_entrega).format('YYYY-MM-DD');
    }
    if (_data.tipo === EXAMEN) {
        _data.fecha_hora_examen = moment(_data.fecha_examen).format('YYYY-MM-DD') + ' ' +_data.hora_examen;
    }
    const body = {..._data}
    if (_data.notas) {
        const draw = _data.notas;
        const html = draftToHtml(convertToRaw(draw.getCurrentContent()))
        body.notas = html;
    }
    dispatch(setLoader(true));
    api.put(`${'formulario'}/${id}`, body).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push('/formularios'));
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${'formulario'}/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('formularioForm', {}));
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(SEARCH, search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().formularios.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const obtenerArchivos = () => (dispatch, getStore) => {
    const resource = getStore().formularios;
    const params = { search: resource.search_archivos };
    if (resource.nivel)
        params.nivel = resource.nivel.id
    dispatch(setLoader(true));
    api.get('archivos', params).then((response) => {
        dispatch(setArchivos(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChangeArchivos = search => (dispatch) => {
    dispatch(setSearch(SEARCH_ARCHIVOS, search));
    dispatch(obtenerArchivos());
};

const changeNivel = nivel => (dispatch) => {
    dispatch(setSearch(NIVEL_ARCHIVOS, nivel));
    dispatch(obtenerArchivos());
};

const setArchivos = archivos => (dispatch) => {
    dispatch({type: ARCHIVOS, archivos});
};


const setearArchivo = (archivo) => (dispatch, getStore) => {
    const resource = getStore().form.formularioForm.values;
    const archivos = resource.archivos ? _.cloneDeep(resource.archivos) : [];

    archivos.push({
        archivo: {...archivo}
    });

    dispatch(change('formularioForm', 'archivos', archivos));
};


const eliminarArchivo = (index) => (dispatch, getStore) => {
    const resource = getStore().form.formularioForm.values;
    let archivos = _.cloneDeep(resource.archivos);

    archivos = _.filter(archivos, (item, _index) => index != _index);

    dispatch(change('formularioForm', 'archivos', archivos));
};

const clearSelect = (e) => (dispatch) => {
    dispatch(change('formularioForm', 'nivel', null));
}

const changeUsuario = value => (dispatch) => {
    dispatch(setUsuario(value));
    dispatch(listar());
};

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    cleanForm,
    obtenerArchivos,
    searchChangeArchivos,
    setearArchivo,
    eliminarArchivo,
    clearSelect,
    changeNivel,
    changeUsuario,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [ARCHIVOS]: (state, { archivos }) => {
        return {
            ...state,
            archivos,
        };
    },
    [SEARCH_ARCHIVOS]: (state, { search }) => {
        return {
            ...state,
            search_archivos: search,
        };
    },
    [NIVEL_ARCHIVOS]: (state, { search }) => {
        return {
            ...state,
            nivel: search,
        };
    },
    [USUARIO]: (state, { data }) => {
        return {
            ...state,
            usuario: data,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    archivos: [],
    search_archivos: '',
    nivel: null,
    usuario: null,
};

export default handleActions(reducers, initialState);
