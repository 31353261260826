import React, { Component, Fragment } from 'react';
import Modal from 'react-responsive-modal';
import {NotificationManager} from "react-notifications";
import Swal from 'sweetalert2';
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';

import AlumnosForm from './AlumnosForm';
import HorarioForm from './HorarioForm';
import EventoModalForm from './EventoModalForm';
import { DISPONIBLE, RESERVAR } from '../../../../../utility/constants';


export default class CambioProfesor extends Component {

    state = {
        step: 1,
        modal: false,
    };

    componentWillMount() {
        this.props.limpiarTodo();
        if (this.props.match.params.id) {
            this.props.leer(this.props.match.params.id);
            this.props.listarAlumnos(this.props.match.params.id);
        }
    };

    componentWillUnmount() {
        this.props.limpiarTodo();
    }

    openModal = (event) => {
        this.props.preCargarEvento(event);
        this.setState({ modal: true });
    };

    closeModal = () => {
        this.setState({ modal: false });
    };

    agregarEvento = (values) => {
        this.props.agregarHorario(values, 'HorarioForm');
        this.closeModal();
    };

    eliminarReserva = (evento) => {
        if (evento.tipo == RESERVAR) {
            Swal.fire({
                title: '¿Borrar el horario reservado?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, borrar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminarReserva(evento, 'HorarioForm');
                }
            });
        }
    };

    nextStep = () => {
        this.setState({ step: 2 })
    };

    previousStep = () => {
        this.setState({ step: 1 })
    };

    guardarCurso = (values) => {
        const { item, agregarReasignacion } = this.props;
        if (!values.alumnos) {
            NotificationManager.error('Seleccione al menos un alumno', 'ERROR');
        } else if (!values.horario) {
            NotificationManager.error('Ingrese al menos un horario', 'ERROR');
        } else if (values.profesor.id == item.profesor.id) {
            NotificationManager.error('Seleccione un profesor diferente', 'ERROR');
        }else {
            agregarReasignacion(values);
            this.previousStep();
        }
    }

    handleSubmit = (values) => {
        const { item, reasignar, alumnos } = this.props;
        let bandera = true;
        for (const item of alumnos ) {
            if (!item.reasignado) {
                bandera = false;
                break;
            }
        }
        if (bandera) {
            reasignar(values, this.props.match.params.id, item.clase_grupal);
        } else {
            NotificationManager.error('Se deben de reasignar todos los alumnos', 'ERROR');
        }
    };

    renderModal = () => {
        return (
            <Modal
                open={this.state.modal}
                onClose={(e) => {
                    this.closeModal();
                }}
                center
            >
                <Fragment>
                    <div className="my-4">
                        <div className="row col-12">
                            <span className="text-sm azul-claro bold">Asignación de horario</span>
                            <div className="d-flex w-100 bg-azul-claro" style={{ height: '3px' }} />
                        </div>
                        <div className="row col-12">
                            <EventoModalForm
                                cancelar={this.closeModal}
                                onSubmit={this.agregarEvento}
                            />
                        </div>
                    </div>
                </Fragment>
            </Modal>
        )
    };

    render() {
        const { step } = this.state;
        const { loader, item } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm col={12}>
                    <LoadMask loading={loader} blur>
                        {
                            step == 1 ? (
                                <AlumnosForm
                                    {...this.props}
                                    nextStep={this.nextStep}
                                    onSubmit={this.handleSubmit}
                                />
                            ) : (
                                <HorarioForm
                                    {...this.props}
                                    cancelar={this.previousStep}
                                    onSubmit={this.guardarCurso}
                                    idAula={this.props.match.params.id}
                                    openModal={this.openModal}
                                    eliminarReserva={this.eliminarReserva}
                                />
                            )
                        }
                    </LoadMask>
                    {this.renderModal()}
                </CardForm>
            </div>
        )
    }

}
