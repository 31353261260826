import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import { initialize as initializeForm, change } from "redux-form";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import { getMe } from '../cuenta/login';
import moment from 'moment';
import _ from "lodash";

const LOADER = 'LOADER_PERFIL';
const DATA = 'DATA_PERFIL';
const ITEM_DATA = 'ITEM_PERFIL';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (type, data) => ({
    type,
    data
})

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

// ------------------------------------
// Actions
// ------------------------------------

const leer = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get('user/me').then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm('EditarPerfilForm', response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (data, attachments = []) => (dispatch) => {
    dispatch(setLoader(true));
    api.putAttachments(`user/update_me`, data, attachments).then(() => {
        NotificationManager.success('Perfil actualizado', 'Éxito', 3000);
        dispatch(push('/perfil'));
        dispatch(getMe());
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const actions = {
    leer,
    editar,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
};

export default handleActions(reducers, initialState);

