import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import Card from "Utils/Cards/Card";
import Header from "Utils/Grid/Header";
import {formatActivo} from "Utils/renderField/renderReadField";
import moment from 'moment';

export default class CuponesList extends Component{
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar: onPageChange, onSortChange, eliminar } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return(
            <div className="mb-4 col-12 mt-4">
                <Header to="serie_factura/crear" textBtn="Nueva serie" {...this.props} />
                <Card>
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                    page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='15%'
                            dataFormat={standardActions({ editar: "serie_factura", eliminar})}
                        />
                        <TableHeaderColumn
                            dataField="nombre_emisor"
                            dataSort
                        >
                            NOMBRE DEL EMISOR
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="prefijo"
                            dataSort
                        >
                            PREFIJO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="numeracion"
                            dataSort
                        >
                            NUMERACIÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tiene_iva"
                            dataSort
                            dataFormat={(cell) => cell ? "SI" : "NO"}
                        >
                            IVA
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
