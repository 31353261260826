import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import moment from 'moment';
import _ from "lodash";

const LOADER = 'LOADER_CUPONES';
const DATA = 'DATA_CUPONES';
const DATA2 = 'DATA2_CUPONES';
const GRAFICAS = 'GRAFICAS_CUPONES';
const PAGE = 'PAGE_CUPONES';
const PAGE2 = 'PAGE2_CUPONES';
const COLABORADOR = 'COLABORADOR_CUPONES';
const ENCABEZADO = 'ENCABEZADO_CUPONES';
const CENTRO = 'CENTRO_CUPONES';
const RECURRENTE = 'RECURRENTE_CUPONES';
const FECHA_INICIO = 'FECHA_INICIO_CUPONES';
const FECHA_FIN = 'FECHA_FIN_CUPONES';


// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (type, data) => ({
    type,
    data,
});

const setPage = (type, page) => ({
    type,
    page,
});


// ------------------------------------
// Actions
// ------------------------------------

const listarGrafica = () => (dispatch, getStore) => {
    const resource = getStore().reporteCupones;
    const params = {};
    if (resource.colaborador)
        params.colaborador = resource.colaborador.id;
    if (resource.encabezado_cupon)
        params.encabezado_cupon = resource.encabezado_cupon.id;
    if (resource.centro)
        params.centro = resource.centro.id;
    if (resource.recurrente)
        params.recurrente = resource.recurrente.value;
    if (resource.fecha_inicio)
        params.fecha_inicio = moment(resource.fecha_inicio).format('YYYY-MM-DD');
    if (resource.fecha_fin)
        params.fecha_fin = moment(resource.fecha_fin).format('YYYY-MM-DD');
    dispatch(setLoader(true));
    api.get('reportes/codigos_graficas', params).then((response) => {
        dispatch(setData(GRAFICAS, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarCanjeados = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().reporteCupones;
    const params = { page };
    if (resource.colaborador)
        params.colaborador = resource.colaborador.id;
    if (resource.encabezado_cupon)
        params.encabezado_cupon = resource.encabezado_cupon.id;
    if (resource.centro)
        params.centro = resource.centro.id;
    if (resource.recurrente)
        params.recurrente = resource.recurrente.value;
    if (resource.fecha_inicio)
        params.fecha_inicio = moment(resource.fecha_inicio).format('YYYY-MM-DD');
    if (resource.fecha_fin)
        params.fecha_fin = moment(resource.fecha_fin).format('YYYY-MM-DD');
    dispatch(setLoader(true));
    api.get('reportes/codigos_canjeados', params).then((response) => {
        dispatch(setData(DATA, response));
        dispatch(setPage(PAGE, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarNoCanjeados = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().reporteCupones;
    const params = { page };
    if (resource.colaborador)
        params.colaborador = resource.colaborador.id;
    if (resource.encabezado_cupon)
        params.encabezado_cupon = resource.encabezado_cupon.id;
    if (resource.centro)
        params.centro = resource.centro.id;
    if (resource.recurrente)
        params.recurrente = resource.recurrente.value;
    if (resource.fecha_inicio)
        params.fecha_inicio = moment(resource.fecha_inicio).format('YYYY-MM-DD');
    if (resource.fecha_fin)
        params.fecha_fin = moment(resource.fecha_fin).format('YYYY-MM-DD');
    dispatch(setLoader(true));
    api.get('reportes/codigos_sin_canjear', params).then((response) => {
        dispatch(setData(DATA2, response));
        dispatch(setPage(PAGE2, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const changeColaborador = value => (dispatch) => {
    dispatch(setData(COLABORADOR, value));
    dispatch(listarGrafica());
    dispatch(listarCanjeados());
    dispatch(listarNoCanjeados());
};

const changeEncabezado = value => (dispatch) => {
    dispatch(setData(ENCABEZADO, value));
    dispatch(listarGrafica());
    dispatch(listarCanjeados());
    dispatch(listarNoCanjeados());
};

const changeCentro = value => (dispatch) => {
    dispatch(setData(CENTRO, value));
    dispatch(listarGrafica());
    dispatch(listarCanjeados());
    dispatch(listarNoCanjeados());
};

const changeRecurrente = value => (dispatch) => {
    dispatch(setData(RECURRENTE, value));
    dispatch(listarGrafica());
    dispatch(listarCanjeados());
    dispatch(listarNoCanjeados());
};

const changeFechaInicio = value => (dispatch) => {
    dispatch(setData(FECHA_INICIO, value));
    dispatch(listarGrafica());
    dispatch(listarCanjeados());
    dispatch(listarNoCanjeados());
};

const changeFechaFin = value => (dispatch) => {
    dispatch(setData(FECHA_FIN, value));
    dispatch(listarGrafica());
    dispatch(listarCanjeados());
    dispatch(listarNoCanjeados());
};

export const actions = {
    listarGrafica,
    listarCanjeados,
    listarNoCanjeados,
    changeColaborador,
    changeEncabezado,
    changeCentro,
    changeRecurrente,
    changeFechaInicio,
    changeFechaFin
};


// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DATA2]: (state, { data }) => {
        return {
            ...state,
            data2: data,
        };
    },
    [GRAFICAS]: (state, { data }) => {
        return {
            ...state,
            graficas: data,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page: page,
        };
    },
    [PAGE2]: (state, { page }) => {
        return {
            ...state,
            page2: page,
        };
    },
    [COLABORADOR]: (state, { data }) => {
        return {
            ...state,
            colaborador: data,
        };
    },
    [ENCABEZADO]: (state, { data }) => {
        return {
            ...state,
            encabezado_cupon: data,
        };
    },
    [CENTRO]: (state, { data }) => {
        return {
            ...state,
            centro: data,
        };
    },
    [RECURRENTE]: (state, { data }) => {
        return {
            ...state,
            recurrente: data,
        };
    },
    [FECHA_INICIO]: (state, { data }) => {
        return {
            ...state,
            fecha_inicio: data,
        };
    },
    [FECHA_FIN]: (state, { data }) => {
        return {
            ...state,
            fecha_fin: data,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    data2: {
        results: [],
        count: 0,
    },
    graficas: {
        usados: 0,
        cupones: 0,
    },
    page: 1,
    page2: 1,
    colaborador: null,
    encabezado_cupon: null,
    centro: null,
    recurrente: null,
    fecha_inicio: null,
    fecha_fin: null,
};

export default handleActions(reducers, initialState);

