import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";
import {formatActivo} from "Utils/renderField/renderReadField"
import moment from "moment"

export default class SeccionesList extends Component{
    componentWillMount() {
        const { listarSecciones, page_secciones } = this.props;
        listarSecciones(page_secciones);
    }

    render() {
        const { listarSecciones: onPageChangeSeccion, onSortChangeSeccion, eliminarSeccion, searchChangeSeccion } = this.props; //Funciones
        const { data_secciones, loader_secciones, page_secciones, search_secciones } = this.props; //Constantes
        return(
            <div className="mb-4 col-12 mt-4">
                <Header to="seccion/crear" textBtn="Agregar" search={search_secciones} searchChange={searchChangeSeccion} placeholder="Buscar por curso"/>
                <Card>
                    <Grid hover striped data={data_secciones} loading={loader_secciones} onPageChange={onPageChangeSeccion} onSortChange={onSortChangeSeccion} page={page_secciones}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='12%'
                            dataFormat={standardActions({ reasignar: "cursos", eliminar: eliminarSeccion })}
                        >
                            ACCIONES
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="curso_activo"
                            width='10%'
                            dataFormat={(cell) => cell  ? (
                                <div className="d-flex flex-row align-items-center">
                                    <div className="circulo bg-aqua mr-1"></div>
                                    <span className="aqua ml-1">Activo</span>
                                </div>
                            ) : (
                                <div className="d-flex flex-row align-items-center">
                                    <div className="circulo bg-rosa mr-1"></div>
                                    <span className="rosa ml-1">Inactivo</span>
                                </div>
                            )}
                        >
                            Estado
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="curso"
                            dataSort
                            dataFormat={(cell) => cell && cell.titulo ? cell.titulo : "Sin curso asignado"}
                        >
                            CURSO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="curso"
                            width='10%'
                            dataFormat={ cell => cell.interno ? <span className="azul-claro">Interno</span> : <span className="mandarina">Externo</span>}
                        >
                            TIPO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre"
                            dataSort
                        >
                            NOMBRE SECCIÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="profesor"
                            dataSort
                            dataFormat={(cell) => cell && cell.nombres && cell.apellidos ? cell.nombres + " " + cell.apellidos : ""}
                        >
                            PROFESOR
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_inicio"
                            dataSort
                            dataFormat={(cell) => cell ? moment(cell).format("DD/MM/YYYY") : ""}
                        >
                            FECHA INICIO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_fin"
                            dataSort
                            dataFormat={(cell) => cell ? moment(cell).format("DD/MM/YYYY") : ""}
                        >
                            FECHA FINAL
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
