import React, {Component, Fragment} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import Card from "Utils/Cards/Card";
import LoadMask from 'Utils/LoadMask/LoadMask';
import moment from 'moment';
import { CUPON_UNICO, CUPON_MULTIPLE, DINERO, CLASE_INDIVIDUAL_CUPONES, CLASE_GRUPAL_CUPONES } from '../../../../../utility/constants';
import { RenderNumber, RenderCurrency } from '../../../Utils/renderField/renderReadField';
import CardForm from '../../../Utils/Cards/CardForm';
import Swal from 'sweetalert2';


export default class VerComunicado extends Component{
    componentWillMount(){
        if(this.props.match.params.id){
            this.props.leer(this.props.match.params.id);
        }
    }

    aprobarComunicado = () => {
        Swal.fire({
            title: '¿Aprobar comunicado?',
            text: '¡No podrá editar este comunicado ya que se enviara por correo electronico!',
            type: 'question',
            showCancelButton: true,
            confirmButtonText: '¡Sí, enviar!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.aprobarPendientes(this.props.match.params.id)
            }
        });
    };

    rechazarComunicado = () => {
        Swal.fire({
            title: '¿Rechazar comunicado?',
            text: '¡Si rechaza el comunicado este no se enviara a los destinatarios!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, rechazar!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.rechazarPendientes(this.props.match.params.id)
            }
        });
    };

    render() {
        const { item_pendientes, loader_pendientes } = this.props; //Constantes
        const destinatarios = item_pendientes && item_pendientes.destinatarios ? {results: item_pendientes.destinatarios} : {results: []};
        return(
            <div className="mb-4 col-12 mt-4">
                <CardForm col={10}>
                    <LoadMask loading={loader_pendientes} blur>
                        <div className="col-12 mb-3 px-0">
                            <span className="text-sm azul-claro bold">Usuario que envio el comunicado</span>
                            <div className="d-flex w-100 bg-gris-oscuro" style={{height: '3px'}}/>
                        </div>
                        <div className="col-12 px-0 mb-3">
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <div className="d-flex flex-column">
                                        <span className="bold">Nombre del usuario</span>
                                        <span>{item_pendientes && item_pendientes.creador && item_pendientes.creador.titulo ? `${item_pendientes.creador.titulo}` : ""}</span>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <div className="d-flex flex-column">
                                        <span className="bold">Rol del usuario</span>
                                        <span>{item_pendientes && item_pendientes.creador && item_pendientes.creador.tipo_usuario_text ? `${item_pendientes.creador.tipo_usuario_text}` : ""}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mb-3 px-0">
                            <span className="text-sm azul-claro bold">Destinatarios</span>
                            <div className="d-flex w-100 bg-gris-oscuro" style={{height: '3px'}}/>
                        </div>
                        <div className="col-12 px-0 mb-3">
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <div className="d-flex flex-column">
                                        <span className="bold">Tipo de destinatario</span>
                                        <span>{item_pendientes && item_pendientes.tipo_destinatario_text ? item_pendientes.tipo_destinatario_text : ""}</span>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <div className="d-flex flex-column">
                                        <span className="bold">Cantidad de destinatario</span>
                                        <span>{item_pendientes && item_pendientes.tipo_mensaje ? item_pendientes.tipo_mensaje : ""}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Grid hover striped data={destinatarios} loading={loader_pendientes} pagination={false}>
                            <TableHeaderColumn
                                dataField="id"
                                isKey
                                hidden
                            />
                            <TableHeaderColumn width="20%"/>
                            <TableHeaderColumn
                                dataField="usuario"
                                dataSort
                                dataAlign="center"
                                width="30%"
                                dataFormat={(cell) => cell && cell.titulo ? `${cell.titulo}` : ''}
                            >
                                USUARIO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="usuario"
                                dataSort
                                dataAlign="center"
                                width="30%"
                                dataFormat={(cell) => cell && cell.tipo_usuario_text ? cell.tipo_usuario_text  : ''}
                            >
                                TIPO USUARIO
                            </TableHeaderColumn>
                            <TableHeaderColumn width="20%"/>
                        </Grid>
                        <div className="mt-3"/>
                        <div className="col-12 mb-3 px-0 mb-3">
                            <span className="text-sm azul-claro bold">Mensaje</span>
                            <div className="d-flex w-100 bg-gris-oscuro" style={{height: '3px'}}/>
                        </div>
                        <div className="col-12 mb-3 px-0 mb-3">
                            <div className="d-flex flex-column">
                                <span className="text-sm bold">Titulo</span>
                                <span className="text-sm">{item_pendientes && item_pendientes.titulo ? item_pendientes.titulo : ""}</span>
                            </div>
                        </div>
                        {
                            item_pendientes && item_pendientes.imagen && (
                            <div className="col-12 mb-3 px-0 mb-3">
                                <img src={item_pendientes && item_pendientes.imagen ? item_pendientes.imagen : ""} alt="Imagen" className="img-fluid"/>
                            </div>
                        )}                
                        <div className="col-12 mb-3 px-0 mb-3">
                            <div className="d-flex flex-column">
                                <span className="text-sm bold">Contenido</span>
                                <p className="">{item_pendientes && item_pendientes.mensaje ? item_pendientes.mensaje : ""}</p>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap justify-content-center align-items-center mt-3">
                            <div className="col-sm-12 col-md-4 col-lg-3 col-12">
                                <Link className="btn btn-secondary btn-block" to="/comunicados">Regresar</Link>
                            </div>
                            {item_pendientes && item_pendientes.aprobado === false && (
                                <div className="col-sm-12 col-md-4 col-lg-3 col-12 mt-md-0 mt-3">
                                    <button type="button" className="btn btn-alter1 btn-block" onClick={() => this.rechazarComunicado()}>Rechazar</button>
                                </div>
                            )}
                            {item_pendientes && item_pendientes.aprobado === false && (
                                <div className="col-sm-12 col-md-4 col-lg-3 col-12 mt-md-0 mt-3">
                                    <button type="button" className="btn btn-primary btn-block" onClick={() => this.aprobarComunicado()}>Aprobar</button>
                                </div>
                            )}
                        </div>
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
