import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm } from 'redux-form';
import { renderField } from '../../../Utils/renderField/renderField';


const CentrosForm = props => {
    const { handleSubmit } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">Nombre del centro</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombre" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="responsable" className="m-0">Nombre del responsable</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="responsable" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="email" className="m-0">Email</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="email" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="telefono" className="m-0">Teléfono</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="telefono" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="cuenta_banco" className="m-0">Cuenta de banco</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="cuenta_banco" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nif" className="m-0">NIF / CIF</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nif" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="direccion" className="m-0">Dirección</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="direccion" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-5">
                    <div className="col-md-4 col-12">
                        <Link className="btn btn-secondary btn-block" to="/centros_colaboradores">Cancelar</Link>
                    </div>
                    <div className="col-md-4 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Guardar</button>
                    </div>
                </div>
            </div>
        </form>
    )
};

export default reduxForm({
    form: 'CentroColaboradorForm',
    asyncBlurFields: [],
    validate: data => {
        return validate(data, {
            'titulo': validators.exists()('Campo requerido.'),
            'ubicacion': validators.exists()('Campo requerido.'),
        })
    }
})(CentrosForm);
