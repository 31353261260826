import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import _ from "lodash";

const LOADER = 'LOADER_CONFIG_COMERCIAL';
const DATA = 'DATA_CONFIG_COMERCIAL';
const PAGE = 'PAGE_CONFIG_COMERCIAL';
const ITEM_DATA = 'ITEM_CONFIG_COMERCIAL';
const ITEM2 = 'ITEM2_CONFIG_COMERCIAL';
const SEARCH = 'SEARCH_CONFIG_COMERCIAL';
const ORDERING = 'ORDERING_CONFIG_COMERCIAL';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = (type, item) => ({
    type,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});


// ------------------------------------
// Actions
// ------------------------------------

const listarLogos = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().configComercial;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get('logos', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leerLogo = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`logos/${id}`).then((response) => {
        dispatch(setItem(ITEM2, response));
        dispatch(initializeForm('LogoForm', response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crearLogo = (data, attachments = []) => (dispatch) => {
    dispatch(setLoader(true));
    api.postAttachments('logos', data, attachments).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(push('/logos'));
    }).catch(() => {
        NotificationManager.error('Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editarLogo = (data, id, attachments = []) => (dispatch) => {
    dispatch(setLoader(true));
    api.putAttachments(`logos/${id}`, data, attachments).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push('/logos'));
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminarLogo = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`logos/${id}`).then(() => {
        dispatch(listarLogos());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const leer = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get('conf_comercial').then((response) => {
        dispatch(setItem(ITEM_DATA, response));
        dispatch(initializeForm('ConfigComercialForm', response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post('conf_comercial', data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(leer());
        // dispatch(push('/CONFIG_COMERCIAL'));
    }).catch((error) => {
        let mensaje = "Error en la creación";
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listarLogos());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().administradores.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listarLogos());
};


export const actions = {
    listarLogos,
    leerLogo,
    crearLogo,
    editarLogo,
    eliminarLogo,
    leer,
    crear,
    searchChange,
    onSortChange,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [ITEM2]: (state, { item }) => {
        return {
            ...state,
            item2: item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    item2: {},
    page: 1,
    ordering: '',
    search: '',
};

export default handleActions(reducers, initialState);
