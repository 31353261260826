import React, { Component, Fragment } from 'react';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Calendario from '../../../Utils/Calendario/Calendario';
import Modal from 'react-responsive-modal';
import { api } from 'api';
import Swal from 'sweetalert2';
import moment from 'moment';
import { CLASE_RECUPERACION, TUTORIA } from '../../../../../utility/constants';

import EventoDetalleModal from './EventoModal';
import ModalCrearEvento from './ModalCrearEvento';

export default class CalendarioProfesor extends Component {

    state = {
        modalDetalle: false,
        modalCrear: false,
        evento: null,
        error: null,
    }

    componentWillMount() {
        this.props.iniciarFechas();
        this.props.obtenerActividades(this.props.match.params.id);
        this.props.leer(this.props.match.params.id);
    };

    componentWillUnmount() {
        this.props.iniciarFechas();
        this.props.limpiarActividades();
    };

    refrescar = () => {
        this.props.obtenerActividades(this.props.match.params.id);
        this.props.leer(this.props.match.params.id);
    }

    openModalDetalle = (evento) => {
        this.props.setearFormEventoDetalle(evento);
        this.setState({ modalDetalle: true, evento: evento });
    };

    openModalCrear = (event) => {
        this.props.preCargarEvento(event);
        this.setState({ modalCrear: true });
    };

    closeModal = (e) => {
        this.setState({ modalDetalle: false, evento: null, modalCrear: false, error: null });
        this.props.resetDetalleAula();
    };

    anularClase = (data) => {
        Swal.fire({
            title: '¿Cancelar clase?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, cancelar clase!',
            cancelButtonText: 'No, salir',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                // this.props.eliminar(id);
                const { evento } = this.state;
                this.props.anularClase(evento.id, data, this.props.match.params.id);
                this.closeModal(null);
            }
        });
    };

    crearEvento = (values) => {
        const { detalle_aula, me } = this.props;
        const hora_inicio = moment(values.hora_inicio, "HH:mm");
        const hora_fin = moment(values.hora_fin, "HH:mm");
        const dif = (hora_fin - hora_inicio) / 3600000;

        if (values.tipo_evento == CLASE_RECUPERACION) {
            // Comprobamos si se pueden realizar las recuperaciones
            let bandera = true;
            for (const item of values.alumnos) {
                const alumno =  _.find(detalle_aula.alumnos_asignados, { id: item.alumno.id });
                if (alumno.horas_pendientes_recuperar < dif)
                    bandera = false;
            }
            if (bandera) {
                const data = {...values, aula: values.aula.id};
                data.alumnos = data.alumnos.map(item => item.alumno.id)
                this.props.crearEvento(data, this.props.match.params.id, this.closeModal);
            } else {
                this.setState({error: "*Error: El tiempo a reponer no coincide con el tiempo pendiente de los alumnos seleccionados"})
            }
        } else {
            // Es una tutoria
            const horas_resta = dif + values.aula.total_tutorias;
            if (horas_resta > values.aula.horas_tutorias) {
                this.setState({error: "*Error: Se excede el limite de horas de tutoria"})
            } else {
                const data = {...values, aula: values.aula.id};
                this.props.crearEvento(data, this.props.match.params.id, this.closeModal);
            }
        }

    }

    renderModalDetalle = () => {
        const { evento } = this.state;
        return (
            <Modal
                open={this.state.modalDetalle}
                onClose={this.closeModal}
                closeIcon={false}
                showCloseIcon={false}
                center
            >
                <Fragment>
                    <div className="my-4">
                        <div className="row col-12 mx-0">
                            <span className="text-sm azul-claro bold">{evento ? evento.titulo : ""}</span>
                            <div className="d-flex w-100 bg-azul-claro" style={{ height: '3px' }} />
                        </div>
                        <div className="row col-12 mx-0">
                            <EventoDetalleModal
                                evento={this.state.evento}
                                cancelar={this.closeModal}
                                onSubmit={this.anularClase}
                                obtenerLink={this.props.obtenerLink}
                                openClase={this.openClase}
                            />
                        </div>
                    </div>
                </Fragment>
            </Modal>
        )
    };

    getCursos = (search) => {
        let params = { profesor: this.props.match.params.id, clase_grupal: false }
        if (search) {
            params.search = search;
        }
        return api.get("aula", params).
            then((data) => {
                if (data) return data.results;
                return [];
            }).catch(() => {
                return [];
            });
    };

    renderModalCrear = () => {
        const { loader } = this.props;
        return (
            <Modal
                open={this.state.modalCrear}
                onClose={this.closeModal}
                closeIcon={false}
                showCloseIcon={false}
                center
            >
                <Fragment>
                    <div className="my-4">
                        <div className="row col-12 mx-0">
                            <span className="text-sm azul-claro bold">Crear clase</span>
                            <div className="d-flex w-100 bg-azul-claro" style={{ height: '3px' }} />
                        </div>
                        <div className="row col-12 mx-0">
                            <LoadMask loading={loader} blur>
                                <ModalCrearEvento
                                    cancelar={this.closeModal}
                                    onSubmit={this.crearEvento}
                                    getCursos={this.getCursos}
                                    obtenerDetalleAula={this.props.obtenerDetalleAula}
                                    detalle_aula={this.props.detalle_aula}
                                    errorModal={this.state.error}
                                />
                            </LoadMask>
                        </div>
                    </div>
                </Fragment>
            </Modal>
        )
    };

    changeFechas = (value) => {
        this.props.changeFechas(value, this.props.match.params.id);
    }

    changeTipoEvento = (value) => {
        this.props.changeTipoEvento(value, this.props.match.params.id);
    }

    openClase = (idAula, idClase) => {
        this.closeModal(null);
        setTimeout(() => {
            this.props.history.push(`/cursos/${idAula}/clase/${idClase}`);
        }, 700);
    }

    render() {
        const { eventos, tipo_evento, loader, item } = this.props;
        return (
            <div>
                {this.renderModalDetalle()}
                {this.renderModalCrear()}
                <LoadMask loading={loader} blur>
                    <div className="d-flex flex-1 flex-column w-100 mt-5 mt-md-4" style={{ height: "calc(100vh - 7.5rem)" }}>
                        <Calendario
                            eventos={eventos}
                            toolbar={true}
                            onlySemana={false}
                            height="100%"
                            tipo_evento={tipo_evento}
                            changeTipoEvento={this.changeTipoEvento}
                            changeFechas={this.changeFechas}
                            onSelectEvent={this.openModalDetalle}
                            titulo={`${item.nombres} ${item.apellidos}`}
                            onSelectSlot={this.openModalCrear}
                            refrescar={this.refrescar}
                        />
                    </div>
                </LoadMask>
            </div>
        )
    }
}
