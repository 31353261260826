import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/reportes/facturacionProfesor';
import ReporteFacturacionProfesor from './FacturacionProfesor';


const ms2p = (state) => {
    return {
        ...state.facturacionProfesor,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ReporteFacturacionProfesor);
