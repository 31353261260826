import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm } from 'redux-form';
import { renderField, renderImagePicker, renderFieldCheck, renderCurrency, renderEditorField2 } from '../../../Utils/renderField/renderField';


let FormServicio = props => {
    const { handleSubmit, editar, setImagen, item } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="col-12 mt-4">
                    <span className="text-sm azul-claro bold">Datos del servicio</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{height: '3px'}}/>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-4 p-0">
                        <div className="col-md-12 form-group">
                            <Field component={renderFieldCheck} className="form-control" name="visible" label={"Visible"} />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">Nombre del servicio *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombre" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="precio" className="m-0">Precio *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="precio" component={renderCurrency} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="descripcion" className="m-0">Descripción *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="descripcion" height="10%" height2="70%" component={renderEditorField2} />
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-4">
                    <span className="text-sm azul-claro bold">Imagen</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{height: '3px'}}/>
                </div>
                {(editar && item && item.imagen) && (
                    <div className="col-md-12 p-0 mb-3 mt-3">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="imagen" className="m-0">Imagen actual</label>
                        </div>
                        <div className="col-md-12 form-group text-center">
                            <img src={item.imagen} alt="imagen" className="img-fluid"/>
                        </div>
                    </div>
                )}
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="en_slider" className="m-0">Imagen *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                name="imagen"
                                setFile={setImagen}
                                component={renderImagePicker}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 text-right">
                    <span className="azul-marino text-xsm">* Campos requeridos</span>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-3">
                    <div className="col-md-4 col-12">
                        <Link className="btn btn-secondary btn-block" to="/servicios">Cancelar</Link>
                    </div>
                    <div className="col-md-4 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Guardar</button>
                    </div>
                </div>
            </div>
        </form>
    )
};

FormServicio = reduxForm({
    form: 'ServicioForm',
    asyncBlurFields: [],
    validate: data => {
        return validate(data, {
            'nombre': validators.exists()('Campo requerido.'),
            'descripcion': validators.exists()('Campo requerido.'),
            'precio': validators.exists()('Campo requerido.')
        })
    },
    initialValues: {
        visible: true,
    },
})(FormServicio);


export default FormServicio;
