import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import { EditorState, convertFromHTML, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {push} from "react-router-redux";
import moment from 'moment';
import { DIAS_SEMANA } from '../../../utility/constants';
import _ from "lodash";
import { setMe } from '../cuenta/login';
import { USER_ALUMNO } from '../../../utility/constants';

const LOADER = 'LOADER_CLASES';
const DATA = 'DATA_CLASES';
const DATA2 = 'DATA2_CLASES';
const ITEM = 'ITEM_CLASES';
const PAGE = 'PAGE_CLASES';
const PAGE2 = 'PAGE2_CLASES';
const ORDERING = 'ORDERING_CLASES';
const SEARCH = 'SEARCH_CLASES';
const SEARCH_CLASE = 'SEARCH_CLASE_CLASES';
const ESTADO_EVENTO = 'ESTADO_EVENTO_CLASES';
const FILTRO_FECHA = 'FILTRO_FECHA_CLASES';
const ARCHIVOS_CLASE = 'ARCHIVOS_CLASE_CLASES';
const PAGE_ARCHIVOS = 'PAGE_ARCHIVOS_CLASES';
const FORMULARIOS_CLASE = 'FORMULARIOS_CLASE_CLASES';
const PAGE_FORMULARIOS = 'PAGE_FORMULARIOS_CLASES';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setData2 = data2 => ({
    type: DATA2,
    data2,
});

const setItem = item => ({
    type: ITEM,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setPage2 = page2 => ({
    type: PAGE,
    page2,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

const setArchivosClase = archivos_clase => ({
    type: ARCHIVOS_CLASE,
    archivos_clase,
});

const setFormulariosClase = formularios_clase => ({
    type: FORMULARIOS_CLASE,
    formularios_clase,
});

const setArchivosPage = page_archivos => ({
    type: PAGE_ARCHIVOS,
    page_archivos,
});

const setFormulariosPage = page_formularios => ({
    type: PAGE_FORMULARIOS,
    page_formularios,
});


// ------------------------------------
// Actions
// ------------------------------------

const listar_cursos = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().cursosAlumno;
    const params = { page };
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get('aula/CLASES', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listar_clases = (id_aula, page = 1) => (dispatch, getStore) => {
    const resource = getStore().cursosAlumno;
    const params = { page, aula: id_aula };
    params.search = resource.search_clase;
    if (resource.estado_evento){
        params.estado_evento = resource.estado_evento.value;
    }
    if (resource.filtro_fecha){
        params.fecha = moment(resource.filtro_fecha).format('YYYY-MM-DD');
    }
    dispatch(setLoader(true));
    api.get(`clase`, params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarRecuperaciones = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().cursosAlumno;
    const params = { page };
    dispatch(setLoader(true));
    api.get('reportes/recuperaciones', params).then((response) => {
        dispatch(setData2(response));
        dispatch(setPage2(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtener_aula = (id_aula) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`aula/${id_aula}`).then((response) => {
        dispatch(setItem(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtener_clase = (id_clase) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`clase/${id_clase}`).then((response) => {
        const _data = { ...response};
        if (response.contenido) {
            const blocksFromHtml = htmlToDraft(response.contenido ? response.contenido : "");
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            _data.contenido = EditorState.createWithContent(contentState)
        }
        dispatch(setItem(_data));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtener_archivos_clase = (id_clase, page = 1) => (dispatch) => {
    const params = { page };
    dispatch(setLoader(true));
    api.get(`clase/${id_clase}/archivos_clase`, params).then((response) => {
        dispatch({type: ARCHIVOS_CLASE, response});
        dispatch(setArchivosClase(response));
        dispatch(setArchivosPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtener_formularios_clase = (id_clase, page = 1) => (dispatch) => {
    const params = { page };
    dispatch(setLoader(true));
    api.get(`clase/${id_clase}/formularios_clase`, params).then((response) => {
        dispatch(setFormulariosClase(response));
        dispatch(setFormulariosPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().cursosAlumno.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar_cursos());
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar_cursos());
};

const searchClaseChange = (search_clase, id_aula) => (dispatch) => {
    dispatch({type: SEARCH_CLASE, search_clase});
    dispatch(listar_clases(id_aula));
};

const changeEstadoEvento = (estado_evento, id_aula) => (dispatch) => {
    dispatch({type: ESTADO_EVENTO, estado_evento});
    dispatch(listar_clases(id_aula));
};

const changeFiltroFecha = (filtro_fecha, id_aula) => (dispatch) => {
    dispatch({type: FILTRO_FECHA, filtro_fecha});
    dispatch(listar_clases(id_aula));
};

export const actions = {
    listar_cursos,
    listarRecuperaciones,
    onSortChange,
    searchChange,
    searchClaseChange,
    obtener_aula,
    listar_clases,
    obtener_clase,
    changeEstadoEvento,
    changeFiltroFecha,
    obtener_archivos_clase,
    obtener_formularios_clase,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DATA2]: (state, { data2 }) => {
        return {
            ...state,
            data2,
        };
    },
    [ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [PAGE2]: (state, { page2 }) => {
        return {
            ...state,
            page2,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH_CLASE]: (state, { search_clase }) => {
        return {
            ...state,
            search_clase,
        };
    },
    [ESTADO_EVENTO]: (state, {estado_evento}) => {
        return {
            ...state,
            estado_evento,
        };
    },
    [FILTRO_FECHA]: (state, {filtro_fecha}) => {
        return {
            ...state,
            filtro_fecha,
        };
    },
    [ARCHIVOS_CLASE]: (state, {archivos_clase}) => {
        return {
            ...state,
            archivos_clase,
        };
    },
    [PAGE_ARCHIVOS]: (state, { page_archivos }) => {
        return {
            ...state,
            page_archivos,
        };
    },
    [FORMULARIOS_CLASE]: (state, {formularios_clase}) => {
        return {
            ...state,
            formularios_clase,
        };
    },
    [PAGE_FORMULARIOS]: (state, { page_formularios }) => {
        return {
            ...state,
            page_formularios,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    data2: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    page2: 1,
    ordering: '',
    search: '',
    search_clase: '',
    estado_evento: null,
    filtro_fecha: null,
    archivos_clase: {
        results: [],
        count: 0,
    },
    page_archivos: 1,
    formularios_clase: {
        results: [],
        count: 0,
    },
    page_formularios: 1,
};

export default handleActions(reducers, initialState);

