import React,  {Component} from "react";
import './VideoUploader.css';
import {NotificationManager} from "react-notifications";

const defaultProps = {
    baseColor: 'gray',
    activeColor: 'green',
    overlayColor: 'rgba(255,255,255,0.3)',
    opacity:0
};

class VideoUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            imageSrc: '',
            loaded: false,
            file_name: '',
        };

        this.onDragEnter  = this.onDragEnter.bind(this);
        this.onDragLeave  = this.onDragLeave.bind(this);
        this.onDrop       = this.onDrop.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
    }
    componentDidUpdate() {
        if (this.state.imageSrc === '' && this.props.source !== undefined && this.props.source !== null) {
            this.setState({imageSrc: this.props.source, loaded: true})
        }
    }

    componentWillMount(){
        if (this.props.img !== null && this.props.img !== undefined){
            // setea la imágen si se le envia una
            this.setState({
                imageSrc: this.props.img,
                loaded: true,
                isImage: true,
            });
        }
    }
    componentWillReceiveProps(nextProps){
        if (nextProps.img !== null && nextProps.img !== undefined){
            // setea la imágen si se le envia una
            this.setState({
                imageSrc: nextProps.img,
                loaded: true
            });
        }
    }

    onDragEnter(e) {
        this.setState({ active: true });
    }

    onDragLeave(e) {
        this.setState({ active: false });
    }

    onDragOver(e) {
        e.preventDefault();
    }

    onDrop(e) {
        e.preventDefault();
        this.setState({ active: false });
        this.onFileChange(e, e.dataTransfer.files[0]);
    }

    onFileChange(e, file) {
        this.props.onFileChange(e, file);
        file = file || e.target.files[0];
        const pattern = /-*/;
        const imagePattern = /image-*/;

        const reader = new FileReader();
        if (file){
            const isImage = !!file.type.match(imagePattern);
            if (!file.type.match(pattern)) {
                NotificationManager.error('Formato inválido', 'ERROR');
                return;
            }

            if (file.size > 10485760) {
                NotificationManager.error('El video supera los 10MB', 'ERROR');
                return;
            }
            else{
                this.setState({ loaded: false });
                reader.onload = (e) => {
                    this.setState({
                        imageSrc: reader.result,
                        isImage,
                        loaded: true
                    });
                };
                reader.readAsDataURL(file);
                this.setState({ file_name: file.name });

            }

        }
    }

    getFileObject() {
        return this.refs.input.files[0];
    }

    getFileString() {
        return this.state.imageSrc;
    }

    render() {
        let state = this.state,
            props = defaultProps,
            labelClass  = `videoUploader ${state.loaded && 'loaded'}`,
            borderColor = state.active ? props.activeColor : props.baseColor,
            iconColor   = state.active
                ? props.activeColor
                : (state.loaded)
                    ? props.overlayColor
                    : props.baseColor,
            hideIcon = state.loaded ? 0 : 1;

        return (
            <label
                className={labelClass}
                onDragEnter={this.onDragEnter}
                onDragLeave={this.onDragLeave}
                onDragOver={this.onDragOver}
                onDrop={this.onDrop}
            >
                {/* <img src={require(`assets/img/uploaded.png`)} className={state.loaded ? 'loaded' : undefined}/> */}
                {state.loaded && (
                    <div className="d-flex flex-column align-items-center loaded-texto">
                        <img src={require(`assets/img/iconos/check.png`)} alt="check" className="loaded-texto"/>
                        <h2 className="text-center mb-0" style={{color: "#686A75"}}>Video cargado</h2>
                        <h2 className="text-center mb-0" style={{color: "#686A75"}}>{this.state.file_name}</h2>
                    </div>
                )}
                {/* <img style={{ color: iconColor, opacity: hideIcon }} className="icon icon-upload" src={} alt=""/> */}
                <div className="d-flex flex-column align-items-center" style={{opacity:hideIcon}}>
                    <h4 className="text-center" style={{color: "#686A75"}}>Arrastra el video para subir</h4>
                    <div className="btn-image-picker mt-2">
                        Seleccione un video
                    </div>
                    <span className="gris text-center mt-3">Tamaño máximo de archivo: 10 MB</span>
                </div>
                <input disabled={this.props.disabled} type="file" accept="video/*" onChange={this.onFileChange} ref="input" />

            </label>
        );
    }
}
export default VideoUploader
