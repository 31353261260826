import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm, change} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import { ENLACE, ARCHIVO } from '../../../utility/constants';
import _ from "lodash";

import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const LOADER = 'LOADER_ARCHIVOS';
const DATA = 'DATA_ARCHIVOS';
const ARCHIVOS = 'ARCHIVOS_ARCHIVOS';
const ITEM = 'ITEM_ARCHIVOS';
const PAGE = 'PAGE_ARCHIVOS';
const ORDERING = 'ORDERING_ARCHIVOS';
const SEARCH = 'SEARCH_ARCHIVOS';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (type, data) => ({
    type,
    data,
});

const setItem = item => ({
    type: ITEM,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const listarNiveles = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().archivos;
    const params = { page };
    params.search = resource.search;
    params.ordering = resource.ordering;
    dispatch(setLoader(true));
    api.get('asignatura_niveles/niveles_archivos', params).then((response) => {
        dispatch(setData(DATA, response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listar = (idNivel) => (dispatch, getStore) => {
    const resource = getStore().archivos;
    const params = { nivel: idNivel };
    dispatch(setLoader(true));
    api.get('archivos', params).then((response) => {
        dispatch(setData(ARCHIVOS, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const crear = (values, archivos_array) => (dispatch) => {
    let archivos = values.archivos;
    archivos = archivos.map( item => {
        item.asignatura = values.asignatura;
        item.nivel = values.nivel;

        return item;
    });

    const data = { archivos: archivos}

    dispatch(setLoader(true));
    api.postAttachments('archivos', data, archivos_array).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(cleanForm()); // Limpiar el formulario
        dispatch(push(`/biblioteca_archivos/${values.nivel}`));
    }).catch((error) => {
        let mensaje = "Error en la creación";
        if (error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id, data, idNivel) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`archivos/${id}`, data).then(() => {
        dispatch(listar(idNivel));
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminar = (id, idNivel) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`archivos/${id}`).then(() => {
        dispatch(listar(idNivel));
        dispatch(cleanForm());
        NotificationManager.success('Archivo eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error al eliminar archivo', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const agregarArchivos = (archivos_nuevos) => (dispatch, getStore) => {
    const form = getStore().form.ArchivosForm.values;
    const archivos = form.archivos ? _.cloneDeep(form.archivos) : [];
    if (form.tipo == ENLACE) {
        archivos.push({
            nombre: form.nombre_enlace,
            tipo: ENLACE,
            enlace: form.enlace,
        });
    }
    else {
        for (const item of archivos_nuevos) {
            const datos = item.name.split(".");
            archivos.push({
                nombre: datos[0],
                tipo: ARCHIVO,
                extension: datos[datos.length -1],
            });
        }
    }
    dispatch(change('ArchivosForm', 'enlace', ''));
    dispatch(change('ArchivosForm', 'nombre_enlace', ''));
    dispatch(change('ArchivosForm', 'archivos', archivos));
};

const eliminarArchivo = (index) => (dispatch, getStore) => {
    const form = getStore().form.ArchivosForm.values;
    let archivos = _.cloneDeep(form.archivos);
    archivos = _.filter(archivos, (item, i) => i != index );
    dispatch(change('ArchivosForm', 'archivos', archivos));
}

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('ArchivosForm', {}));
    dispatch(initializeForm('DetalleArchivosForm', {}));
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listarNiveles());
};


const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().archivos.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listarNiveles());
};

const setearFormDetalle = (nombre) => (dispatch) => {
    dispatch(change('DetalleArchivosForm', 'nombre', nombre));
}

export const actions = {
    listarNiveles,
    listar,
    crear,
    editar,
    eliminar,
    agregarArchivos,
    eliminarArchivo,
    searchChange,
    onSortChange,
    setearFormDetalle
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ARCHIVOS]: (state, { data }) => {
        return {
            ...state,
            archivos: data,
        };
    },
    [ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    archivos: [],
    item: {},
    page: 1,
    ordering: '',
    search: '',
};

export default handleActions(reducers, initialState);
