import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {push} from "react-router-redux";
import _ from "lodash";
import {reset} from 'redux-form';
import { OPC_MULT } from '../../../utility/constants';

const LOADER = 'LOADER_FORMS_CURSOS';
const DATA = 'DATA_FORMS_CURSOS';
const ITEM = 'ITEM_FORMS_CURSOS';
const PAGE = 'PAGE_FORMS_CURSOS';
const ORDERING = 'ORDERING_FORMS_CURSOS';
const SEARCH = 'SEARCH_FORMS_CURSOS';
const ESTADO_FORM = 'ESTADO_FORMS_CURSOS';
const PROFESOR = 'PROFESOR_FORMS_CURSOS';
const ALUMNO = 'ALUMNO_FORMS_CURSOS';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (type, data) => ({
    type,
    data,
});

const setItem = item => ({
    type: ITEM,
    item,
});

const setPage = (type, page) => ({
    type,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = (type, search) => ({
    type,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().formsCursos;
    const params = { page };
    params.search = resource.search;
    if (resource.estado_form) {
        if (resource.estado_form.value == 1){
            params.enviado = false;
        } else if (resource.estado_form.value == 2) {
            params.enviado = true;
            params.calificado = false;
        } else {
            params.calificado = true;
        }
    }
    if (resource.profesor)
        params.formulario__aula__profesor = resource.profesor.id;
    if (resource.alumno)
        params.alumno = resource.alumno.id;
    dispatch(setLoader(true));
    api.get('form_respuesta', params).then((response) => {
        dispatch(setData(DATA, response));
        dispatch(setPage(PAGE, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = (id, calificar=true) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`form_respuesta/${id}`).then((response) => {
        dispatch(setItem(response));

        const blocksFromHtml = htmlToDraft(response.formulario.formulario.notas ? response.formulario.formulario.notas : "");
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        const data = {
            notas_form: EditorState.createWithContent(contentState),
            respuestas: response.respuestas,
        }

        if (calificar) {
            data.respuestas = response.respuestas.map( item => {
                item.punteo = 0;
                if (item.pregunta.tipo == OPC_MULT) {
                    const op_elegida = _.find(item.pregunta.respuestas, {id: item.respuesta_multiple});
                    if (op_elegida) {
                        item.punteo = op_elegida.respuesta_correcta ? item.pregunta.puntaje : 0;
                    }
                }
                return item;
            });
        } else {
            const blocksFromHtml2 = htmlToDraft(response.notas ? response.notas : "");
            let { contentBlocks, entityMap } = blocksFromHtml2;
            const contentState2 = ContentState.createFromBlockArray(contentBlocks, entityMap);
            data.notas = EditorState.createWithContent(contentState2);
        }

        dispatch(initializeForm('EjercicioForm', data));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(SEARCH, search));
    dispatch(listar());
};

const changeEstadoForm = value => (dispatch) => {
    dispatch(setData(ESTADO_FORM, value));
    dispatch(listar());
};

const changeProfesor = value => (dispatch) => {
    dispatch(setData(PROFESOR, value));
    dispatch(listar());
};

const changeAlumno = value => (dispatch) => {
    dispatch(setData(ALUMNO, value));
    dispatch(listar());
};

export const actions = {
    listar,
    leer,
    searchChange,
    changeEstadoForm,
    changeProfesor,
    changeAlumno,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [ESTADO_FORM]: (state, { data }) => {
        return {
            ...state,
            estado_form: data,
        };
    },
    [PROFESOR]: (state, { data }) => {
        return {
            ...state,
            profesor: data,
        };
    },
    [ALUMNO]: (state, { data }) => {
        return {
            ...state,
            alumno: data,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    estado_form: null,
    profesor: null,
    alumno: null,
};

export default handleActions(reducers, initialState);
