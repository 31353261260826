import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import _ from "lodash";
import {reset} from 'redux-form';
import moment from 'moment';

const LOADER_COMUNICADOS_PENDIENTES = 'LOADER_COMUNICADOS_PENDIENTES';
const DATA_COMUNICADOS_PENDIENTES = 'DATA_COMUNICADOS_PENDIENTES';
const ITEM_DATA_COMUNICADOS_PENDIENTES = 'ITEM_COMUNICADOS_PENDIENTES';
const PAGE_COMUNICADOS_PENDIENTES = 'PAGE_COMUNICADOS_PENDIENTES';
const ORDERING_COMUNICADOS_PENDIENTES = 'ORDERING_COMUNICADOS_PENDIENTES';
const SEARCH_COMUNICADOS_PENDIENTES = 'SEARCH_COMUNICADOS_PENDIENTES';
const FILTRO_MES_INICIO_COMUNICADOS_PENDIENTES = 'FILTRO_MES_INICIO_COMUNICADOS_PENDIENTES';
const FILTRO_MES_FIN_COMUNICADOS_PENDIENTES = 'FILTRO_MES_FIN_COMUNICADOS_PENDIENTES';

const LOADER_COMUNICADOS_APROBADOS = 'LOADER_COMUNICADOS_APROBADOS';
const DATA_COMUNICADOS_APROBADOS = 'DATA_COMUNICADOS_APROBADOS';
const ITEM_DATA_COMUNICADOS_APROBADOS = 'ITEM_DATA_COMUNICADOS_APROBADOS';
const PAGE_COMUNICADOS_APROBADOS = 'PAGE_COMUNICADOS_APROBADOS';
const ORDERING_COMUNICADOS_APROBADOS = 'ORDERING_COMUNICADOS_APROBADOS';
const SEARCH_COMUNICADOS_APROBADOS = 'SEARCH_COMUNICADOS_APROBADOS';
const FILTRO_MES_INICIO_COMUNICADOS_APROBADOS = 'FILTRO_MES_INICIO_COMUNICADOS_APROBADOS';
const FILTRO_MES_FIN_COMUNICADOS_APROBADOS = 'FILTRO_MES_FIN_COMUNICADOS_APROBADOS';

// -----------------------------------
// Pure Actions Comunicados Pendientes
// -----------------------------------

const setLoaderPendientes = loader_pendientes => ({
    type: LOADER_COMUNICADOS_PENDIENTES,
    loader_pendientes,
});

const setDataPendientes = data_pendientes => ({
    type: DATA_COMUNICADOS_PENDIENTES,
    data_pendientes,
});

const setItemPendientes = item_pendientes => ({
    type: ITEM_DATA_COMUNICADOS_PENDIENTES,
    item_pendientes,
});

const setPagePendientes = page_pendientes => ({
    type: PAGE_COMUNICADOS_PENDIENTES,
    page_pendientes,
});

const setOrderingPendientes = ordering_pendientes => ({
    type: ORDERING_COMUNICADOS_PENDIENTES,
    ordering_pendientes,
});

const setSearchPendientes = search_pendientes => ({
    type: SEARCH_COMUNICADOS_PENDIENTES,
    search_pendientes,
});

// -----------------------------------
// Pure Actions Comunicados Aprobados
// -----------------------------------

const setLoaderAprobados = loader_aprobados => ({
    type: LOADER_COMUNICADOS_APROBADOS,
    loader_aprobados,
});

const setDataAprobados = data_aprobados => ({
    type: DATA_COMUNICADOS_APROBADOS,
    data_aprobados,
});

const setItemAprobados = item_aprobados => ({
    type: ITEM_DATA_COMUNICADOS_APROBADOS,
    item_aprobados,
});

const setPageAprobados = page_aprobados => ({
    type: PAGE_COMUNICADOS_APROBADOS,
    page_aprobados,
});

const setOrderingAprobados = ordering_aprobados => ({
    type: ORDERING_COMUNICADOS_APROBADOS,
    ordering_aprobados,
});

const setSearchAprobados = search_aprobados => ({
    type: SEARCH_COMUNICADOS_APROBADOS,
    search_aprobados,
});

// ------------------------------------
// Actions
// ------------------------------------

const crear = (data, attachments = []) => (dispatch) => {
    dispatch(setLoaderPendientes(true));
    api.postAttachments('comunicado', data, attachments).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(cleanForm()); // Limpiar el formulario
        dispatch(push('/comunicados'));
    }).catch((error) => {
        let mensaje = "Error en la creación";
        if (error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoaderPendientes(false));
    });
}

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('ComunicadoForm', {}));
};

const leer = id => (dispatch) => {
    dispatch(setLoaderPendientes(true));
    api.get(`${'comunicado'}/${id}`).then((response) => {
        dispatch(setItemPendientes(response));
        dispatch(initializeForm('ComunicadoForm', response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoaderPendientes(false));
    });
};

// COMUNICADOS PENDIENTES
const listarPendientes = (page = 1, aprobados = 'False') => (dispatch, getStore) => {
    const resource = getStore().comunicados;
    const params = { page, aprobados };
    params.ordering = resource.ordering_pendientes;
    params.search = resource.search_pendientes;
    if (resource.mes_inicio_filtro_pendientes && resource.mes_fin_filtro_pendientes) {
        params.fecha_inicio = moment(resource.mes_inicio_filtro_pendientes).format()
        params.fecha_fin = moment(resource.mes_fin_filtro_pendientes).format()
    }
    dispatch(setLoaderPendientes(true));
    api.get('comunicado', params).then((response) => {
        dispatch(setDataPendientes(response));
        dispatch(setPagePendientes(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoaderPendientes(false));
    });
};

const aprobarPendientes = id => (dispatch) => {
    dispatch(setLoaderPendientes(true));
    api.put(`${'comunicado'}/${id}/aprobar_comunicado`).then(() => {
        dispatch(listarPendientes());
        dispatch(listarAprobados());
        dispatch(push('/comunicados'));
        NotificationManager.success('Comunicado aprobado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoaderPendientes(false));
    });
}

const rechazarPendientes = id => (dispatch) => {
    dispatch(setLoaderPendientes(true));
    api.eliminar(`${'comunicado'}/${id}`).then(() => {
        dispatch(listarPendientes());
        dispatch(listarAprobados());
        dispatch(push('/comunicados'));
        NotificationManager.success('Comunicado rechazado y eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoaderPendientes(false));
    });
}

const searchChangePendientes = search_pendientes => (dispatch) => {
    dispatch(setSearchPendientes(search_pendientes));
    dispatch(listarPendientes());
};

const onSortChangePendientes = ordering_pendientes => (dispatch, getStore) => {
    const sort = getStore().comunicados.ordering_pendientes;
    if (ordering_pendientes === sort) {
        dispatch(setordering_pendientesPendientes(`-${ordering_pendientes}`));
    } else {
        dispatch(setOrderingPendientes(ordering_pendientes));
    }
    dispatch(listarPendientes());
};

const filtroMesInicioPendientes = mes_inicio_filtro_pendientes => (dispatch) => {
    dispatch({type: FILTRO_MES_INICIO_COMUNICADOS_PENDIENTES, mes_inicio_filtro_pendientes})
    dispatch(listarPendientes());
};

const filtroMesFinPendientes = mes_fin_filtro_pendientes => (dispatch) => {
    dispatch({type: FILTRO_MES_FIN_COMUNICADOS_PENDIENTES, mes_fin_filtro_pendientes})
    dispatch(listarPendientes());
};


// APROBADOS
const listarAprobados = (page = 1, aprobados = 'True') => (dispatch, getStore) => {
    const resource = getStore().comunicados;
    const params = { page, aprobados };
    params.ordering = resource.ordering_aprobados;
    params.search = resource.search_aprobados;
    if (resource.mes_inicio_filtro_aprobados && resource.mes_fin_filtro_aprobados) {
        params.fecha_inicio = moment(resource.mes_inicio_filtro_aprobados).format()
        params.fecha_fin = moment(resource.mes_fin_filtro_aprobados).format()
    }
    dispatch(setLoaderAprobados(true));
    api.get('comunicado', params).then((response) => {
        dispatch(setDataAprobados(response));
        dispatch(setPageAprobados(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoaderAprobados(false));
    });
};

const searchChangeAprobados = search_aprobados => (dispatch) => {
    dispatch(setSearchAprobados(search_aprobados));
    dispatch(listarAprobados());
};

const onSortChangeAprobados = ordering_aprobados => (dispatch, getStore) => {
    const sort = getStore().cursosGrupales.ordering_aprobados;
    if (ordering_aprobados === sort) {
        dispatch(setOrderingAprobados(`-${ordering_aprobados}`));
    } else {
        dispatch(setOrderingAprobados(ordering_aprobados));
    }
    dispatch(listarAprobados());
};

const filtroMesInicioAprobados = mes_inicio_filtro_aprobados => (dispatch) => {
    dispatch({type: FILTRO_MES_INICIO_COMUNICADOS_APROBADOS, mes_inicio_filtro_aprobados})
    dispatch(listarAprobados());
};

const filtroMesFinAprobados = mes_fin_filtro_aprobados => (dispatch) => {
    dispatch({type: FILTRO_MES_FIN_COMUNICADOS_APROBADOS, mes_fin_filtro_aprobados})
    dispatch(listarAprobados());
};

export const actions = {
    crear,
    cleanForm,
    leer,
    // Comunicados pendientes
    listarPendientes,
    aprobarPendientes,
    rechazarPendientes,
    searchChangePendientes,
    onSortChangePendientes,
    filtroMesInicioPendientes,
    filtroMesFinPendientes,
    // Comunicados aprobados
    listarAprobados,
    searchChangeAprobados,
    onSortChangeAprobados,
    filtroMesInicioAprobados,
    filtroMesFinAprobados,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    // REDUCERS DE COMUNICADOS PENDIENTES
    [LOADER_COMUNICADOS_PENDIENTES]: (state, { loader_pendientes }) => {
        return {
            ...state,
            loader_pendientes,
        };
    },
    [DATA_COMUNICADOS_PENDIENTES]: (state, { data_pendientes }) => {
        return {
            ...state,
            data_pendientes,
        };
    },
    [ITEM_DATA_COMUNICADOS_PENDIENTES]: (state, { item_pendientes }) => {
        return {
            ...state,
            item_pendientes,
        };
    },
    [PAGE_COMUNICADOS_PENDIENTES]: (state, { page_pendientes }) => {
        return {
            ...state,
            page_pendientes,
        };
    },
    [ORDERING_COMUNICADOS_PENDIENTES]: (state, { ordering_pendientes }) => {
        return {
            ...state,
            ordering_pendientes,
        };
    },
    [SEARCH_COMUNICADOS_PENDIENTES]: (state, { search_pendientes }) => {
        return {
            ...state,
            search_pendientes,
        };
    },
    // FILTROS DE LOS COMUNICADOS PENDIENTES
    [FILTRO_MES_INICIO_COMUNICADOS_PENDIENTES]: (state, { mes_inicio_filtro_pendientes }) => {
        return {
            ...state,
            mes_inicio_filtro_pendientes,
        };
    },
    [FILTRO_MES_FIN_COMUNICADOS_PENDIENTES]: (state, { mes_fin_filtro_pendientes }) => {
        return {
            ...state,
            mes_fin_filtro_pendientes,
        };
    },
    // REDUCERS DE COMUNICADOS APROBADOS
    [LOADER_COMUNICADOS_APROBADOS]: (state, { loader_aprobados }) => {
        return {
            ...state,
            loader_aprobados,
        };
    },
    [DATA_COMUNICADOS_APROBADOS]: (state, { data_aprobados }) => {
        return {
            ...state,
            data_aprobados,
        };
    },
    [ITEM_DATA_COMUNICADOS_APROBADOS]: (state, { item_aprobados }) => {
        return {
            ...state,
            item_aprobados,
        };
    },
    [PAGE_COMUNICADOS_APROBADOS]: (state, { page_aprobados }) => {
        return {
            ...state,
            page_aprobados,
        };
    },
    [ORDERING_COMUNICADOS_APROBADOS]: (state, { ordering_aprobados }) => {
        return {
            ...state,
            ordering_aprobados,
        };
    },
    [SEARCH_COMUNICADOS_APROBADOS]: (state, { search_aprobados }) => {
        return {
            ...state,
            search_aprobados,
        };
    },
    // FILTROS DE LOS COMUNICADOS PENDIENTES
    [FILTRO_MES_INICIO_COMUNICADOS_APROBADOS]: (state, { mes_inicio_filtro_aprobados }) => {
        return {
            ...state,
            mes_inicio_filtro_aprobados,
        };
    },
    [FILTRO_MES_FIN_COMUNICADOS_APROBADOS]: (state, { mes_fin_filtro_aprobados }) => {
        return {
            ...state,
            mes_fin_filtro_aprobados,
        };
    },
};

export const initialState = {
    // COMUNICADOS PENDIENTES
    loader_pendientes: false,
    data_pendientes: {
        results: [],
        count: 0,
    },
    item_pendientes: {},
    page_pendientes: 1,
    ordering_pendientes: '',
    search_pendientes: '',
    mes_inicio_filtro_pendientes: moment().startOf('month'),
    mes_fin_filtro_pendientes: moment().endOf('month'),
    // COMUNICADOS APROBADOS
    loader_aprobados: false,
    data_aprobados: {
        results: [],
        count: 0,
    },
    item_aprobados: {},
    page_aprobados: 1,
    ordering_aprobados: '',
    search_aprobados: '',
    mes_inicio_filtro_aprobados: moment().startOf('month'),
    mes_fin_filtro_aprobados: moment().endOf('month'),
};

export default handleActions(reducers, initialState);
