import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import ColaboradorForm from './ColaboradorForm';

export default class ColaboradorEditar extends Component {

    state = {
        ver_password: false,
    }

    componentWillMount() {
        this.props.leer(this.props.match.params.id);
    }

    change_ver_password = () => {
        this.setState({ver_password: !this.state.ver_password});
    };

    actualizar = (data) =>{
        if (data.password)
            data.new_password = data.password
        this.props.editar(this.props.match.params.id, data)
    };

    render() {
        const { loader } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm col={8}>
                    <LoadMask loading={loader} blur>
                        <ColaboradorForm
                            onSubmit={this.actualizar}
                            change_ver_password={this.change_ver_password}
                            ver_password={this.state.ver_password}
                        />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
