import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";
import {formatActivo} from "Utils/renderField/renderReadField"

export default class UsuariosList extends Component{
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar: onPageChange, onSortChange, eliminar } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        const _data = {results: [{nombre: "Kevin", email:"kevin.gomez@ciancoders.dev", id:0}]}
        return(
            <div className="mb-4 col-12 mt-4">
                <Header to="administrador/crear" textBtn="Nuevo Administrador" {...this.props} />
                <Card>
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                    page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='20%'
                            dataFormat={standardActions({ editar: "administrador", eliminar})}
                        />
                        <TableHeaderColumn
                            dataField="nombres"
                            dataSort
                            dataFormat={(cell, row) => cell && row && row.apellidos ? cell + ' ' + row.apellidos : ''}
                        >
                            NOMBRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="email"
                            dataSort
                        >
                            EMAIL
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="is_active"
                            dataSort
                            dataFormat={(cell) => cell ? (
                                <div className="d-flex flex-row align-items-center">
                                    <div className="circulo bg-aqua mr-1"></div>
                                    <span className="aqua ml-1">Activo</span>
                                </div>
                            ) : (
                                <div className="d-flex flex-row align-items-center">
                                    <div className="circulo bg-rosa mr-1"></div>
                                    <span className="rosa ml-1">Inactivo</span>
                                </div>
                            )}
                        >
                            ACCESO AL SISTEMA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="left"
                            dataField="id"
                            width='8%'
                            dataSort
                        >
                            CÓDIGO
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
