import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/aulas/aula';
import EditarAula from './EditarAula';


const ms2p = (state) => {
  return {
    ...state.aula,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(EditarAula);
