import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField, renderNumber, RenderImageUpload, renderFieldRadio2 } from '../../../Utils/renderField/renderField';
import { api } from '../../../../../utility/api';


let CursoGrupalForm = props => {
    const { handleSubmit, editando, setFotografia, foto, cancelar } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="row col-md-12 p-0 mb-3">
                    {editando && (
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="fotografia" className="m-0">Fotografía actual</label>
                            </div>
                            <div className="col-md-12 form-group text-center">
                                <img src={foto} alt="fotografia" className="img-fluid" style={{ height: "150px", marginTop: "7px" }} />
                            </div>
                        </div>
                    )}
                    <div className={editando ? "col-md-6 p-0" : "col-md-12 p-0"}>
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="fotografia" className="m-0">{editando ? "Nueva fotografía" : "Fotografía"}</label>
                        </div>
                        <div className="col-md-12 ">
                            <RenderImageUpload aspect={1} multiple={false} setFile={setFotografia} />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="profesor" className="m-0">Nombre</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="profesor" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0 ">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="genero" className="m-0">Género</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <div className="d-flex flex-row">
                                <Field
                                    label='Hombre'
                                    type="radio"
                                    value={true}
                                    name="genero"
                                    parse={val => !!val}
                                    component={renderFieldRadio2}
                                />
                                <Field
                                    label='Mujer'
                                    type="radio"
                                    value={false}
                                    name="genero"
                                    parse={val => !val}
                                    component={renderFieldRadio2}
                                />

                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-3">
                    <div className="col-md-4 col-12">
                        <button className="btn btn-secondary btn-block" onClick={cancelar}>Atras</button>
                    </div>
                    <div className="col-md-4 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Siguiente</button>
                    </div>
                </div>
            </div>
        </form>
    )

};
CursoGrupalForm = reduxForm({
    form: 'CursoGrupalForm',
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    asyncBlurFields: [],
    validate: data => {
        return validate(data, {
            'profesor': validators.exists()('Campo requerido.'),
        })
    }
})(CursoGrupalForm);

const selector = formValueSelector('CursoGrupalForm');
CursoGrupalForm = connect(state => {
    const a = 'test';
    return {
        a
    };
})(CursoGrupalForm);

export default CursoGrupalForm;
