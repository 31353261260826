import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/config/mensajes';
import MensajeCrear from './MensajeCrear';

const mstp = state => {
    return {...state.mensajes}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(MensajeCrear)
