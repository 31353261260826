import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/areaFinanciera/nominas';
import NominaCrear from './NominaCrear';

const ms2p = (state) => {
    return {
        ...state.nominas,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(NominaCrear);
