import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/servicios';
import ListarServicios from './ListarServicios';

const ms2p = (state) => {
    return {
        ...state.servicios,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListarServicios);
