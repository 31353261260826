import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/biblioteca/archivos';
import NivelesArchivosGrid from './NivelesGrid';


const ms2p = (state) => {
  return {
    ...state.archivos,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(NivelesArchivosGrid);
