import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import ArchivosForm from './ArchivosForm';
import { ENLACE, ARCHIVO } from '../../../../../utility/constants';
import {NotificationManager} from "react-notifications";
import _ from 'lodash';
import { api } from '../../../../../utility/api';

export default class NivelCrear extends Component {

    state = {
        editar: false,
        archivos: [],
        nivel: {
            nombre: '',
            asignatura: '',
            id_asignatura: null,
        },
    }

    componentWillMount() {
        const id = this.props.match.params.id;
        api.get(`asignatura_niveles/${id}`).then( response => {
            this.setState({
                nivel: {
                    nombre: response.nombre,
                    asignatura: response.asignatura.nombre,
                    id_asignatura: response.asignatura.id
                }
            });
            this.props.listar(response.id);
        }).catch();
    };

    onSubmit = (values) => {
        const { nivel } = this.state;
        const id = this.props.match.params.id;
        const data = {
            asignatura: nivel.id_asignatura,
            nivel: id,
            archivos: values.archivos
        }
        if ( values.archivos.length ) {
            this.props.crear(data, this.state.archivos);
        } else {
            NotificationManager.error('Agregue al menos un archivo para subir', 'ERROR');
        }
    };

    cargarArchivos = (e, file) => {
        const { form: {values} } = this.props;
        const archivos = this.state.archivos;
        const archivos_nuevos = e.target.files;
        for (let index = 0; index < archivos_nuevos.length; index++) {
            archivos.push({
                name: `${values.archivos.length + index}`,
                file: archivos_nuevos[index],
            });
        }
        // Seteamos en redux tambiem
        this.props.agregarArchivos(archivos_nuevos);
    };

    cargarEnlace = () => {
        this.props.agregarArchivos(null);
    };

    eliminarArchivo = (index) => {
        const { form: {values: {archivos}} } = this.props;
        if (archivos.length > index) {
            if (archivos[index].tipo == ARCHIVO) {
                // eliminar del state tambien
                let archivos = this.state.archivos;
                const archivo_index = _.findIndex(archivos, (item) => item.name == index);
                if (archivo_index > -1) {
                    archivos = _.filter(archivos, item => item.name != index );
                    archivos = archivos.map((item, index) => {
                        const key = parseFloat(item.name);
                        if (key > index) {
                            item.name = `${key -1}`;
                        }
                        return item;
                    });
                }

                this.setState({archivos: archivos});
            }
            // Eliminamos en el de redux
            this.props.eliminarArchivo(index);
        }
    };

    render() {
        const { loader } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm col={12} noPaddingXContent>
                    <LoadMask loading={loader} blur>
                        <ArchivosForm
                            nivel={this.state.nivel}
                            onSubmit={this.onSubmit}
                            cargarArchivos={this.cargarArchivos}
                            cargarEnlace={this.cargarEnlace}
                            eliminarArchivo={this.eliminarArchivo}
                            idNivel={this.props.match.params.id}
                        />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
