import React, { Component, Fragment } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { validate, validators } from 'validate-redux-form';
import { SelectField, renderTimeField, renderDatePicker, AsyncSelectField,
    MultiAsyncSelectField } from '../../../Utils/renderField';
import { CLASE_RECUPERACION, TUTORIA } from '../../../../../utility/constants';
import { RenderNumber } from '../../../Utils/renderField/renderReadField';
import Grid from '../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { api } from 'api';


const tipos_eventos = [
    { value: CLASE_RECUPERACION, label: 'Recuperacion' },
    { value: TUTORIA, label: 'Tutoría' },
]

class ModalCrearEvento extends Component {

    componentWillMount() {
        this.getAlumnosCurso('');
    }

    getAlumnosCurso = (search) => {
        const params = { search }
        if (this.props.aula) {
            params.aula = this.props.aula.id;
        }
        return api.get("aula/alumnos_asignados", params).
            then((data) => {
                if (data) return data.results;
                return [];
            }).catch(() => {
                return [];
            });
    }

    render() {
        const { handleSubmit, cancelar, getCursos, obtenerDetalleAula, detalle_aula, loader } = this.props;
        return (
            <form name="CrearEventoForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
                <div className="form-group grid-container mx-md-2">
                    <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-md-5 mt-3">
                        <div className="row col-12 px-md-5">
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="aula" className="m-0">Curso</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        name="aula"
                                        loadOptions={getCursos}
                                        type="text"
                                        component={AsyncSelectField}
                                        // top={{ top: "5px", position: "inherit" }}
                                        valueKey="id"
                                        labelKey="nombre"
                                        isClearable={false}
                                        className="form-control"
                                        onCambio={(value) => {
                                            if (value) {
                                                obtenerDetalleAula(value.id);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            {(this.props.aula) && (
                                <div className="col-md-6 p-0">
                                    <div className="col-md-12 col-sm-12">
                                        <label htmlFor="tipo_evento" className="m-0">Tipo de clase</label>
                                    </div>
                                    <div className="col-md-12  form-group">
                                        <Field
                                            name="tipo_evento"
                                            component={SelectField}
                                            options={tipos_eventos}
                                            type="text"
                                            className="form-control"
                                            onCambio={(value) => {
                                                if (value == CLASE_RECUPERACION) {
                                                    obtenerDetalleAula(this.props.aula.id);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                            )}
                        </div>
                        {
                            (this.props.tipo_evento) && (this.props.tipo_evento == CLASE_RECUPERACION) && (
                                <div className="row col-12 px-md-5 px-0">
                                    <div className="col-12 p-0">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="fecha" className="m-0">Alumnos</label>
                                        </div>
                                        <div className="col-md-12  form-group">
                                            <Field
                                                name="alumnos"
                                                loadOptions={this.getAlumnosCurso}
                                                type="text"
                                                component={MultiAsyncSelectField}
                                                valueKey="id"
                                                labelKey="nombres"
                                                isClearable={false}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        <div className="row col-12 px-md-5">
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="fecha" className="m-0">Fecha</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="fecha" component={renderDatePicker} />
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="hora_inicio" className="m-0">Hora inicio</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="hora_inicio" component={renderTimeField} />
                                </div>
                            </div>
                        </div>
                        <div className="row col-12 px-md-5">
                            <div className="col-md-6 p-0 ">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="hora_fin" className="m-0">Hora fin</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="hora_fin" component={renderTimeField} />
                                </div>
                            </div>
                            {
                                (this.props.tipo_evento) && (this.props.tipo_evento == TUTORIA) ? (
                                    <div className="col-md-6 p-0 ">
                                        <div className="d-flex flex-1 flex-column justify-content-center align-items-center">
                                            <label htmlFor="hora_fin" className="m-0">Horas de tutoria restantes</label>
                                            <RenderNumber
                                                value={this.props.aula ? this.props.aula.horas_tutorias - this.props.aula.total_tutorias : 0 }
                                                suffix=" hora(s)"
                                                className="h3 rosa"
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div></div>
                                )
                            }
                        </div>
                        {
                            (this.props.tipo_evento) && (this.props.tipo_evento == CLASE_RECUPERACION) ? (
                                (detalle_aula) && (detalle_aula.alumnos_asignados) && (
                                    <div style={{width: "50vw", overflowX: "auto"}} className="row col-12 px-md-5 px-0 mt-3">
                                        <Grid
                                            hover
                                            striped
                                            data={{results: detalle_aula.alumnos_asignados, count: 1}}
                                            loading={loader}
                                            pagination={false}
                                        >
                                            <TableHeaderColumn
                                                dataField="id"
                                                dataAlign="center"
                                                isKey
                                                width='5%'
                                                dataFormat={cell => ''}
                                            />
                                            <TableHeaderColumn
                                                dataField="nombre"
                                            >
                                                Alumno
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataField="horas_pendientes_recuperar"
                                            >
                                                HORAS PENDIENTES
                                            </TableHeaderColumn>
                                        </Grid>
                                    </div>
                                )
                            ) : (
                                <div></div>
                            )
                        }
                        {
                            this.props.errorModal && (
                                <div className="rd-flex flex-row align-items-center w-100 mt-3">
                                    <h4 className="rosa" style={{ textAlign: "center" }}>{this.props.errorModal}</h4>
                                </div>
                            )
                        }
                    </div>
                    <div className="d-md-flex d-none buttons-box mt-5 px-md-5">
                        <button type="button" style={{width: "10rem"}} className="btn d-flex btn-secondary mr-3" onClick={cancelar}>Cancelar</button>
                        <button type="submit" style={{width: "10rem"}} className="btn d-flex btn-primary">Crear</button>
                    </div>
                    <div className="d-md-none d-flex flex-column w-100 buttons-box mt-5 px-md-5">
                        <button type="button" className="btn d-flex flex-1 w-100 btn-secondary" onClick={cancelar}>Cancelar</button>
                        <button type="submit" className="btn d-flex flex-1 w-100 btn-primary mt-3">Crear</button>
                    </div>
                </div>
            </form>
        )
    }
}

const selector = formValueSelector('CrearEventoForm');

const CrearEventoForm = reduxForm({
    form: 'CrearEventoForm',
    asyncBlurFields: [],
    validate: data => {
        return validate(data, {
            'tipo_evento': validators.exists()('Campo requerido.'),
            'fecha': validators.exists()('Campo requerido.'),
            'hora_inicio': validators.exists()('Campo requerido.'),
            'hora_fin': validators.exists()('Campo requerido.'),
            'aula': validators.exists()('Campo requerido.'),
            'alumnos': validators.exists()('Campo requerido.'),
        })
    }
})(ModalCrearEvento);

const mstp = state => {
    const tipo_evento = selector(state, 'tipo_evento');
    const aula = selector(state, 'aula');

    return {
        tipo_evento,
        aula,
    }
};

export default connect(mstp, null)(CrearEventoForm);


