import React, {Component, Fragment} from 'react';
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card";
import Header from "Utils/Grid/Header";

export default class NivelesGrid extends Component{

    componentWillMount() {
        const { listarAsignaturas, page2 } = this.props;
        listarAsignaturas(page2);
    }

    render() {
        const { listarAsignaturas: onPageChange, onSortChange, eliminarAsignatura, searchChangeAsignatura } = this.props; //Funciones
        const { data2, loader, page2, search2 } = this.props; //Constantes
        return(
            <div className="mt-4">
                <Header
                    to="asignatura/crear"
                    textBtn="Nueva Asignatura"
                    {...this.props}
                    searchChange={searchChangeAsignatura}
                    search={search2}
                />
                <Card>
                    <Grid hover striped data={data2} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                    page={page2}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            isKey
                            width='20%'
                            dataFormat={standardActions({ editar: "asignatura", eliminar: eliminarAsignatura})}
                        />
                        <TableHeaderColumn
                            dataField="nombre"
                        >
                            NOMBRE ASIGNATURA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="id"
                        >
                            CODIGO
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
