import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './NominaForm';
import EditarNominaForm from './NominaForm';

export default class NominaCrear extends Component {
    state = {
        editar: false
    };

    componentWillMount(){
        if(this.props.match.params.id){
            this.props.leer(this.props.match.params.id);
            this.setState({editar: true});
        }
        else{
            this.props.getProfesores()
        }
    }

    actualizar = (data) => {
        this.props.editar(data, this.props.match.params.id)
    };

    componentWillUnmount(){
        this.props.cleanForm();
    }

    render() {
        const { crear, update } = this.props;
        const { updateData, loader } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm>
                    <LoadMask loading={loader} blur>
                        {
                        this.state.editar ?
                            <EditarNominaForm
                                onSubmit={this.actualizar}
                                updateData={updateData}
                                editando={true}
                            />
                            :
                            <Form
                                onSubmit={crear}
                            />
                        }
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
