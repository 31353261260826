import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm, reset} from "redux-form";
import {NotificationManager} from "react-notifications";
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import {push} from "react-router-redux";
import moment from 'moment';
import _ from "lodash";
import { USER_ADMIN, USER_ALUMNO, USER_PROFESOR, DIAS_SEMANA } from "../../../utility/constants";

const LOADER = 'LOADER_PROFESORES';
const DATA = 'DATA_PROFESORES';
const ITEM_DATA = 'ITEM_PROFESORES';
const PAGE = 'PAGE_PROFESORES';
const ORDERING = 'ORDERING_PROFESORES';
const SEARCH = 'SEARCH_PROFESORES';
const EVENTOS = 'EVENTOS_PROFESOR';
const TIPO_EVENTO = 'TIPO_EVENTO_PROFESOR';
const FECHA_INICIO = 'FECHA_INICIO_PROFESOR';
const FECHA_FIN = 'FECHA_FIN_PROFESOR';
const DETALLE_AULA = 'DETALLE_AULA_PROFESOR';


// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setDataGeneral = (type, data) => ({
    type,
    data
})

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().profesores;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    params.tipo_usuario = USER_PROFESOR;
    dispatch(setLoader(true));
    api.get('user', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch) => {
    const params = {};
    params.tipo_usuario = USER_PROFESOR;
    dispatch(setLoader(true));
    api.get(`${'user'}/${id}`, params).then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm('ProfesorForm', response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    data.tipo_usuario = USER_PROFESOR;
    dispatch(setLoader(true));
    api.post('user', data).then(() => {
        NotificationManager.success('Profesor creado', 'Éxito', 3000);
        dispatch(cleanForm()); // Limpiar el formulario
        dispatch(push('/profesores'));
    }).catch((error) => {
        let mensaje = 'Error en la creación';
        if (error && error.detail)
            mensaje = error.detail;
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const editar = (data, id) => (dispatch) => {
    data.tipo_usuario = USER_PROFESOR;
    dispatch(setLoader(true));
    api.put(`${'user'}/${id}`, data).then(() => {
        NotificationManager.success('Profesor actualizado', 'Éxito', 3000);
        dispatch(push('/profesores'));
    }).catch((error) => {
        let mensaje = 'Error al editar';
        if (error && error.detail)
            mensaje = error.detail;
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${'user'}/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Profesor eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('ProfesorForm', {}));
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().administradores.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const iniciarFechas = () => (dispatch) => {
    const fecha = moment();
    const fecha2 = moment();
    if (fecha.get('d') == 0) {
        dispatch(setData(FECHA_INICIO, fecha.day(-6)));
        dispatch(setData(FECHA_FIN, fecha2));
    } else {
        dispatch(setData(FECHA_INICIO, fecha.day(1)));
        dispatch(setData(FECHA_FIN, fecha2.day(7)));
    }
};

const obtenerActividades = (id) => (dispatch, getStore) => {
    const resource = getStore().profesores;
    const params = { profesor: id };
    if (resource.tipo_evento)
        params.tipo_evento = resource.tipo_evento.value;
    if (resource.fecha_inicio)
        params.fecha_inicial = moment(resource.fecha_inicio).format("YYYY-MM-DD")
    if (resource.fecha_fin)
        params.fecha_fin = moment(resource.fecha_fin).format("YYYY-MM-DD")
    dispatch(setLoader(true));
    api.get('clase/get_clases_profesor', params).then((response) => {
        let eventos = response.results;
        eventos = eventos.map(item => {
            item.start = new Date(moment(item.fecha_hora_inicio));
            item.end = new Date(moment(item.fecha_hora_fin));
            item.tipo = item.tipo_evento;
            item.title = item.titulo;
            return item;
        })
        dispatch(setDataGeneral(EVENTOS, eventos));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const limpiarActividades = () => (dispatch, getStore) => {
    dispatch(setDataGeneral(EVENTOS, []));
};

const changeTipoEvento = (value, id) => (dispatch) => {
    dispatch(setDataGeneral(TIPO_EVENTO, value));
    dispatch(obtenerActividades(id));
};

const changeFechas = (value, id) => (dispatch) => {
    const fecha1 = moment(value[0]);
    const fecha2 = moment(value[6]);
    dispatch(setDataGeneral(FECHA_INICIO, fecha1));
    dispatch(setDataGeneral(FECHA_FIN, fecha2));
    dispatch(obtenerActividades(id));
};

const setearFormEventoDetalle = (evento) => (dispatch) => {

    const blocksFromHtml = htmlToDraft(evento.contenido ? evento.contenido : "");
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

    const data = { ...evento, contenido: EditorState.createWithContent(contentState)};
    data.dia = moment(evento.fecha_hora_inicio).get('d');
    data.dia = _.find(DIAS_SEMANA, { id: data.dia }).value;
    data.hora_inicio = moment(evento.fecha_hora_inicio).format("HH:mm:ss");
    data.hora_fin = moment(evento.fecha_hora_fin).format("HH:mm:ss");
    dispatch(initializeForm('EventoEditForm', data));

}

const anularClase = (id, data, idP) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`clase/${id}/anular_clase`, {motivo_anulacion: data.motivo_anulacion}).then(() => {
        dispatch(obtenerActividades(idP));
        NotificationManager.success('Clase cancelada', 'Éxito', 3000);
    }).catch((error) => {
        let mensaje = 'Error al cancelar la clase';
        if (error) {
            if (error.detail)
                mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtenerDetalleAula = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`aula/${id}/horas_pendientes_recuperar`).then((response) => {
        dispatch(setDataGeneral(DETALLE_AULA, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const resetDetalleAula = () => (dispatch) => {
    dispatch(setDataGeneral(DETALLE_AULA, null));
};

const preCargarEvento = (evento) => (dispatch) => {
    const start = moment(evento.start);
    const end = moment(evento.end);
    // const dia = _.find(DIAS_SEMANA, { id: start.get('d') });
    const data = {
        fecha: start,
        hora_inicio: start.format('HH:mm'),
        hora_fin: end.format('HH:mm')
    }
    dispatch(initializeForm('CrearEventoForm', data));
};

const crearEvento = (data, id, closeModal) => (dispatch) => {
    dispatch(setLoader(true));
    const dia = moment(data.fecha).format("YYYY-MM-DD")
    data.fecha_hora_inicio = moment(`${dia} ${data.hora_inicio}`).format();
    data.fecha_hora_fin = moment(`${dia} ${data.hora_fin}`).format();
    api.post('clase', data).then(() => {
        NotificationManager.success('Clase de recuperacion creada', 'Éxito', 3000);
        dispatch(obtenerActividades(id));
        closeModal();
    }).catch((error) => {
        let mensaje = "Error en la creación";
        if (error) {
            if (error.detail) {
                mensaje = error.detail;
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtenerLink = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`clase/${id}/link_admin`).then((response) => {
        if (response.link) {
            window.open(response.link, "_blank");
        } else {
            NotificationManager.error("Clase aun no disponible", 'ERROR');
        }
    }).catch( error => {
        let mensaje = "Error al obtener el link de la clase";
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    cleanForm,
    iniciarFechas,
    obtenerActividades,
    limpiarActividades,
    changeTipoEvento,
    changeFechas,
    setearFormEventoDetalle,
    anularClase,
    obtenerDetalleAula,
    preCargarEvento,
    crearEvento,
    resetDetalleAula,
    obtenerLink,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [EVENTOS]: (state, { data }) => {
        return {
            ...state,
            eventos: data,
        };
    },
    [TIPO_EVENTO]: (state, { data }) => {
        return {
            ...state,
            tipo_evento: data,
        };
    },
    [FECHA_INICIO]: (state, { data }) => {
        return {
            ...state,
            fecha_inicio: data,
        };
    },
    [FECHA_FIN]: (state, { data }) => {
        return {
            ...state,
            fecha_fin: data,
        };
    },
    [DETALLE_AULA]: (state, { data }) => {
        return {
            ...state,
            detalle_aula: data,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    eventos: [],
    tipo_evento: null,
    fecha_inicio: null,
    fecha_fin: null,
    detalle_aula: null,
};

export default handleActions(reducers, initialState);
