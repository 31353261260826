import React, {Fragment} from 'react';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import { Link } from 'react-router-dom';


const AlumnosForm = props => {
    const { handleSubmit, alumnos, nextStep, loader, item } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="col-12 text-right px-lg-5">
                    <button type="button" onClick={nextStep} className="btn btn-primary">Reasignar Alumnos</button>
                </div>
                <div className="col-12 px-lg-5">
                    <span className="text-sm azul-claro bold">{`Reasignar alumnos del curso: ${item.nombre}`}</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{height: '3px'}}/>
                </div>
                <div className="col-md-12 m-0 px-lg-5 mt-3">
                    <Grid
                        hover
                        striped
                        data={{ results: alumnos, count: 1 }}
                        loading={loader}
                        pagination={false}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            isKey
                            width='5%'
                            dataFormat={cell => ""}
                        />
                        <TableHeaderColumn
                            dataField="titulo"
                        >
                            NOMBRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="reasignado"
                            dataFormat={(cell) => cell ? (
                                <div className="d-flex flex-row align-items-center">
                                    <div className="circulo bg-aqua mr-1"></div>
                                    <span className="aqua ml-1">Reasignado</span>
                                </div>
                            ) : (
                                <div className="d-flex flex-row align-items-center">
                                    <div className="circulo bg-rosa mr-1"></div>
                                    <span className="rosa ml-1">Pendiente</span>
                                </div>
                            )}
                        >
                            Estado
                        </TableHeaderColumn>
                    </Grid>
                </div>
                <div className="d-none d-lg-flex buttons-box mt-5">
                    <Link
                        className="btn btn-secondary mr-3"
                        to={item.clase_grupal ? '/cursos_grupales' : '/cursos_individuales'}
                    >Cancelar</Link>
                    <button type="submit" className="btn btn-primary ml-3">Guardar</button>
                </div>
                <div className="d-flex d-lg-none flex-column mt-5">
                    <button type="submit" className="w-100 btn btn-primary">Guardar</button>
                    <Link
                        className="w-100 btn btn-secondary mt-3"
                        to={item.clase_grupal ? '/cursos_grupales' : '/cursos_individuales'}
                    >Cancelar</Link>
                </div>
            </div>
        </form>
    )
};


export default reduxForm({
    form: 'CambioProfeForm',
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: false, // <------ unregister fields on unmount
    asyncBlurFields: [],
    initialValues: {
        cursos: []
    },
})(AlumnosForm);
