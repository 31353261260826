import React, {Fragment} from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, FieldArray, } from 'redux-form';
import { AsyncSelectField } from '../../../Utils/renderField/renderField';
import { api } from "api";
import CardEmpty from "Utils/Cards/CardEmpty";
import { generos } from "../../../../../utility/constants";

const getNiveles = (search) => {
    let params = { }
    if (search) {
        params.search = search;
    }
    return api.get("asignatura_niveles", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
}

const renderNiveles = ({fields, updateData}) => (
    <div className="row col-12">
        <div className="col-md-8 col-12 px-0">
            {fields.map((nivel, index) => {
                return(
                    <div key={index}>
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor={`${nivel}.nombre`} className="m-0">Nivel y asignatura</label>
                        </div>
                        <div className="col-md-12 form-group row">
                            <div className="col-10">
                                <Field
                                    name={`${nivel}.nivel`}
                                    loadOptions={getNiveles}
                                    type="text"
                                    component={AsyncSelectField}
                                    valueKey="id"
                                    labelKey="titulo"
                                    isClearable={true}
                                    className="form-control"
                                />
                            </div>
                            <div className="col-2 d-flex align-items-center px-0">
                                <button className ="btn-delete" onClick={(e) =>{e.preventDefault(); fields.remove(index)}}><span className="material-icons rosa">close</span></button>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
        <div className="col-md-4 col-12 form-group mt-sm-3 mt-0 px-0">
            <button type="button" className="btn btn-dark btn-block" onClick={() => fields.push({})}>Agregar</button>
        </div>
    </div>
);

let PasoCuatroForm = props => {
    const { handleSubmit, setStep, ver_password, change_ver_password, previousStep } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="col-12">
                    <span className="text-sm azul-claro bold">Niveles</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{height: '3px'}}/>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 px-0">
                            <FieldArray name="niveles" component={renderNiveles} {...props} />
                        </div>
                    </div>
                </div>
                <div className="d-none d-lg-flex buttons-box mt-4">
                    <button className="btn btn-secondary mr-5" onClick={previousStep}>Regresar</button>
                    <button type="submit" className="btn btn-primary">Siguiente</button>
                </div>
                <div className="d-flex d-lg-none flex-column mt-4">
                    <button className="btn btn-secondary flex-1" onClick={previousStep}>Regresar</button>
                    <button type="submit" className="btn btn-primary flex-1 mt-3">Siguiente</button>
                </div>
            </div>
        </form>
    )
};

PasoCuatroForm = reduxForm({
    form: 'ProfesorForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    asyncBlurFields: [],
    initialValues:{
        niveles: [{}],
    },
})(PasoCuatroForm);

export default PasoCuatroForm
