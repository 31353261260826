import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import ConfigForm from './Form';
import Swal from 'sweetalert2';


export default class ConfigPlataforma extends Component {

    componentWillMount() {
        this.props.leer();
    };

    restarHora = () => {
        Swal.fire({
            title: '¿Restar una hora a todas las clases a futuro?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, restar!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value)
                this.props.cambiarHoraClases(true);
        });
    }

    incrementarHora = () => {
        Swal.fire({
            title: '¿Incrementar una hora a todas las clases a futuro?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, incrementar!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value)
                this.props.cambiarHoraClases();
        });
    }


    render() {
        const { crear, loader } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm>
                    <LoadMask loading={loader} blur>
                        <ConfigForm
                            onSubmit={crear}
                            restarHora={this.restarHora}
                            incrementarHora={this.incrementarHora}
                        />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
