import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderPasswordField } from '../../Utils/renderField';
import {Link, Redirect} from 'react-router-dom';

const LoginForm = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    return (
        <form name="loginForm" className="form-validate form-login mb-lg" onSubmit={handleSubmit}>
            <div className="form-group has-feedback w-100">
                <label htmlFor="email">Email</label>
                <Field name="email" label="Usuario" component={renderField} type="text" className="form-control" />
            </div>
            <div className="form-group has-feedback w-100">
                <label htmlFor="password">Contraseña</label>
                <Field
                    name="password"
                    label="Contraseña"
                    component={renderPasswordField}
                    type={`${props.ver_password ? "text" : "password"}`}
                    className="form-control"
                    ver_password={props.ver_password}
                    change_ver_password={props.change_ver_password}
                />
            </div>
            <div className="buttons-box w-100 px-5">
                <button type="submit" className="btn btn-primary flex-1 m-1 align-self-center">Login</button>
            </div>
            <div className="col-12 text-center mt-1 mt-md-3">
                <Link to="/recuperar_pass" className="puntero">Recuperar contraseña</Link>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'login', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            username: validators.exists()('Este campo es requerido'),
            password: validators.exists()('Este campo es requerido'),
        });
    },
})(LoginForm);
