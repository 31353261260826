import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/administradores';
import AdminCrear from './AdminCrear';

const mstp = state => {
    return {...state.administradores}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(AdminCrear)
