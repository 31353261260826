import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import _ from "lodash";
import {reset} from 'redux-form';
import { USER_ADMIN } from "../../../utility/constants";

const LOADER = 'LOADER_ADMINS';
const DATA = 'DATA_ADMINS';
const ITEM_DATA = 'ITEM_ADMINS';
const PAGE = 'PAGE_ADMINS';
const ORDERING = 'ORDERING_ADMINS';
const SEARCH = 'SEARCH_ADMINS';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().administradores;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    params.tipo_usuario = USER_ADMIN;
    dispatch(setLoader(true));
    api.get('user', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${'user'}/${id}`).then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm('EditarAdminForm', response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post('user', data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(cleanForm()); // Limpiar el formulario
        dispatch(push('/administradores'));
    }).catch((error) => {
        let mensaje = 'Error en la creación';
        if (error && error.detail)
            mensaje = error.detail;
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${'user'}/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push('/administradores'));
    }).catch((error) => {
        let mensaje = 'Error al editar';
        if (error && error.detail)
            mensaje = error.detail;
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${'user'}/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

// const desactivar = (id, data) => (dispatch) => {
//     dispatch(setLoader(true));
//     api.post(`finca/${id}/desactivar`, data).then(() => {
//         dispatch(listar());
//         NotificationManager.success('Usuario Desactivado', 'Éxito', 3000);
//     }).catch(() => {
//         NotificationManager.error('Error en la transacción', 'ERROR', 3000);
//     }).finally(() => {
//         dispatch(setLoader(false));
//     });
// };

// const activar = id => (dispatch) => {
//     dispatch(setLoader(true));
//     api.post(`finca/${id}/activar`).then(() => {
//         dispatch(listar());
//         NotificationManager.success('Usuario Activado', 'Éxito', 3000);
//     }).catch(() => {
//         NotificationManager.error('Error en la transacción', 'ERROR', 3000);
//     }).finally(() => {
//         dispatch(setLoader(false));
//     });
// };

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('EditarAdminForm', {}));
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().administradores.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    // desactivar,
    // activar,
    cleanForm,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
};

export default handleActions(reducers, initialState);
