import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import ConfigForm from './Form';
import { EditorState, convertToRaw } from 'draft-js';

import draftToHtml from 'draftjs-to-html';

export default class ConfigBienvenida extends Component {

    componentWillMount() {
        this.props.leer();
    };

    onEditorStateChange = (editorState) => {
        this.setState({
          editorState,
        });
    };

    crear = (data) => {
        const draw = data.editor;
        const markup = draftToHtml(convertToRaw(draw.getCurrentContent()))
        //  ! Falta probar convertir al reves
    }


    render() {
        const { crear, loader } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm>
                    <LoadMask loading={loader} blur>
                        <ConfigForm
                            onSubmit={crear}
                        />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
