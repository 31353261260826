import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/formularios';
import FormulariosList from './Formulario';


const ms2p = (state) => {
  return {
    ...state.formularios,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(FormulariosList);
