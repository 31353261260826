import React, { useState, Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { Link } from 'react-router-dom';
import { renderField, renderNumber, AsyncSelectField, renderDatePicker, renderFieldCheck } from '../../../Utils/renderField';
import { RenderCurrency, RenderNumber } from '../../../Utils/renderField/renderReadField';
import Calendario from '../../../Utils/Calendario/Calendario';
import moment from 'moment';
import { api } from '../../../../../utility/api';


const getProfesores = (search) => {
    let params = { tipo_usuario: 20 }
    if (search) {
        params.search = search;
    }
    return api.get("user", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
}

const getCursos = (search) => {
    let params = {}
    if (search) {
        params.search = search;
    }
    return api.get("curso", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
}

const getSeriesFacturas = (search) => {
    let params = {}
    if (search) {
        params.search = search;
    }
    return api.get("serie_factura", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
}

class Asignacion extends Component {

    mostrarFechas = () => {
        const { curso } = this.props;
        if (curso) {
            if (!curso.interno && !curso.curso_vencimiento)
                return false
        }
        return true;
    }

    render() {

        const { handleSubmit, curso } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <div className="form-group grid-container mx-md-5 px-md-5">
                    <div className="col-12">
                        <span className="text-sm azul-claro bold">Datos del formulario</span>
                        <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                    </div>
                    <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="curso" className="m-0">Curso</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name="curso"
                                    loadOptions={getCursos}
                                    type="text"
                                    component={AsyncSelectField}
                                    // top={{ top: "5px", position: "inherit" }}
                                    valueKey="id"
                                    labelKey="titulo"
                                    isClearable={true}
                                    className="form-control"
                                    onCambio={(value) => {
                                        if (value) {
                                            this.props.obtenerPrecio(value.id);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="nombre" className="m-0">Nombre de la sección</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="nombre" component={renderField} type="text" className="form-control" />
                            </div>
                        </div>
                    </div>
                    {(curso) && (curso.interno) && (
                        <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="profesor" className="m-0">Profesor</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        name="profesor"
                                        loadOptions={getProfesores}
                                        type="text"
                                        component={AsyncSelectField}
                                        // top={{ top: "5px", position: "inherit" }}
                                        valueKey="id"
                                        labelKey="nombres"
                                        isClearable={false}
                                        className="form-control"
                                        onCambio={(value) => {
                                            if (value) {
                                                this.props.obtenerHorario(value.id);
                                                // this.props.calcularTotales();
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="creditos_clase" className="m-0">Créditos por clase</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="creditos_clase" component={renderNumber} type="text" className="form-control" />
                                </div>
                            </div>
                        </div>
                    )}
                    {(this.mostrarFechas()) && (
                        <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="fecha_inicio" className="m-0">Fecha inicio</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="fecha_inicio" component={renderDatePicker} onCambio={this.props.totalClaseGrupal}/>
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="fecha_fin" className="m-0">Fecha fin</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="fecha_fin" component={renderDatePicker} onCambio={this.props.totalClaseGrupal}/>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="serie_factura" className="m-0">Serie de factura</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name="serie_factura"
                                    loadOptions={getSeriesFacturas}
                                    type="text"
                                    component={AsyncSelectField}
                                    valueKey="id"
                                    labelKey="prefijo"
                                    isClearable={true}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        {(curso) && (curso.interno) && (
                            <div className="col-md-6 p-0">
                                <div className="col-md-12  form-group">
                                    <Field name="curso_calificable" component={renderFieldCheck} label=" Curso calificable"/>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                        {(curso) && (curso.interno) && (
                            <div className="d-flex flex-2 justify-content-center align-items-center">
                                <div className="d-flex flex-md-row flex-column justify-content-center align-items-center">
                                    <h2 className="mr-2">Total de horas</h2>
                                    <RenderNumber value={this.props.horas_prometidas} decimalScale={2} className="h2 rosa" />
                                </div>
                            </div>
                        )}
                        <div className="d-flex flex-2 justify-content-center align-items-center">
                            <div className="d-flex flex-md-row flex-column justify-content-center align-items-center ">
                                <h2 className="mr-2">Valor del curso</h2>
                                <RenderCurrency value={this.props.precio_total} className="h2 verde" />
                            </div>
                        </div>
                        {/* <div className="d-flex flex-1 justify-content-center align-items-center">
                            <div className="d-flex flex-md-row flex-column justify-content-center align-items-center ">
                                <label htmlFor="tiene_iva" className="m-0 mr-2">Agregar IVA</label>
                                <Field name="tiene_iva" component={renderFieldCheck} onCambio={this.props.totalClaseGrupal} />
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="form-group grid-container">
                    {(curso) && (curso.interno) && (
                        <div className="d-flex flex-1 w-100" style={{ overflowX: "auto" }}>
                            <Calendario
                                eventos={this.props.horarios}
                                toolbar={true}
                                onlySemana={true}
                                onSelectSlot={this.props.openModal}
                                onSelectEvent={this.props.eliminarReserva}
                            />
                        </div>
                    )}
                    <div className="buttons-box mt-5">
                        <Link className="btn btn-secondary mr-5" to="/cursos_grupales">Cancelar</Link>
                        <button type="submit" className="btn btn-primary">Guardar</button>
                    </div>
                </div>
            </form>
        );
    }
};

const selector = formValueSelector('AsignacionForm');

const AsignacionForm = reduxForm({
    form: 'AsignacionForm', // a unique identifier for this form
    asyncBlurFields: [],
    initialValues: {
        fecha_inicio: moment(),
        fecha_fin: moment(),
        horas_prometidas: 0,
        precio_total: 0,
        clase_grupal: false,
        tiene_iva: false,
        porcentaje_iva: null,
        curso_calificable: false,
        precio_hora: 0,
    },
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()('Campo requerido.'),
            profesor: validators.exists()('Campo requerido.'),
            serie_factura: validators.exists()('Campo requerido.'),
            horas_tutorias: validators.exists()('Campo requerido.'),
            fecha_inicio: validators.exists()('Campo requerido.'),
            fecha_fin: validators.exists()('Campo requerido.'),
            creditos_clase: validators.exists()('Campo requerido.'),
        });
    },
})(Asignacion);

const mstp = state => {
    const curso = selector(state, 'curso');
    const horas_prometidas = selector(state, 'horas_prometidas');
    const fecha_fin = selector(state, 'fecha_fin');
    const precio_total = selector(state, 'precio_total');
    let horario = selector(state, 'horario');

    horario = horario ? horario.length : 0;

    return {
        curso,
        horas_prometidas,
        fecha_fin,
        precio_total,
        horario
    }
};

export default connect(mstp, null)(AsignacionForm);
