import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import LogoForm from './LogoForm';

export default class CrearLogo extends Component {

    state = {
        logo: null,
    };

    componentWillMount(){
        if(this.props.match.params.id){
            this.props.leerLogo(this.props.match.params.id);
        }
    }

    setLogo = (logo) => {
        this.setState({ logo });
    };

    onSubmit = (data) => {
        const id = this.props.match.params.id;
        if(id) {
            this.props.editarLogo(data, id, [{ "file": this.state.logo, "name": "logo" }])
        } else {
            this.props.crearLogo(data, [{ "file": this.state.logo, "name": "logo" }])
        }
    };

    render() {
        const { loader, item2 } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm col={10}>
                    <LoadMask loading={loader} blur>
                        <LogoForm
                            onSubmit={this.onSubmit}
                            id={this.props.match.params.id}
                            logo={this.state.logo}
                            setLogo={this.setLogo}
                            item={item2}
                        />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
