import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import Card from "Utils/Cards/Card";
import Header2 from "Utils/Grid/Header2";
import {formatActivo, RenderCurrency} from "Utils/renderField/renderReadField";
import MonthPicker from 'Utils/MonthPicker';
import moment from 'moment';

export default class CuponesList extends Component{
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar: onPageChange, onSortChange, eliminar, filtroMesInicioNominas ,filtroMesFinNominas } = this.props; //Funciones
        const { data, loader, page, mes_inicio_filtro_nominas, mes_fin_filtro_nominas} = this.props; //Constantes

        return(
            <div className="mb-4 col-12 mt-4">
                <Header2
                    to="nomina/crear"
                    textBtn="Agregar nomina"
                    textBtn2="Descargar excel"
                    btn2Click={this.props.descargar}
                    filtro2={
                        <MonthPicker
                            value={mes_inicio_filtro_nominas}
                            onChange={filtroMesInicioNominas}
                            placeholder="Mes inicio"
                        />
                    }
                    filtro1={
                        <MonthPicker
                            value={mes_fin_filtro_nominas}
                            onChange={filtroMesFinNominas}
                            placeholder="Mes fin"
                        />
                    }
                    {...this.props}
                />
                <Card>
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                    page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            isKey
                            width='15%'
                            dataFormat={standardActions({ editar: "nomina", eliminar})}
                        >
                            ACCIONES
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="usuario"
                            dataSort
                            dataFormat={(cell) => cell && cell.nombres && cell.apellidos ? cell.nombres + ' ' + cell.apellidos : ''}
                        >
                            USUARIO CREADOR
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="mes_anio"
                            dataSort
                            dataFormat={(cell) => cell ? moment(cell).format('MMMM/YYYY') : ""}
                        >
                            MES NOMINA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="total_a_pagar"
                            dataSort
                            dataFormat={(cell) => cell ? <RenderCurrency value={cell} /> : ""}
                        >
                            TOTAL A PAGAR
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
