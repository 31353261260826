import { createAction, handleActions } from 'redux-actions';
import {NotificationManager} from "react-notifications";
import { api } from "api";
import moment from 'moment';
import _ from "lodash";

const LOADER = 'LOADER_FACTURACION_PROFE';
const DATA = 'DATA_FACTURACION_PROFE';
const GRAFICA = 'GRAFICA_FACTURACION_PROFE';
const PAGE = 'ITEM_FACTURACION_PAGE';
const FECHA_INICIO = 'FECHA_INICIO_FACTURACION_PROFE';
const FECHA_FIN = 'FECHA_FIN_FACTURACION_PROFE';
const PROFESOR = 'PROFESOR_FACTURACION_PROFE';
const ALUMNO = 'ALUMNO_FACTURACION_PROFE';


// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (type, data) => ({
    type,
    data,
});

// ------------------------------------
// Actions
// ------------------------------------

const listarGrafica = () => (dispatch, getStore) => {
    const resource = getStore().facturacionProfesor;
    const params = {};
    if (resource.profesor)
        params.profesor = resource.profesor.id;
    if (resource.alumno)
        params.alumno = resource.alumno.id;
    if (resource.fecha_inicio)
        params.fecha_inicio = moment(resource.fecha_inicio).format("YYYY-MM-DD");
    if (resource.fecha_fin)
        params.fecha_fin = moment(resource.fecha_fin).format("YYYY-MM-DD");
    dispatch(setLoader(true));
    api.get('reportes/grafica_facturacion_profesor', params).then((response) => {
        const data = [['Facturado', 'Profesor']];
        for (const item of response.resp) {
            data.push([item.profesor, item.total]);
        }
        dispatch(setData(GRAFICA, data));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarTabla = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().facturacionProfesor;
    const params = { page };
    if (resource.profesor)
        params.profesor = resource.profesor.id;
    if (resource.alumno)
        params.alumno = resource.alumno.id;
    if (resource.fecha_inicio)
        params.fecha_inicio = moment(resource.fecha_inicio).format("YYYY-MM-DD");
    if (resource.fecha_fin)
        params.fecha_fin = moment(resource.fecha_fin).format("YYYY-MM-DD");
    dispatch(setLoader(true));
    api.get('reportes/tabla_facturacion_profesor', params).then((response) => {
        dispatch(setData(DATA, response));
        dispatch(setData(PAGE, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const descargarExcel = () => (dispatch, getStore) => {
    const resource = getStore().facturacionProfesor;
    let endpoint = '/api/reportes/excel_facturacion_profesor?';
    if (resource.profesor)
        endpoint += `profesor=${resource.profesor.id}&`;
    if (resource.alumno)
        endpoint += `alumno=${resource.alumno.id}&`;
    if (resource.fecha_inicio)
        endpoint += `fecha_inicio=${moment(resource.fecha_inicio).format("YYYY-MM-DD")}&`;
    if (resource.fecha_fin)
        endpoint += `fecha_fin=${moment(resource.fecha_fin).format("YYYY-MM-DD")}&`;

    endpoint += `auth_token=${sessionStorage.getItem("token")}`
    window.open(endpoint, '_blank');
}

const changeProfesor = value => (dispatch) => {
    dispatch(setData(PROFESOR, value));
    dispatch(listarGrafica());
    dispatch(listarTabla());
};

const changeAlumno = value => (dispatch) => {
    dispatch(setData(ALUMNO, value));
    dispatch(listarGrafica());
    dispatch(listarTabla());
};

const changeFechaInicio = value => (dispatch) => {
    dispatch(setData(FECHA_INICIO, value));
    dispatch(listarGrafica());
    dispatch(listarTabla());
};

const changeFechaFin = value => (dispatch) => {
    dispatch(setData(FECHA_FIN, value));
    dispatch(listarGrafica());
    dispatch(listarTabla());
};

export const actions = {
    listarGrafica,
    listarTabla,
    descargarExcel,
    changeProfesor,
    changeAlumno,
    changeFechaInicio,
    changeFechaFin,
};


// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [GRAFICA]: (state, { data }) => {
        return {
            ...state,
            grafica: data,
        };
    },
    [PAGE]: (state, { data }) => {
        return {
            ...state,
            page: data,
        };
    },
    [PROFESOR]: (state, { data }) => {
        return {
            ...state,
            profesor: data,
        };
    },
    [ALUMNO]: (state, { data }) => {
        return {
            ...state,
            alumno: data,
        };
    },
    [FECHA_INICIO]: (state, { data }) => {
        return {
            ...state,
            fecha_inicio: data,
        };
    },
    [FECHA_FIN]: (state, { data }) => {
        return {
            ...state,
            fecha_fin: data,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    grafica: [['Facturado', 'Profesor']],
    page: 1,
    profesor: null,
    alumno: null,
    fecha_inicio: null,
    fecha_fin: null,
};

export default handleActions(reducers, initialState);
