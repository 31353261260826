import React, { useState, Component } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField } from '../../../Utils/renderField';


const AsignaturaForm = (props) => {
    const { handleSubmit } = props;
    return (
        <form name="AsignaturaForm" onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">Nombre Asignatura</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombre" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="buttons-box mt-5">
                    <Link className="btn btn-secondary mr-5" to="/asignaturas_niveles">Cancelar</Link>
                    <button type="submit" className="btn btn-primary">Guardar</button>
                </div>
            </div>
        </form>
    );
};


export default reduxForm({
    form: 'AsignaturaForm', // a unique identifier for this form
    validate: data => {
        return validate(data, {
            'nombre': validators.exists()('Campo requerido.'),
        })
    }
})(AsignaturaForm);
