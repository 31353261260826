import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {change, initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import _ from "lodash";
import {reset} from 'redux-form';
import { USER_ADMIN, USER_ALUMNO, USER_PROFESOR } from "../../../utility/constants";
import { EditorState, convertFromHTML, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const LOADER = 'LOADER_CURSOS_GRUPALES';
const DATA = 'DATA_CURSOS_GRUPALES';
const ITEM_DATA = 'ITEM_CURSOS_GRUPALES';
const PAGE = 'PAGE_CURSOS_GRUPALES';
const ORDERING = 'ORDERING_CURSOS_GRUPALES';
const SEARCH = 'SEARCH_CURSOS_GRUPALES';

const LOADER_SECCIONES = 'LOADER_SECCIONES';
const DATA_SECCIONES = 'DATA_SECCIONES';
const ITEM_DATA_SECCIONES = 'ITEM_DATA_SECCIONES';
const PAGE_SECCIONES = 'PAGE_SECCIONES';
const ORDERING_SECCIONES = 'ORDERING_SECCIONES';
const SEARCH_SECCIONES = 'SEARCH_SECCIONES';

// -----------------------------------
// Pure Actions Cursos Grupales
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

// -----------------------------------
// Pure Actions Secciones
// -----------------------------------

const setLoaderSecciones = loader_secciones => ({
    type: LOADER_SECCIONES,
    loader_secciones,
});

const setDataSecciones = data_secciones => ({
    type: DATA_SECCIONES,
    data_secciones,
});

const setItemSecciones = item_secciones => ({
    type: ITEM_DATA_SECCIONES,
    item_secciones,
});

const setPageSecciones = page_secciones => ({
    type: PAGE_SECCIONES,
    page_secciones,
});

const setOrderingSecciones = ordering_secciones => ({
    type: ORDERING_SECCIONES,
    ordering_secciones,
});

const setSearchSecciones = search_secciones => ({
    type: SEARCH_SECCIONES,
    search_secciones,
});

// ------------------------------------
// Actions
// ------------------------------------

// CURSOS GRUPALES
const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().cursosGrupales;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get('curso', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => async (dispatch) => {
    const params = {};
    dispatch(setLoader(true));
    try {
        const response = await api.get(`${'curso'}/${id}`, params)

        // Descripcion
        const blocksFromHtml = htmlToDraft(response.descripcion ? response.descripcion : "");
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        // Temario
        const blocksFromHtml2 = htmlToDraft(response.temario ? response.temario : "");
        const contentState2 = ContentState.createFromBlockArray(blocksFromHtml2.contentBlocks, blocksFromHtml2.entityMap);

        const _data = { ...response, descripcion: EditorState.createWithContent(contentState), temario: EditorState.createWithContent(contentState2) };
        dispatch(setItem(response));
        dispatch(initializeForm('CursoGrupalForm', _data));
    } catch(err) {
    } finally {
        dispatch(setLoader(false));
    }
};

const crear = (data, attachments = []) => (dispatch) => {
    const _data = data;
    const body = {..._data}
    if (_data.descripcion) {
        const draw = _data.descripcion;
        const html = draftToHtml(convertToRaw(draw.getCurrentContent()))
        body.descripcion = html;
    }
    if (_data.temario) {
        const draw = _data.temario;
        const html = draftToHtml(convertToRaw(draw.getCurrentContent()))
        body.temario = html;
    }
    dispatch(setLoader(true));
    api.postAttachments('curso', body, attachments).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(cleanForm()); // Limpiar el formulario
        dispatch(push('/cursos_grupales'));
    }).catch((error) => {
        let mensaje = "Error en la creación";
        if (error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const editar = (data, id, attachments = []) => (dispatch) => {
    const _data = data;
    const body = {..._data}
    if (_data.descripcion) {
        const draw = _data.descripcion;
        const html = draftToHtml(convertToRaw(draw.getCurrentContent()))
        body.descripcion = html;
    }
    if (_data.temario) {
        const draw = _data.temario;
        const html = draftToHtml(convertToRaw(draw.getCurrentContent()))
        body.temario = html;
    }
    dispatch(setLoader(true));
    api.putAttachments(`${'curso'}/${id}`, body, attachments).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(cleanForm()); // Limpiar el formulario
        dispatch(push('/cursos_grupales'));
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${'curso'}/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('CursoGrupalForm', {interno: true, iva: false, curso_vencimiento: true}));
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().administradores.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

// SECCIONES
const listarSecciones = (page_secciones = 1) => (dispatch, getStore) => {
    const resource = getStore().cursosGrupales;
    const params = { page: page_secciones };
    params.ordering = resource.ordering_secciones;
    params.search = resource.search_secciones;
    dispatch(setLoaderSecciones(true));
    api.get('aula/get_secciones', params).then((response) => {
        dispatch(setDataSecciones(response));
        dispatch(setPageSecciones(page_secciones));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoaderSecciones(false));
    });
};

const leerSeccion = id => (dispatch) => {
    const params = {};
    dispatch(setLoaderSecciones(true));
    api.get(`aula/${id}`, params).then((response) => {
        dispatch(setItemSecciones(response));
        dispatch(initializeForm('SeccionesForm', response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoaderSecciones(false));
    });
};

const crearSeccion = data => (dispatch) => {
    dispatch(setLoaderSecciones(true));
    api.post('aula', data).then(() => {
        NotificationManager.success('Seccion creada correctamente', 'Éxito', 3000);
        dispatch(cleanFormSeccion()); // Limpiar el formulario
        dispatch(push('/cursos_grupales'));
    }).catch((error) => {
        let mensaje = "Error en la creación";
        if (error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoaderSecciones(false));
    });
};

const editarSeccion = (data, id) => (dispatch) => {
    const params = {};
    dispatch(setLoaderSecciones(true));
    api.put(`aula/${id}`, data, params).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push('/cursos_grupales'));
    }).catch((error) => {
        let mensaje = "Error en la edición";
        if (error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoaderSecciones(false));
    });
};

const eliminarSeccion = id => (dispatch) => {
    dispatch(setLoaderSecciones(true));
    api.eliminar(`${'aula'}/${id}`).then(() => {
        dispatch(listarSecciones());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoaderSecciones(false));
    });
}

const cleanFormSeccion = () => (dispatch) => {
    dispatch(initializeForm('SeccionesForm', {}));
};

const searchChangeSeccion = search_secciones => (dispatch) => {
    dispatch(setSearchSecciones(search_secciones));
    dispatch(listarSecciones());
};

const onSortChangeSeccion = ordering_secciones => (dispatch, getStore) => {
    const sort = getStore().cursosGrupales.ordering_secciones;
    if (ordering_secciones === sort) {
        dispatch(setOrderingSecciones(`-${ordering_secciones}`));
    } else {
        dispatch(setOrderingSecciones(ordering_secciones));
    }
    dispatch(listarSecciones());
};

const changeIva = () => (dispatch, getStore) => {
    const form = getStore().form.CursoGrupalForm.values;
    console.log("iva ", form.iva)
    if (form.iva) {
        dispatch(setLoader(true));
        api.get('conf_plataforma').then((response) => {
            dispatch(change('CursoGrupalForm', 'porcentaje_iva', response.iva));
        }).catch(() => {
        }).finally(() => {
            dispatch(setLoader(false));
        });
    } else {
        dispatch(change('CursoGrupalForm', 'porcentaje_iva', 0));
    }
}

export const actions = {
    // Cursos grupales
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    cleanForm,
    // Secciones
    listarSecciones,
    leerSeccion,
    crearSeccion,
    editarSeccion,
    eliminarSeccion,
    searchChangeSeccion,
    onSortChangeSeccion,
    cleanFormSeccion,
    changeIva,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [LOADER_SECCIONES]: (state, { loader_secciones }) => {
        return {
            ...state,
            loader_secciones,
        };
    },
    [DATA_SECCIONES]: (state, { data_secciones }) => {
        return {
            ...state,
            data_secciones,
        };
    },
    [ITEM_DATA_SECCIONES]: (state, { item_secciones }) => {
        return {
            ...state,
            item_secciones,
        };
    },
    [PAGE_SECCIONES]: (state, { page_secciones }) => {
        return {
            ...state,
            page_secciones,
        };
    },
    [ORDERING_SECCIONES]: (state, { ordering_secciones }) => {
        return {
            ...state,
            ordering_secciones,
        };
    },
    [SEARCH_SECCIONES]: (state, { search_secciones }) => {
        return {
            ...state,
            search_secciones,
        };
    },
};

export const initialState = {
    // CURSOS GRUPALES
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    // SECCIONES
    loader_secciones: false,
    data_secciones: {
        results: [],
        count: 0,
    },
    item_secciones: {},
    page_secciones: 1,
    ordering_secciones: '',
    search_secciones: '',
};

export default handleActions(reducers, initialState);
