import React, { Component } from 'react';
import { push } from "react-router-redux";
import {
    Accordion,
    AccordionItem,
    AccordionItemState,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { Badge } from 'antd';
import { Link, NavLink } from "react-router-dom";
import "./styles.css";
import { api } from "api";


const avatar = require("../../../../../assets/img/avatar.png");
const perfil_verde = require("../../../../../assets/img/icons/perfil_verde.png");
const perfil_blanco = require("../../../../../assets/img/icons/perfil_blanco.png");
const alerta_verde = require("../../../../../assets/img/icons/alerta_verde.png");
const alerta_blanco = require("../../../../../assets/img/icons/alerta_blanco.png");
const mensaje_blanco = require("../../../../../assets/img/icons/mensaje_blanco.png");
const mensaje_verde = require("../../../../../assets/img/icons/mensaje_verde.png");
const inicio_azul = require("../../../../../assets/img/icons/inicio_azul.png");
const inicio_blanco = require("../../../../../assets/img/icons/inicio_blanco.png");
const conf_azul = require("../../../../../assets/img/icons/conf_azul.png");
const conf_blanco = require("../../../../../assets/img/icons/conf_blanco.png");
const dep_comercial_azul = require("../../../../../assets/img/icons/dep_comercial_azul.png");
const dep_comercial_blanco = require("../../../../../assets/img/icons/dep_comercial_blanco.png");
const biblioteca_azul = require("../../../../../assets/img/icons/biblioteca_azul.png");
const biblioteca_blanco = require("../../../../../assets/img/icons/biblioteca_blanco.png")
const cursos_azul = require("../../../../../assets/img/icons/cursos_azul.png");
const cursos_blanco = require("../../../../../assets/img/icons/cursos_blanco.png");
const reportes_blanco = require("../../../../../assets/img/icons/reportes_blanco.png");
const reportes_azul = require("../../../../../assets/img/icons/reportes_azul.png");
const euro_blanco = require("../../../../../assets/img/icons/euro_blanco.png");
const euro_azul = require("../../../../../assets/img/icons/euro_azul.png");
const salir = require("../../../../../assets/img/iconos/salir.png");

class SideBar extends Component {

    state = {
        item_activo: "Inicio",
        subMenus: [
            // {path: "/perfil", nombre: "Perfil"}
        ],
        notificaciones: false
    }
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const url = window.location.href;
        this.comprobarNotificaciones();
        this.interval = setInterval(() => this.comprobarNotificaciones(), 150000)

        if (url.includes("reporte") || url.includes("bitacora")) {
            this.selectReportes();
        } else if (url.includes("alumno") || url.includes("profesor") || url.includes("administrador")
        || url.includes("config_plataforma") || url.includes("colaborador") || url.includes("centros_colaboradores")) {
            this.selectAdmin();
        } else if (url.includes("/comercial") || url.includes("cupon") || url.includes("mensajes") || url.includes("logo") || url.includes("noticias_campañas")) {
            this.selectDeptoComercial();
        } else if (url.includes("asignaturas_niveles") || url.includes("asignatura") || url.includes("nivel")) {
            this.selectBiblioteca();
        } else if (url.includes("perfil")) {
            this.selectPerfil();
        } else if (url.includes("comunicado")) {
            this.selectComunicados();
        } else if (url.includes("cursos") || url.includes("curso_grupal") || url.includes("curso_individual") || url.includes("curso")) {
            this.selectCursos();
        } else if (url.includes("series_facturas") || url.includes("serie_factura") || url.includes("nomina") || url.includes("factura")
        || url.includes("servicios")) {
            this.selectAreaFinanciera();
        } else if (url.includes("biblioteca_archivos") || url.includes("formulario") ) {
            this.selectBiblioteca();
        } else if (url.includes("notificaciones")) {
            this.selectNotificaciones();
        }
    }

    comprobarNotificaciones = () => {
        api.get('notificaciones/comprobar').then((response) => {
            this.setState({ notificaciones: response.resp ? true : false });
        }).catch(() => {
            this.setState({ notificaciones: false });
        });
    }

    selectHome = () => {
        this.setState({ item_activo: "Inicio" });
        this.setState({ subMenus: [] });
    };

    selectAdmin = () => {
        this.setState({ item_activo: "Administración" });
        this.setState({
            subMenus: [
                { path: "/alumnos", nombre: "Alumnos" },
                { path: "/profesores", nombre: "Profesores" },
                { path: "/administradores", nombre: "Administradores" },
                { path: "/colaboradores", nombre: "Colaboradores" },
                { path: "/centros_colaboradores", nombre: "Centros Colaboradores" },
                { path: "/config_plataforma", nombre: "Config. Plataforma" },
            ]
        })
    };

    selectDeptoComercial = () => {
        this.setState({ item_activo: "Depto. Comercial" });
        this.setState({
            subMenus: [
                { path: "/comercial/mensaje", nombre: "Textos de la pagina" },
                { path: "/logos", nombre: "Logos" },
                { path: "/cupones", nombre: "Cupones" },
                { path: "/mensajes", nombre: "Gestión de mensajes profesor" },
                { path: "/noticias_campañas", nombre: "Noticias y campañas" },
                { path: "/comercial/descuentos", nombre: "Descuentos" },
            ]
        })
    };

    selectPerfil = () => {
        this.setState({ item_activo: "Mi perfil" });
        this.setState({
            subMenus: [
                { path: "/perfil", nombre: "Datos perfil" },
            ]
        });
    };

    selectComunicados = () => {
        this.setState({ item_activo: "Comunicados" });
        this.setState({
            subMenus: [
                { path: "/comunicados", nombre: "Comunicados" },
            ]
        });
    };

    selectAreaFinanciera = () => {
        this.setState({ item_activo: "Área Financiera" });
        this.setState({
            subMenus: [
                { path: "/series_facturas", nombre: "Series para facturas" },
                { path: "/nominas", nombre: "Nominas" },
                { path: "/facturas", nombre: "Facturas" },
                { path: "/servicios", nombre: "Servicios" },
            ]
        })
    };

    selectBiblioteca = () => {
        this.setState({ item_activo: "Gestión y Biblioteca" });
        this.setState({
            subMenus: [
                { path: "/asignaturas_niveles", nombre: "Asignaturas y niveles" },
                { path: "/formularios", nombre: "Ejercicios y examenes" },
            ]
        })
    };

    selectCursos = () => {
        this.setState({ item_activo: "Cursos" });
        this.setState({
            subMenus: [
                { path: "/cursos_grupales", nombre: "Grupales" },
                { path: "/cursos_individuales", nombre: "Individuales" },
                { path: "/cursos/formularios", nombre: "Ejercicios y exámenes" },
                { path: "/cursos/recuperaciones", nombre: "Tiempo de recuperación" },
            ]
        })
    };

    selectReportes = () => {
        this.setState({ item_activo: "Reportes" });
        this.setState({
            subMenus: [
                { path: "/bitacora", nombre: "Bitacora" },
                { path: "/reporte/avance_alumnos", nombre: "Avance Alumnos" },
                { path: "/reporte/puntuaciones", nombre: "Puntuación de Alumnos" },
                { path: "/reporte/cupones", nombre: "Códigos" },
                { path: "/reporte/horas_trabajadas", nombre: "Horas trabajadas" },
                { path: "/reporte/ingresos_gastos", nombre: "Ingresos y gastos" },
                { path: "/reporte/facturacion_profesor", nombre: "Facturación por profesor" },
            ]
        })
    };

    selectNotificaciones = () => {
        this.setState({ item_activo: "Notificaciones" });
        this.setState({
            subMenus: [
            ]
        })
    };

    render() {
        const { toggleOpen, navToggle, logOut, me } = this.props;
        const { item_activo, subMenus, notificaciones } = this.state;
        const url = window.location.href;
        return (
            <div className={`main-sidebar menu d-flex flex-row flex-1 ${toggleOpen ? '' : 'open'}`}>
                <aside className="icons-part d-flex flex-1 flex-column">
                    <div className="main-navbar">
                        <nav
                            className="align-items-stretch bg-white flex-md-nowrap p-0 navbar navbar-light my-lg-4 my-2">
                            <a href="#" className="w-100 mr-0 navbar-brand" onClick={this.selectHome} >
                                <div className="d-table m-auto">
                                    <img
                                        id="main-logo"
                                        className="d-inline-block align-top"
                                        src={require('assets/img/Isotipo.png')}
                                        alt="Logo"
                                        width="30"
                                    />
                                </div>
                            </a>
                            {/* <a  className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
                                onClick={navToggle}>
                                <i className="material-icons"></i>
                            </a> */}
                        </nav>
                    </div>
                    <div className="nav-wrapper h-100">
                        <div className="menu-icon h-100">
                            <Accordion className="nav--no-borders flex-column nav h-100">
                                {/* EJEMPLO DE UN ITEM SIN SUB MENU */}
                                <div className="nav-item">
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <AccordionItemState>
                                                    {({ expanded }) => (
                                                        <NavLink to="/" exact className={`nav-link ${item_activo === "Inicio" && 'active'}`} onClick={this.selectHome} >
                                                            <div className="item-menu">
                                                                <div className="d-inline-block item-icon-wrapper">
                                                                    {/* <i className="material-icons">home</i> */}
                                                                    <img
                                                                        className="align-center img_azul_display"
                                                                        src={inicio_azul}
                                                                        alt="Logo"
                                                                        width="15"
                                                                    />
                                                                    <img
                                                                        className="align-center img_blanco_display"
                                                                        src={inicio_blanco}
                                                                        alt="Logo"
                                                                        width="15"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </NavLink>
                                                    )}
                                                </AccordionItemState>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                    </AccordionItem>
                                </div>
                                {/* EJEMPLO DE UN ITEM CON SUB MENU */}
                                <div className="nav-item">
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <AccordionItemState>
                                                    {({ expanded }) => (
                                                        <div
                                                            className={`nav-link ${this.state.item_activo === "Administración" && 'active'}`}
                                                            onClick={this.selectAdmin}
                                                        >
                                                            <div className="item-menu">
                                                                <div className="d-inline-block item-icon-wrapper">
                                                                    {/* <i className="material-icons">vertical_split</i> */}
                                                                    <img
                                                                        className="align-center img_azul_display"
                                                                        src={conf_azul}
                                                                        alt="Logo"
                                                                        width="15"
                                                                    />
                                                                    <img
                                                                        className="align-center img_blanco_display"
                                                                        src={conf_blanco}
                                                                        alt="Logo"
                                                                        width="15"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </AccordionItemState>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                    </AccordionItem>
                                </div>
                                <div className="nav-item">
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <AccordionItemState>
                                                    {({ expanded }) => (
                                                        <div
                                                            className={`nav-link ${this.state.item_activo === "Depto. Comercial" && 'active'}`}
                                                            onClick={this.selectDeptoComercial}
                                                        >
                                                            <div className="item-menu">
                                                                <div className="d-inline-block item-icon-wrapper">
                                                                    {/* <i className="material-icons">vertical_split</i> */}
                                                                    <img
                                                                        className="align-center img_azul_display"
                                                                        src={dep_comercial_azul}
                                                                        alt="Logo"
                                                                        width="15"
                                                                    />
                                                                    <img
                                                                        className="align-center img_blanco_display"
                                                                        src={dep_comercial_blanco}
                                                                        alt="Logo"
                                                                        width="15"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </AccordionItemState>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                    </AccordionItem>
                                </div>
                                {/* Cursos */}
                                <div className="nav-item">
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <AccordionItemState>
                                                    {({ expanded }) => (
                                                        <div
                                                            className={`nav-link ${this.state.item_activo === "Cursos" && 'active'}`}
                                                            onClick={this.selectCursos}
                                                        >
                                                            <div className="item-menu">
                                                                <div className="d-inline-block item-icon-wrapper">
                                                                    <img
                                                                        className="align-center img_azul_display"
                                                                        src={cursos_azul}
                                                                        alt="Logo"
                                                                        width="15"
                                                                    />
                                                                    <img
                                                                        className="align-center img_blanco_display"
                                                                        src={cursos_blanco}
                                                                        alt="Logo"
                                                                        width="15"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </AccordionItemState>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                    </AccordionItem>
                                </div>
                                {/* Biblioteca */}
                                <div className="nav-item">
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <AccordionItemState>
                                                    {({ expanded }) => (
                                                        <div
                                                            className={`nav-link ${this.state.item_activo === "Gestión y Biblioteca" && 'active'}`}
                                                            onClick={this.selectBiblioteca}
                                                        >
                                                            <div className="item-menu">
                                                                <div className="d-inline-block item-icon-wrapper">
                                                                    <img
                                                                        className="align-center img_azul_display"
                                                                        src={biblioteca_azul}
                                                                        alt="Logo"
                                                                        width="15"
                                                                    />
                                                                    <img
                                                                        className="align-center img_blanco_display"
                                                                        src={biblioteca_blanco}
                                                                        alt="Logo"
                                                                        width="15"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </AccordionItemState>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                    </AccordionItem>
                                </div>
                                {/* Area Financiera */}
                                <div className="nav-item">
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <AccordionItemState>
                                                    {({ expanded }) => (
                                                        <div
                                                            className={`nav-link ${this.state.item_activo === "Área Financiera" && 'active'}`}
                                                            onClick={this.selectAreaFinanciera}
                                                        >
                                                            <div className="item-menu">
                                                                <div className="d-inline-block item-icon-wrapper">
                                                                    <img
                                                                        className="align-center img_azul_display"
                                                                        src={euro_azul}
                                                                        alt="Logo"
                                                                        width="15"
                                                                    />
                                                                    <img
                                                                        className="align-center img_blanco_display"
                                                                        src={euro_blanco}
                                                                        alt="Logo"
                                                                        width="15"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </AccordionItemState>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                    </AccordionItem>
                                </div>
                                {/* Reportes */}
                                <div className="nav-item">
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <AccordionItemState>
                                                    {({ expanded }) => (
                                                        <div
                                                            className={`nav-link ${this.state.item_activo === "Reportes" && 'active'}`}
                                                            onClick={this.selectReportes}
                                                        >
                                                            <div className="item-menu">
                                                                <div className="d-inline-block item-icon-wrapper">
                                                                    <img
                                                                        className="align-center img_azul_display"
                                                                        src={reportes_azul}
                                                                        alt="Logo"
                                                                        width="15"
                                                                    />
                                                                    <img
                                                                        className="align-center img_blanco_display"
                                                                        src={reportes_blanco}
                                                                        alt="Logo"
                                                                        width="15"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </AccordionItemState>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                    </AccordionItem>
                                </div>
                                {/* Logout */}
                                <div className="nav-item">
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <AccordionItemState>
                                                    {({ expanded }) => (
                                                        <NavLink
                                                            to="/login" onClick={logOut} exact className={`nav-link ${item_activo === "logut" && 'active'}`} >
                                                            <div className="item-menu">
                                                                <div className="d-inline-block item-icon-wrapper">
                                                                    <img className="material-icons icon-menu" src={salir} />
                                                                    {/* <i className="material-icons">edit</img> */}
                                                                </div>
                                                            </div>
                                                        </NavLink>
                                                    )}
                                                </AccordionItemState>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                    </AccordionItem>
                                </div>
                                <div className="cola-menu d-flex w-100 flex-1"></div>
                            </Accordion>
                        </div>
                    </div>
                </aside>
                <div className="items-part d-flex flex-column flex-3">
                    <div className="perfil-header w-100 d-flex flex-column justify-content-center align-items-center">
                        <div className="">
                            <img className="perfil-img" src={me.avatar ? me.avatar :  avatar} alt="Perfil" />
                        </div>
                        <div className="perfil-menu mt-1 d-flex flex-column w-100">
                            <span className="perfil-nombre">{`${me.nombres && me.nombres}`}</span>
                            <div className="perfil-actions d-flex justify-content-center">
                                <button className="perfil-action px-0" onClick={this.selectPerfil} >
                                    {
                                        (url.includes("perfil")) ? (
                                            <img src={perfil_blanco} style={{width: 30}} />

                                        ) : (
                                            <img src={perfil_verde} style={{width: 35}} />
                                        )
                                    }
                                </button>
                                <Link className="perfil-action px-0" to='/notificaciones' onClick={this.selectNotificaciones} >
                                    {
                                        (url.includes("notificaciones")) ? (
                                            <img src={alerta_blanco} style={{width: 30}} />

                                        ) : (
                                            <Badge dot={notificaciones} offset={[-8, 10]}>
                                                <img src={alerta_verde} style={{width: 35}} />
                                            </Badge>
                                        )
                                    }
                                </Link>
                                <button className="perfil-action px-0" onClick={this.selectComunicados}>
                                    {
                                        (url.includes("comunicado")) ? (
                                            <img src={mensaje_blanco} style={{width: 30}} />

                                        ) : (
                                            <img src={mensaje_verde} style={{width: 35}} />
                                        )
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="menu-items w-100">
                        <div className="header-item">
                            <span className="text-nav">{this.state.item_activo}</span>
                        </div>
                        <ul className="sub-menu-list">
                            {
                                this.state.subMenus.map((item, index) => (
                                    <li className="sub-nav-item" key={index}>
                                        <NavLink to={item.path} exact className="sub-nav-link " activeClassName={'active'}>
                                            <div className="sub-item-menu">
                                                <span className="sub-text-nav">{item.nombre}</span>
                                            </div>
                                        </NavLink>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default SideBar;
