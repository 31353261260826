import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";

export default class ListarMensajes extends Component{

    componentWillMount() {
        const { listar } = this.props;
        listar();
    }

    render() {
        const { listar: onPageChange, onSortChange, eliminar } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return(
            <div className="mb-4 col-12 mt-4">
                <Header to="/mensajes/crear" textBtn="Agregar" {...this.props} />
                <Card>
                    <Grid
                        hover
                        striped
                        data={data}
                        loading={loader}
                        onPageChange={onPageChange}
                        onSortChange={onSortChange}
                        page={page}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='20%'
                            dataFormat={standardActions({ editar: "mensajes", eliminar})}
                        />
                        <TableHeaderColumn
                            dataField="titulo"
                            dataSort
                        >
                            Título
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="mensaje"
                            width="50%"
                        >
                            Cuerpo del mensaje
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
