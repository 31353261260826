import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm, change} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import {reset} from 'redux-form';
import _ from "lodash";
import moment from "moment";
import { DIAS_SEMANA, RESERVADO, RESERVAR, DISPONIBLE, DIAS_CALENDARIO } from '../../../utility/constants';

const LOADER = 'LOADER_AULAS';
const DATA = 'DATA_AULAS';
const ITEM_DATA = 'ITEM_AULAS';
const PAGE = 'PAGE_AULAS';
const ORDERING = 'ORDERING_AULAS';
const SEARCH = 'SEARCH_AULAS';
const HORARIOS_OCUPADOS = 'HORARIOS_OCUPADOS_AULAS';
const PRECIO_CLASES_GRUPALES = 'PRECIO_CLASES_GRUPALES';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

const setHorarios = horarios => ({
    type: HORARIOS_OCUPADOS,
    horarios,
});

const setPrecioClase = precio_clase_grupal => ({
    type: PRECIO_CLASES_GRUPALES,
    precio_clase_grupal,
});

// ------------------------------------
// Actions
// ------------------------------------

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().aulas;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    params.clase_grupal = false;
    dispatch(setLoader(true));
    api.get('aula', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`aula/${id}`).then((response) => {
        dispatch(setItem(response));
        obtenerHorario(response.profesor.id)
        dispatch(initializeForm('AsignacionForm', response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtenerPrecio = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`curso/${id}`).then((response) => {
        dispatch(setPrecioClase(response.precio))
        dispatch(change('AsignacionForm', 'porcentaje_iva', null));
        dispatch(change('AsignacionForm', 'tiene_iva', false));
        if (response.porcentaje_iva) {
            dispatch(change('AsignacionForm', 'porcentaje_iva', response.porcentaje_iva));
            dispatch(change('AsignacionForm', 'tiene_iva', true));
        }
        dispatch(change('AsignacionForm', 'precio_sin_iva', response.precio_sin_iva));
        dispatch(change('AsignacionForm', 'precio_total', response.precio));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const obtenerIva = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get('conf_plataforma').then((response) => {
        dispatch(change('AsignacionForm', 'porcentaje_iva', response.iva));
        // dispatch(initializeForm('AsignacionForm', { porcentaje_iva: response.iva }));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const obtenerHorario = id => (dispatch, getStore) => {
    const form = getStore().form.AsignacionForm.values;
    const params = {};
    params.profesor = id;
    dispatch(setLoader(true));
    api.get(`aula/horario_profesor`, params).then((response) => {
        // Seteamos los horarios ocupados del profesor
        for (const item of response) {
            const dia = moment(item.fecha_hora_inicio);
            let start = null;
            let end = null;
            if (moment().get('d') != 0 &&  dia.get('d') == 0) {
                start = moment(dia.format("HH:mm:ss"), "HH:mm:ss").day(7);
                end = _.cloneDeep(start).add(item.duracion, 'h');
            } else {
                if (moment().get('d') == 0) {
                    if (dia.get('d') != 0) {
                        start = moment(dia.format("HH:mm:ss"), "HH:mm:ss").day(-1);
                    }
                }
                start = moment(dia.format("HH:mm:ss"), "HH:mm:ss").day(dia.get('d'));
                end = _.cloneDeep(start).add(item.duracion, 'h');
            }

            item.title = 'Reservado';
            item.tipo = RESERVADO;
            item.start = new Date(start);
            item.end   = new Date(end);
        }

        for (const dia of DIAS_CALENDARIO) {
            for (const item of form.profesor[dia.key]) {
                // Lunes
                let start = moment(item.hora_inicio, "HH:mm:ss");
                let end = moment(item.hora_fin, "HH:mm:ss");

                if (moment().get('d') != 0 && dia.value == 0) {
                    start = start.day(7);
                    end = end.day(7);
                } else {
                    if (moment().get('d') == 0) {
                        if (dia.value != 0) {
                            start = start.day(-1);
                            end = end.day(-1);
                        }
                    }
                    start = start.day(dia.value);
                    end = end.day(dia.value);
                }
                response.push({
                    title: '',
                    tipo: DISPONIBLE,
                    start: new Date(start),
                    end: new Date(end),
                })
            }
        }
        dispatch(setHorarios(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = (data, asignacion_alumno = true) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('aula', data).then(() => {
        if (asignacion_alumno) {
            NotificationManager.success('Alumno asignado', 'Éxito', 3000);
            dispatch(cleanForm()); // Limpiar el formulario
            dispatch(push('/cursos_individuales'));
        }
        else{
            NotificationManager.success('Curso asignado', 'Éxito', 3000);
            dispatch(cleanForm()); // Limpiar el formulario
            dispatch(push('/cursos_grupales'));
        }
    }).catch(() => {
        NotificationManager.error('Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (data, id) => (dispatch) => {
    const params = {};
    params.tipo_usuario = USER_PROFESOR;
    dispatch(setLoader(true));
    api.put(`aula/${id}`, data, params).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push('/AULAS'));
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`aula/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Curso eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('EditarProfesorForm', {}));
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().aulas.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const preCargarEvento = (evento) => (dispatch) => {
    const start = moment(evento.start);
    const end = moment(evento.end);
    const dia = _.find(DIAS_SEMANA, { id: start.get('d') });
    const data = {
        dia: dia.value,
        hora_inicio: start.format('HH:mm'),
        hora_fin: end.format('HH:mm')
    }
    dispatch(initializeForm('AsignacionEventoForm', data));
};

const preCargarPrecio = (profesor) => (dispatch) => {
    dispatch(change('AsignacionForm', 'precio_hora', profesor.precio_x_hora));
    dispatch(calcularTotales());
};

const agregarHorario = (evento) => (dispatch, getStore) => {
    const horarios = getStore().aulas.horarios;
    const form = getStore().form.AsignacionForm.values;
    const horario = form.horario ? form.horario : [];
    const dia = _.find(DIAS_SEMANA, { value: evento.dia });

    let start = moment(evento.hora_inicio, "HH:mm");
    let end = moment(evento.hora_fin, "HH:mm");

    if (moment().get('d') != 0 &&  dia.id == 0) {
        start = start.day(7);
        end = end.day(7);
    } else {
        if (moment().get('d') == 0) {
            if (dia.id != 0) {
                start = start.day(-1);
                end = end.day(-1);
            }
        }
        start = start.day(dia.id);
        end = end.day(dia.id);
    }


    // Validamos si tenemos profesor
    if (form.profesor) {

        // Encontramos la menor hora inicio y la mayor hora fin
        let min_inicio = null;
        let max_fin = null;

        const dia_aux = _.find(DIAS_CALENDARIO, { value: start.get('d') });
        const dia_habil = form.profesor[dia_aux.key]

        for (const item of dia_habil) {
            if (min_inicio == null)
                min_inicio = moment(item.hora_inicio, "HH:mm:ss")
            if (max_fin == null)
                max_fin = moment(item.hora_fin, "HH:mm:ss")
            if (moment(item.hora_inicio, "HH:mm:ss") < min_inicio )
                min_inicio = moment(item.hora_inicio, "HH:mm:ss")
            if (moment(item.hora_fin, "HH:mm:ss") > max_fin )
                max_fin = moment(item.hora_fin, "HH:mm:ss")
        }

        if (min_inicio != null && max_fin != null) {
            if (moment().get('d') != 0 &&  dia.id == 0) {
                min_inicio = min_inicio.day(7);
                max_fin = max_fin.day(7);
            } else {
                if (moment().get('d') == 0) {
                    if (dia.id != 0) {
                        min_inicio = min_inicio.day(-1);
                        max_fin = max_fin.day(-1);
                    }
                }
                min_inicio = min_inicio.day(dia.id);
                max_fin = max_fin.day(dia.id);
            }
        }

        // Validamos si es dentro del día habil
        if (min_inicio == null || max_fin == null) {
            NotificationManager.error('Clase fuera del horario disponible del profesor', 'ERROR');
        } else if (min_inicio > start) {
            NotificationManager.error('Clase fuera del horario disponible del profesor', 'ERROR');
        } else if (max_fin < start) {
            NotificationManager.error('Clase fuera del horario disponible del profesor', 'ERROR');
        } else if (max_fin < end) {
            NotificationManager.error('Clase fuera del horario disponible del profesor', 'ERROR');
        } else {
            // Dentro del rango de fechas
            const id = `id-${horario.length + 1}`;
            horarios.push({
                id: id,
                title : 'Reservar',
                tipo  : RESERVAR,
                start : new Date(start),
                end   : new Date(end),
            }),
            dispatch(setHorarios(horarios));
            horario.push({
                id: id,
                fecha_hora_inicio: start.format(),
                duracion: (end - start) / 3600000,
                dia: evento.dia,
                hora_inicio: evento.hora_inicio,
                hora_fin: evento.hora_fin
            });
            dispatch(change('AsignacionForm', 'horario', horario));

            dispatch(calcularTotales());
        }

    } else {
        NotificationManager.error('Seleccione un profesor antes', 'ERROR');
    }
};

const agregarHorarioClaseGrupal = (evento) => (dispatch, getStore) => {
    const horarios = getStore().aulas.horarios;
    const form = getStore().form.AsignacionForm.values;
    const horario = form.horario ? form.horario : [];
    const dia = _.find(DIAS_SEMANA, { value: evento.dia });

    let start = moment(evento.hora_inicio, "HH:mm");
    let end = moment(evento.hora_fin, "HH:mm");

    if (moment().get('d') != 0 &&  dia.id == 0) {
        start = start.day(7);
        end = end.day(7);
    } else {
        if (moment().get('d') == 0) {
            if (dia.id != 0) {
                start = start.day(-1);
                end = end.day(-1);
            }
        }
        start = start.day(dia.id);
        end = end.day(dia.id);
    }

    // Validamos si tenemos profesor
    if (form.profesor) {

        // Encontramos la menor hora inicio y la mayor hora fin
        let min_inicio = null;
        let max_fin = null;

        const dia_aux = _.find(DIAS_CALENDARIO, { value: start.get('d') });
        const dia_habil = form.profesor[dia_aux.key]

        for (const item of dia_habil) {
            if (min_inicio == null)
                min_inicio = moment(item.hora_inicio, "HH:mm:ss")
            if (max_fin == null)
                max_fin = moment(item.hora_fin, "HH:mm:ss")
            if (moment(item.hora_inicio, "HH:mm:ss") < min_inicio )
                min_inicio = moment(item.hora_inicio, "HH:mm:ss")
            if (moment(item.hora_fin, "HH:mm:ss") > max_fin )
                max_fin = moment(item.hora_fin, "HH:mm:ss")
        }

        if (min_inicio != null && max_fin != null) {
            if (moment().get('d') != 0 &&  dia.id == 0) {
                min_inicio = min_inicio.day(7);
                max_fin = max_fin.day(7);
            } else {
                if (moment().get('d') == 0) {
                    if (dia.id != 0) {
                        min_inicio = min_inicio.day(-1);
                        max_fin = max_fin.day(-1);
                    }
                }
                min_inicio = min_inicio.day(dia.id);
                max_fin = max_fin.day(dia.id);
            }
        }

        // Validamos si es dentro del día habil
        if (min_inicio == null || max_fin == null) {
            NotificationManager.error('Clase fuera del horario disponible del profesor', 'ERROR');
        } else if (min_inicio > start) {
            NotificationManager.error('Clase fuera del horario disponible del profesor', 'ERROR');
        } else if (max_fin < start) {
            NotificationManager.error('Clase fuera del horario disponible del profesor', 'ERROR');
        } else if (max_fin < end) {
            NotificationManager.error('Clase fuera del horario disponible del profesor', 'ERROR');
        } else {
            // Dentro del rango de fechas
            const id = `id-${horario.length + 1}`;
            horarios.push({
                id: id,
                title : 'Reservar',
                tipo  : RESERVAR,
                start : new Date(start),
                end   : new Date(end),
            }),
            dispatch(setHorarios(horarios));
            horario.push({
                id: id,
                fecha_hora_inicio: start.format(),
                duracion: (end - start) / 3600000,
                dia: evento.dia,
                hora_inicio: evento.hora_inicio,
                hora_fin: evento.hora_fin
            });
            dispatch(change('AsignacionForm', 'horario', horario));

            dispatch(totalClaseGrupal());
        }

    } else {
        NotificationManager.error('Seleccione un profesor antes', 'ERROR');
    }
};

const calcularTotales = () => (dispatch, getStore) => {
    const form = getStore().form.AsignacionForm.values;
    const horario = form.horario ? form.horario : [];

    // Manejamos total de horas
    let total_horas = 0;
    let fecha = moment(form.fecha_inicio.format("YYYY-MM-DD"));
    const fecha_fin = moment(form.fecha_fin).endOf('month');
    while (fecha <= fecha_fin) {
        const dia_semana = _.find(DIAS_SEMANA, { id: fecha.get('d') });
        const dias = horario.filter(item => item.dia == dia_semana.value );
        for (const dia of dias) {
            let diferencia = moment(dia.hora_fin, "HH:mm") - moment(dia.hora_inicio, "HH:mm");
            diferencia = diferencia / 3600000; // # Convertirlo a horas
            total_horas += diferencia;
        }
        fecha = fecha.add(1, 'days');
    }

    // Costo
    let costo = form.precio_hora * total_horas;
    const sin_iva = form.precio_hora * total_horas;
    // Evaluamos el iva
    if (form.tiene_iva) {
        costo = costo + (costo * form.porcentaje_iva);
    }

    dispatch(change('AsignacionForm', 'horas_prometidas', total_horas));
    dispatch(change('AsignacionForm', 'precio_sin_iva', sin_iva));
    dispatch(change('AsignacionForm', 'precio_total', costo));
};

const totalClaseGrupal = () => (dispatch, getStore) => {
    const form = getStore().form.AsignacionForm.values;
    const horario = form.horario ? form.horario : [];
    console.log(horario)
    // Manejamos total de horas
    let total_horas = 0;
    let fecha = moment(form.fecha_inicio.format("YYYY-MM-DD"));
    const fecha_fin = moment(form.fecha_fin.format("YYYY-MM-DD"));
    while (fecha <= fecha_fin) {
        console.log("while")
        const dia_semana = _.find(DIAS_SEMANA, { id: fecha.get('d') });
        const dias = horario.filter(item => item.dia == dia_semana.value );
        for (const dia of dias) {
            let diferencia = moment(dia.hora_fin, "HH:mm") - moment(dia.hora_inicio, "HH:mm");
            diferencia = diferencia / 3600000; // # Convertirlo a horas
            total_horas += diferencia;
        }
        fecha = fecha.add(1, 'days');
    }
    console.log("total_horas ", total_horas)
    dispatch(change('AsignacionForm', 'horas_prometidas', total_horas));
}

const limpiar = () => (dispatch) => {
    dispatch(setHorarios([]));
};

const eliminarReserva = (evento) => (dispatch, getStore) => {
    const resource = getStore().aulas;
    const form = getStore().form.AsignacionForm.values;

    const horarios = resource.horarios.filter(item => item.id != evento.id );
    const horario  = form.horario.filter(item => item.id != evento.id );

    dispatch(setHorarios(horarios));
    dispatch(change('AsignacionForm', 'horario', horario));

    dispatch(calcularTotales());
}

const eliminarReservaClaseGrupal = (evento) => (dispatch, getStore) => {
    const resource = getStore().aulas;
    const form = getStore().form.AsignacionForm.values;

    const horarios = resource.horarios.filter(item => item.id != evento.id );
    const horario  = form.horario.filter(item => item.id != evento.id );

    dispatch(setHorarios(horarios));
    dispatch(change('AsignacionForm', 'horario', horario));
    dispatch(totalClaseGrupal());
}


export const actions = {
    listar,
    leer,
    obtenerIva,
    obtenerHorario,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    cleanForm,
    preCargarEvento,
    preCargarPrecio,
    agregarHorario,
    calcularTotales,
    limpiar,
    eliminarReserva,
    obtenerPrecio,
    totalClaseGrupal,
    agregarHorarioClaseGrupal,
    eliminarReservaClaseGrupal,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [HORARIOS_OCUPADOS]: (state, { horarios }) => {
        return {
            ...state,
            horarios,
        };
    },
    [PRECIO_CLASES_GRUPALES]: (state, { precio_clase_grupal }) => {
        return {
            ...state,
            precio_clase_grupal,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    horarios: [],
    precio_clase_grupal: 0,
};

export default handleActions(reducers, initialState);
