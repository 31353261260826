import React, {Component, Fragment} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import Card from "Utils/Cards/Card";
import Header from "Utils/Grid/Header";
import {formatActivo} from "Utils/renderField/renderReadField";
import moment from 'moment';
import { CUPON_UNICO, CUPON_MULTIPLE, DINERO, CLASE_INDIVIDUAL_CUPONES, CLASE_GRUPAL_CUPONES } from '../../../../../utility/constants';
import { RenderNumber, RenderCurrency } from '../../../Utils/renderField/renderReadField';
import { PrintContainer, actions as printActions } from 'Utils/Print';

// Mostrar cupones
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const top_cupon = require("../../../../../../assets/img/cupon.png")
const arrow = require("../../../../../../assets/img/iconos/arrow_down.png")

export default class CuponesList extends Component{
    state = {
        mostrar_cupones: false
    };

    componentWillMount(){
        if(this.props.match.params.id){
            this.props.leer(this.props.match.params.id);
        }
    }

    imprimirCupones = () => {
        printActions.print('listado_cupones');
    }

    render() {
        const { item, loader } = this.props; //Constantes
        const cupones = item && item.cupones ? item.cupones : [];
        const ganacias = item && item.ganancias_colaborador ? {results: item.ganancias_colaborador} : {results: []};
        return(
            <div className="mb-4 col-12 mt-4">
                <Card>
                    <div className="col-12 mb-4">
                        <div className="col-12 px-3 pb-0 mb-5 bg-celeste-claro card-gris-encabezado">
                            <div className="row">
                                <div className="col-md-3 col-12 d-flex flex-wrap flex-column mb-3">
                                    <span className="azul-claro bold">Tipo de cupón</span>
                                    <span className="black">{item && item.tipo_cupon_text ? item.tipo_cupon_text : ""}</span>
                                </div>
                                {(item) && (item.colaborador) && (
                                    <div className="col-md-3 col-12 d-flex flex-wrap flex-column mb-3">
                                        <span className="azul-claro bold">Colaborador</span>
                                        <span className="black">{`${item.colaborador.nombres} ${item.colaborador.apellidos}`}</span>
                                    </div>
                                )}
                                {(item) && (item.centro_colaborador) && (
                                    <div className="col-md-3 col-12 d-flex flex-wrap flex-column mb-3">
                                        <span className="azul-claro bold">Centro Colaborador</span>
                                        <span className="black">{`${item.centro_colaborador.nombre}`}</span>
                                    </div>
                                )}
                                <div className="col-md-3 col-12 d-flex flex-wrap flex-column mb-3">
                                    <span className="azul-claro bold">Nombre del cupón</span>
                                    <span className="black">{item && item.nombre ? item.nombre : ""}</span>
                                </div>
                                <div className="col-md-3 col-12 d-flex flex-wrap flex-column mb-3">
                                    <span className="azul-claro bold">Tipo de formación</span>
                                    <span className="black">{item && item.tipo_formacion_text ? item.tipo_formacion_text : ""}</span>
                                </div>
                                <div className="col-md-3 col-12 d-flex flex-wrap flex-column mb-3">
                                    <span className="azul-claro bold">Fecha inicial</span>
                                    <span className="black">{item && item.fecha_inicial ? moment(item.fecha_inicial).format('DD/MM/YYYY') : ""}</span>
                                </div>
                                <div className="col-md-3 col-12 d-flex flex-wrap flex-column mb-3">
                                    <span className="azul-claro bold">Fecha final</span>
                                    <span className="black">{item && item.fecha_final ? moment(item.fecha_final).format('DD/MM/YYYY') : ""}</span>
                                </div>
                                <div className="col-md-3 col-12 d-flex flex-wrap flex-column mb-3">
                                    <span className="azul-claro bold">Descuento del cupón</span>
                                    <span className="black">{item && item.descuento && item.tipo_descuento ? (item.tipo_descuento === DINERO ? item.descuento + ' €' : item.descuento + '%') : ""}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mb-3 px-0">
                            <span className="text-sm azul-claro bold">Ganancias del colaborador</span>
                            <div className="d-flex w-100 bg-gris-oscuro" style={{height: '3px'}}/>
                        </div>
                        <Grid hover striped data={ganacias} loading={loader} pagination={false}>
                            <TableHeaderColumn
                                dataField="id"
                                isKey
                                hidden
                            />
                            <TableHeaderColumn width="20%"/>
                            <TableHeaderColumn
                                dataField="cantidad_estudiantes"
                                dataSort
                                dataAlign="center"
                                width="30%"
                                dataFormat={(cell) => cell ? cell + (cell === 1 ? ' estudiante' : ' estudiantes') : ''}
                            >
                                CANTIDAD DE ESTUDIANTES
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="precio_x_estudiante"
                                dataSort
                                dataAlign="center"
                                width="30%"
                                dataFormat={(cell) => cell ? <RenderCurrency value={cell} />  : ''}
                            >
                                EUROS * ALUMMNO
                            </TableHeaderColumn>
                            <TableHeaderColumn width="20%"/>
                        </Grid>
                        <div className="mt-5"/>
                        <Accordion>
                            <AccordionSummary
                                // expandIcon={<ExpandMoreIcon />}
                                expandIcon={<button type="button" class="d-flex flex-1 rbc-btn azul"><img src={arrow} class="w-100 h-100" /></button>}
                                aria-controls={`cupones-content`}
                                id={`cupones-header`}
                            >
                                {item.tipo_cupon === CUPON_UNICO && (
                                    <span className="text-sm azul-claro bold">CUPONES - Total de usos disponibles: {item.cantidad_usos ? item.cantidad_usos : 0}</span>
                                )}
                                {item.tipo_cupon === CUPON_MULTIPLE && (
                                    <span className="text-sm azul-claro bold">CUPONES - Total de cupones disponibles: {cupones.length ? cupones.length : 0}</span>
                                )}
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="col-12 mt-3">
                                    <div className="print-only mt-0">
                                        <PrintContainer name='listado_cupones'>
                                            <div className="row">
                                                <div className="col-12 text-center mb-3 dont-print">
                                                    <button className="btn btn-primary" onClick={this.imprimirCupones}>IMPRIMIR</button>
                                                </div>
                                                {/* <div className="col-12 text-center mb-3 no-mostrar">
                                                    <h3>LISTADO DE CUPONES</h3>
                                                </div> */}
                                                {cupones.map((cupon, index) => {
                                                    return(
                                                        <Fragment>
                                                            <div className="col-sm-4 col-md-6 col-lg-4 col-12 col-cupones mb-3" key={cupon.id}>
                                                                {/* <h4>{(index + 1) % 20 === 0 ? "SE ROMPE" : "NO SE ROMPE"}</h4> */}
                                                                <div className="col-12 px-0 borde-cupon">
                                                                    <img src={top_cupon} alt="Top cupon" className="imagen-cupon"/>
                                                                    <div className="col-12 d-flex flex-column align-items-center mb-2">
                                                                        <div className="cuadro-cupon text-center mt-3 mb-2">
                                                                            <span className="azul text-lg bold texto-cupon">{cupon.cupon}</span>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="px-0 h-100 d-flex justify-content-between">
                                                                                <div className="col-5 px-0 d-flex flex-column">
                                                                                    <span className="text-equal text-right black bold">Tipo</span>
                                                                                    {/* <span className="text-equal text-right black">{item && item.tipo_cupon_text ? item.tipo_cupon_text : ""}</span> */}
                                                                                    <span className="text-equal text-right black">Cupón unico</span>
                                                                                    <span className="text-equal text-right black bold">Expira</span>
                                                                                    <span className="text-equal text-right black">{item && item.fecha_final ? moment(item.fecha_final).format('DD/MM/YYYY') : ""}</span>
                                                                                </div>
                                                                                {/* <div className="d-flex h-100 bg-gris-oscuro" style={{width: '3px'}}/> */}
                                                                                <div className="d-flex bg-verde-claro px-0" style={{width: '3px'}}/>
                                                                                <div className="col-5 px-0 d-flex flex-column">
                                                                                    <span className="text-equal text-left black bold">Descuento</span>
                                                                                    <span className="text-equal text-left black">{item && item.descuento && item.tipo_descuento ? (item.tipo_descuento === DINERO ? item.descuento + ' €' : item.descuento + '%') : ""}</span>
                                                                                    <span className="text-equal text-left black bold">Aplica para</span>
                                                                                    {item && item.tipo_formacion && item.tipo_formacion === CLASE_INDIVIDUAL_CUPONES && (
                                                                                        <span className="text-equal text-left black">Curso individual</span>
                                                                                    )}
                                                                                    {item && item.tipo_formacion && item.cursos_grupales && item.tipo_formacion === CLASE_GRUPAL_CUPONES && (
                                                                                        <Fragment>
                                                                                            {item.cursos_grupales.map((curso, index) => {
                                                                                                return (
                                                                                                    <span className="text-equal text-left black" key={index}>{curso && curso.curso_grupal && curso.curso_grupal.titulo ? curso.curso_grupal.titulo : ""}</span>
                                                                                                )
                                                                                            })}
                                                                                        </Fragment>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {(index + 1) % 15 === 0 && (index + 1) === 15 && (
                                                                <div className="col-12 salto-pagina"></div>
                                                            )}
                                                            {(index + 1) % 15 === 0 && (index + 1) != 15 && (
                                                                <div className="col-12 salto-pagina"></div>
                                                            )}
                                                        </Fragment>
                                                    )
                                                })}
                                            </div>
                                        </PrintContainer>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <div className="d-flex flex-wrap justify-content-center align-items-center mt-3">
                            <div className="col-md-4 col-12">
                                <Link className="btn btn-secondary btn-block" to="/cupones">Regresar</Link>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        )
    }
}
