import React, { Component, cloneElement } from 'react';
import Chart from "react-google-charts";
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Select, { Async } from 'react-select';
import classNames from 'classnames';
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import Card from "Utils/Cards/Card"
import MonthPicker from "../../../Utils/MonthPicker";
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import HeaderFiltros from '../../../Utils/Grid/HeaderFiltros';
import { RenderCurrency } from '../../../Utils/renderField/renderReadField';
import moment from 'moment';
import { api } from "api";

import { USER_ALUMNO, USER_PROFESOR } from '../../../../../utility/constants';

const getProfesores = (search) => {
    return api
        .get("user", { search, tipo_usuario: USER_PROFESOR })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};

const getAlumnos = (search) => {
    return api
        .get("user", { search, tipo_usuario: USER_ALUMNO })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};

export default class ReporteCupones extends Component {

    componentWillMount() {
        this.props.listarData();
        this.props.listarDataIngresos();
        this.props.listarDataNominas();
        this.props.listarDataColaborador();
        this.props.listarDataCentroColab();
        this.interval = setInterval(() => this.props.descargarFacturas(), 5000)
    }

    render() {
        const {
            loader,
            // Filtros
            filtro_mes_anio,
            filtro_profesor,
            filtro_alumno,
            // Data
            task_id,
            data,
            grafica_ingresos_egresos,
            grafica_ingresos,
            grafica_egresos,
            resumen_ingresos,
            data_egresos_nominas,
            data_egresos_colaboradores,
            data_egresos_centro_colaborar,
            // pages
            page_ingresos,
            page_egresos_nominas,
            page_egresos_colaboradores,
            page_egresos_centro_colaborar,

            totales,
        } = this.props; //Constantes
        const { filtroFechaMesAnio, filtroProfesor, filtroAlumno } = this.props; //Funciones

        return (
            <div className="mb-4 col-12 mt-4">
                <HeaderFiltros
                    filtro1={
                        <MonthPicker
                            value={filtro_mes_anio}
                            onChange={filtroFechaMesAnio}
                            placeholder="Fecha"
                        />
                    }
                    filtro2={
                        <Async
                            isClearable={true}
                            backspaceRemovesValue={false}
                            value={filtro_profesor}
                            isSearchable={true}
                            loadOptions={getProfesores}
                            getOptionValue={(option) => option["id"]}
                            getOptionLabel={(option) => option["titulo"]}
                            type="text"
                            onChange={(e) => filtroProfesor(e)}
                            multi={false}
                            autoload={false}
                            cache={false}
                            className={classNames(
                                "react-select-container w-100"
                            )}
                            defaultOptions={true}
                            placeholder="Filtrar por profesor.."
                        />
                    }
                    filtro3={
                        <Async
                            isClearable={true}
                            backspaceRemovesValue={false}
                            value={filtro_alumno}
                            isSearchable={true}
                            loadOptions={getAlumnos}
                            getOptionValue={(option) => option["id"]}
                            getOptionLabel={(option) => option["titulo"]}
                            type="text"
                            onChange={(e) => filtroAlumno(e)}
                            multi={false}
                            autoload={false}
                            cache={false}
                            className={classNames(
                                "react-select-container w-100"
                            )}
                            defaultOptions={true}
                            placeholder="Filtrar por alumno.."
                        />
                    }
                />
                <Card>
                    <LoadMask loading={loader} blur>
                        <div className="col-12 mt-3 mb-2 px-0">
                            <div className='d-flex flex-1 justify-content-end align-items-center mb-2 mr-2'>
                                <div className='text-right'>
                                    <button type='button' onClick={this.props.descargarExcel} className="btn btn-primary">Descargar Excel</button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                    <div className="row col-12 mx-0">
                                        <span className="text-sm azul-claro bold">Ingresos / Gastos</span>
                                        <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px' }} />
                                    </div>
                                    <Chart
                                        width={'100%'}
                                        height={'40vh'}
                                        chartType="PieChart"
                                        loader={<div>Cargando grafico</div>}
                                        data={[
                                            ['', ''],
                                            ['Ingresos', grafica_ingresos_egresos.ingresos],
                                            ['Gastos', grafica_ingresos_egresos.egresos],
                                        ]}
                                        options={{
                                            colors: ['#53A513', '#366AED'],
                                        }}
                                        rootProps={{ 'data-testid': '1' }}
                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                    <div className="row col-12 mx-0">
                                        <span className="text-sm azul-claro bold">Ingresos</span>
                                        <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px' }} />
                                    </div>
                                    <Chart
                                        width={'100%'}
                                        height={'40vh'}
                                        chartType="PieChart"
                                        loader={<div>Cargando grafico</div>}
                                        data={[
                                            ['', ''],
                                            ['Manuales', grafica_ingresos.manuales],
                                            ['Automaticos', grafica_ingresos.automaticos],
                                        ]}
                                        options={{
                                            colors: ['#53A513', '#366AED'],
                                        }}
                                        rootProps={{ 'data-testid': '1' }}
                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                    <div className="row col-12 mx-0">
                                        <span className="text-sm azul-claro bold">Gastos</span>
                                        <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px' }} />
                                    </div>
                                    <Chart
                                        width={'100%'}
                                        height={'40vh'}
                                        chartType="PieChart"
                                        loader={<div>Cargando grafico</div>}
                                        data={[
                                            ['', ''],
                                            ['Nominas', grafica_egresos.nominas],
                                            ['Colaboradores', grafica_egresos.colaboradores],
                                            ['Centro colaborador', grafica_egresos.centro_colaborador],
                                        ]}
                                        options={{
                                            colors: ['#53A513', '#366AED', '#2CB2E5'],
                                        }}
                                        rootProps={{ 'data-testid': '1' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-3 mb-2 px-0">
                            <div className="row col-12 mx-0">
                                <span className="text-sm azul-claro bold">Resumen</span>
                                <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px' }} />
                            </div>
                            <div className="row col-12 mx-0 my-3">
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3 d-flex align-items-end">
                                    <div className="col-12 d-flex flex-column">
                                        <span className="gris-oscuro bold text-sm">Gasto de pago a profesores</span>
                                        <RenderCurrency value={totales.pago_nomina} className="azul-claro bold text-qts"/>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3 d-flex align-items-end">
                                    <div className="col-12 d-flex flex-column">
                                        <span className="gris-oscuro bold text-sm">Gasto de pago de porcentaje a usuarios colaboradores</span>
                                        <RenderCurrency value={totales.cupones_cobrados} className="azul-claro bold text-qts"/>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3 d-flex align-items-end">
                                    <div className="col-12 d-flex flex-column">
                                        <span className="gris-oscuro bold text-sm">Gasto de pago de porcentaje a centros colaboradores</span>
                                        <RenderCurrency value={totales.cursos_vendidos} className="azul-claro bold text-qts"/>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3 d-flex align-items-end">
                                    <div className="col-12 d-flex flex-column">
                                        <span className="gris-oscuro bold text-sm">Ingreso Bruto</span>
                                        <RenderCurrency value={totales.ingreso_bruto} className="azul-claro bold text-qts"/>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-3 d-flex align-items-end">
                                    <div className="col-12 d-flex flex-column">
                                        <span className="gris-oscuro bold text-sm">Ingreso Neto</span>
                                        <RenderCurrency value={totales.ingreso_neto} className="azul-claro bold text-qts"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </LoadMask>
                    <div className="mt-3 px-lg-3">
                        <Tabs
                            defaultActiveKey="Ingresos"
                            tabBarPosition="top"
                            onChange={this.callback}
                            renderTabBar={() => <ScrollableInkTabBar />}
                            renderTabContent={() => <TabContent />}
                        >
                            <TabPane tab="Ingresos" key="Ingresos">
                                <div className="mb-3 col-12 mt-4">
                                    {resumen_ingresos && resumen_ingresos.count > 0 && (
                                        <div className='d-flex flex-1 justify-content-end align-items-center mb-3'>
                                            <div className='text-right'>
                                                <button type='button' onClick={this.props.SolicitarDescargaFacturas} disabled={Object.is(task_id, null) ? false : true} className="btn btn-primary">Descargar Facturas</button>
                                            </div>
                                        </div>
                                    )}
                                    <Grid hover striped data={resumen_ingresos} loading={loader} page={page_ingresos} onPageChange={this.props.listarDataIngresos}>
                                        <TableHeaderColumn
                                            dataField="id"
                                            dataAlign="center"
                                            isKey
                                            width='5%'
                                            dataFormat={cell => ""}
                                        />
                                        <TableHeaderColumn
                                            dataField="correlativo"
                                            dataFormat={(cell, row) => cell && row && row.serie_factura && row.serie_factura.prefijo ? `${row.serie_factura.prefijo}-${cell}` : "" }
                                        >
                                            NO. FACTURA
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="detalle_factura"
                                            dataFormat={(cell, row) => {
                                                if (cell && cell.length > 0) {
                                                    let destinos = '';
                                                    cell.forEach(servicios => {
                                                        destinos.length > 0 && (destinos+= ', ');
                                                        destinos+= servicios.servicio;
                                                    });
                                                    return destinos;
                                                }
                                                return '--';
                                            }}
                                        >
                                            CURSO / SERVICIO
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="nombre"
                                        >
                                            ALUMNO
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="total"
                                            dataFormat={cell => <RenderCurrency value={cell ? cell : 0}/>}
                                        >
                                            TOTAL
                                        </TableHeaderColumn>
                                    </Grid>
                                </div>
                            </TabPane>
                            <TabPane tab="Gastos" key="Gastos">
                                <div className="mb-3 col-12 mt-4">
                                    <span className="text-sm azul-claro bold ml-3">Nominas</span>
                                    <Grid hover striped data={data_egresos_nominas} loading={loader} page={page_egresos_nominas} onPageChange={this.props.listarDataNominas}>
                                        <TableHeaderColumn
                                            dataField="id"
                                            dataAlign="center"
                                            isKey
                                            width='5%'
                                            dataFormat={cell => ""}
                                        />
                                        <TableHeaderColumn
                                            dataField="profesor"
                                            dataFormat={cell => cell && cell.titulo ? `${cell.titulo}` : '----'}
                                        >
                                            PROFESOR
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="salario"
                                            dataFormat={cell => <RenderCurrency value={cell ? cell : 0}/>}
                                        >
                                            TOTAL
                                        </TableHeaderColumn>
                                    </Grid>
                                </div>
                                <div className="mb-3 col-12 mt-4">
                                    <span className="text-sm azul-claro bold ml-3">Colaboradores</span>
                                    <Grid hover striped data={data_egresos_colaboradores} loading={loader} page={page_egresos_colaboradores} onPageChange={this.props.listarDataColaborador}>
                                        <TableHeaderColumn
                                            dataField="id"
                                            dataAlign="center"
                                            isKey
                                            width='5%'
                                            dataFormat={cell => ""}
                                        />
                                        <TableHeaderColumn
                                            dataField="titulo"
                                            dataFormat={(cell, row) => cell ? cell : '----'}
                                        >
                                            COLABORADORES
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="total_a_pagar"
                                            dataFormat={cell => <RenderCurrency value={cell ? cell : 0}/>}
                                        >
                                            TOTAL
                                        </TableHeaderColumn>
                                    </Grid>
                                </div>
                                <div className="mb-3 col-12 mt-4">
                                    <span className="text-sm azul-claro bold ml-3">Centros colaboradores</span>
                                    <Grid hover striped data={data_egresos_centro_colaborar} loading={loader} page={page_egresos_centro_colaborar} onPageChange={this.props.listarDataCentroColab}>
                                        <TableHeaderColumn
                                            dataField="id"
                                            dataAlign="center"
                                            isKey
                                            width='5%'
                                            dataFormat={cell => ""}
                                        />
                                        <TableHeaderColumn
                                            dataField="centro_colaborador"
                                            dataFormat={cell => cell ? cell.nombre : ''}
                                        >
                                            CENTRO COLABORADOR
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="detalle_factura"
                                            dataFormat={cell => cell.length ? cell[0].servicio : ''}
                                        >
                                            CURSO / SERVICIO
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="total_centro"
                                            dataFormat={cell => <RenderCurrency value={cell ? cell : 0}/>}
                                        >
                                            TOTAL
                                        </TableHeaderColumn>
                                    </Grid>
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                </Card>
            </div>
        )
    }


}
