import { createAction, handleActions } from 'redux-actions';
import {NotificationManager} from "react-notifications";
import { api } from "api";
import moment from 'moment';
import _ from "lodash";
import { CELERY_PENDIENTE, CELERY_EXITO, CELERY_ERROR } from '../../../utility/constants';

const LOADER = 'LOADER_INGRESOS_GASTOS';
const DATA = 'DATA_INGRESOS_GASTOS';
const GRAFICA_INGRESOS_EGRESOS = 'GRAFICA_INGRESOS_EGRESOS_INGRESOS_GASTOS';
const GRAFICA_INGRESOS = 'GRAFICA_INGRESOS_INGRESOS_GASTOS';
const GRAFICA_EGRESOS = 'GRAFICA_EGRESOS_INGRESOS_GASTOS';
const RESUMEN_INGRESOS = 'RESUMEN_INGRESOS_INGRESOS_GASTOS';
const PAGE_INGRESOS = 'RESUMEN_PAGE_INGRESOS_GASTOS';
const RESUMEN_EGRESOS_NOMINAS = 'RESUMEN_EGRESOS_INGRESOS_GASTOS_NOMINAS';
const PAGE_EGRESOS_NOMINAS = 'RESUMEN_PAGE_INGRESOS_GASTOS_NOMINAS';
const RESUMEN_EGRESOS_COLAB = 'RESUMEN_EGRESOS_INGRESOS_GASTOS_COLAB';
const PAGE_COLAB = 'PAGE_INGRESOS_GASTOS_COLAB';
const RESUMEN_EGRESOS_CENTRO_COLAB = 'RESUMEN_EGRESOS_INGRESOS_GASTOS_CENTRO_COLAB';
const PAGE_CENTRO_COLAB = 'PAGE_INGRESOS_GASTOS_CENTRO_COLAB';
const TOTALES = 'TOTALES_INGRESOS_GASTOS';
const FECHA = 'FECHA_INGRESOS_GASTOS';
const PROFESOR = 'PROFESOR_INGRESOS_GASTOS';
const ALUMNO = 'ALUMNO_INGRESOS_GASTOS';
const TASK_ID = 'TASK_ID_INGRESOS_GASTOS';
const TASK = 'TASK_INGRESOS_GASTOS';


// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (data) => ({
    type: DATA,
    data,
});

const setPage = (type, page) => ({
    type,
    page,
});

const setGraficaIngresosEgresos = (grafica_ingresos_egresos) => ({
    type: GRAFICA_INGRESOS_EGRESOS,
    grafica_ingresos_egresos,
});

const setGraficaIngresos = (grafica_ingresos) => ({
    type: GRAFICA_INGRESOS,
    grafica_ingresos,
});

const setGraficaEgresos = (grafica_egresos) => ({
    type: GRAFICA_EGRESOS,
    grafica_egresos,
});

const setResumenIngresos = (resumen_ingresos) => ({
    type: RESUMEN_INGRESOS,
    resumen_ingresos,
});

const setResumenEgresosNominas = (data_egresos_nominas) => ({
    type: RESUMEN_EGRESOS_NOMINAS,
    data_egresos_nominas,
});

const setResumenEgresosColaborador = (data_egresos_colaboradores) => ({
    type: RESUMEN_EGRESOS_COLAB,
    data_egresos_colaboradores,
});

const setResumenEgresosCentroColab = (data_egresos_centro_colaborar) => ({
    type: RESUMEN_EGRESOS_CENTRO_COLAB,
    data_egresos_centro_colaborar,
});

const setTotales = (totales) => ({
    type: TOTALES,
    totales,
});

const setTaskId = (task_id) => ({
    type: TASK_ID,
    task_id,
});

const setTask = (task) => ({
    type: TASK,
    task,
});


// ------------------------------------
// Actions
// ------------------------------------

const listarData = () => (dispatch, getStore) => {
    const resource = getStore().ingresosYGastos;
    const params = {};
    if (resource.filtro_profesor)
        params.profesor = resource.filtro_profesor.id;
    if (resource.filtro_alumno)
        params.alumno = resource.filtro_alumno.id;
    if (resource.filtro_mes_anio) {
        params.fecha_inicio = moment(resource.filtro_mes_anio).startOf('month').format("YYYY-MM-DDThh:mm:ssZ");
        params.fecha_fin = moment(resource.filtro_mes_anio).endOf('month').format("YYYY-MM-DDThh:mm:ssZ");
    }
    dispatch(setLoader(true));
    api.get('reportes/data_reporte_ingresos_gastos', params).then((response) => {
        dispatch(setData(response));
        dispatch(setGraficaIngresosEgresos(response.grafica_ingresos_egresos));
        dispatch(setGraficaIngresos(response.grafica_ingresos));
        dispatch(setGraficaEgresos(response.grafica_egresos));
        dispatch(setTotales(response.totales));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarDataIngresos = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().ingresosYGastos;
    const params = { page };
    if (resource.filtro_profesor)
        params.profesor = resource.filtro_profesor.id;
    if (resource.filtro_alumno)
        params.alumno = resource.filtro_alumno.id;
    if (resource.filtro_mes_anio) {
        params.fecha_inicio = moment(resource.filtro_mes_anio).startOf('month').format("YYYY-MM-DDThh:mm:ssZ");
        params.fecha_fin = moment(resource.filtro_mes_anio).endOf('month').format("YYYY-MM-DDThh:mm:ssZ");
    }
    dispatch(setLoader(true));
    api.get('reportes/data_reporte_ingresos_gastos_facturas', params).then((response) => {
        dispatch(setResumenIngresos(response));
        dispatch(setPage(PAGE_INGRESOS, page))
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarDataNominas = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().ingresosYGastos;
    const params = { page };
    if (resource.filtro_profesor)
        params.profesor = resource.filtro_profesor.id;
    if (resource.filtro_alumno)
        params.alumno = resource.filtro_alumno.id;
    if (resource.filtro_mes_anio) {
        params.fecha_inicio = moment(resource.filtro_mes_anio).startOf('month').format("YYYY-MM-DDThh:mm:ssZ");
        params.fecha_fin = moment(resource.filtro_mes_anio).endOf('month').format("YYYY-MM-DDThh:mm:ssZ");
    }
    dispatch(setLoader(true));
    api.get('reportes/data_reporte_ingresos_gastos_nominas', params).then((response) => {
        dispatch(setResumenEgresosNominas(response));
        dispatch(setPage(PAGE_EGRESOS_NOMINAS, page))
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarDataColaborador = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().ingresosYGastos;
    const params = { page };
    if (resource.filtro_profesor)
        params.profesor = resource.filtro_profesor.id;
    if (resource.filtro_alumno)
        params.alumno = resource.filtro_alumno.id;
    if (resource.filtro_mes_anio) {
        params.fecha_inicio = moment(resource.filtro_mes_anio).startOf('month').format("YYYY-MM-DDThh:mm:ssZ");
        params.fecha_fin = moment(resource.filtro_mes_anio).endOf('month').format("YYYY-MM-DDThh:mm:ssZ");
    }
    dispatch(setLoader(true));
    api.get('reportes/data_reporte_ingresos_gastos_colab', params).then((response) => {
        dispatch(setResumenEgresosColaborador(response));
        dispatch(setPage(PAGE_COLAB, page))
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarDataCentroColab = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().ingresosYGastos;
    const params = { page };
    if (resource.filtro_profesor)
        params.profesor = resource.filtro_profesor.id;
    if (resource.filtro_alumno)
        params.alumno = resource.filtro_alumno.id;
    if (resource.filtro_mes_anio) {
        params.fecha_inicio = moment(resource.filtro_mes_anio).startOf('month').format("YYYY-MM-DDThh:mm:ssZ");
        params.fecha_fin = moment(resource.filtro_mes_anio).endOf('month').format("YYYY-MM-DDThh:mm:ssZ");
    }
    dispatch(setLoader(true));
    api.get('reportes/data_reporte_ingresos_gastos_centro_colab', params).then((response) => {
        dispatch(setResumenEgresosCentroColab(response));
        dispatch(setPage(PAGE_CENTRO_COLAB, page))
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const SolicitarDescargaFacturas = () => (dispatch, getStore) => {
    const resource = getStore().ingresosYGastos;
    const params = {};
    if (resource.filtro_profesor)
        params.profesor = resource.filtro_profesor.id;
    if (resource.filtro_alumno)
        params.alumno = resource.filtro_alumno.id;
    if (resource.filtro_mes_anio) {
        params.fecha_inicio = moment(resource.filtro_mes_anio).startOf('month').format("YYYY-MM-DDThh:mm:ssZ");
        params.fecha_fin = moment(resource.filtro_mes_anio).endOf('month').format("YYYY-MM-DDThh:mm:ssZ");
    }
    dispatch(setLoader(true));
    api.get('reportes/solicitar_descargar_facturas', params).then((response) => {
        dispatch(setTaskId(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const descargarFacturas = (task_id) => (dispatch, getStore) => {
    const resource = getStore().ingresosYGastos;
    const params = {};
    if (resource.task_id) {
        params.task_id = resource.task_id
        dispatch(setLoader(true));
        api.get('reportes/descargar_facturas', params).then((response) => {
            if (response.estado === CELERY_PENDIENTE) {
                dispatch(setTask(response));
                NotificationManager.warning(`Faltan ${response.facturas_pendientes} factura(s) por comprimir.`, 'INFORMACIÓN', 5000);
            }
            if (response.estado === CELERY_EXITO) {
                dispatch(setTask(response));
                dispatch(setTaskId(null));
                NotificationManager.success('Se terminaron de comprimir las facturas, la descarga comenzara en breve.', 'ÉXITO', 5000);
                window.location.replace(`/media/archivos_zip/${response.nombre_archivo}`)
            }
            if (response.estado === CELERY_ERROR) {
                dispatch(setTask(response));
                dispatch(setTaskId(null));
                NotificationManager.error('Hubo un error al descarga el archivo', 'ERROR', 5000);
            }
        }).catch(() => {
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
};

const descargarExcel = () => (dispatch, getStore) => {
    const resource = getStore().ingresosYGastos;
    let endpoint = '/api/reportes/excel_ingresos_gastos?';
    if (resource.filtro_profesor)
        endpoint += `profesor=${resource.filtro_profesor.id}&`;
    if (resource.filtro_alumno)
        endpoint += `alumno=${resource.filtro_alumno.id}&`;
    if (resource.filtro_mes_anio) {
        endpoint += `fecha_inicio=${moment(resource.filtro_mes_anio).startOf('month').format("YYYY-MM-DDThh:mm:ssZ")}&`;
        endpoint += `fecha_fin=${moment(resource.filtro_mes_anio).endOf('month').format("YYYY-MM-DDThh:mm:ssZ")}&`;
    }

    endpoint += `auth_token=${sessionStorage.getItem("token")}`
    window.open(endpoint, '_blank');
}

const filtroFechaMesAnio = filtro_mes_anio => (dispatch) => {
    dispatch({type: FECHA, filtro_mes_anio})
    dispatch(listarData());
    dispatch(listarDataIngresos());
    dispatch(listarDataNominas());
    dispatch(listarDataColaborador());
    dispatch(listarDataCentroColab());
};

const filtroProfesor = filtro_profesor => (dispatch) => {
    dispatch({type: PROFESOR, filtro_profesor})
    dispatch(listarData());
    dispatch(listarDataIngresos());
    dispatch(listarDataNominas());
    dispatch(listarDataColaborador());
    dispatch(listarDataCentroColab());
};

const filtroAlumno = filtro_alumno => (dispatch) => {
    dispatch({type: ALUMNO, filtro_alumno})
    dispatch(listarData());
    dispatch(listarDataIngresos());
    dispatch(listarDataNominas());
    dispatch(listarDataColaborador());
    dispatch(listarDataCentroColab());
};

export const actions = {
    listarData,
    listarDataIngresos,
    listarDataNominas,
    listarDataColaborador,
    listarDataCentroColab,
    SolicitarDescargaFacturas,
    descargarFacturas,
    descargarExcel,
    filtroFechaMesAnio,
    filtroProfesor,
    filtroAlumno,
};


// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [GRAFICA_INGRESOS_EGRESOS]: (state, { grafica_ingresos_egresos }) => {
        return {
            ...state,
            grafica_ingresos_egresos,
        };
    },
    [GRAFICA_INGRESOS]: (state, { grafica_ingresos }) => {
        return {
            ...state,
            grafica_ingresos,
        };
    },
    [GRAFICA_EGRESOS]: (state, { grafica_egresos }) => {
        return {
            ...state,
            grafica_egresos,
        };
    },
    [RESUMEN_INGRESOS]: (state, { resumen_ingresos }) => {
        return {
            ...state,
            resumen_ingresos,
        };
    },
    [RESUMEN_EGRESOS_NOMINAS]: (state, { data_egresos_nominas }) => {
        return {
            ...state,
            data_egresos_nominas,
        };
    },
    [RESUMEN_EGRESOS_COLAB]: (state, { data_egresos_colaboradores }) => {
        return {
            ...state,
            data_egresos_colaboradores,
        };
    },
    [RESUMEN_EGRESOS_CENTRO_COLAB]: (state, { data_egresos_centro_colaborar }) => {
        return {
            ...state,
            data_egresos_centro_colaborar,
        };
    },
    [PAGE_INGRESOS]: (state, { page }) => {
        return {
            ...state,
            page_ingresos: page,
        };
    },
    [PAGE_EGRESOS_NOMINAS]: (state, { page }) => {
        return {
            ...state,
            page_egresos_nominas: page,
        };
    },
    [PAGE_COLAB]: (state, { page }) => {
        return {
            ...state,
            page_egresos_colaboradores: page,
        };
    },
    [PAGE_CENTRO_COLAB]: (state, { page }) => {
        return {
            ...state,
            page_egresos_centro_colaborar: page,
        };
    },
    [TOTALES]: (state, { totales }) => {
        return {
            ...state,
            totales,
        };
    },
    [FECHA]: (state, { filtro_mes_anio }) => {
        return {
            ...state,
            filtro_mes_anio,
        };
    },
    [PROFESOR]: (state, { filtro_profesor }) => {
        return {
            ...state,
            filtro_profesor,
        };
    },
    [ALUMNO]: (state, { filtro_alumno }) => {
        return {
            ...state,
            filtro_alumno,
        };
    },
    [TASK_ID]: (state, { task_id }) => {
        return {
            ...state,
            task_id,
        };
    },
    [TASK]: (state, { task }) => {
        return {
            ...state,
            task,
        };
    },
};

export const initialState = {
    loader: false,
    data: [],
    grafica_ingresos_egresos: {},
    grafica_ingresos: {},
    grafica_egresos: {},
    resumen_ingresos: {
        results: [],
        count: 0,
    },
    data_egresos_nominas : {
        results: [],
        count: 0,
    },
    data_egresos_colaboradores : {
        results: [],
        count: 0,
    },
    data_egresos_centro_colaborar : {
        results: [],
        count: 0,
    },
    page_ingresos: 1,
    page_egresos_nominas: 1,
    page_egresos_colaboradores: 1,
    page_egresos_centro_colaborar: 1,
    totales: {},
    filtro_mes_anio: moment(),
    filtro_profesor: null,
    filtro_alumno: null,
    task_id: null,
    task: {}
};

export default handleActions(reducers, initialState);
