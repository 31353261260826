import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderEditorField } from '../../../Utils/renderField/renderField';
import { api } from '../../../../../utility/api';



let CursoGrupalForm = props => {
    const { handleSubmit, cancelar } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="row col-md-12 p-0">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="descripcion" className="m-0">Descripción</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="descripcion" height="10%" height2="70%" component={renderEditorField} />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 p-0">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="temario" className="m-0">Temario del curso</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="temario" height="10%" height2="70%" component={renderEditorField} />
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-3">
                    <div className="col-md-4 col-12">
                        <button className="btn btn-secondary btn-block" onClick={cancelar}>Atras</button>
                    </div>
                    <div className="col-md-4 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Guardar</button>
                    </div>
                </div>
            </div>
        </form>
    )
};
CursoGrupalForm = reduxForm({
    form: 'CursoGrupalForm',
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    asyncBlurFields: [],
    validate: data => {
        return validate(data, {
            'descripcion': validators.exists()('Campo requerido.'),
            'temario': validators.exists()('Campo requerido.'),
        })
    }
})(CursoGrupalForm);

const selector = formValueSelector('CursoGrupalForm');
CursoGrupalForm = connect(state => {
    const a = 'test';
    return {
        a
    };
})(CursoGrupalForm);

export default CursoGrupalForm;
