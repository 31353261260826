import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm } from 'redux-form';
import {
    renderField, renderFieldRadio, renderPasswordField, renderNumber,
    SelectField, AsyncSelectField
} from '../../../Utils/renderField/renderField';
import { generos, USER_COLABORADOR } from "../../../../../utility/constants";

const ColaboradorForm = props => {
    const { handleSubmit, ver_password, change_ver_password } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="col-12">
                    <span className="text-sm azul-claro bold">Para ingresar al sistema</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="email" className="m-0">Correo *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="email" component={renderField} type="email" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="password" className="m-0">Nueva Contraseña *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                name="password"
                                label="Contraseña"
                                component={renderPasswordField}
                                type={`${ver_password ? "text" : "password"}`}
                                className="form-control"
                                ver_password={ver_password}
                                change_ver_password={change_ver_password}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-3">
                    <span className="text-sm azul-claro bold">Datos personales</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombres" className="m-0">Nombres *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombres" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="apellidos" className="m-0">Apellidos *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="apellidos" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nif" className="m-0">DNI *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nif" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="direccion" className="m-0">Dirección *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="direccion" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="genero" className="m-0">Género *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="genero" component={SelectField} options={generos} placeholder="Seleccione un genero..." type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="no_cuenta_banco" className="m-0">No. cuenta de banco *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="no_cuenta_banco" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="col-12 text-right">
                    <span className="azul-marino text-xsm">* Campos requeridos</span>
                </div>
                <div className="buttons-box mt-3">
                    <Link className="btn btn-secondary mr-5" to="/colaboradores">Cancelar</Link>
                    <button type="submit" className="btn btn-primary">Guardar</button>
                </div>
            </div>
        </form>
    )
};


export default reduxForm({
    form: 'ColaboradorForm',
    asyncBlurFields: [],
    validate: data => {
        return validate(data, {
            'email': validators.exists()('Campo requerido.'),
            'nombres': validators.exists()('Campo requerido.'),
            'apellidos': validators.exists()('Campo requerido.'),
            'nif': validators.exists()('Campo requerido.'),
            'direccion': validators.exists()('Campo requerido.'),
            'genero': validators.exists()('Campo requerido.'),
            'no_cuenta_banco': validators.exists()('Campo requerido.'),
            'usuario': validators.exists()('Campo requerido.'),
        })
    }
})(ColaboradorForm);
