import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/config/configComercial';
import Config from './Config';


const ms2p = (state) => {
  return {
    ...state.configComercial,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Config);
