import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/aulas/clases';
import DetalleClaseAlumno from './DetalleClase';


const ms2p = (state) => {
    return {
        me: state.login.me,
        ...state.clases,
    };
};

const md2p = {
    ...actions,
};

export default connect(ms2p, md2p)(DetalleClaseAlumno);
