import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import AsignaturaForm from './AsignaturaForm';

export default class AsignaturaCrear extends Component {

    state = {
        editar: false
    }

    componentWillMount(){
        if(this.props.match.params.id){
            this.props.leerAsignatura(this.props.match.params.id);
            this.setState({editar: true});
        }
    }


    onSubmit = (values) => {
        if (this.state.editar) {
            this.props.editarAsignatura(values, this.props.match.params.id);
        } else {
            this.props.crearAsignatura(values);
        }
    }

    render() {
        const { loader } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm>
                    <LoadMask loading={loader} blur>
                        <AsignaturaForm
                            onSubmit={this.onSubmit}
                        />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
