import React, {Component} from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';

import NivelesGrid from './NivelesGrid';
import AsignaturasGrid from './AsignaturasGrid';

export default class ListarAsigNiveles extends Component{

    render() {
        return(
            <div className="mb-4 col-12 mt-4">
                <Tabs
                    defaultActiveKey="NIVELES"
                    tabBarPosition="top"
                    onChange={this.callback}
                    renderTabBar={() => <ScrollableInkTabBar />}
                    renderTabContent={() => <TabContent />}
                >
                    <TabPane tab="NIVELES" key="NIVELES">
                        <NivelesGrid {...this.props} />
                    </TabPane>
                    <TabPane tab="ASIGNATURAS" key="ASIGNATURAS">
                        <AsignaturasGrid {...this.props} />
                    </TabPane>
                </Tabs>
                {/* <Header to="alumno/crear" textBtn="Nuevo alumno" {...this.props} /> */}

            </div>
        )
    }
}
