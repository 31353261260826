import React, { Component, cloneElement } from 'react';
import Chart from "react-google-charts";
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Select, { Async } from 'react-select';
import classNames from 'classnames';
import Card from "Utils/Cards/Card"
import DatePicker from 'react-datepicker';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import HeaderFiltros from '../../../Utils/Grid/HeaderFiltros';
import { api } from "api";

import CanjeadosGrid from './CanjeadosGrid';
import NoCanjeadosGrid from './NoCanjeadosGrid';

const getColaboradores = (search) => {
    return api
        .get("user", { search, es_colaborador: true })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};

const getCentros = (search) => {
    return api
        .get("centro_colaborador", { search })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};

export default class ReporteCupones extends Component {

    componentWillMount() {
        this.props.listarGrafica();
        // this.props.listarTabla();
    }


    changeFechaInicio = (value) => {
        const { fecha_fin } = this.props;
        this.props.changeFechaInicio(value);
        // if (value <= fecha_fin) {
        // }
    }

    changeFechaFin = (value) => {
        const { fecha_inicio } = this.props;
        this.props.changeFechaFin(value);
        // if (value >= fecha_inicio) {
        // }
    }

    render() {
        const { loader, graficas } = this.props; //Constantes

        return (
            <div className="mb-4 col-12 mt-4">
                <HeaderFiltros
                    filtro1={
                        <DatePicker
                            showPopperArrow={false}
                            isClearable
                            dateFormat="DD/MM/YYYY"
                            placeholderText="Fecha inicial"
                            onChange={(value) => {this.changeFechaInicio(value)}}
                            selected={this.props.fecha_inicio}
                        />
                    }
                    filtro2={
                        <DatePicker
                            showPopperArrow={false}
                            isClearable
                            dateFormat="DD/MM/YYYY"
                            placeholderText="Fecha final"
                            onChange={(value) => {this.changeFechaFin(value)}}
                            selected={this.props.fecha_fin}
                        />
                    }
                    filtro3={
                        <Async
                            isClearable={true}
                            backspaceRemovesValue={false}
                            value={this.props.colaborador}
                            isSearchable={true}
                            loadOptions={getColaboradores}
                            getOptionValue={(option) => option["id"]}
                            getOptionLabel={(option) => option["titulo"]}
                            type="text"
                            onChange={(e) => this.props.changeColaborador(e)}
                            multi={false}
                            autoload={false}
                            cache={false}
                            className={classNames(
                                "react-select-container w-100"
                            )}
                            defaultOptions={true}
                            placeholder="Filtrar por colaborador.."
                        />
                    }
                    filtro4={
                        <Async
                            isClearable={true}
                            backspaceRemovesValue={false}
                            value={this.props.centro}
                            isSearchable={true}
                            loadOptions={getCentros}
                            getOptionValue={(option) => option["id"]}
                            getOptionLabel={(option) => option["nombre"]}
                            type="text"
                            onChange={(e) => this.props.changeCentro(e)}
                            multi={false}
                            autoload={false}
                            cache={false}
                            className={classNames(
                                "react-select-container w-100"
                            )}
                            defaultOptions={true}
                            placeholder="Filtrar por centro.."
                        />
                    }
                    filtro5={
                        <Select
                            isClearable={true}
                            className={classNames('react-select-container')}
                            backspaceRemovesValue={false}
                            isSearchable={true}
                            options={[{ value: 'True', label: 'Recurrente'}, { value: 'False', label: 'No recurrente'}]}
                            placeholder="Filtrar por tipo"
                            value={this.props.recurrente}
                            onChange={this.props.changeRecurrente}
                        />
                    }
                />
                <Card>
                    <LoadMask loading={loader} blur>
                        <div className="d-flex flex-lg-row flex-column flex-1 mt-3 mb-2">
                            <div className="flex-1">
                                <div className="row col-12 mx-0">
                                    <span className="text-sm azul-claro bold">Gráfica de cantidades</span>
                                    <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px' }} />
                                </div>
                                <Chart
                                    width={'100%'}
                                    height={'40vh'}
                                    chartType="ColumnChart"
                                    loader={<div>Loading Chart</div>}
                                    data={[
                                        ['No.', 'Canjeados', 'No canjeados'],
                                        ["", graficas.usados, graficas.cupones],
                                    ]}
                                    options={{
                                        // title: 'Population of Largest U.S. Cities',
                                        chartArea: { width: '50%' },
                                        colors: ['#53A513', '#366AED'],
                                        hAxis: {
                                            title: '',
                                            minValue: 0,

                                        },
                                        vAxis: {
                                            title: 'Cantidad de codigos',
                                            baselineColor: "#1C375E",
                                            titleTextStyle: {
                                                color: '#1C375E',
                                                bold: true,
                                                italic: false
                                            },
                                            textStyle: {
                                                color: "#1C375E",
                                                bold: false,
                                                italic: false
                                            },
                                        },
                                    }}
                                    rootProps={{ 'data-testid': '4' }}
                                />
                            </div>
                            <div className="flex-1">
                                <div className="row col-12 mx-0">
                                    <span className="text-sm azul-claro bold">Gráfica de porcentajes</span>
                                    <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px' }} />
                                </div>
                                <Chart
                                    width={'100%'}
                                    height={'40vh'}
                                    chartType="PieChart"
                                    loader={<div>Loading Chart</div>}
                                    data={[
                                        ['', ''],
                                        ['Canjeados', graficas.usados],
                                        ['No canjeados', graficas.cupones],
                                    ]}
                                    options={{
                                        colors: ['#53A513', '#366AED'],
                                    }}
                                    rootProps={{ 'data-testid': '1' }}
                                />
                            </div>
                        </div>
                    </LoadMask>
                    <div className="mt-3 px-lg-3">
                        <Tabs
                            defaultActiveKey="Canjeados"
                            tabBarPosition="top"
                            onChange={this.callback}
                            renderTabBar={() => <ScrollableInkTabBar />}
                            renderTabContent={() => <TabContent />}
                        >
                            <TabPane tab="Canjeados" key="Canjeados">
                                <CanjeadosGrid  {...this.props} />
                            </TabPane>
                            <TabPane tab="No canjeados" key="No canjeados">
                                <NoCanjeadosGrid {...this.props} />
                            </TabPane>
                        </Tabs>
                    </div>
                </Card>
            </div>
        )
    }


}
