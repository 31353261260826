import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import Card from "Utils/Cards/Card";
import { RenderNumber } from '../../../Utils/renderField/renderReadField'

import moment from 'moment';


export default class DetalleHorasTrabajadas extends Component {

    componentDidMount() {
        const fecha = this.props.match.params.fecha;
        this.props.leer(fecha);
    }


    render() {
        const { loader, item, profesor, descargarExcel } = this.props;
        const fecha = this.props.match.params.fecha;
        return (
            <div className="mb-4 col-12 mt-4">
                <Card>
                    <div className="col-12 mb-4 p-lg-5">
                        <div className="col-12 mb-4 px-0">
                            <span className="text-sm azul-claro bold">{`Resumen ${moment(fecha ? fecha : '').format('LL')}`}</span>
                            <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                        </div>
                        <div className="d-flex flex-lg-row flex-column mb-3">
                            <div className='d-flex flex-1 flex-column'>
                                <div>
                                    <span className='text-sm azul-claro mr-2'>Profesor:</span>
                                    <span className='text-sm azul-claro bold'>{profesor ? `${profesor.id} - ${profesor.nombres}` : ''}</span>
                                </div>
                                <div>
                                    <span className='text-sm azul-claro mr-2'>Horas totales del día:</span>
                                    <RenderNumber value={item ? item.total : 0} decimalScale={2} className='text-sm azul-claro bold' suffix=' horas' />
                                </div>
                            </div>
                        </div>
                        <div className='mb-2'>
                            <Grid
                                hover
                                striped
                                data={item}
                                loading={loader}
                                pagination={false}
                            >
                                <TableHeaderColumn
                                    dataField="id"
                                    dataAlign="center"
                                    isKey
                                    width='10%'
                                    dataFormat={cell => ''}
                                />
                                <TableHeaderColumn
                                    dataField="aula"
                                    dataFormat={(cell, row) => cell ? cell.nombre : row.nombre_aula}
                                >
                                    CURSO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="clase"
                                    dataFormat={cell => cell ? moment(cell.fecha_hora_inicio).format('HH:mm') : ''}
                                >
                                    HORA INICIO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="clase"
                                    dataFormat={cell => cell ? moment(cell.fecha_hora_fin).format('HH:mm') : ''}
                                >
                                    HORA FIN
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="hrs_trabajadas"
                                >
                                    HORAS TOTALES
                                </TableHeaderColumn>
                            </Grid>
                        </div>
                        <div className="d-flex flex-wrap justify-content-center align-items-center mt-4">
                            <div className="col-md-4 col-12">
                                <Link className="btn btn-secondary btn-block" to="/reporte/horas_trabajadas">Regresar</Link>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        )
    }
}

