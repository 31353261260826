import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './AlumnoForm';
import { AlumnoUpdateForm } from './AlumnoForm';

export default class AlumnoCrear extends Component {
    state = {
        editar: false,
        password: false,
    };

    componentWillMount(){
        if(this.props.match.params.id){
            this.props.leer(this.props.match.params.id);
            this.setState({
                editar: true,
            });
        }
    }

    actualizar = (data) => {
        if (data.password)
            data.new_password = data.password
        this.props.editar(data, this.props.match.params.id)
    };

    changeVerPassword = () => {
        this.setState({password: !this.state.password});
    };

    render() {
        const { crear, update } = this.props;
        const { updateData, loader } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm>
                    <LoadMask loading={loader} blur>
                        {
                        this.state.editar ?
                            <AlumnoUpdateForm
                                mostrar_pass={this.state.password}
                                changeVerPassword={this.changeVerPassword}
                                onSubmit={this.actualizar}
                                updateData={updateData}
                                editando={true}
                            />
                            :
                            <Form
                                mostrar_pass={this.state.password}
                                changeVerPassword={this.changeVerPassword}
                                onSubmit={crear}
                            />
                        }
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
