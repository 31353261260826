import React, {Component, Fragment} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";
import {formatActivo} from "Utils/renderField/renderReadField"
import DatePicker from 'react-datepicker';
import moment from 'moment';

export default class Pendientes extends Component{
    componentWillMount() {
        const { listarPendientes, page_pendientes } = this.props;
        listarPendientes(page_pendientes);
    }

    onPageChangePendientes = (page_pendientes) => {
        this.props.listarPendientes(page_pendientes);
    };

    render() {
        //Funciones
        const { listarPendientes:
            onPageChangePendientes,
            onSortChangePendientes,
            eliminar,
            searchChangePendientes,
            search_pendientes,
            filtroMesInicioPendientes,
            filtroMesFinPendientes,
        } = this.props;

        //Constantes
        const {
            data_pendientes,
            loader_pendientes,
            page_pendientes,
            mes_inicio_filtro_pendientes,
            mes_fin_filtro_pendientes,
        } = this.props;
        return(
            <div className="mb-4 col-12 mt-4">
                <Header
                    to="comunicado/crear"
                    textBtn="Nuevo"
                    {...this.props}
                    searchChange={searchChangePendientes}
                    search={search_pendientes}
                    filtro2={
                        <DatePicker
                            showPopperArrow={false}
                            isClearable
                            dateFormat="DD/MM/YYYY"
                            placeholderText="Fecha inicio"
                            onChange={filtroMesInicioPendientes}
                            selected={mes_inicio_filtro_pendientes}
                        />
                    }
                    filtro1={
                        <DatePicker
                            showPopperArrow={false}
                            isClearable
                            dateFormat="DD/MM/YYYY"
                            placeholderText="Fecha fin"
                            onChange={filtroMesFinPendientes}
                            selected={mes_fin_filtro_pendientes}
                        />
                    }
                />
                <Card>
                    <Grid hover striped
                        data={data_pendientes}
                        loading={loader_pendientes}
                        onPageChange={this.onPageChangePendientes}
                        onSortChange={onSortChangePendientes}
                        page={page_pendientes}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='20%'
                            dataFormat={standardActions({ ver: "comunicado"})}
                        >
                            ACCIONES
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="creado"
                            dataSort
                            dataFormat={(cell) => cell ? moment(cell).format('DD/MM/YYYY') : ""}
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="titulo"
                            dataSort
                        >
                            TITULO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="creador"
                            dataFormat={ (cell, row) => {
                                return (
                                    <div>
                                        {
                                            cell && (
                                                <Fragment>
                                                    <span>{cell.titulo ? `${cell.titulo}` : ""}</span>
                                                    <br />
                                                </Fragment>
                                            )
                                        }
                                        {
                                            cell && (
                                                <Fragment>
                                                    <span>{cell.tipo_usuario_text ? cell.tipo_usuario_text : ""}</span>
                                                    <br />
                                                </Fragment>
                                            )
                                        }
                                    </div>
                                )
                            }}
                        >
                            USUARIO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipo_destinatario_text"
                            dataSort
                            dataFormat={ (cell, row) => {
                                return (
                                    <div>
                                        {
                                            cell && (
                                                <Fragment>
                                                    <span>{cell ? cell : ""}</span>
                                                    <br />
                                                </Fragment>
                                            )
                                        }
                                        {
                                            cell && (
                                                <Fragment>
                                                    <span>{row.tipo_mensaje ? row.tipo_mensaje : ""}</span>
                                                    <br />
                                                </Fragment>
                                            )
                                        }
                                    </div>
                                )
                            }}
                        >
                            DESTINO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="mensaje"
                            dataSort
                            dataFormat={(cell) => cell ? <span className="parrafo-corto">{cell}</span> : ""}
                        >
                            MENSAJE
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
