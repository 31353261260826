import React, { Component } from "react";
import Chart from "react-google-charts";
import { Async } from "react-select";
import classNames from "classnames";
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import Card from "Utils/Cards/Card";
import MonthPicker from "../../../Utils/MonthPicker";
import HeaderFiltros from "../../../Utils/Grid/HeaderFiltros";
import { USER_ALUMNO } from "../../../../../utility/constants";
import moment from "moment";

import { api } from "api";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import { RenderNumber } from "Utils/renderField/renderReadField";
import { Link } from "react-router-dom";

const getAlumnos = (search) => {
    return api
        .get("user", { search, tipo_usuario: USER_ALUMNO })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};

export default class ReporteCreditosAlumno extends Component {
    state = {
        cursos: [],
    };

    componentDidUpdate(prevProps) {
        if (this.props.alumno !== prevProps.alumno) {
            this.getCursos("");
        }
    }

    getCursos = (search) => {
        const { alumno } = this.props;
        return api
            .get("aula/cursos_alumnos", {
                search,
                alumno: alumno ? alumno.id : null,
            })
            .then((data) => {
                if (data) {
                    this.setState({ cursos: data.results });
                    return data.results;
                }
                this.setState({ cursos: [] });
                return [];
            })
            .catch(() => {
                this.setState({ cursos: [] });
                return [];
            });
    };

    changeFechaInicio = (value) => {
        const { fecha_fin } = this.props;
        if (value <= fecha_fin) {
            const comprobacion = moment(fecha_fin).subtract(2, "y");
            if (value >= comprobacion) {
                this.props.changeFechaInicio(value);
            }
        }
    };

    changeFechaFin = (value) => {
        const { fecha_inicio } = this.props;
        if (value >= fecha_inicio) {
            const comprobacion = moment(fecha_inicio).add(2, "y");
            if (value <= comprobacion) {
                this.props.changeFechaFin(value);
            }
        }
    };

    render() {
        const { listarTabla, listarGrafica, listarForms } = this.props; //Funciones
        const { loader, data, data2, alumno, data3, page3 } = this.props; //Constantes

        return (
            <div className="mb-4 col-12 mt-4">
                <HeaderFiltros
                    filtro1={
                        <Async
                            isClearable={false}
                            backspaceRemovesValue={false}
                            value={alumno}
                            isSearchable={true}
                            loadOptions={getAlumnos}
                            getOptionValue={(option) => option["id"]}
                            getOptionLabel={(option) => option["titulo"]}
                            type="text"
                            onChange={(e) => this.props.changeAlumno(e)}
                            multi={false}
                            autoload={false}
                            cache={false}
                            className={classNames(
                                "react-select-container w-100"
                            )}
                            defaultOptions={true}
                            placeholder="Filtrar por alumno.."
                        />
                    }
                    filtro2={
                        <Async
                            isClearable={true}
                            backspaceRemovesValue={false}
                            value={this.props.aula}
                            isSearchable={true}
                            loadOptions={this.getCursos}
                            getOptionValue={(option) => option["id"]}
                            getOptionLabel={(option) => option["nombre"]}
                            type="text"
                            onChange={(e) => this.props.changeAula(e)}
                            multi={false}
                            autoload={false}
                            cache={false}
                            className={classNames(
                                "react-select-container w-100"
                            )}
                            defaultOptions={this.state.cursos}
                            placeholder="Filtrar por curso.."
                        />
                    }
                    filtro3={
                        <MonthPicker
                            value={this.props.fecha_inicio}
                            onChange={this.changeFechaInicio}
                            placeholder="Fecha inicio"
                        />
                    }
                    filtro4={
                        <MonthPicker
                            value={this.props.fecha_fin}
                            onChange={this.changeFechaFin}
                            placeholder="Fecha fin"
                        />
                    }
                />
                <Card>
                    {alumno && (
                        <div className="d-flex flex-column flex-1 my-3 px-lg-5">
                            <h2 className="bold azul-claro">
                                Objetivo del alumno:
                            </h2>
                            <h3 className="">{alumno.objetivos}</h3>
                        </div>
                    )}
                    <div className="d-flex flex-row flex-1 mt-3 mb-2">
                        <Chart
                            width="100%"
                            height={"50vh"}
                            chartType="LineChart"
                            loader={<LoadMask loading={loader} blur />}
                            data={data}
                            options={{
                                hAxis: {
                                    title: "Meses",
                                    baselineColor: "#1C375E",
                                    titleTextStyle: {
                                        color: "#1C375E",
                                        bold: true,
                                        italic: false,
                                    },
                                    textStyle: {
                                        color: "#1C375E",
                                        bold: false,
                                        italic: false,
                                    },
                                },
                                vAxis: {
                                    title: "Créditos",
                                    position: "top",
                                    baselineColor: "#1C375E",
                                    titleTextStyle: {
                                        color: "#1C375E",
                                        bold: true,
                                        italic: false,
                                    },
                                    textStyle: {
                                        color: "#1C375E",
                                        bold: false,
                                        italic: false,
                                    },
                                    minValue: 0,
                                },
                                pointSize: 7,
                                curveType: "function",
                                chartArea: { width: "90%", height: "80%" },
                                legend: { position: "none" },
                            }}
                            rootProps={{ "data-testid": "2" }}
                        />
                    </div>
                    <div className="my-5 px-lg-3">
                        <div className="mt-1">
                            <Grid
                                hover
                                striped
                                data={data3}
                                loading={loader}
                                onPageChange={listarForms}
                                page={page3}
                            >
                                <TableHeaderColumn
                                    dataField="id"
                                    dataAlign="center"
                                    isKey
                                    width="5%"
                                    dataFormat={(cell, row) => {
                                        return (
                                            <div className="d-flex justify-content-center">
                                                <Link to={`/cursos/formulario/${cell}`} className="" >
                                                    <i className="material-icons ver-action">remove_red_eye</i>
                                                </Link>
                                            </div>
                                        )
                                    }}
                                />
                                <TableHeaderColumn dataField="nombre_form">
                                    Ejercicio/Exámen
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="tipo_form"
                                    width="10%"
                                >
                                    TIPO
                                </TableHeaderColumn>
                                <TableHeaderColumn dataField="aula">
                                    Curso
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="fecha_entrega"
                                    dataFormat={ cell => moment(cell).format("DD/MM/YYYY H:mm") }
                                >
                                    Fecha
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="punteo"
                                    dataFormat={(cell, row) => (
                                        <div className="d-flex flex-row align-items-center">
                                            <span className="azul-claro">
                                                <RenderNumber
                                                    value={cell ? cell : 0}
                                                    decimalScale={2}
                                                />
                                            </span>
                                            &nbsp;/&nbsp;
                                            <span className="azul-claro">
                                                <RenderNumber
                                                    value={row.puntaje_form}
                                                    decimalScale={2}
                                                />
                                            </span>
                                        </div>
                                    )}
                                >
                                    PUNTEO
                                </TableHeaderColumn>
                            </Grid>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
}
