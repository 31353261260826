import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card"
import { RenderNumber } from '../../../Utils/renderField/renderReadField';
import HeaderFiltros from "Utils/Grid/HeaderFiltros";
import { api } from 'api';
import Select, { Async } from 'react-select';
import classNames from 'classnames';
import { USER_ALUMNO, USER_PROFESOR } from '../../../../../utility/constants';

const getProfesores = (search) => {
    return api.get("user", { search, tipo_usuario: USER_PROFESOR }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const getAlumnos = (search) => {
    return api.get("user", { search, tipo_usuario: USER_ALUMNO }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const getCursos = (search) => {
    return api.get("aula", { search }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

export default class RecuperacionesAdmin extends Component{

    componentWillMount() {
        const { listarRecuperaciones } = this.props;
        listarRecuperaciones();
    }

    render() {
        const { listarRecuperaciones: onPageChange, onSortChange,
            filtroCursoRecuperaciones,
            filtroAlumnoRecuperaciones,
            filtroProfesorRecuperaciones,
        } = this.props; //Funciones
        const { data, loader, page, alumno, profesor, curso } = this.props; //Constantes
        return(
            <div className="mb-4 col-12 mt-4">
                <HeaderFiltros
                    filtro1={
                        <Async
                            isClearable={true}
                            backspaceRemovesValue={false}
                            value={alumno}
                            isSearchable={true}
                            loadOptions={getAlumnos}
                            getOptionValue={(option) => (option["id"])}
                            getOptionLabel={(option) => (option["titulo"])}
                            type="text"
                            onChange={(e) => filtroAlumnoRecuperaciones(e)}
                            multi={true}
                            autoload={false}
                            cache={false}
                            className={classNames('react-select-container ')}
                            defaultOptions={true}
                            placeholder="Filtrar por alumno ..."
                        />
                    }
                    filtro2={
                        <Async
                            isClearable={true}
                            backspaceRemovesValue={false}
                            value={profesor}
                            isSearchable={true}
                            loadOptions={getProfesores}
                            getOptionValue={(option) => (option["id"])}
                            getOptionLabel={(option) => (option["titulo"])}
                            type="text"
                            onChange={(e) => filtroProfesorRecuperaciones(e)}
                            multi={true}
                            autoload={false}
                            cache={false}
                            className={classNames('react-select-container ')}
                            defaultOptions={true}
                            placeholder="Filtrar por profesor ..."
                        />
                    }
                    filtro3={
                        <Async
                            isClearable={true}
                            backspaceRemovesValue={false}
                            value={curso}
                            isSearchable={true}
                            loadOptions={getCursos}
                            getOptionValue={(option) => (option["id"])}
                            getOptionLabel={(option) => (option["nombre"])}
                            type="text"
                            onChange={(e) => filtroCursoRecuperaciones(e)}
                            multi={true}
                            autoload={false}
                            cache={false}
                            className={classNames('react-select-container ')}
                            defaultOptions={true}
                            placeholder="Filtrar por curso ..."
                        />
                    }
                />
                <Card>
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                    page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            isKey
                            width='10%'
                            dataFormat={standardActions({})}
                        />
                        <TableHeaderColumn
                            dataField="alumno"
                            dataFormat={ cell => cell.titulo}
                        >
                            Alumno
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="aula"
                        >
                            Curso
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="profesor"
                            dataFormat={ cell => cell.titulo}
                        >
                            Profesor
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="horas_pendientes"
                            dataFormat={ cell => <RenderNumber value={cell} suffix=" horas" decimalScale={2} /> }
                        >
                            Tiempo
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
