import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";
import { RenderCurrency, RenderNumber } from '../../../Utils/renderField/renderReadField'
import { DINERO } from '../../../../../utility/constants';
import moment from 'moment';

export default class ListarDescuentos extends Component{

    componentWillMount() {
        const { listar } = this.props;
        listar();
    }

    render() {
        const { listar: onPageChange, onSortChange, eliminar } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return(
            <div className="mb-4 col-12 mt-4">
                <Header to="/comercial/descuento/crear" textBtn="Agregar descuento" {...this.props} />
                <Card>
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                    page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='15%'
                            dataFormat={standardActions({ editar: "/comercial/descuento", eliminar})}
                        />
                        <TableHeaderColumn
                            dataField="creado"
                            dataFormat={cell => moment(cell).format("DD/MM/YYYY")}
                            width='18%'
                            dataSort
                        >
                            FECHA DE CREACIÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="alumno"
                            dataFormat={cell => cell.titulo}
                        >
                            ALUMNO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="aula"
                            dataFormat={cell => cell.nombre}
                        >
                            CURSO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="descuento"
                            dataAlign="right"
                            thStyle={{paddingRight: "2.5rem"}}
                            tdStyle={{paddingRight: "2.5rem"}}
                            dataFormat={(cell, row) => (
                                row.tipo_descuento == DINERO ? (
                                    <RenderCurrency value={cell} />
                                ) : (
                                    <RenderNumber value={cell} decimalScale={2} suffix=" %" />
                                )
                            )}
                        >
                            DESCUENTO
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
