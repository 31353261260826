import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './NoticiasCampañasForm';
import EditarNoticiasCampañasForm from './NoticiasCampañasForm';
import { URL_FRONTPAGE } from '../../../../../utility/constants'

export default class NoticiasCampañasCrear extends Component {
    state = {
        editar: false,
        imagen: null,
        video: null,
    };

    componentWillMount(){
        if(this.props.match.params.id){
            this.props.leer(this.props.match.params.id);
            this.setState({ editar: true });
        }
    }

    componentWillUnmount(){
        this.props.cleanForm();
    }

    publicar = () => {
        this.props.publicar(this.props.match.params.id)
    };

    setImagen = (imagen) => {
        this.setState({imagen});
    };

    setVideo = (video) => {
        this.setState({video});
    };

    viewPreview = () => {
        var hrefs = [
            {
                // Mostrar la preview de las noticias en el home
                url: `${URL_FRONTPAGE}/?preview=true`
            },
            {
                // Mostrar la preview de las noticias en el home
                url: `${URL_FRONTPAGE}/noticias?preview=true`
            },
            {
                // Mostrar la preview del detalle de la noticia
                url: `${URL_FRONTPAGE}/noticias/${this.props.match.params.id}/detalle?preview=true`
            }
        ]
        for (var i in hrefs){
            window.open(hrefs[i].url, "_blank");
        }
    }

    actualizar = (data) =>{
        const body = {...data}
        if (data.cupon) {
            body.cupon = data.cupon.id
        }
        this.props.editar(
            body,
            this.props.match.params.id,
            [{"file": this.state.imagen, "name": "imagen"}, {"file": this.state.video, "name": "video"}]
        )
    };

    crear = (data) => {
        const body = {...data}
        if (data.cupon) {
            body.cupon = data.cupon.id
        }
        this.props.crear(body, [{"file": this.state.imagen, "name": "imagen"}, {"file": this.state.video, "name": "video"}])
    }

    render() {
        const { crear, update } = this.props;
        const { updateData, loader, item } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm>
                    <LoadMask loading={loader} blur>
                        {
                        this.state.editar ?
                            <EditarNoticiasCampañasForm
                                onSubmit={this.actualizar}
                                updateData={updateData}
                                editando={true}
                                publicar={this.publicar}
                                setImagen={this.setImagen}
                                setVideo={this.setVideo}
                                item={item}
                                viewPreview={this.viewPreview}
                            />
                            :
                            <Form
                                onSubmit={this.crear}
                                setImagen={this.setImagen}
                                setVideo={this.setVideo}
                            />
                        }
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
