import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import LoginForm from './LoginForm';
import './login.css';
import LoadMask from "Utils/LoadMask/LoadMask";

const logo = require("../../../../../assets/img/logo.png");
const logoLogin = require("../../../../../assets/img/logo_login.png");
const logoResponsivo = require("../../../../../assets/img/logo_login_movil.png");

class Login extends Component {
    state = {
        ver_password: false,
        show_animacion: false,
        show_animacion2: false,
    }

    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    componentDidMount(props) {
        this.state = { prueba: true };
        setTimeout(() => {this.setState({show_animacion: true})}, 500);
        setTimeout(() => {this.setState({show_animacion2: true})}, 1000);
    }

    change_ver_password = () => {
        this.setState({ver_password: !this.state.ver_password});
    }

    getAnimacion = () => {
        const { show_animacion } = this.state;
        if (show_animacion) {
            return "animado";
        }
        return "";
    };

    getAnimacion2 = () => {
        const { show_animacion2 } = this.state;
        if (show_animacion2) {
            return "animado";
        }
        return "";
    };

    render() {
        const { onSubmit, loader } = this.props;
        if (sessionStorage.getItem('token')) {
            return (<Redirect to="/" />);
        }
        return (
            <div className="d-flex flex-row bg-celeste flex-1 login-container">
                <div className={`flex-1 align-items-center align-self-center card-conteiner`}>
                    <div className={`d-lg-flex d-none card card-login ${this.getAnimacion2()}`} style={{boxShadow: "0px 10px 30px #0000003B"}}>
                        <div className={`logo-card-login mb-3`}>
                            <img src={logo} alt="Sidioms" style={{width: "30%"}} />
                        </div>
                        <LoadMask loading={loader} light>
                            <LoginForm
                                onSubmit={onSubmit}
                                change_ver_password={this.change_ver_password}
                                ver_password={this.state.ver_password}
                            />
                        </LoadMask>
                    </div>
                    <div className={`d-flex d-lg-none card card-login-responsive ${this.getAnimacion()}`} style={{boxShadow: "0px 10px 30px #0000003B"}}>
                        <div className={`logo-card-login mb-3 flex-1`}>
                            <img src={logo} alt="Sidioms" style={{width: "80%"}} />
                        </div>
                        <div className={`d-flex flex-4 align-items-center`}>
                            <LoadMask loading={loader} light>
                                <LoginForm
                                    onSubmit={onSubmit}
                                    change_ver_password={this.change_ver_password}
                                    ver_password={this.state.ver_password}
                                />
                            </LoadMask>

                        </div>
                    </div>
                </div>
                <div className={`d-lg-flex d-none logo-login ${this.getAnimacion()} justify-content-center align-items-center`}>
                    <img className="img-logo-login" src={logoLogin}/>
                </div>
                <div className={`d-lg-none d-block logo-login-responsive`}>
                    <img className="img-logo-login-responsive" src={logoResponsivo}/>
                </div>
            </div>
        );
    }
}

export default Login;
