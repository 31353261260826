import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import DetalleArchivos from './DetalleArchivos';
import { ENLACE, ARCHIVO } from '../../../../../utility/constants';
import {NotificationManager} from "react-notifications";
import { api } from '../../../../../utility/api';
import Swal from 'sweetalert2';
import _ from 'lodash';

export default class BibliotecaArchivos extends Component {

    state = {
        nivel: {
            nombre: '',
            asignatura: ''
        },
        seleccionado: null,
    }

    componentWillMount() {
        const id = this.props.match.params.id;
        api.get(`asignatura_niveles/${id}`).then( response => {
            this.setState({nivel: {nombre: response.nombre, asignatura: response.asignatura.nombre}});
            this.props.listar(response.id);
        }).catch();
    };

    seleccionar = (index, item) => {
        this.setState({seleccionado: item});
        this.props.setearFormDetalle(item.nombre);
    };

    eliminar = () => {
        const { seleccionado } = this.state;
        const id = this.props.match.params.id;
        Swal.fire({
            title: '¿Eliminar?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, eliminar!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.eliminar(seleccionado.id, id);
                this.setState({seleccionado: null});
            }
        });
    }

    editar = (values) => {
        const { seleccionado } = this.state;
        const id = this.props.match.params.id;
        this.props.editar(seleccionado.id, values, id);
    }


    render() {
        const { loader } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm col={12} noPaddingXContent>
                    <LoadMask loading={loader} blur>
                        <DetalleArchivos
                            nivel={this.state.nivel}
                            archivos={this.props.archivos}
                            seleccionar={this.seleccionar}
                            seleccionado={this.state.seleccionado}
                            idNivel={this.props.match.params.id}
                            eliminar={this.eliminar}
                            onSubmit={this.editar}
                        />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
