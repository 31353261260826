import React, {Fragment} from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { renderField, renderMonthPicker, renderCurrency, renderNumber, renderImagePicker, renderFieldCheck, SelectField } from '../../../Utils/renderField/renderField';
import { RenderCurrency } from '../../../Utils/renderField/renderReadField';
import { tipo_documento_identificacion, CIF, NIF } from '../../../../../utility/constants';
import moment from 'moment';

const renderDetalles = ({fields, updateData, editando}) => (
    <div className=" col-12">
        <div className="react-bs-table-container">
            <div className="react-bs-table react-bs-table-bordered" style={{height: "100%"}}>
                <div className="react-bs-container-header table-header-wrapper">
                    <table className="table table-hover table-bordered">
                        <colgroup>
                            <col style={{width: "10%", minWidth: "10%"}}/>
                            <col style={{width: "10%", minWidth: "10%"}}/>
                            <col style={{width: "30%", minWidth: "30%"}}/>
                            <col style={{width: "30%", minWidth: "30%"}}/>
                            <col style={{width: "10%", minWidth: "10%"}}/>
                        </colgroup>
                        <thead>
                            <tr>
                                <th className="sort-column" style={{textAlign: "center"}}>
                                    <span className="order">
                                        <span className="dropdown">
                                            <span className="caret" style={{margin: "10px 0px 10px 5px", color: "rgb(204, 204, 204)"}}></span>
                                        </span>
                                        <span className="dropup">
                                            <span className="caret" style={{margin: "10px 0px", color: "rgb(204, 204, 204)"}}></span>
                                        </span>
                                    </span>
                                    <div></div>
                                </th>
                                <th className="sort-column" style={{textAlign: "center"}}>
                                    <span className="order">
                                        <span className="dropdown">
                                            <span className="caret" style={{margin: "10px 0px 10px 5px", color: "rgb(204, 204, 204)"}}></span>
                                        </span>
                                        <span className="dropup">
                                            <span className="caret" style={{margin: "10px 0px", color: "rgb(204, 204, 204)"}}></span>
                                        </span>
                                    </span>
                                    <div></div>
                                </th>
                                <th className="sort-column" title="Cantidad estudiantes" style={{textAlign: "left"}}>PROFESOR
                                    <span className="order">
                                        <span className="dropdown">
                                            <span className="caret" style={{margin: "10px 0px 10px 5px", color: "rgb(204, 204, 204)"}}></span>
                                        </span>
                                        <span className="dropup">
                                            <span className="caret" style={{margin: "10px 0px", color: "rgb(204, 204, 204)"}}></span>
                                        </span>
                                    </span>
                                    <div></div>
                                </th>
                                <th className="sort-column" title="Euro/Alumno" style={{textAlign: "center"}}>SALARIO
                                    <span className="order">
                                        <span className="dropdown">
                                            <span className="caret" style={{margin: "10px 0px 10px 5px", color: "rgb(204, 204, 204)"}}></span>
                                        </span>
                                        <span className="dropup">
                                            <span className="caret" style={{margin: "10px 0px", color: "rgb(204, 204, 204)"}}></span>
                                        </span>
                                    </span>
                                    <div></div>
                                </th>
                                <th className="sort-column" style={{textAlign: "center"}}>
                                    <span className="order">
                                        <span className="dropdown">
                                            <span className="caret" style={{margin: "10px 0px 10px 5px", color: "rgb(204, 204, 204)"}}></span>
                                        </span>
                                        <span className="dropup">
                                            <span className="caret" style={{margin: "10px 0px", color: "rgb(204, 204, 204)"}}></span>
                                        </span>
                                    </span>
                                    <div></div>
                                </th>
                            </tr>
                        </thead>
                    </table>
                </div>
                {/*Contenido de la tabla*/}
                <div className="react-bs-container-body">
                    <table className="table table-striped table-bordered table-hover">
                        <colgroup>
                            <col style={{width: "10%", minWidth: "10%"}}/>
                            <col style={{width: "10%", minWidth: "10%"}}/>
                            <col style={{width: "30%", minWidth: "30%"}}/>
                            <col style={{width: "30%", minWidth: "30%"}}/>
                            <col style={{width: "10%", minWidth: "10%"}}/>
                        </colgroup>
                        <tbody>
                        {fields.map((ganancia, index) => {
                            let todos = fields.getAll();
                            const dato_actual = fields.get(index); // Pago actual
                            todos[index]['index'] = index; //Mutar cada fila par agregarle el index
                            return(
                            <tr key={index}>
                                <td></td>
                                {/* <td className="text-center sin-borde-top">
                                    <button className ="btn-delete" onClick={(e) =>{e.preventDefault(); fields.remove(index)}}><span className="material-icons rosa">close</span></button>
                                </td> */}
                                <td></td>
                                <td className="sin-borde-top">
                                    {editando ?
                                        <span>{`${dato_actual.profesor.nombres ? dato_actual.profesor.nombres : ""} ${dato_actual.profesor.apellidos ? dato_actual.profesor.apellidos : ""}`}</span>
                                        :
                                        <span>{`${dato_actual.nombres ? dato_actual.nombres : ""} ${dato_actual.apellidos ? dato_actual.apellidos : ""}`}</span>
                                    }
                                </td>
                                <td>
                                    <Field name={`${ganancia}.salario`} component={renderNumber} decimalScale={2} suffix=" €" />
                                </td>
                                <td></td>
                            </tr>
                        )})}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        {/* <div className="d-flex justify-content-center mt-2">
            <button type="button" className="btn btn-dark py-0" onClick={() => fields.push({})}>
                Agregar ganancia colaborador
            </button>
        </div> */}
    </div>
);

let NominaForm = props => {
    const { handleSubmit, editando, total_a_pagar} = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="col-12 mt-4">
                    <span className="text-sm azul-claro bold">Datos nomina</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{height: '3px'}}/>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="mes_anio" className="m-0">Mes / Año de la nomina*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="mes_anio" component={renderMonthPicker} type="text" disabled={editando ? true : false} />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 form-group d-flex justify-content-end align-items-center">
                            <span className="text-qts azul bold mr-2">Total a pagar:</span>
                            <RenderCurrency value={total_a_pagar} className="text-qts verde-claro bold"/>
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-12 px-0">
                        <FieldArray name="detalles" component={renderDetalles} {...props}/>
                    </div>
                </div>
                <div className="col-12 text-right mt-3">
                    <span className="azul-marino text-xsm">* Campos requeridos</span>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-3">
                    <div className="col-md-4 col-12">
                        <Link className="btn btn-secondary btn-block" to="/nominas">Cancelar</Link>
                    </div>
                    <div className="col-md-4 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Guardar</button>
                    </div>
                </div>
            </div>
        </form>
    )
};
NominaForm = reduxForm({
    form: 'NominaForm',
    asyncBlurFields: [],
})(NominaForm);

const selector = formValueSelector('NominaForm');
NominaForm = connect(state => {
    const form = state.form.NominaForm;
    let total_a_pagar = 0;

    if (form && form.values) {
        const values = form.values;

        if (values.detalles) {
            for (let i = 0; i < values.detalles.length; i++) {
                total_a_pagar += parseFloat(values.detalles[i].salario);
            }
            values.total_a_pagar = total_a_pagar;
        }
    }
    return {
        total_a_pagar
    }
})(NominaForm);

export default NominaForm;
