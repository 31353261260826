import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField, renderSwitch } from '../../../Utils/renderField/renderField';

let AdminForm = props => {
    const { handleSubmit, editando } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombres" className="m-0">Nombres</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombres" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="apellidos" className="m-0">Apellidos</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="apellidos" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="email" className="m-0">Email</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="email" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0 ">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="password" className="m-0">{editando ? 'Nueva Contraseña' : 'Contraseña'}</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="password" component={renderField} type="password" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    {(editando) && (
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="metodo_pago" className="m-0">Usuario activo</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name="is_active"
                                    label=""
                                    component={renderSwitch}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-5">
                    <div className="col-md-4 col-12">
                        <Link className="btn btn-secondary btn-block" to="/administradores">Cancelar</Link>
                    </div>
                    <div className="col-md-4 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Guardar</button>
                    </div>
                </div>
            </div>
        </form>
    )
};
AdminForm = reduxForm({
    form: 'EditarAdminForm',
    validate: data => {
        return validate(data, {
            'nombres': validators.exists()('Campo requerido.'),
            'apellidos': validators.exists()('Campo requerido.'),
            'email': validators.exists()('Campo requerido.'),
            'new_password': validators.exists()('Campo requerido.'),
        })
    }
})(AdminForm);
const selector = formValueSelector('EditarAdminForm');
AdminForm = connect(state => {
    const a = 'test';
    return {
        a
    };
})(AdminForm);

export const AdminUpdateForm = reduxForm({
    form: 'EditarAdminForm',
    validate: data => {
        return validate(data, {
            'nombres': validators.exists()('Campo requerido.'),
            'apellidos': validators.exists()('Campo requerido.'),
            'email': validators.exists()('Campo requerido.'),
        })
    }
})(AdminForm);

export default AdminForm;
