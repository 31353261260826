import React, { Fragment } from 'react';
import { Tooltip } from 'antd';
import _ from 'lodash';
import { ENLACE, EXTENSIONES } from '../../../../utility/constants';
import { getIcono } from '../../../../utility/iconos';

const clear = require('../../../../../assets/img/iconos/clear.png');
const check = require('../../../../../assets/img/iconos/check.png');


function CardArchivo(props) {
    const { index, nombre, tipo, extension, seleccionar, seleccionado, eliminar, item } = props;
    return (
        <Fragment>
            <div
                className={`py-2 card-archivo ${eliminar && 'eliminar'} ${seleccionado && 'seleccionado'}`}
                style={{ cursor: seleccionar ? 'pointer' : 'default', height: '100%' }}
                onClick={() => seleccionar && seleccionar(index, item)}
            >
                <div className="d-flex align-items-center justify-content-center">
                    <img src={getIcono(tipo, extension)} className="card-archivo-icon" />
                </div>
                <div className="d-flex align-items-center justify-content-center mt-2 card-tooltip">
                    <Tooltip title={nombre} placement="bottom">
                        <label className="card-archivo-label">{nombre}</label>
                    </Tooltip>
                </div>
                {
                    eliminar && (
                        <button className="card-archivo-eliminar" type="button" onClick={() => eliminar(index)}>
                            <img className="card-icon-clear" src={clear} />
                            {/* <i className="material-icons card-icon-clear">clear</img> */}
                        </button>
                    )
                }
                {
                    seleccionado && (
                        <div className="card-archivo-seleccionado">
                            <img className="card-icon-clear" src={check} />
                        </div>
                    )
                }
            </div>
        </Fragment>

    )
}

export default CardArchivo;
