import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm } from 'redux-form';
import { renderField, renderFieldRadio2, RenderImageUpload } from '../../../Utils/renderField/renderField';
import { EMPRESA, INVESTIGACION } from '../../../../../utility/constants'


const LogoForm = props => {
    const { handleSubmit, setLogo, logo, id, item } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="row col-md-12 p-0 mb-3">
                    {id && (
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="fotografia" className="m-0">Fotografía actual</label>
                            </div>
                            <div className="col-md-12 form-group text-center">
                                <img src={item.logo} alt="fotografia" className="img-fluid" style={{ height: "150px", marginTop: "7px" }} />
                            </div>
                        </div>
                    )}
                    <div className={id ? "col-md-6 p-0" : "col-md-12 p-0"}>
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="fotografia" className="m-0">{id ? "Nueva fotografía" : "Fotografía"}</label>
                        </div>
                        <div className="col-md-12 ">
                            <RenderImageUpload aspect={2 / 1} multiple={false} setFile={setLogo} />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 p-0">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="titulo" className="m-0">Título</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="titulo" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="ubicacion" className="m-0">Ubicación del logo</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <div className="d-flex flex-row flex-1">
                                <Field
                                    label='Empresas'
                                    type="radio"
                                    value={EMPRESA}
                                    name={'ubicacion'}
                                    parse={val => +val}
                                    component={renderFieldRadio2}
                                />
                                <Field
                                    label='Investigación'
                                    type="radio"
                                    value={INVESTIGACION}
                                    name={'ubicacion'}
                                    parse={val => +val}
                                    component={renderFieldRadio2}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-3">
                    <div className="col-md-4 col-12">
                        <Link className="btn btn-secondary btn-block" to="/logos">Cancelar</Link>
                    </div>
                    <div className="col-md-4 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Guardar</button>
                    </div>
                </div>
            </div>
        </form>
    )
};

export default reduxForm({
    form: 'LogoForm',
    asyncBlurFields: [],
    initialValues: {
        ubicacion: 10,
    },
    validate: data => {
        return validate(data, {
            'titulo': validators.exists()('Campo requerido.'),
            'ubicacion': validators.exists()('Campo requerido.'),
        })
    }
})(LogoForm);
