import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/cursosGrupales';
import CursoGrupalCrear from './CursoGrupalCrear';


const ms2p = (state) => {
    return {
        ...state.cursosGrupales,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CursoGrupalCrear);
