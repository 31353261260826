import React, { useState, Component, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { USER_ALUMNO, DINERO, PORCENTAJE } from '../../../../../utility/constants';
import { renderNumber, renderFieldRadio, AsyncSelectField } from '../../../Utils/renderField';

import {api} from 'api';

const getAlumnos = (search) => {
    let params = { tipo_usuario: USER_ALUMNO }
    if (search) {
        params.search = search;
    }
    return api.get("user", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
};

let DescuentoForm = (props) => {
    const [cursos, setCursos] = useState([]);
    const [idAlumno, setAlumno] = useState(null);

    const { handleSubmit, tipo_descuento, alumno, item } = props;

    useEffect(() => {
        if (item.alumno) {
            changeAlumno(item.alumno);
        }
    }, []);


    const getAulas = (search, id) => {
        let params = {};
        if (search) {
            params.search = search;
        }
        if (id) {
            params.alumno = id;
        } else if (idAlumno) {
            params.alumno = idAlumno;
        } else if (alumno) {
            params.alumno = alumno.id;
        }
        return api.get("aula/cursos_alumnos", params).
            then((data) => {
                if (data) {
                    setCursos(data.results);
                    return data.results
                };
                setCursos([]);
                return [];
            }).catch(() => {
                setCursos([]);
                return [];
            });
    };

    const changeAlumno = (e) => {
        if (e) {
            setAlumno(e.id);
            getAulas('', e.id);
        } else {
            setAlumno(null);
            setCursos([]);
        }
        props.limpiarAula();
    }

    return (
        <form name="DescuentoForm" onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="row col-12 p-0 mb-4">
                    <span className="text-sm azul-claro bold">Datos del descuento</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="alumnos_activos" className="m-0">Alumno</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                name="alumno"
                                loadOptions={getAlumnos}
                                type="text"
                                component={AsyncSelectField}
                                valueKey="id"
                                labelKey="titulo"
                                isClearable={false}
                                className="form-control"
                                onCambio={ e => changeAlumno(e)}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="aula" className="m-0">Aula</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                name="aula"
                                loadOptions={(search) => getAulas(search, null)}
                                // loadOptions={getAulas}
                                type="text"
                                component={AsyncSelectField}
                                valueKey="id"
                                labelKey="nombre"
                                isClearable={false}
                                className="form-control"
                                defaultOptions={cursos}
                            />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="profesores" className="m-0">Tipo de descuento</label>
                        </div>
                        <div className="col-md-12 d-flex flex-wrap justify-content-start align-items-center mt-2">
                            <Field
                                label='Dinero'
                                type="radio"
                                value={DINERO}
                                name="tipo_descuento"
                                parse={val => +val}
                                component={renderFieldRadio}
                            />
                            <div className="mt-md-0 mt-2">
                                <Field
                                    label='Porcentaje'
                                    type="radio"
                                    value={PORCENTAJE}
                                    name="tipo_descuento"
                                    parse={val => +val}
                                    component={renderFieldRadio}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="descuento" className="m-0">Descuento</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="descuento" component={renderNumber} decimalScale={2} suffix={tipo_descuento == DINERO ? ' €': ' %'} />
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-5">
                    <div className="col-md-4 col-12">
                        <Link className="btn btn-secondary btn-block" to="/comercial/descuentos">Cancelar</Link>
                    </div>
                    <div className="col-md-4 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Guardar</button>
                    </div>
                </div>
            </div>
        </form>
    );
};

DescuentoForm = reduxForm({
    form: 'DescuentoForm',
    initialValues: {
        tipo_descuento: DINERO,
    },
    asyncBlurFields: [],
    validate: data => {
        return validate(data, {
            'alumno': validators.exists()('Campo requerido.'),
            'aula': validators.exists()('Campo requerido.'),
            'tipo_descuento': validators.exists()('Campo requerido.'),
            'descuento': validators.exists()('Campo requerido.'),
        })
    },
})(DescuentoForm);

const selector = formValueSelector('DescuentoForm');
const mstp = state => {
    const alumno = selector(state, 'alumno');
    const tipo_descuento = selector(state, 'tipo_descuento');

    return {
        alumno,
        tipo_descuento,
    }
};

export default connect(mstp, null)(DescuentoForm);
