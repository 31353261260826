import React, {Component} from 'react'
import { Async } from "react-select";
import classNames from "classnames";
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";

import { api } from "api";

const getUsuarios = (search) => {
    return api
        .get("user/profesores_admins/", { search })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};

export default class FormulariosList extends Component{
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar: onPageChange, onSortChange, eliminar } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return(
            <div className="mb-4 col-12 mt-4">
                <Header to="formulario/crear" textBtn="Nuevo ejercicio / examen" {...this.props}
                    filtro1={
                        <Async
                            isClearable={true}
                            backspaceRemovesValue={false}
                            value={this.props.usuario}
                            isSearchable={true}
                            loadOptions={getUsuarios}
                            getOptionValue={(option) => option["id"]}
                            getOptionLabel={(option) => option["titulo"]}
                            type="text"
                            onChange={(e) => this.props.changeUsuario(e)}
                            multi={false}
                            autoload={false}
                            cache={false}
                            className={classNames(
                                "react-select-container w-100"
                            )}
                            defaultOptions={true}
                            placeholder="Filtrar por usuario.."
                        />
                    }
                />
                <Card>
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                    page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='12%'
                            dataFormat={standardActions({ editar: "formulario", eliminar})}
                        />
                        <TableHeaderColumn
                            dataField="titulo"
                            dataSort
                        >
                            TITULO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="asignatura"
                            dataSort
                            dataFormat={(cell) => cell && cell.nombre ? cell.nombre : ""}
                        >
                            NOMBRE DE ASIGNATURA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nivel"
                            dataSort
                            dataFormat={(cell, row) => cell ? cell.nombre : ''}
                        >
                            NIVEL
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="subido_por"
                            dataSort
                            dataFormat={(cell) => cell ? cell.titulo : '-----'}
                        >
                            SUBIDO POR
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipo_text"
                            width='10%'
                            dataSort
                        >
                            TIPO
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
