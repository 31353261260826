import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField, AsyncSelectField, renderNumber, RenderImageUpload, renderFieldRadio, renderFieldCheck, renderSwitch } from '../../../Utils/renderField/renderField';
import { api } from '../../../../../utility/api';
import { RenderCurrency } from '../../../Utils/renderField/renderReadField';


const getAsignaturas = (search) => {
    let params = {}
    if (search) {
        params.search = search;
    }
    return api.get("asignatura_niveles/listar_asignaturas", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
}

const getCentros = (search) => {
    let params = {}
    if (search) {
        params.search = search;
    }
    return api.get("centro_colaborador", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
}

let CursoGrupalForm = props => {
    const { handleSubmit, editando, setFotografia, foto } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="row col-md-12 p-0 mb-3">
                    {editando && (
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="fotografia" className="m-0">Fotografía actual</label>
                            </div>
                            <div className="col-md-12 form-group text-center">
                                <img src={foto} alt="fotografia" className="img-fluid" style={{ height: "150px", marginTop: "7px" }} />
                            </div>
                        </div>
                    )}
                    <div className={editando ? "col-md-6 p-0" : "col-md-12 p-0"}>
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="fotografia" className="m-0">{editando ? "Nueva fotografía" : "Fotografía"}</label>
                        </div>
                        <div className="col-md-12 ">
                            <RenderImageUpload aspect={2 / 1} multiple={false} setFile={setFotografia} />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 my-4">
                    <div className="col-md-12 d-flex flex-wrap justify-content-start align-items-center">
                        <Field
                            label='Curso interno'
                            type="radio"
                            value={true}
                            name="interno"
                            parse={val => !!val}
                            component={renderFieldRadio}
                            disabled={editando}
                        />
                        <div className="ml-3" />
                        <Field
                            label='Curso externo'
                            type="radio"
                            value={false}
                            name="interno"
                            parse={val => !val}
                            component={renderFieldRadio}
                            disabled={editando}
                        />
                    </div>
                </div>
                <div className="row col-md-12 p-0">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="titulo" className="m-0">Título</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="titulo" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="precio_sin_iva" className="m-0">Precio</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="precio_sin_iva" component={renderNumber} suffix=" €" decimalScale={2} type="number" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0 ">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="plazas_disponibles" className="m-0">Máximo de personas</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="plazas_disponibles" component={renderNumber} type="number" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="precio" className="m-0">Asignatura</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                name="asignatura"
                                loadOptions={getAsignaturas}
                                type="text"
                                component={AsyncSelectField}
                                valueKey="id"
                                labelKey="nombre"
                                isClearable={false}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="col-md-6 p-0 ">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nivel" className="m-0">Nivel</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nivel" component={renderField} className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="evaluaciones" className="m-0">Evaluaciones</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="evaluaciones" component={renderField} className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0 ">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="ejercicios" className="m-0">Ejercicios</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="ejercicios" component={renderField} className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="horas" className="m-0">Horas del curso</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="horas" component={renderField} className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0 ">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="certificado" className="m-0">Certificado de finalización</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="certificado" component={renderField} className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0 ">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="inscritos_inicial" className="m-0">Número inicial de alumnos inscritos</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="inscritos_inicial" component={renderNumber} type="number" className="form-control" />
                        </div>
                    </div>
                    {(!props.interno) && (
                        <div className="col-md-6 p-0 ">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="inscritos_inicial" className="m-0">Centro colaborador</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    valueKey="id"
                                    name="centro_colaborador"
                                    labelKey="nombre"
                                    label="nombre"
                                    component={AsyncSelectField}
                                    loadOptions={getCentros}
                                    disabled={editando}
                                />
                            </div>
                        </div>
                    )}
                </div>
                {(!props.interno) && (
                    <div className="row  col-md-12 p-0">
                        <div className="col-md-6 p-0 ">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="porcentaje_centro" className="m-0">% de distribución para el centro</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name="porcentaje_centro"
                                    component={renderNumber}
                                    suffix=' %'
                                    decimalScale={2}
                                    type="number"
                                    className="form-control"
                                    disabled={editando}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 p-0 ">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="porcentaje_centro" className="m-0"></label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name="curso_vencimiento"
                                    label="Activar cursos con fechas"
                                    value={true}
                                    component={renderSwitch}
                                />
                            </div>
                        </div>
                    </div>
                )}
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="d-flex flex-2 justify-content-center align-items-center">
                        <div className="d-flex flex-md-row flex-column justify-content-center align-items-center ">
                            <h2 className="mr-2">Precio del curso</h2>
                            <RenderCurrency value={props.precio} className="h2 verde" />
                        </div>
                    </div>
                    <div className="d-flex flex-1 justify-content-center align-items-center">
                        <div className="d-flex flex-md-row flex-column justify-content-center align-items-center ">
                            <label htmlFor="iva" className="m-0 mr-2">Agregar IVA</label>
                            <Field name="iva" component={renderFieldCheck} onCambio={(e) => props.changeIva()} />
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-3">
                    <div className="col-md-4 col-12">
                        <Link className="btn btn-secondary btn-block" to="/cursos_grupales">Cancelar</Link>
                    </div>
                    <div className="col-md-4 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Siguiente</button>
                    </div>
                </div>
            </div>
        </form>
    )
};
CursoGrupalForm = reduxForm({
    form: 'CursoGrupalForm',
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    asyncBlurFields: [],
    initialValues: {
        interno: true,
        iva: false,
        curso_vencimiento: true,
    },
    validate: data => {
        return validate(data, {
            'titulo': validators.exists()('Campo requerido.'),
            'precio': validators.exists()('Campo requerido.'),
            'plazas_disponibles': validators.exists()('Campo requerido.'),
            'asignatura': validators.exists()('Campo requerido.'),
            'nivel': validators.exists()('Campo requerido.'),
            'evaluaciones': validators.exists()('Campo requerido.'),
            'ejercicios': validators.exists()('Campo requerido.'),
            'horas': validators.exists()('Campo requerido.'),
            'certificado': validators.exists()('Campo requerido.'),
            'inscritos_inicial': validators.exists()('Campo requerido.'),
        })
    }
})(CursoGrupalForm);

const selector = formValueSelector('CursoGrupalForm');
CursoGrupalForm = connect(state => {
    const interno = selector(state, 'interno');
    let precio = 0;
    const precio_sin_iva = selector(state, 'precio_sin_iva');
    const iva = selector(state, 'iva');
    const porcentaje_iva = selector(state, 'porcentaje_iva');

    if (precio_sin_iva) {
        precio = parseFloat(precio_sin_iva);
    }

    if (iva) {
        precio = precio + ( precio * parseFloat(porcentaje_iva))
    }

    return {
        interno,
        precio,
        iva,
    };
})(CursoGrupalForm);

export default CursoGrupalForm;
