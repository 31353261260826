import React, { Fragment } from 'react';
import { Field, reduxForm, FieldArray, } from 'redux-form';
import { renderField, SelectField, renderDatePicker, renderFieldRadio } from '../../../Utils/renderField/renderField';
import { TIPOS_FERIADOS, FESTIVO, VACACIONES } from "../../../../../utility/constants";

const renderFeriados = ({ fields, editar }) => (
    <div className="row col-12 px-0">
        <div className="col-12 px-0">
            {fields.map((feriado, index) => {
                return (
                    <div key={index} className="d-flex flex-column pb-3 mt-3" style={{borderBottom: "2px solid #D1D8EC"}}>
                        <div className="d-flex flex-column flex-lg-row">
                            <div className="d-flex flex-1 flex-column">
                                <div className="flex-1">
                                    <label htmlFor={`${feriado}.tipo`} className="m-0">Tipo</label>
                                </div>
                                <div className="d-flex flex-row flex-1">
                                    <div className="flex-2">
                                        <Field
                                            label='Festivos'
                                            type="radio"
                                            value={FESTIVO}
                                            name={`${feriado}.tipo`}
                                            parse={val => +val}
                                            component={renderFieldRadio}
                                            // disabled={editar}
                                        />
                                    </div>
                                    <div className="flex-2">
                                        <Field
                                            label='Vacaciones'
                                            type="radio"
                                            value={VACACIONES}
                                            name={`${feriado}.tipo`}
                                            parse={val => +val}
                                            component={renderFieldRadio}
                                            // disabled={editar}
                                        />
                                    </div>
                                    <div className="d-flex d-lg-none flex-1 justify-content-center align-items-center">
                                        <button className="btn-delete" onClick={(e) => { e.preventDefault(); fields.remove(index) }}><span className="material-icons rosa">close</span></button>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-1 flex-column mt-3 mt-lg-0">
                                <label htmlFor={`${feriado}.nombre`} className="m-0">Nombre *</label>
                                <div className="d-flex flex-row">
                                    <div className="flex-5">
                                        <Field name={`${feriado}.nombre`} component={renderField} type="text" className="form-control"
                                        // disabled={editar}
                                        />
                                    </div>
                                    <div className="d-none d-lg-flex flex-1 justify-content-center align-items-center">
                                        <button className="btn-delete" onClick={(e) => { e.preventDefault(); fields.remove(index) }}><span className="material-icons rosa">close</span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-row mt-3">
                            <div className="d-flex flex-1 flex-column pr-3">
                                <label htmlFor={`${feriado}.fecha_inicio`} className="m-0">Desde *</label>
                                <Field
                                    name={`${feriado}.fecha_inicio`}
                                    component={renderDatePicker}
                                    // disabled={editar}
                                />
                            </div>
                            <div className="d-flex flex-1 flex-column">
                                <label htmlFor={`${feriado}.fecha_fin`} className="m-0">Hasta *</label>
                                <Field
                                    name={`${feriado}.fecha_fin`}
                                    component={renderDatePicker}
                                    // disabled={editar}
                                />
                            </div>
                            <div className="d-none d-lg-flex flex-1"></div>
                        </div>
                    </div>
                )
            })}
        </div>
        <div className="col-md-4 col-12 form-group mt-sm-3 mt-3 px-0">
            <button type="button" className="btn btn-dark btn-block" onClick={() => fields.push({tipo: FESTIVO})}>Agregar</button>
        </div>
    </div>
);

let PasoCincoForm = props => {
    const { handleSubmit, previousStep } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="row col-12 px-0">
                    <span className="text-sm azul-claro bold">Días festivos o vacaciones</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 px-0">
                            <FieldArray name="feriados" component={renderFeriados} {...props} />
                        </div>
                    </div>
                </div>
                <div className="d-none d-lg-flex buttons-box mt-4">
                    <button className="btn btn-secondary mr-5" onClick={previousStep}>Regresar</button>
                    <button type="submit" className="btn btn-primary">Guardar</button>
                </div>
                <div className="d-flex d-lg-none flex-column mt-4">
                    <button className="btn btn-secondary flex-1" onClick={previousStep}>Regresar</button>
                    <button type="submit" className="btn btn-primary flex-1 mt-3">Guardar</button>
                </div>
            </div>
        </form>
    )
};
PasoCincoForm = reduxForm({
    form: 'ProfesorForm',
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    asyncBlurFields: [],
    initialValues: {
        feriados: [],
    },
})(PasoCincoForm);

export default PasoCincoForm
