import React, { Component } from "react";
import NotificationSystem from 'react-notification-system'
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logOut, getMe, newToken } from "./redux/modules/cuenta/login";

// maquetado base
import SiderBar from './common/components/layout/Sidebar/SideBar';
import Footer from './common/components/layout/Footer/Footer';

import Navbar from "./common/components/layout/Navbar/Navbar";
import { VerifyLogin } from "./common/components/layout";

import { messaging } from "./utility/init-fcm";


class PrivateRouteBase extends Component {

    notificationSystem = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            toggleOpen: true,
        };
    }

    async componentDidMount() {
        // FCM
        messaging.requestPermission()
            .then(() => {
                this.pushToken(messaging);
            })
            .catch(function (err) {
                console.log("Error al obtener el token.", err);
            });
        navigator.serviceWorker.addEventListener("message", (message) => {
            this.handleNotificacion(message);
        });
    }

    pushToken = async (messaging) => {
        const token = await messaging.getToken();
        this.props.newToken(token);
    }

    handleNotificacion = (message) => {
        console.log('message ', message);
        const { data: { 'firebase-messaging-msg-data': { notification } } } = message;
        const notificacion = this.notificationSystem.current;
        notificacion.addNotification({
            children: (
                <div
                    className="h-100 w-100 d-flex"
                    onClick={() => notification.click_action ? this.props.history.push(`${notification.click_action}`) : null}
                >
                    <div className="w-25 h-100 pr-3">
                    </div>
                    <div className="w-75 h-100 noti-min-heigth notification-body">
                        <p className="title mb-2">{notification.title}</p>
                        <p className='m-0'>{notification.body}</p>
                    </div>
                </div>
            ),
            level: 'info',
            position: 'tr',
            dismissible: true,
            autoDismiss: 0,
        });
    }

    navToggle = () => {
        this.setState({ toggleOpen: !this.state.toggleOpen });
    };

    isAuthenticated = () => {
        const token = sessionStorage.getItem("token");
        const { getMe, login: { me } } = this.props;
        if (!!token && !!me.email) {
            return true;
        } else if (token) {
            getMe();
            return "Verifying"
        }
        return false;
    };

    render() {
        const { component: Component, logOut, title: titulo, subtitle: subtitulo, icon: icono, login: { me }, ...rest } = this.props;
        const isAuthenticated = this.isAuthenticated();
        return (
            <Route
                {...rest}
                render={props =>
                    isAuthenticated ? (
                        (isAuthenticated === true) ? (<div>
                            <SiderBar
                                toggleOpen={this.state.toggleOpen}
                                navToggle={this.navToggle}
                                me={me}
                                logOut={logOut}
                            />
                            <NotificationSystem ref={this.notificationSystem} />
                            <main className="main-content p-0 col-sm-12 col-md-9 offset-md-3 col-lg-10 offset-lg-2">
                                <Navbar navToggle={this.navToggle} logOut={logOut} user={me} title={titulo} subtitle={subtitulo} icon={icono} />
                                <div className="main-content-container px-3 container-fluid">
                                    <Component {...props} />
                                </div>
                                {/* <Footer /> */}
                            </main>
                        </div>) : (
                                <VerifyLogin />
                            )
                    ) : (
                            <Redirect
                                to={{
                                    pathname: "/login",
                                    state: { from: props.location }
                                }}
                            />
                        )
                }
            />
        );
    }
}

const mstp = state => ({ ...state });

const mdtp = { logOut, getMe, newToken };

const ProtectedRoute = connect(
    mstp,
    mdtp
)(withRouter(PrivateRouteBase));

export default ProtectedRoute;

