import React, {Component} from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';

import CursosGrupalesList from './CursosGrupales';
import SeccionesList from './Secciones';

export default class Listados extends Component{
    render() {
        return(
            <div className="mb-4 col-12 mt-4">
                <Tabs
                    defaultActiveKey="LISTADO"
                    tabBarPosition="top"
                    onChange={this.callback}
                    renderTabBar={() => <ScrollableInkTabBar />}
                    renderTabContent={() => <TabContent />}
                >
                    <TabPane tab="LISTADO" key="LISTADO">
                        <CursosGrupalesList {...this.props} />
                    </TabPane>
                    <TabPane tab="SECCIONES" key="SECCIONES">
                        <SeccionesList {...this.props} />
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}
