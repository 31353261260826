import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";

import moment from "moment";
import Swal from 'sweetalert2';


export default class CursosIndividualesGrid extends Component {

    componentWillMount() {
        const { listar } = this.props;
        listar();
    }

    eliminar = (id) => {
        Swal.fire({
            title: '¿Eliminar este curso?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, eliminar!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.eliminar(id);
            }
        });
    }

    render() {
        const { listar: onPageChange, onSortChange } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return (
            <div className="mb-4 col-12 mt-4">
                <Header to="curso_individual/crear" textBtn="Nuevo curso individual" {...this.props} />
                <Card>
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                        page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='15%'
                            dataFormat={ (cell, row) => (
                                <div className="d-flex justify-content-center">
                                    {(row.curso_activo) && (
                                        <Link className="px-1" to={`curso/${cell}/editar`} title="Editar" >
                                            <i className="material-icons verde editar-action">edit</i>
                                        </Link>
                                    )}
                                    <Link className="px-1" to={`cursos/${cell}/reasignar`} title="Reasignar" >
                                        <i className="material-icons azul-claro asignar-action">supervised_user_circle</i>
                                    </Link>
                                    <a className="px-1" style={{cursor: "pointer", color: "#c4183c"}} title="Eliminar" onClick={() => this.eliminar(cell)}>
                                        <i className="material-icons azul-marino eliminar-action">clear</i>
                                    </a>
                                </div>
                            )}
                        >
                            ACCIONES
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="curso_activo"
                            width='10%'
                            dataFormat={(cell) => cell  ? (
                                <div className="d-flex flex-row align-items-center">
                                    <div className="circulo bg-aqua mr-1"></div>
                                    <span className="aqua ml-1">Activo</span>
                                </div>
                            ) : (
                                <div className="d-flex flex-row align-items-center">
                                    <div className="circulo bg-rosa mr-1"></div>
                                    <span className="rosa ml-1">Inactivo</span>
                                </div>
                            )}
                        >
                            Estado
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre"
                            width='22%'
                            dataSort
                            tdStyle={{ whiteSpace: 'normal' }}
                        >
                            NOMBRE CURSO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="profesor"
                            dataSort
                            dataFormat={(cell) => cell && `${cell.nombres} ${cell.apellidos}`}
                        >
                            PROFESOR
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_inicio"
                            dataSort
                            dataFormat={(cell) => cell ? moment(cell).format("DD/MM/YYYY") : ""}
                        >
                            FECHA INICIO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_fin"
                            dataSort
                            dataFormat={(cell) => cell ? moment(cell).format("DD/MM/YYYY") : ""}
                        >
                            FECHA FINAL
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="curso_empresa"
                            dataSort
                            dataFormat={(cell) => cell  ? (
                                <div className="d-flex flex-row align-items-center">
                                    <span className="bold aqua ml-1">Sí</span>
                                </div>
                            ) : (
                                <div className="d-flex flex-row align-items-center">
                                    <span className="bold rosa ml-1">No</span>
                                </div>
                            )}
                        >
                            Curso empresa
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
