// Frontpage
export const URL_FRONTPAGE = 'https://sidioms.com';
// Generos
export const generos = [
    {value: 10, label: "Hombre"},
    {value: 20, label: "Mujer"},
    {value: 30, label: "Otro"}
];

// Tipos de preguntas
export const tipos_preguntas = [
    {value: 10, label: "Respuesta breve"},
    {value: 20, label: "Opción multiple"}
];

export const RESPUESTA_BREVE = 10;
export const OPC_MULT = 20;

// Tipo de usuario
export const USER_ADMIN = 10;
export const USER_PROFESOR = 20;
export const USER_ALUMNO = 30;
export const USER_COLABORADOR = 40;

// Tipos de Eventos
export const CLASE_INDIVIDUAL = 1;
export const CLASE_GRUPAL = 5;
export const CLASE_CANCELADA = 10;
export const CLASE_RECUPERACION = 15;
export const TUTORIA = 20;
export const FESTIVOS = 2; //Se dejo asi por que el backend tenia este valor
// Extras
export const RESERVADO = 30;
export const DISPONIBLE = 40;
export const RESERVAR = 50;

export const TIPOS_EVENTOS = [
    { value: CLASE_INDIVIDUAL, label: 'Clase Individual', color: "#366AED", texto: "#FFFFFF"},
    { value: CLASE_GRUPAL, label: 'Clase Grupal', color: "#53A513", texto: "#FFFFFF" },
    { value: CLASE_CANCELADA, label: 'Clase Cancelada', color: "#686A75", texto: "#FFFFFF" },
    { value: CLASE_RECUPERACION, label: 'Recuperacion', color: "#E9206A", texto: "#FFFFFF"},
    { value: TUTORIA, label: 'Tutoria', color: "#2CB2E5", texto: "#FFFFFF"},
];

export const TIPOS_EVENTOS_CALENDARIO = [
    { value: CLASE_INDIVIDUAL, label: 'Clase Individual', color: "#366AED", texto: "#FFFFFF"},
    { value: CLASE_GRUPAL, label: 'Clase Grupal', color: "#53A513", texto: "#FFFFFF" },
    { value: CLASE_CANCELADA, label: 'Clase Cancelada', color: "#686A75", texto: "#FFFFFF" },
    { value: CLASE_RECUPERACION, label: 'Recuperacion', color: "#E9206A", texto: "#FFFFFF"},
    { value: TUTORIA, label: 'Tutoria', color: "#2CB2E5", texto: "#FFFFFF"},
    { value: FESTIVOS, label: 'Festivos', color: "#E97821", texto: "#FFFFFF"},
    { value: RESERVADO, label: 'Reservado', color: "#BABFCE", texto: "#151A34"},
    { value: DISPONIBLE, label: 'Disponible', color: "#C0EC9E", texto: "#151A34"},
    { value: RESERVAR, label: 'Reservar', color: "#366AED", texto: "#FFFFFF"},
];

// Estados del evento
export const PENDIENTE = 10;
export const PAGADA = 20;
export const FINALIZADA = 30;
export const CANCELADO_ALUMNO = 40;
export const CANCELADO_ADMIN = 50;
export const CANCELADO_PROFESOR = 60;
export const CLASE_REPUESTA = 70;

export const ESTADOS_EVENTOS = [
    { value: PAGADA, label: 'Clase pagada', color: "#366AED" },
    { value: FINALIZADA, label: 'Clase finalizada', color: "#E9206A" },
    { value: CANCELADO_ALUMNO, label: 'Clase cancelada por el alumno', color: "#686A75" },
    { value: CANCELADO_ADMIN, label: 'Clase cancelada por un administrador', color: "#686A75" },
    { value: CANCELADO_PROFESOR, label: 'Clase cancelada por el profesor', color: "#686A75" },
    { value: CLASE_REPUESTA, label: 'Clase recuperada', color: "#53A513" },
];

// Dias de la semana
export const DIAS_SEMANA = [
    { value: 'Domingo', label: 'Domingo', id: 0},
    { value: 'Lunes', label: 'Lunes', id: 1},
    { value: 'Martes', label: 'Martes', id: 2},
    { value: 'Miércoles', label: 'Miércoles', id: 3},
    { value: 'Jueves', label: 'Jueves', id: 4},
    { value: 'Viernes', label: 'Viernes', id: 5},
    { value: 'Sábado', label: 'Sábado', id: 6},
];
    // Dias
export const dias = [
    {value: 'Lunes', label: "Lunes"},
    {value: 'Martes', label: "Martes"},
    {value: 'Miércoles', label: "Miércoles"},
    {value: 'Jueves', label: "Jueves"},
    {value: 'Viernes', label: "Viernes"},
    {value: 'Sábado', label: "Sábado"},
    {value: 'Domingo', label: "Domingo"}
];

export const ENLACE  = 5;
export const ARCHIVO = 10;

export const TIPOS_ARCHIVOS = [
    {value: 10, label: 'Archivo'},
    {value: 5, label: 'Enlace'},
]

export const EXTENSIONES = [
    {value: 'xlsx', label: 'excel'},
    {value: 'xlsm', label: 'excel'},
    {value: 'xls', label: 'excel'},
    {value: 'pdf', label: 'pdf'},
    {value: 'pptx', label: 'presentacion'},
    {value: 'pptm', label: 'presentacion'},
    {value: 'ppt', label: 'presentacion'},
    {value: 'odp', label: 'presentacion'},
    {value: 'avi', label: 'video'},
    {value: 'mp4', label: 'video'},
    {value: 'm4v', label: 'video'},
    {value: 'mov', label: 'video'},
    {value: 'mpg', label: 'video'},
    {value: 'mpeg', label: 'video'},
    {value: 'wmv', label: 'video'},
    {value: 'docx', label: 'word'},
    {value: 'docm', label: 'word'},
    {value: 'dotx', label: 'word'},
]

export const EJERCICIO = 10;
export const EXAMEN = 20;

export const TIPOS_FORMS = [
    {value: EJERCICIO, label: 'Ejercicio'},
    {value: EXAMEN, label: 'Examen'},
];

export const TARJETA = 10
export const TRANSFERENCIA = 20

export const METODOS_PAGO = [
    {value: TARJETA, label: 'Tarjeta'},
    {value: TRANSFERENCIA, label: 'Transferencia'},
]

// --------------- CUPONES ---------------
// Tipos de cupones
export const CUPON_UNICO = 10;
export const CUPON_MULTIPLE = 20;

// Tipos de formación
export const CLASE_INDIVIDUAL_CUPONES = 10;
export const CLASE_GRUPAL_CUPONES = 20;

// Tipos de descuento
export const DINERO = 10;
export const PORCENTAJE = 20;

export const meses = [
    {value: 0, label: "Enero"},
    {value: 1, label: "Febrero"},
    {value: 2, label: "Marzo"},
    {value: 3, label: "Abril"},
    {value: 4, label: "Mayo"},
    {value: 5, label: "Junio"},
    {value: 6, label: "Julio"},
    {value: 7, label: "Agosto"},
    {value: 8, label: "Septiembre"},
    {value: 9, label: "Octubre"},
    {value: 10, label: "Noviembre"},
    {value: 11, label: "Diciembre"},
];

// Tipo de documento de identificación
export const NIF = 10;
export const CIF = 20;

export const tipo_documento_identificacion = [
    {value: 10, label: "NIF"},
    {value: 20, label: "CIF"}
];

// Tipo de noticia o campaña
export const NOTICIA = 10;
export const CAMPAÑA = 20;

// Tipo de multimedia
export const IMAGEN = 10;
export const VIDEO = 20;

export const EMPRESA = 10;
export const INVESTIGACION = 20;

export const UBICACION_LOGO = [
    {value: EMPRESA, label: "Vista de empresas"},
    {value: INVESTIGACION, label: "Vista de investigación"}
];

// TIPO DE COMUNICADO
export const COMUNICADO_ALUMNOS = 10;
export const COMUNICADO_PROFESORES = 20;

export const AUTOMATICA = 10
export const MANUAL = 20

export const TIPO_FACTURA = [
    {value: AUTOMATICA, label: "Automática"},
    {value: MANUAL, label: "Manual"}
];

// ESTADOS DE LA DESCARGA
export const CELERY_PENDIENTE = 10
export const CELERY_EXITO = 20
export const CELERY_ERROR = 30

// Feriados
export const FESTIVO = 10;
export const VACACIONES = 20;

export const TIPOS_FERIADOS = [
    {value: FESTIVO, label: "Día festivo"},
    {value: VACACIONES, label: "Vacaciones"}
];


export const DIAS_CALENDARIO = [
    {value: 0, key: "domingo"},
    {value: 1, key: "lunes"},
    {value: 2, key: "martes"},
    {value: 3, key: "miercoles"},
    {value: 4, key: "jueves"},
    {value: 5, key: "viernes"},
    {value: 6, key: "sabado"},
];
