import React, { useState, Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderNumber } from '../../../Utils/renderField';


const ConfigForm = (props) => {
    const { handleSubmit, incrementarHora, restarHora } = props;
    return (
        <form name="ConfigPlataformaForm" onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="row  col-md-12 p-0">
                    <div className="col-12 p-0 mb-4">
                        <span className="text-sm azul-claro bold">Cambio de horario en clases</span>
                        <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                    </div>
                    <div className="col-12 p-0 mb-4">
                        <div className="d-flex flex-lg-row flex-column justify-content-center flex-1">
                            <div className="flex-1"></div>
                            <div className="flex-2 px-lg-2 py-2">
                                <button type="button" onClick={restarHora} className="btn btn-secondary w-100">Restar una hora</button>
                            </div>
                            <div className="flex-2 px-lg-2 py-2">
                                <button type="button" onClick={incrementarHora} className="btn btn-secondary w-100">Incrementar una hora</button>
                            </div>
                            <div className="flex-1"></div>
                        </div>
                    </div>


                    <div className="col-12 p-0 mb-4">
                        <span className="text-sm azul-claro bold">Parametros de configuración</span>
                        <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                    </div>

                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="bienvenida_titulo" className="m-0">Porcentaje de iva</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="iva" component={renderNumber} suffix=" %" decimalScale={2} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="limite_cancelacion" className="m-0">Tiempo limite para cancelación de una clase</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="limite_cancelacion" component={renderNumber} suffix=" hrs" type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="buttons-box mt-5">
                    {/* <Link className="btn btn-secondary mr-5" to="/alumnos">Cancelar</Link> */}
                    <button type="submit" className="d-none d-lg-flex btn btn-primary">Guardar</button>
                    <button type="submit" className="d-flex d-lg-none flex-1 btn btn-primary">Guardar</button>
                </div>
            </div>
        </form>
    );
};


export default reduxForm({
    form: 'ConfigPlataformaForm', // a unique identifier for this form
})(ConfigForm);
