import React, {Fragment} from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField, renderFieldRadio, RenderImageUpload, renderFieldCheck, MultiAsyncSelectField, renderTextArea } from '../../../Utils/renderField/renderField';
import { COMUNICADO_ALUMNOS, COMUNICADO_PROFESORES, USER_ALUMNO, USER_PROFESOR } from '../../../../../utility/constants';
import { api } from '../../../../../utility/api';

const getAlumnos = (search) => {
    let params = { tipo_usuario: USER_ALUMNO }
    if (search) {
        params.search = search;
    }
    return api.get("user", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
}

const getProfesores = (search) => {
    let params = { tipo_usuario: USER_PROFESOR }
    if (search) {
        params.search = search;
    }
    return api.get("user", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
}

let ComunicadoForm = props => {
    const { handleSubmit, setImagen, tipo_destinatario, desactivar_seleccion} = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="col-12 mt-4">
                    <span className="text-sm azul-claro bold">Selección el tipo de usuario a quien desea enviar mensaje *</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{height: '3px'}}/>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-center px-0 mt-3">
                    <div className="col-md-12 d-flex flex-wrap justify-content-start align-items-center">
                        <Field
                            label='Alumnos'
                            type="radio"
                            value={COMUNICADO_ALUMNOS}
                            name="tipo_destinatario"
                            parse={val => +val}
                            component={renderFieldRadio}
                        />
                        <div className="ml-3" />
                        <Field
                            label='Profesores'
                            type="radio"
                            value={COMUNICADO_PROFESORES}
                            name="tipo_destinatario"
                            parse={val => +val}
                            component={renderFieldRadio}
                        />
                    </div>
                </div>
                {tipo_destinatario === COMUNICADO_ALUMNOS && (
                    <Fragment>
                        <div className="col-12 mt-4">
                            <span className="text-sm azul-claro bold">Selecciona uno o varios alumnos *</span>
                            <div className="d-flex w-100 bg-gris-oscuro" style={{height: '3px'}}/>
                        </div>
                        <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                            <div className="col-md-6 p-0">
                                <div className="col-md-12">
                                    <Field
                                        component={renderFieldCheck}
                                        className="form-control"
                                        name="mandar_todos_alumnos"
                                        label=" Seleccionar a todos"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                            <div className="col-md-12 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="destinatarios" className="m-0">Alumnos</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        name="destinatarios_alumnos"
                                        loadOptions={getAlumnos}
                                        type="text"
                                        component={MultiAsyncSelectField}
                                        valueKey="id"
                                        labelKey="titulo"
                                        isClearable={false}
                                        className="form-control"
                                        disabled={desactivar_seleccion}
                                    />
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}

                {tipo_destinatario === COMUNICADO_PROFESORES && (
                    <Fragment>
                        <div className="col-12 mt-4">
                            <span className="text-sm azul-claro bold">Selecciona uno o varios profesores *</span>
                            <div className="d-flex w-100 bg-gris-oscuro" style={{height: '3px'}}/>
                        </div>
                        <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                            <div className="col-md-6 p-0">
                                <div className="col-md-12">
                                    <Field
                                        component={renderFieldCheck}
                                        className="form-control"
                                        name="mandar_todos_profesores"
                                        label=" Seleccionar a todos"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                            <div className="col-md-12 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="destinatarios" className="m-0">Profesores *</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        name="destinatarios_profesores"
                                        loadOptions={getProfesores}
                                        type="text"
                                        component={MultiAsyncSelectField}
                                        valueKey="id"
                                        labelKey="titulo"
                                        isClearable={false}
                                        className="form-control"
                                        disabled={desactivar_seleccion}
                                    />
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}
                <div className="col-12 mt-4">
                    <span className="text-sm azul-claro bold">Mensaje</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{height: '3px'}}/>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="titulo" className="m-0">Titulo *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="titulo" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="titulo" className="m-0">Imagen *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <RenderImageUpload aspect={2 / 1} multiple={false} setFile={setImagen} />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="mensaje" className="m-0">Mensajes *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="mensaje" component={renderTextArea} type="text" className="form-control" rows={5} />
                        </div>
                    </div>
                </div>
                <div className="col-12 text-right">
                    <span className="azul-marino text-xsm">* Campos requeridos</span>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-3">
                    <div className="col-md-4 col-12">
                        <Link className="btn btn-secondary btn-block" to="/comunicados">Cancelar</Link>
                    </div>
                    <div className="col-md-4 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Guardar</button>
                    </div>
                </div>
            </div>
        </form>
    )
};
ComunicadoForm = reduxForm({
    form: 'ComunicadoForm',
    asyncBlurFields: [],
    validate: data => {
        return validate(data, {
            'tipo_destinatario': validators.exists()('Campo requerido.'),
            'titulo': validators.exists()('Campo requerido.'),
            'mensaje': validators.exists()('Campo requerido.')
        })
    },
    initialValues: {
        tipo_destinatario: COMUNICADO_ALUMNOS,
        mandar_todos_alumnos: false,
        mandar_todos_profesores: false,
    },
})(ComunicadoForm);

const selector = formValueSelector('ComunicadoForm');
ComunicadoForm = connect(state => {
    const form = state.form.ComunicadoForm;
    let tipo_destinatario = COMUNICADO_ALUMNOS;
    let desactivar_seleccion = false;

    if (form && form.values) {
        const values = form.values;

        if (values.tipo_destinatario === COMUNICADO_ALUMNOS) {
            tipo_destinatario = COMUNICADO_ALUMNOS;
            values.mandar_todos_profesores = false;
            values.destinatarios_profesores = null;
        }
        if (values.tipo_destinatario === COMUNICADO_PROFESORES) {
            tipo_destinatario = COMUNICADO_PROFESORES;
            values.mandar_todos_alumnos = false;
            values.destinatarios_alumnos = null;
        }
        if (values.mandar_todos_alumnos ){
            desactivar_seleccion = true;
            values.destinatarios_alumnos = null;
        }
        if (values.mandar_todos_profesores){
            desactivar_seleccion = true;
            values.destinatarios_profesores = null;
        }
    }
    return {
        tipo_destinatario,
        desactivar_seleccion,
    }
})(ComunicadoForm);

export default ComunicadoForm;
