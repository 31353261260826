import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { Link } from 'react-router-dom';
import { AsyncSelectField, SelectField, renderField } from '../../../Utils/renderField';
import Calendario from '../../../Utils/Calendario/Calendario';
import { api } from '../../../../../utility/api';
import { USER_PROFESOR } from '../../../../../utility/constants';
import moment from 'moment';


const getProfesores = (search) => {
    let params = { tipo_usuario: USER_PROFESOR }
    if (search) {
        params.search = search;
    }
    return api.get("user", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
};

const renderAlumnos = ({ fields, options }) => (
    <div className="row col-lg-8 col-12">
        <div className="col-12 px-0">
            {fields.map((alumno, index) => {
                return(
                    <div key={index}>
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor={`${alumno}.alumno`} className="m-0">{`Alumno no. ${index+1}`}</label>
                        </div>
                        <div className="col-md-12 form-group row">
                            <div className="col-10">
                                <Field
                                    name={`${alumno}.alumno`}
                                    component={SelectField}
                                    options={options}
                                    placeholder="Seleccione un método..."
                                    type="text"
                                    valueKey="id"
                                    labelKey="titulo"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-2 d-flex align-items-center px-0">
                                <button className ="btn-delete" onClick={(e) =>{e.preventDefault(); fields.remove(index)}}><span className="material-icons rosa">close</span></button>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
        <div className="col-12 form-group mt-sm-3 mt-0" style={{paddingLeft: "1rem", paddingRight: "6rem"}}>
            <button type="button" className="btn btn-dark btn-block" onClick={() => fields.push({})}>Agregar</button>
        </div>
    </div>
);

class Form extends Component {

    render() {

        const { handleSubmit, cancelar, loader, idAula } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <div className="form-group grid-container mx-md-5 px-md-5">
                    <div className="col-12">
                        <span className="text-sm azul-claro bold">Datos generales del nuevo curso</span>
                        <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                    </div>
                    <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="nombre" className="m-0">Nombre del Curso</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="nombre" component={renderField} type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="profesor" className="m-0">Profesor</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name="profesor"
                                    loadOptions={getProfesores}
                                    type="text"
                                    component={AsyncSelectField}
                                    valueKey="id"
                                    labelKey="titulo"
                                    isClearable={false}
                                    className="form-control"
                                    onCambio={(value) => {
                                        if (value) {
                                            this.props.obtenerHorarioProfe(value.id);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <span className="text-sm azul-claro bold">Alumnos pendientes de reasignar</span>
                        <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                    </div>
                    <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                        <div className="col-md-12 p-0">
                            <div className="col-md-12 px-0">
                                <FieldArray
                                    name="alumnos"
                                    component={renderAlumnos}
                                    options={this.props.alumnos.filter(item => !item.reasignado)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-group grid-container">
                    <div className="d-flex flex-1 w-100" style={{ overflowX: "auto" }}>
                        <Calendario
                            eventos={this.props.horarios}
                            toolbar={true}
                            onlySemana={true}
                            onSelectSlot={this.props.openModal}
                            onSelectEvent={this.props.eliminarReserva}
                        />
                    </div>
                    <div className="d-none d-lg-flex buttons-box mt-5">
                        <button type="button" onClick={cancelar} className="btn btn-secondary mr-3">Cancelar</button>
                        <button type="submit" className="btn btn-primary ml-3">Guardar</button>
                    </div>
                    <div className="d-flex d-lg-none flex-column mt-5">
                        <button type="button" onClick={cancelar} className="w-100 btn btn-secondary mb-3">Cancelar</button>
                        <button type="submit" className="w-100 btn btn-primary">Guardar</button>
                    </div>
                </div>
            </form>
        );
    }
};

const selector = formValueSelector('HorarioForm');

const HorarioForm = reduxForm({
    form: 'HorarioForm', // a unique identifier for this form
    asyncBlurFields: [],
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: false, // <------ unregister fields on unmount
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()('Campo requerido.'),
            profesor: validators.exists()('Campo requerido.'),
            alumnos: validators.exists()('Campo requerido.'),
        });
    },
})(Form);

const mstp = state => {
    let horario = selector(state, 'horario');

    horario = horario ? horario.length : 0;

    return {
        horario
    }
};

export default connect(mstp, null)(HorarioForm);
