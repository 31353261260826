import React, { Component, cloneElement } from "react";
import Chart from "react-google-charts";
import DatePicker from 'react-datepicker';
import Select, { Async } from "react-select";
import classNames from "classnames";
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import Card from "Utils/Cards/Card";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import { RenderCurrency } from "../../../Utils/renderField/renderReadField";
import HeaderFiltros from "../../../Utils/Grid/HeaderFiltros";

import { api } from "api";
import moment from 'moment';

const getProfesores = (search) => {
    return api
        .get("user", { search, tipo_usuario: 20 })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};

const getAlumnos = (search) => {
    return api
        .get("user", { search, tipo_usuario: 30 })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};


export default class ReporteFacturacionProfesor extends Component {

    componentWillMount() {
        this.props.listarGrafica();
        this.props.listarTabla();
    }



    render() {
        const { loader, data, grafica, page, listarTabla } = this.props;

        return (
            <div className="mb-4 col-12 mt-4">
                <HeaderFiltros
                    filtro1={
                        <DatePicker
                            showPopperArrow={false}
                            isClearable
                            dateFormat="DD/MM/YYYY"
                            placeholderText="Fecha inicio"
                            onChange={this.props.changeFechaInicio}
                            selected={this.props.fecha_inicio}
                        />
                    }
                    filtro2={
                        <DatePicker
                            showPopperArrow={false}
                            isClearable
                            dateFormat="DD/MM/YYYY"
                            placeholderText="Fecha fin"
                            onChange={this.props.changeFechaFin}
                            selected={this.props.fecha_fin}
                        />
                    }
                    filtro3={
                        <Async
                            isClearable={true}
                            backspaceRemovesValue={false}
                            value={this.props.profesor}
                            isSearchable={true}
                            loadOptions={getProfesores}
                            getOptionValue={(option) => option["id"]}
                            getOptionLabel={(option) => option["titulo"]}
                            type="text"
                            onChange={(e) => this.props.changeProfesor(e)}
                            multi={false}
                            autoload={false}
                            cache={false}
                            className={classNames(
                                "react-select-container w-100"
                            )}
                            defaultOptions={true}
                            placeholder="Filtrar por profesor.."
                        />
                    }
                    filtro4={
                        <Async
                            isClearable={true}
                            backspaceRemovesValue={false}
                            value={this.props.alumno}
                            isSearchable={true}
                            loadOptions={getAlumnos}
                            getOptionValue={(option) => option["id"]}
                            getOptionLabel={(option) => option["titulo"]}
                            type="text"
                            onChange={(e) => this.props.changeAlumno(e)}
                            multi={false}
                            autoload={false}
                            cache={false}
                            className={classNames(
                                "react-select-container w-100"
                            )}
                            defaultOptions={true}
                            placeholder="Filtrar por alumno.."
                        />
                    }
                />
                <Card>
                    <LoadMask loading={loader} blur>
                        <div className="d-flex flex-column flex-1 mt-3 mb-2">
                            <div className="flex-1">
                                <div className="row col-12 mx-0">
                                    <span className="text-sm azul-claro bold">
                                        Pagos realizados por alumno
                                    </span>
                                    <div
                                        className="d-flex w-100 bg-gris-claro"
                                        style={{ height: "3px" }}
                                    />
                                </div>
                            </div>
                            <div className="flex-1">
                                <Chart
                                    width={"100%"}
                                    height={"40vh"}
                                    chartType="ColumnChart"
                                    loader={<div>Loading Chart</div>}
                                    data={grafica}
                                    options={{
                                        chartArea: { width: "80%" },
                                        legend: { position: 'none' },
                                        bar: { groupWidth: "35%" },
                                        colors: ["#53A513", "#366AED"],
                                        hAxis: {
                                            title: "",
                                            minValue: 0,
                                        },
                                        vAxis: {
                                            title: "Total recaudado",
                                            baselineColor: "#1C375E",
                                            titleTextStyle: {
                                                color: "#1C375E",
                                                bold: true,
                                                italic: false,
                                            },
                                            textStyle: {
                                                color: "#1C375E",
                                                bold: false,
                                                italic: false,
                                            },
                                        },
                                    }}
                                    rootProps={{ "data-testid": "4" }}
                                />
                            </div>
                        </div>
                    </LoadMask>
                    <div className="mt-3 px-lg-3">
                        <div className="mb-4 col-12 mt-4">
                            <Card>
                                <div className='d-flex flex-1 justify-content-end align-items-center mb-3'>
                                    <div className='text-right'>
                                        <button type='button' onClick={this.props.descargarExcel} className="btn btn-primary">Descargar Excel</button>
                                    </div>
                                </div>
                                <Grid
                                    hover
                                    striped
                                    data={data}
                                    loading={loader}
                                    onPageChange={listarTabla}
                                    page={page}
                                >
                                    <TableHeaderColumn
                                        dataField="id"
                                        dataAlign="center"
                                        isKey
                                        width='5%'
                                        dataFormat={cell => ''}
                                    />
                                    <TableHeaderColumn
                                        dataField="creado"
                                        dataFormat={cell => moment(cell).format("DD/MM/YYYY")}
                                    >
                                        Fecha
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="serie_factura"
                                        dataFormat={(cell, row) => `${cell.prefijo}-${row.correlativo}`}
                                    >
                                        No. Factura
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="profesor"
                                        dataFormat={cell => cell ? cell.titulo : '----'}
                                    >
                                        Profesor
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="alumno"
                                        dataFormat={cell => cell ? cell.titulo : '----'}
                                    >
                                        Alumno
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="total"
                                        dataAlign='right'
                                        tdStyle={{paddingRight: '2.5rem'}}
                                        thStyle={{paddingRight: '2.5rem'}}
                                        dataFormat={cell => <RenderCurrency value={cell} />}
                                    >
                                        Monto
                                    </TableHeaderColumn>
                                </Grid>
                            </Card>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
}
