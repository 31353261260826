import React, { Component } from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import DatePicker from 'react-datepicker';
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";

import moment from 'moment';

export default class Bitacora extends Component{

    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar: onPageChange, onSortChange } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes

        return(
            <div className="mb-4 col-12 mt-4">
                <Header
                    {...this.props}
                    filtro3={
                        <DatePicker
                            showPopperArrow={false}
                            isClearable
                            dateFormat="DD/MM/YYYY"
                            placeholderText="Fecha inicial"
                            onChange={(value) => {this.props.changeFechaInicio(value)}}
                            selected={this.props.fecha_inicio}
                        />
                    }
                    filtro2={
                        <DatePicker
                            showPopperArrow={false}
                            isClearable
                            dateFormat="DD/MM/YYYY"
                            placeholderText="Fecha final"
                            onChange={(value) => {this.props.changeFechaFin(value)}}
                            selected={this.props.fecha_fin}
                        />
                    }
                    filtro1={<div></div>}
                />
                <Card>
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                    page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='15%'
                            dataFormat={standardActions({ ver: "bitacora"})}
                        >Acciones</TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="creado"
                            dataSort
                            dataFormat={(cell, row) => moment(cell).format("DD/MM/YYYY HH:mm")}
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="usuario"
                            dataFormat={(cell, row) => cell ? cell.titulo : ''}

                        >
                            USUARIO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="accion"
                            width='40%'
                        >
                            ACCIÓN
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
