import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/areaFinanciera/facturas';
import ListarFacturas from './ListarFacturas';


const ms2p = (state) => {
    return {
        ...state.facturas,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListarFacturas);
