import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { SelectField, renderTimeField, renderField } from '../../../Utils/renderField';
import { DIAS_SEMANA } from '../../../../../utility/constants';
import { api } from 'api';


const EventoModalForm = (props) => {
    const { handleSubmit, cancelar } = props;
    return (
        <form name="AsignacionForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="form-group grid-container mx-md-5 px-md-5">
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="d-flex flex-row align-items-center w-100">
                        <div className="flex-1">
                            <label htmlFor="dia" className="m-0">Día</label>
                        </div>
                        <div className="flex-1">
                            <Field name="dia" component={SelectField} options={DIAS_SEMANA} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center w-100 mt-3">
                        <div className="flex-1">
                            <label htmlFor="hora_inicio" className="m-0">Hora inicio</label>
                        </div>
                        <div className="flex-1">
                            <Field name="hora_inicio" component={renderTimeField}/>
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center w-100 mt-3">
                        <div className="flex-1">
                            <label htmlFor="hora_fin" className="m-0">Hora fin</label>
                        </div>
                        <div className="flex-1">
                            <Field name="hora_fin" component={renderTimeField}/>
                        </div>
                    </div>
                </div>
                <div className="buttons-box mt-5">
                    <button type="button" className="btn btn-secondary mr-5" onClick={cancelar}>Cancelar</button>
                    <button type="submit" className="btn btn-primary">Guardar</button>
                </div>
            </div>
        </form>
    )
}

export default reduxForm({
    form: 'AsignacionEventoForm',
    asyncBlurFields: [],
    validate: data => {
        return validate(data, {
            'dia': validators.exists()('Campo requerido.'),
            'hora_inicio': validators.exists()('Campo requerido.'),
            'hora_fin': validators.exists()('Campo requerido.'),
        })
    }
})(EventoModalForm);


