import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";

export default class ListarLogos extends Component{
    componentWillMount() {
        const { listarLogos } = this.props;
        listarLogos();
    }

    render() {
        const { listarLogos: onPageChange, onSortChange, eliminarLogo } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return(
            <div className="mb-4 col-12 mt-4">
                <Header to="logo/crear" textBtn="Nuevo Logo" {...this.props} />
                <Card>
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                    page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='20%'
                            dataFormat={standardActions({ editar: "logo", eliminar: eliminarLogo})}
                        />
                        <TableHeaderColumn
                            dataField="titulo"
                            dataSort
                        >
                            NOMBRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="ubicacion_text"
                        >
                            Ubicación del logo
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
