import React, { useState, Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderTextArea, renderNumber } from '../../../Utils/renderField';


const ConfigForm = (props) => {
    const { handleSubmit } = props;
    return (
        <form name="ConfigComercialForm" onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="row col-12 p-0 mb-4">
                    <span className="text-sm azul-claro bold">Datos estadísticos</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="alumnos_activos" className="m-0">Alumnos activos</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="alumnos_activos" component={renderNumber}  className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="titulos_ortogados" className="m-0">Titulos otorgados</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="titulos_ortogados" component={renderNumber}  className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="profesores" className="m-0">Profesores</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="profesores" component={renderNumber}  className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="cursos_activos" className="m-0">Cursos activos</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="cursos_activos" component={renderNumber}  className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="experiencia" className="m-0">Experiencias</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="experiencia" component={renderNumber}  className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="reconocimientos" className="m-0">Reconocimientos</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="reconocimientos" component={renderNumber}  className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row col-12 p-0 mb-4">
                    <span className="text-sm azul-claro bold">Mensaje de bienvenida</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="bienvenida_titulo" className="m-0">Titulo Mensaje de Bienvenida</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="bienvenida_titulo" component={renderTextArea} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="bienvenida_contenido" className="m-0">Cuerpo Mensaje de Bienvenida</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="bienvenida_contenido" component={renderTextArea} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="buttons-box mt-5">
                    {/* <Link className="btn btn-secondary mr-5" to="/alumnos">Cancelar</Link> */}
                    <button type="submit" className="btn btn-primary">Guardar</button>
                </div>
            </div>
        </form>
    );
};


export default reduxForm({
    form: 'ConfigComercialForm', // a unique identifier for this form
})(ConfigForm);
