import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField, renderPasswordField, renderTextArea, renderTimeField, SelectField, renderSwitch } from '../../../Utils/renderField/renderField';
import { METODOS_PAGO } from '../../../../../utility/constants';

let AlumnoForm = props => {
    const { handleSubmit, editando, mostrar_pass, changeVerPassword } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombres" className="m-0">Nombres*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombres" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="apellidos" className="m-0">Apellidos*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="apellidos" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="email" className="m-0">Email*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="email" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0 ">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="password" className="m-0">{editando ? 'Nueva Contraseña' : 'Contraseña*'}</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                name="password"
                                component={renderPasswordField}
                                type={mostrar_pass ? "text" : "password"}
                                className="form-control"
                                ver_password={mostrar_pass}
                                change_ver_password={changeVerPassword}
                            />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="telefono" className="m-0">Telefono*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="telefono" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0 ">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="direccion" className="m-0">Dirección*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="direccion" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="poblacion" className="m-0">Población (Ciudad o lugar de vivienda)*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="poblacion" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0 ">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="codigo_postal" className="m-0">Código Postal*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="codigo_postal" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="horario_contacto" className="m-0">Horario Preferido</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="horario_contacto" component={renderTimeField} />
                        </div>
                    </div>
                    <div className="col-md-6 p-0 ">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nif" className="m-0">Facturación (CIF, DNI O NIE)*</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nif" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="metodo_pago" className="m-0">Método de pago</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                name="metodo_pago"
                                component={SelectField}
                                options={METODOS_PAGO}
                                placeholder="Seleccione un método..."
                                type="text"
                                className="form-control"
                            />
                        </div>
                    </div>
                    {(editando) && (
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="metodo_pago" className="m-0">Usuario activo</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name="is_active"
                                    label=""
                                    component={renderSwitch}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="objetivos" className="m-0">Objetivos de Aprendizaje</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="objetivos" component={renderTextArea} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="buttons-box mt-5">
                    <Link className="btn btn-secondary mr-5" to="/alumnos">Cancelar</Link>
                    <button type="submit" className="btn btn-primary">Guardar</button>
                </div>
            </div>
        </form>
    )
};
AlumnoForm = reduxForm({
    form: 'EditarAlumnoForm',
    asyncBlurFields: [],
    validate: data => {
        return validate(data, {
            'nombres': validators.exists()('Campo requerido.'),
            'apellidos': validators.exists()('Campo requerido.'),
            'email': validators.exists()('Campo requerido.'),
            'password': validators.exists()('Campo requerido.'),
            'telefono': validators.exists()('Campo requerido.'),
            'direccion': validators.exists()('Campo requerido.'),
            'poblacion': validators.exists()('Campo requerido.'),
            'codigo_postal': validators.exists()('Campo requerido.'),
            'nif': validators.exists()('Campo requerido.'),
        })
    }
})(AlumnoForm);
// const selector = formValueSelector('EditarAlumnoForm');
// AlumnoForm = connect(state => {
//     const a = 'test';
//     return {
//         a
//     };
// })(AlumnoForm);

export const AlumnoUpdateForm = reduxForm({
    form: 'EditarAlumnoForm',
    asyncBlurFields: [],
    validate: data => {
        return validate(data, {
            'nombres': validators.exists()('Campo requerido.'),
            'apellidos': validators.exists()('Campo requerido.'),
            'email': validators.exists()('Campo requerido.'),
            'telefono': validators.exists()('Campo requerido.'),
            'direccion': validators.exists()('Campo requerido.'),
            'poblacion': validators.exists()('Campo requerido.'),
            'codigo_postal': validators.exists()('Campo requerido.'),
            'nif': validators.exists()('Campo requerido.'),
        })
    }
})(AlumnoForm);

export default AlumnoForm;
