import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";


export default class NivelesArchivosGrid extends Component{

    componentWillMount() {
        const { listarNiveles, page } = this.props;
        listarNiveles(page);
    }

    render() {
        const { listarNiveles: onPageChange, onSortChange } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return(
            <div className="mb-4 col-12 mt-4">
                <Header to="/biblioteca_archivos/crear" textBtn="Agregar archivo" {...this.props} />
                <Card>
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                    page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='20%'
                            dataFormat={standardActions({ editar: "nivel" })}
                        />
                        <TableHeaderColumn
                            dataField="asignatura"
                            dataFormat={(cell) => cell.nombre}
                        >
                            NOMBRE DE ASIGNATURA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre"
                            dataSort
                        >
                            NIVEL
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="archivos"
                            dataAlign="center"
                        >
                            CANTIDAD DE ARCHIVOS
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
