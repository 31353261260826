import { api } from "api";
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SearchInput from './SearchInput';
import classNames from "classnames";
import Select from "react-select";
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import CardEmpty from "Utils/Cards/CardEmpty";

export default class Header2 extends Component {
    render() {
        const { filtro1, filtro2, filtro3 } = this.props;
        const { search, to, textBtn, textBtn2, disabledBtn2, btn2Click, searchChange, placeholder} = this.props;
        return (
            <div className="d-flex w-100">
                <div className="d-flex flex-1 flex-column flex-lg-row row col-12 mb-3 px-1">
                    <div className="d-flex flex-row justify-content-center justify-content-lg-start flex-2 mx-1">
                        {(to !== undefined) && (
                            <Link to={to} className="btn btn-primary mb-3">{textBtn}</Link>
                        )}
                         {(textBtn2 !== undefined) && (
                            <button onClick={btn2Click} disabled={disabledBtn2 ? disabledBtn2 : false} className="btn btn-secondary ml-2 mb-3">{textBtn2}</button>
                        )}
                    </div>
                    <div className="d-flex flex-column flex-1 mx-1 my-2 my-lg-0">
                        {filtro2 && filtro2}
                    </div>
                    <div className="d-flex flex-column flex-1 mx-1 my-2 my-lg-0">
                        {filtro1 && filtro1}
                    </div>
                    <div className="d-flex flex-column flex-2 mx-1">
                        {(search !== undefined) && (
                            <SearchInput search={search} searchChange={searchChange} placeholder={placeholder} />
                        )}
                    </div>
                </div>
            </div>
        )
    }
}
