import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/administradores';
import AdminsList from './Admins';


const ms2p = (state) => {
  return {
    ...state.administradores,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(AdminsList);
