import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import _ from "lodash";
import moment from 'moment';
import { CELERY_PENDIENTE, CELERY_EXITO, CELERY_ERROR } from '../../../utility/constants';

const LOADER = 'LOADER_FACTURAS';
const DATA = 'DATA_FACTURAS';
const ITEM_DATA = 'ITEM_FACTURAS';
const PAGE = 'PAGE_FACTURAS';
const ORDERING = 'ORDERING_FACTURAS';
const SEARCH = 'SEARCH_FACTURAS';
const ESTADO = 'ESTADO_FACTURAS';
const TIPO = 'TIPO_FACTURAS';
const CONFIG = 'CONFIG_FACTURAS';
const TASK_ID = 'TASK_ID_FACTURAS';
const TASK = 'TASK_FACTURAS';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (type, data) => ({
    type,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

const setTaskId = (task_id) => ({
    type: TASK_ID,
    task_id,
});

const setTask = (task) => ({
    type: TASK,
    task,
});

// ------------------------------------
// Actions
// ------------------------------------

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().facturas;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    if (resource.tipo)
        params.tipo_factura = resource.tipo.value;
    if (resource.estado)
        params.anulada = resource.estado.value;
    dispatch(setLoader(true));
    api.get('facturas', params).then((response) => {
        dispatch(setData(DATA, response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const obtenerConfigPlataforma = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get('conf_plataforma').then((response) => {
        dispatch(setData(CONFIG ,response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post('facturas', data).then((response) => {
        NotificationManager.success('Factura generada', 'Éxito', 3000);
        window.open(response.documento, '_blank')
        dispatch(push('/facturas'));
    }).catch((error) => {
        let mensaje = "Error en la creación";
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const anular = id => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`facturas/${id}/anular_factura`, {}).then((response) => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().facturas.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const changeEstado = value => (dispatch) => {
    dispatch(setData(ESTADO, value));
    dispatch(listar());
}

const changeTipo = value => (dispatch) => {
    dispatch(setData(TIPO, value));
    dispatch(listar());
}

const SolicitarDescargaFacturas = () => (dispatch, getStore) => {
    const resource = getStore().facturas;
    const params = {};
    if (resource.tipo)
        params.tipo_factura = resource.tipo.value;
    if (resource.estado)
        params.anulada = resource.estado.value;
    api.get('facturas/solicitar_descargar_facturas', params).then((response) => {
        dispatch(setTaskId(response));
    }).catch(() => {
    }).finally(() => {
    });
};

const descargarFacturas = (task_id) => (dispatch, getStore) => {
    const resource = getStore().facturas;
    const params = {};
    if (resource.task_id) {
        params.task_id = resource.task_id
        api.get('reportes/descargar_facturas', params).then((response) => {
            if (response.estado === CELERY_PENDIENTE) {
                dispatch(setTask(response));
                NotificationManager.warning(`Faltan ${response.facturas_pendientes} factura(s) por comprimir.`, 'INFORMACIÓN', 5000);
            }
            if (response.estado === CELERY_EXITO) {
                dispatch(setTask(response));
                dispatch(setTaskId(null));
                NotificationManager.success('Se terminaron de comprimir las facturas, la descarga comenzara en breve.', 'ÉXITO', 5000);
                window.location.replace(`/media/archivos_zip/${response.nombre_archivo}`)
            }
            if (response.estado === CELERY_ERROR) {
                dispatch(setTask(response));
                dispatch(setTaskId(null));
                NotificationManager.error('Hubo un error al descarga el archivo', 'ERROR', 5000);
            }
        }).catch(() => {
        }).finally(() => {
        });
    }
};

export const actions = {
    listar,
    obtenerConfigPlataforma,
    crear,
    anular,
    searchChange,
    onSortChange,
    changeEstado,
    changeTipo,
    // Descarga de facturas
    SolicitarDescargaFacturas,
    descargarFacturas,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [ESTADO]: (state, { data }) => {
        return {
            ...state,
            estado: data,
        };
    },
    [TIPO]: (state, { data }) => {
        return {
            ...state,
            tipo: data,
        };
    },
    [CONFIG]: (state, { data }) => {
        return {
            ...state,
            config: data,
        };
    },
    [TASK_ID]: (state, { task_id }) => {
        return {
            ...state,
            task_id,
        };
    },
    [TASK]: (state, { task }) => {
        return {
            ...state,
            task,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    estado: null,
    tipo: null,
    config: null,
    task_id: null,
    task: {},
};

export default handleActions(reducers, initialState);
