import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import ColaboradorForm from './ColaboradorForm';

export default class ColaboradorCrear extends Component {

    state = {
        ver_password: false,
        option: true,
    }

    change_ver_password = () => {
        this.setState({ver_password: !this.state.ver_password});
    };

    changeOption = (value) => {
        this.setState({option:value})
    }

    crear = (values) => {
        const data = {...values};
        data.nuevo = this.state.option;
        if (!data.nuevo){
            data.usuario = data.usuario.id;
        }
        this.props.crear(data);
    }

    render() {
        const { updateData, loader } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm col={8}>
                    <LoadMask loading={loader} blur>
                        <ColaboradorForm
                            onSubmit={this.crear}
                            change_ver_password={this.change_ver_password}
                            ver_password={this.state.ver_password}
                            option={this.state.option}
                            changeOption={this.changeOption}
                        />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
