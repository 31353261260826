import React, {Component, Fragment} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";
import {formatActivo} from "Utils/renderField/renderReadField";
import DatePicker from 'react-datepicker';
import moment from 'moment';

export default class Aprobados extends Component{
    componentWillMount() {
        const { listarAprobados, page_aprobados } = this.props;
        listarAprobados(page_aprobados);
    }

    onPageChangeAprobados = (page_aprobados) => {
        this.props.listarAprobados(page_aprobados);
    };

    render() {
        //Funciones
        const { listarAprobados:
            onPageChangeAprobados,
            onSortChangeAprobados,
            eliminar,
            searchChangeAprobados,
            filtroMesInicioAprobados,
            filtroMesFinAprobados,
        } = this.props;

        //Constantes
        const {
            data_aprobados,
            loader_aprobados,
            page_aprobados,
            search_aprobados,
            mes_inicio_filtro_aprobados,
            mes_fin_filtro_aprobados,
        } = this.props;
        return(
            <div className="mb-4 col-12 mt-4">
                <Header
                    {...this.props}
                    searchChange={searchChangeAprobados}
                    search={search_aprobados}
                    filtro2={
                        <DatePicker
                            showPopperArrow={false}
                            isClearable
                            dateFormat="DD/MM/YYYY"
                            placeholderText="Fecha inicio"
                            onChange={filtroMesInicioAprobados}
                            selected={mes_inicio_filtro_aprobados}
                        />
                    }
                    filtro1={
                        <DatePicker
                            showPopperArrow={false}
                            isClearable
                            dateFormat="DD/MM/YYYY"
                            placeholderText="Fecha fin"
                            onChange={filtroMesFinAprobados}
                            selected={mes_fin_filtro_aprobados}
                        />
                    }
                />
                <Card>
                    <Grid hover striped
                        data={data_aprobados}
                        loading={loader_aprobados}
                        onPageChange={this.onPageChangeAprobados}
                        onSortChange={onSortChangeAprobados}
                        page={page_aprobados}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='10%'
                            dataFormat={standardActions({ ver: "comunicado"})}
                        >
                            ACCIONES
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="creado"
                            dataSort
                            dataFormat={(cell) => cell ? moment(cell).format('DD/MM/YYYY') : ""}
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="titulo"
                            dataSort
                        >
                            TITULO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="creador"
                            dataFormat={ (cell, row) => {
                                return (
                                    <div>
                                        {
                                            cell && (
                                                <Fragment>
                                                    <span>{cell.titulo ? `${cell.titulo}` : ""}</span>
                                                    <br />
                                                </Fragment>
                                            )
                                        }
                                        {
                                            cell && (
                                                <Fragment>
                                                    <span>{cell.tipo_usuario_text ? cell.tipo_usuario_text : ""}</span>
                                                    <br />
                                                </Fragment>
                                            )
                                        }
                                    </div>
                                )
                            }}
                        >
                            USUARIO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipo_destinatario_text"
                            dataSort
                            dataFormat={ (cell, row) => {
                                return (
                                    <div>
                                        {
                                            cell && (
                                                <Fragment>
                                                    <span>{cell ? cell : ""}</span>
                                                    <br />
                                                </Fragment>
                                            )
                                        }
                                        {
                                            cell && (
                                                <Fragment>
                                                    <span>{row.tipo_mensaje ? row.tipo_mensaje : ""}</span>
                                                    <br />
                                                </Fragment>
                                            )
                                        }
                                    </div>
                                )
                            }}
                        >
                            DESTINO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="mensaje"
                            dataSort
                            dataFormat={(cell) => cell ? <span className="parrafo-corto">{cell}</span> : ""}
                        >
                            MENSAJE
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
