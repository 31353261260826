import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './CuponForm';
import EditarCuponForm from './CuponForm';
import {NotificationManager} from "react-notifications";


export default class CuponCrear extends Component {
    state = {
        editar: false
    };

    componentWillMount(){
        if(this.props.match.params.id){
            this.props.leer(this.props.match.params.id);
            this.setState({
                editar: true,
            });
        }
    };

    crear = (data) => {
        const cantidad = data.cantidad_usos ? parseInt(data.cantidad_usos) : parseInt(data.cantidad_a_generar);
        let cantidad_mayor = 0;
        if (data.ganancias_colaborador) {
            for (const item of data.ganancias_colaborador) {
                if (cantidad_mayor < parseInt(item.cantidad_estudiantes))
                    cantidad_mayor = parseInt(item.cantidad_estudiantes)
            }

            if (cantidad_mayor > cantidad) {
                NotificationManager.error("No se puede exceder de la cantidad de cupones a generar para las ganancias del colaborador", 'ERROR');

            } else {
                this.props.crear(data);
            }
        } else {
            NotificationManager.error("Ingrese ganancias para el colaborador", 'ERROR');
        }
    }

    actualizar = (data) => {
        this.props.editar(data, this.props.match.params.id)
    };

    componentWillUnmount(){
        this.props.cleanForm();
    }

    render() {
        const { crear, update } = this.props;
        const { updateData, loader } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm>
                    <LoadMask loading={loader} blur>
                        {
                        this.state.editar ?
                            <EditarCuponForm
                                onSubmit={this.actualizar}
                                updateData={updateData}
                                editando={true}
                            />
                            :
                            <Form
                                onSubmit={this.crear}
                            />
                        }
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
